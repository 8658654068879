import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ReportBankReconcilliationService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/ReportBankReconcilliationService";


export interface ReportBankReconcilliation {
    currentPage: number;
    maxReconciliationId: number;
    totalRows: number;
    listingData: [];
};
// Define the state type for Checklist
type ReportBankReconcilliationState = {
    reportBankReconcilliations: ReportBankReconcilliation;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ReportBankReconcilliationState = {
    reportBankReconcilliations: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllReportBankReconcilliation = createAsyncThunk(
    "fetchAllReportBankReconcilliation",
    async (data: any, thunkAPI) => {
        try {
            const response = await ReportBankReconcilliationService.GetAllReportBankReconcilliation(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const ReportBankReconcilliationSlice = createSlice({
    name: "reportBankReconcilliation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReportBankReconcilliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllReportBankReconcilliation.fulfilled, (state, action: PayloadAction<ReportBankReconcilliation>) => {
                state.status = "idle";
                state.reportBankReconcilliations = action.payload;
            })
            .addCase(fetchAllReportBankReconcilliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default ReportBankReconcilliationSlice.reducer;
