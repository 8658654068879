import { yupResolver } from "@hookform/resolvers/yup";
import { error } from "console";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { object, string, number } from "yup";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { fetchAllBranch } from "../../../store/slice/Administrator/BranchManagementSlice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";


function AddBranchStepOne({ onSubmitStep, initialData, onCancel }: any) {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { branches } = useSelector((state: RootState) => state.branch);

    const validationSchema = object().shape({
        branchName: string()
            .required('Branch Name is required'),
        noOfEmployees: number()
            .typeError('Number of employees must be a number')
            .required('Employees is required')
            .positive('Number of employees must be a positive number')
            .integer('Number of employees must be an integer'),
        noOfLicense: number()
            .typeError('Number of license must be a number')
            .required('License is required')
            .positive('Number of license must be a positive number')
            .integer('Number of license must be an integer'),
        branchAddress: string(),
        branchPostCode: string()
            .required('Branch PostCode is required'),
        dxaddressNumber: string(),
        dxaddressExchange: string(),

    })

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };
    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllBranch());
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);

    useEffect(() => {
        const id = searchParams.get("id");
        if (id != null && branches) {
            const branchUpdate = branches.find((branch: any) => branch.branchId === Number(id));
            if (branchUpdate) {
                reset(branchUpdate);
            } else {
                console.warn(`branches with ID ${id} not found.`);
            }
        }
    }, [searchParams, setValue, branches]);


    return (

        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div>
                <div className="grid grid-cols-3 gap-x-10">
                    <div>
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Branch Name</h3>
                        <div className="grid grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Branch Name <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.branchName ? 'is-invalid' : ''}`} {...register('branchName')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.branchName?.message?.toString()}</div>
                            </div>

                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Number of employees <span className="text-red-500">*</span>
                                </label>
                                <input type="numbre" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.noOfEmployees ? 'is-invalid' : ''}`} {...register('noOfEmployees')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.noOfEmployees?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Number of License <span className="text-red-500">*</span>
                                </label>
                                <input type="number" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.noOfLicense ? 'is-invalid' : ''}`} {...register('noOfLicense')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.noOfLicense?.message?.toString()}</div>
                            </div>

                        </div>
                    </div>

                    <div>
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Branch Contact Details</h3>
                        <div className="grid grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address <span className="text-red-500">*</span>
                                </label>
                                <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`} {...register('branchAddress')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Postal Code <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.branchPostCode ? 'is-invalid' : ''}`} {...register('branchPostCode')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.branchPostCode?.message?.toString()}</div>
                            </div>

                        </div>
                    </div>

                    <div>
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">DX Address</h3>
                        <div className="grid grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Number
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('dxaddressNumber')} />

                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Exchange
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('dxaddressExchange')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddBranchStepOne };
