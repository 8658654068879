import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeBankDepositService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/BankDepositService";



export interface OfficeBankDeposit {
    bankDepositNumber: number;
    bankDepositId?: number;
    depositToBankAccountId: number;
    authorisedBy: number;
    paymentMethodId: number
    paymentReference: string;
    receivedFrom: string;
    transactionDate: string
    comments: string;
    amounts: Amounts[];
};
export interface Amounts {
    accountId: number;
    description: string;
    amount: string
}

export interface SearchOfficeBankDepositResult {
    listingData: any[];
    currentPage: number;
    recordPerPage: number;
    totalRows: number

};


// export interface OfficeBankDepositData {
//     listingData: [],
//     page: number,
  
// }
// Define the state type for Checklist
type OfficeBankDepositState = {
    searchOfficeBankDepositResult: SearchOfficeBankDepositResult;
    officeBankDeposits: OfficeBankDeposit[];
   // officeBankDepositData: OfficeBankDepositData;
    status: "idle" | "loading" | "failed";
    error: string | null;
    officeBankDepositDataById: any | null;
};

// Initial state
const initialState: OfficeBankDepositState = {
    searchOfficeBankDepositResult: null!,
    officeBankDeposits: [],
   // officeBankDepositData: null!,
    status: "idle",
    error: null,
    officeBankDepositDataById: null,
};

// Async thunks for CRUD operations
export const fetchAllOfficeBankDeposit = createAsyncThunk(
    "fetchAllOfficeBankDeposit",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeBankDepositService.GetAllOfficeBankDeposit(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllOfficeBankDepositById = createAsyncThunk(
    "fetchAllOfficeBankDepositById",
    async (id: number, thunkAPI) => {
        try {
            const response = await OfficeBankDepositService.GetOfficeBankDepositById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOfficeBankDeposit = createAsyncThunk(
    "addOfficeBankDeposit",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeBankDepositService.PostOfficeBankDeposit(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOfficeBankDeposit = createAsyncThunk(
    "updateOfficeBankDeposit",
    async ({ id, data }: { id: number, data: OfficeBankDeposit }, thunkAPI) => {
        try {
            const response = await OfficeBankDepositService.PutOfficeBankDeposit(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteOfficeBankDeposit = createAsyncThunk(
    "deleteOfficeBankDeposit",
    async (id: number, thunkAPI) => {
        try {
            await OfficeBankDepositService.deleteOfficeBankDeposit(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeBankDepositSlice = createSlice({
    name: "officeBankDeposit",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeBankDeposit.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeBankDeposit.fulfilled, (state, action: PayloadAction<SearchOfficeBankDepositResult>) => {
                state.status = "idle";
                state.searchOfficeBankDepositResult = action.payload;
            })
            .addCase(fetchAllOfficeBankDeposit.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchAllOfficeBankDepositById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeBankDepositById.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.officeBankDepositDataById = action.payload;
            })
            .addCase(fetchAllOfficeBankDepositById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addOfficeBankDeposit.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOfficeBankDeposit.fulfilled, (state, action: PayloadAction<OfficeBankDeposit>) => {
                state.status = "idle";
                state.officeBankDeposits.push(action.payload);
            })
            .addCase(addOfficeBankDeposit.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOfficeBankDeposit.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOfficeBankDeposit.fulfilled, (state, action: PayloadAction<OfficeBankDeposit>) => {
                state.status = "idle";
                const index = state.officeBankDeposits.findIndex(t => t.bankDepositNumber === action.payload.bankDepositNumber);
                if (index !== -1) {
                    state.officeBankDeposits[index] = action.payload;
                }
            })
            .addCase(updateOfficeBankDeposit.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteOfficeBankDeposit.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOfficeBankDeposit.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.officeBankDeposits = state.officeBankDeposits.filter(t => t.bankDepositNumber !== action.payload);
            })
            .addCase(deleteOfficeBankDeposit.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default OfficeBankDepositSlice.reducer;
