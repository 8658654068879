import { useEffect, useRef, useState } from "react";
import { AddFundTransfer } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddFundTransfer";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteOfficeFundTransfer,
  fetchAllOfficeFundTransfer,
} from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/OfficeFundTransferSlice";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEye } from "react-icons/fa";
import DeleteModal from "../Modal/DeleteModal";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { Pagination } from "../Pagination";


function FundTransfer() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [officeFundTransferDatas, setOfficeFundTransferDatas] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const  searchOfficeFundTransferResult  = useSelector(
    (state: RootState) => state.officeFundTransfer.officeFundTransferData
  );
  
  const [currentOfficeFundTransfer, setCurrentOfficeFundTransfer] = useState<any[]>([]);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [officeFundTransferId, setOfficeFundTransferId] = useState<number>(0);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const {accountSearchNominalAccounts }= useSelector((state: RootState) => state.accountSearchNominalAccount);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [toLedgerList, setToLedgerList] = useState<any[]>([]);
  const [fromLedgerList, setFromLedgerList] = useState<any[]>([]);
 const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '', 
    endDate: '',   
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    toLedgerId: 0,
    fromLedgerId: 0,
  });
  
  const optiondata = [
    {
      name: "From Ledger",
      options: fromLedgerList
    },
    {
      name: "To Ledger",
      options: toLedgerList
    },
  ]

  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        toBank: filters.toLedgerId,
        fromBank: filters.fromLedgerId,
      }
      dispatch(fetchAllOfficeFundTransfer(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    // if (status === "failed" && !errorShownRef.current) {
    //   toast.error(error);
    //   errorShownRef.current = true;
    // }
  }, [dispatch, isOnAddedOrUpdate,filters]);

  useEffect(() => {
    if (searchOfficeFundTransferResult != null && searchOfficeFundTransferResult.listingData !== null) {
      setCurrentOfficeFundTransfer(searchOfficeFundTransferResult.listingData);
      setTotalRecords(searchOfficeFundTransferResult.totalRows);
    }
  }, [searchOfficeFundTransferResult]);

   const onPageChange =(page: number) =>{
        setCurrentPage(page);
        let searchdata= {
          keyword: "",
          currentPage: page,
          recordsPerPage: totalPage
        }
        dispatch(fetchAllOfficeFundTransfer(searchdata))
          .then(() => setInitialLoading(false))
          .catch(() => setInitialLoading(false));
    }

    useEffect(() => {
      if (accountSearchNominalAccounts !== null && accountSearchNominalAccounts.accounts.length > 0) {
        let data = accountSearchNominalAccounts.accounts.map((item: any) => ({
          value: item.accountId,
          label: `${item.accountName}`,
        }));
        data.unshift({ value: 0, label: "ToLedger" });
        setToLedgerList(data);
      }
    }, [accountSearchNominalAccounts]);

    useEffect(() => {
      if (accountSearchNominalAccounts !== null && accountSearchNominalAccounts?.accounts?.length > 0) {
        let data = accountSearchNominalAccounts.accounts.map((item: any) => ({
          value: item.accountId,
          label: `${item.accountName}`,
        }));
        data.unshift({ value: 0, label: "FromLedger" });
        setFromLedgerList(data);
      }
    }, [accountSearchNominalAccounts, ]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };
  const onDeleteCall = () => {
    try {
      dispatch(deleteOfficeFundTransfer(officeFundTransferId)).unwrap();
      setCurrentOfficeFundTransfer((prevData) => prevData.filter(item => item.officeFundTransferId !== officeFundTransferId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted office fundTransfer");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };



  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OAFundTransfer"
        getData={officeFundTransferDatas}
        title="Fund Transfer"
      />
    );
  }

  if (openModal) {
    return (
      <AddFundTransfer
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        officeFundTransfer={officeFundTransferDatas}
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={officeFundTransferId}
            title="Fund Transfer"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Fund Transfer"
            buttonName="Transfer Fund"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
        <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
        </div>
          {/* <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="From Ledger" />
              <CheckBoxFilter title="To Ledger" />
            </div>
          </div> */}
        
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto h-[calc(100vh-10px)]">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Ref
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  From Ledger
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  To Ledger
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 text-center w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentOfficeFundTransfer.map(
                (officeFundTransfer: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    TF{officeFundTransfer.referenceNumber}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {officeFundTransfer.description}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {officeFundTransfer.accountName}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {officeFundTransfer.toAccountName}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatCurrency(officeFundTransfer.amount)}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <div className="flex gap-3 w-full">
                        <FaEye
                          className="fill-gray-500 w-6 h-6"
                          onClick={() => {
                            setOpenViewDrawer(true);
                            setIsView(true);
                            setIsEdit(false);
                            setOfficeFundTransferDatas(officeFundTransfer);
                          }}
                        />
                        <FaEdit
                          className="fill-blue-500 w-6 h-6"
                          onClick={() => {
                            setOpenModal(true);
                            setIsEdit(true);
                            setOfficeFundTransferDatas(officeFundTransfer);
                          }}
                        />
                        <RiDeleteBin5Fill
                          className="fill-red-600 w-6 h-6"
                          onClick={() => {
                            setOpenDelModal(true);
                            setOfficeFundTransferId(
                              officeFundTransfer.accountToAccountTransactionId
                            );
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Fund Transfer Details found.</p>
            </div>
          )}
           {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => {onPageChange(page);}}
            />
          </div>
        )}
           </div>
          </div>
      </div>
    </>
  );
}

export { FundTransfer };
