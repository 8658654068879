import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { SelectBox } from '../../../SelectBox';
import { addFeeType, updateFeeType } from '../../../../store/slice/Accounting/AccountSetup/FeeTypeSlice';
import { toast } from 'react-toastify';
import { fetchAllOfficeVatRate } from '../../../../store/slice/Accounting/OfficeAccounts/OfficeVatRateSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

function AddFeeTypes({ openDrawer, Close, isEdit, feeType }: { openDrawer: boolean, Close: any, isEdit: boolean, feeType: any }) {
    const [title] = useState((!isEdit ? 'Add New FeeType' : 'Edit FeeType'))
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement,] = useState('center');
    const [isLoading, setIsLoading] = useState(false);
    const { officeVatRates } = useSelector((state: RootState) => state.officeVatRate);
    const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);
    
    const validationSchema = object().shape({
        // feeCode: string()
        //     .required('Fee Code is required'),
        feeTypeId: number(),
        feeType: string(),
        billingDescription: string(),
        price: string(),
        fkVatTypeId: number(),
        priceType: string(),
        isVatInclude: boolean(),
        isActive: boolean()
    });

    const { register, handleSubmit, reset, setValue,getValues,  watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
             isActive: true,
            isVatInclude: true,
            feeTypeId: 0,
        }
    });

 

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            if (isEdit && feeType) {
               
                data.feeTypeId = feeType.feeTypeId;
                await dispatch(addFeeType(data )).unwrap();
                toast.success("Successfully Updated Fee Type");
               
            } else {
                await dispatch(addFeeType(data)).unwrap();
                toast.success("Successfully Added Fee Type");
            }
            Close();
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
      };

    useEffect(() =>{
        dispatch(fetchAllOfficeVatRate());
    },[dispatch]);

    useEffect(() => {
        if (officeVatRates.length > 0) {
          let data = officeVatRates.map((officeVatRate: any, index) => ({
            value: officeVatRate.vatTypeId,
            label: `${officeVatRate.vatType}`,      
          }));
          setOfficeVatRateLists(data);
          let findObj = officeVatRates.find((x) => x.vatTypeId === 1);
          if(findObj && findObj!==null) {
            
            setValue("fkVatTypeId", findObj?.vatTypeId)
          }
        }
      }, [officeVatRates]);

      useEffect(() => {
        if(feeType != null && isEdit === true){
            setValue('feeType', feeType?.feeType)
            setValue('billingDescription', feeType?.billingDescription)
            setValue('price', feeType?.price)
            setValue('priceType', feeType?.priceType)
            setValue('isVatInclude', feeType?.isVatInclude)
            setValue('isActive', feeType?.isActive)
            setValue('fkVatTypeId', feeType?.fkVatTypeId)
            setValue('feeTypeId', feeType?.feeTypeId)
        }
    }, [feeType, isEdit, reset, setValue]);

  


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                    <div className="space-y-3 w-full">

                        {/* <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Fee Code <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.feeCode ? 'is-invalid' : ''}`} {...register('feeCode')}
                                placeholder="Enter Fee Code"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.feeCode?.message?.toString()}</div>
                        </div> */}
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Fee Type
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('feeType')}
                                placeholder="Enter Fee Type"
                            />
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Billing Description
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('billingDescription')}
                                placeholder="Enter Billing Description"
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                VAT Type
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={officeVatRateLists}
                                value={getValues().fkVatTypeId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkVatTypeId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="relative max-w-full flex gap-x-10">
                            <label className="block mb-2 font-medium text-gray-900 dark:text-white-bg">
                                Price Type
                            </label>
                            <div className="flex gap-5">
                                <div>
                                    <input
                                        type="radio"
                                        value="fixed"
                                        checked={watch('priceType') === 'fixed'}
                                        onChange={() => setValue('priceType', 'fixed')}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Fixed
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        value="variable"
                                        checked={watch('priceType') === 'variable'}
                                        onChange={() => setValue('priceType', 'variable')}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Variable
                                    </label>
                                </div>
                            </div>
                        </div>
                        {watch('priceType') === 'fixed' && (
                            <div className="relative max-w-full col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Price
                                </label>
                                <input
                                    type="text"
                                    className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    {...register('price')}
                                    placeholder="Enter Price"
                                />
                            </div>
                        )}
                        <div className='grid grid-cols-2 gap-x-5'>
                            <div className="flex items-center gap-3">
                                <label className="block dark:text-white-bg">
                                    Include VAT
                                </label>
                                <div className='flex gap-5'>
                                    <div>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={watch('isVatInclude') === true}
                                            onChange={() => setValue('isVatInclude', true)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="false"
                                            checked={watch('isVatInclude') === false}
                                            onChange={() => setValue('isVatInclude', false)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                    </div>
                                </div>
                            </div>
                            {isEdit && (
                            <div className="flex items-center gap-3">
                                <label className="block dark:text-white-bg">
                                    Is Active
                                </label>
                                <div className='flex gap-5'>
                                    <div>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={watch('isActive') === true}
                                            onChange={() => setValue('isActive', true)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="false"
                                            checked={watch('isActive') === false}
                                            onChange={() => setValue('isActive', false)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>


                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddFeeTypes };
