import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteVatPeriod,
  fetchAllVatPeriod,
} from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/VatPeriodSlice";
import { toast } from "react-toastify";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { AddVatPeriod } from "../Modal/AccountSetup/AddVatPeriod";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import {
  fetchAllAccountingMethods,
  fetchAllBusinessTypes,
  fetchAllGeneralOffice,
} from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/GeneralSlice";

function VatPeriod() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { vatPeriods, status, error } = useSelector(
    (state: RootState) => state.vatPeriod
  );
  const [vatPeriodData, setVatPeriodData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(10);
  const [currentVatPeriod, setCurrentVatPeriod] = useState<any[]>([]);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [vatPeriodId, setVatPeriodId] = useState<number>(0);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: "",
        currentPage: currentPage,
        recordsPerPage: totalPage,
      };
      dispatch(fetchAllVatPeriod(searchdata));
      dispatch(fetchAllGeneralOffice());
      dispatch(fetchAllAccountingMethods());
      dispatch(fetchAllBusinessTypes())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, isOnAddedOrUpdate, status, error]);

  useEffect(() => {
    if (
      vatPeriods != null &&
      vatPeriods.dataList != null &&
      vatPeriods.dataList.length > 0
    ) {
      const totalPages = vatPeriods.totalRows;
      setTotalRecords(vatPeriods.totalRows);
      setCurrentVatPeriod(vatPeriods.dataList);
    }
  }, [vatPeriods]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteVatPeriod(vatPeriodId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted vatPeriod");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage,
    };
    dispatch(fetchAllVatPeriod(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={vatPeriodId}
            title="Bank Receipt"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="VAT Period"
            buttonName="VAT Period"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          {openModal && (
            <AddVatPeriod
              openDrawer={openModal}
              Close={toggleDrawer}
              isEdit={isEdit}
              vatPeriod={vatPeriodData}
            />
          )}
          <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
            <div>
              <SearchInput title="Keyword" onChange={(e) => e} />
            </div>
            <div>
              <div className="flex gap-4 md:gap-1 w-full">
                <CheckBoxFilter title="Year" />
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden sm:rounded-lg my-5">
            <div className="overflow-x-auto max-h-96">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th
                      scope="col"
                      className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      VAT Period ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      VAT Period
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Accounting Method
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Is VAT Returned
                    </th>
                    <th
                      scope="col"
                      className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="text-lg 3xl:text-xs">
                  {currentVatPeriod.map((VatPeriod: any, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {VatPeriod.vatPeriodId}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatDateOnlyDisplay(VatPeriod.vatStartDate)} -{" "}
                        {formatDateOnlyDisplay(VatPeriod.vatEndDate)}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {VatPeriod.accountingMethod}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {/* {VatPeriod.isVatReturned} */}
                        No
                      </td>
                      <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="flex gap-3 w-full">
                          <FaEdit
                            className="fill-blue-500 w-6 h-6"
                            onClick={() => {
                              setOpenModal(true);
                              setIsEdit(true);
                              setVatPeriodData(VatPeriod);
                            }}
                          />
                          <RiDeleteBin5Fill
                            className="fill-red-600 w-6 h-6"
                            onClick={() => {
                              setOpenDelModal(true);
                              setVatPeriodId(VatPeriod.vatPeriodId);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalRecords === 0 && (
              <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                <p className="text-red-800">No VAT period Details found.</p>
              </div>
            )}
          </div>
          {totalRecords > 0 && (
            <div className="w-full flex flex-col pb-[100px]">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPage}
                totalRecords={totalRecords}
                onPageChange={(page) => {
                  onPageChange(page);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { VatPeriod };
