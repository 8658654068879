import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, boolean } from "yup";
import { FaPlus } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import {
  BreadCrumb,
  HeaderTitle,
  MainAnimation,
  SelectBox,
} from "../../../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaArrowsAlt } from "react-icons/fa";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { addCheckListItem, ChecklistItem, deleteCheckListItem, fetchCheckListById, fetchCheckListData, fetchCheckListItemData } from "../../../../store/slice/SystemMaintenance/ChecklistSlice";
import { toast } from "react-toastify";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import { GetAllAreasOfPractice } from "../../../../store/slice/Administrator/AreasOfPracticeSlice";
import { fetchAllCaseType } from "../../../../store/slice/SystemMaintenance/CaseTypeSlice";
import { fetchAllCaseSubType } from "../../../../store/slice/SystemMaintenance/CaseSubTypeSlice";

const EditChecklist = ({
  openModal,
  Close,
}: {
  openModal: boolean;
  Close: any;
}) => {
  const { referenceNumber } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [checklistItemName, setChecklistItemName] = useState("");
  const [Cierrors, setCiErrors] = useState<{ checklistItem?: { message: string } }>({});
  const [isCILoading, setIsCILoading] = useState(false);
  const checkListItemData = useSelector((state: RootState) => state.checklist.checkListItemData);
  const checkListByIdData = useSelector((state: RootState) => state.checklist.checkListByIdData);
  const [initialLoading, setInitialLoading] = useState(true);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [clItemName, setCLItemName] = useState<string>();
  const [clItemId, setCLItemId] = useState<number>(0);
  const [checkListListingData, setCheckListListingData] = useState<any[]>([]);
  const { areasOfPractice } = useSelector((state: RootState) => state.areasOfPractice);
  const { casetypes } = useSelector((state: RootState) => state.caseType);
  const { CaseSubTypes } = useSelector((state: RootState) => state.caseSubType);
  const [filteredCaseTypes, setFilteredCaseTypes] = useState<any[]>([]);
  const [filteredCaseSubTypes, setFilteredCaseSubTypes] = useState<any[]>([]);
  const [areaOptionsLists, setAreaOptionsLists] = useState<any[]>([]);
  const [caseTypeOptionsLists, setCaseTypeOptionsLists] = useState<any[]>([]);
  const [subCaseOptionsLists, setSubCaseOptionsLists] = useState<any[]>([]);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let data = {
        checklistId: Number(referenceNumber)
      }
      dispatch(fetchCheckListItemData(data))
      dispatch(fetchCheckListById(Number(referenceNumber)))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (checkListItemData !== null && checkListItemData?.itemListingData.length > 0) {
      setCheckListListingData(checkListItemData?.itemListingData)
    }
  }, [checkListItemData]);

  // useEffect(() => {
  //   if (areasOfPractice.length > 0) {
  //     let data = areasOfPractice.map((area: any, index) => ({
  //       value: area.areasOfPracticeId,
  //       label: area.area,
  //     }));
  //     setAreaOptionsLists(data);
  //   }

  //   if (referenceNumber) {
  //     setFilteredCaseTypes(
  //       casetypes.filter(
  //         (type: any) =>
  //           type.fkAreasOfPracticeId === checkListByIdData.fkAreasOfPracticeId
  //       )
  //     );
  //     setFilteredCaseSubTypes(
  //       CaseSubTypes.filter(
  //         (subType: any) => subType.fkCaseTypeId === checkListByIdData.fkCaseTypeId
  //       )
  //     );
  //   }
  // }, [areasOfPractice, casetypes]);

  useEffect(() => {
    if (filteredCaseTypes != null && filteredCaseTypes.length > 0) {
      let data = filteredCaseTypes.map((casetype: any) => ({
        value: casetype.caseTypeId,
        label: casetype.caseType,
      }));
      setCaseTypeOptionsLists(data);
    }
  }, [filteredCaseTypes]);

  useEffect(() => {
    if (filteredCaseSubTypes != null && filteredCaseSubTypes.length > 0) {
      let data = filteredCaseSubTypes.map((subcase: any) => ({
        value: subcase.caseSubTypeId,
        label: subcase.subCaseType,
      }));
      setSubCaseOptionsLists(data);
    }
  }, [filteredCaseSubTypes]);

  const handleAddStepClick = () => {
    setIsFormVisible(true);
  };
  const handleCancelClick = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    if (checkListByIdData !== null) {
      setValue("checklistName", checkListByIdData?.checklistName);
      setValue("fkAreasOfPracticeId", checkListByIdData?.fkAreasOfPracticeId);
      setValue("fkCaseTypeId", checkListByIdData?.fkCaseTypeId);
      setValue("fkCaseSubTypeId", checkListByIdData?.fkCaseSubTypeId);
      setValue("checklistIsActive", checkListByIdData?.checklistIsActive);
    }
  }, [checkListByIdData])

  const validationSchema = object().shape({
    checklistName: string().required("Checklist Name is required"),
    fkAreasOfPracticeId: number().required("Areas of Practice is required"),
    fkCaseTypeId: number().required("Case Type is required"),
    fkCaseSubTypeId: number(),
    checklistIsActive: boolean(),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      checklistIsActive: true,
    },
  });

  const onSubmit = async (data: any) => {
    // data.stepId = 0;
    // data.fkWfId = 0;
    // console.log(JSON.stringify(data, null, 2));
    // const newStep: Step = { name, description, estimatedtime };
    // setSteps([...steps, newStep]);
    // setIsFormVisible(false);
    // setIsLoading(true);
    // try {
    //     await dispatch(addWorkflowSetup(data)).unwrap();
    //     // reset();
    //     toast.success("Successfully Added Workflow Request");
    // } catch (error: any) {
    //     toast.error(error.toString());
    // } finally {
    //     setIsLoading(false);
    // }
  };

  const onCancelRedirect = () => {
    navigate("/systemmanagement/systemmaintenance/checklist");
  };

  const handleSaveClick = async () => {
    if (!checklistItemName.trim()) {
      setCiErrors({ checklistItem: { message: "Checklist item name is required" } });
      return;
    }

    const newChecklistItem: ChecklistItem = {
      checkListItemId: 0,
      fkCheckListId: Number(referenceNumber),
      checkListItemName: checklistItemName,
    };
    setIsCILoading(true);
    try {
      await dispatch(addCheckListItem(newChecklistItem)).unwrap();
      toast.success("Successfully Added CleckList Item");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsCILoading(false);
    }
    setChecklistItemName("");
    setCiErrors({});
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteCheckListItem(clItemId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted CheckList Item");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  return (
    <>
      {/* {openModal != false && ( */}
      <div className="">
        <BreadCrumb path={location} />
      </div>
      <div className="mt-1 flex-col w-full gap-4 p-10 md:p-2 lg:p-2">
        <div>
          <HeaderTitle title="Add Checklist" />
        </div>
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={clItemId}
            title={`CheckList Item (${clItemName})`}
            message="Are you sure you want to delete?"
            onDeleteAccept={onDeleteCall}
          />
        )}
        {initialLoading ? (<MainAnimation />) : ""}
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex-col gap-5">
          <div className="grid grid-cols-3 md:grid-cols-1 mt-3 w-full gap-x-5">
            <div className="relative mb-5">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.checklistName ? "is-invalid" : ""
                  }`}
                {...register("checklistName")}
                placeholder="Enter Checklist Name"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.checklistName?.message?.toString()}
              </div>
            </div>
            <div className="mb-5">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Area of Practice <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border  border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="areaotionList"
                options={areaOptionsLists}
                isSearchable={true}
                value={getValues().fkAreasOfPracticeId}
                placeholder="Area of Practice"
                shape="round"
                color="indigo_50"
                size="xs"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.fkAreasOfPracticeId?.message?.toString()}
              </div>
            </div>
            <div className="mb-5">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Case Type <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="caseTypeOptionList"
                options={caseTypeOptionsLists}
                value={getValues().fkCaseTypeId}
                isSearchable={true}
                placeholder="Case Type"
                shape="round"
                color="indigo_50"
                size="xs"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.fkCaseTypeId?.message?.toString()}
              </div>
            </div>
            <div className="mb-5">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Case Sub Type
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="subCaseTypeOptionList"
                options={subCaseOptionsLists}
                value={getValues().fkCaseSubTypeId}
                isSearchable={true}
                placeholder="Case Sub Type"
                shape="round"
                color="indigo_50"
                size="xs"
              />
            </div>
            <div className="relative max-w-full flex gap-6">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Is Active
              </label>
              <div className="flex gap-5">
                <div>
                  <input
                    type="radio"
                    value="true"
                    checked={watch("checklistIsActive") === true}
                    onChange={() => setValue("checklistIsActive", true)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    id="bordered-radio-1"
                    type="radio"
                    value="false"
                    checked={watch("checklistIsActive") === false}
                    onChange={() => setValue("checklistIsActive", false)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                onClick={() => {
                  reset();
                  setCheckListListingData([]);
                  onCancelRedirect();
                }}
                type="button"
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <div className="">
          <button
            className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
            type="button"
            onClick={handleAddStepClick}
          >
            <FaPlus className="w-4 h-4 fill-white-bg" />
            <span className="text-white-bg mx-2">Add Item</span>
          </button>
        </div>
        {isFormVisible && (
          <div className="mt-4">
            <form>
              <div className="grid grid-cols-4 md:grid-cols-1 gap-x-5 justify-center items-center">
                <div className="mb-5">
                  <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Checklist Item <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={checklistItemName}
                    onChange={(e) => setChecklistItemName(e.target.value)}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Enter Checklist Item"
                  />
                </div>
                <button
                  onClick={handleSaveClick}
                  type="button"
                  disabled={isCILoading}
                  className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px] ${isCILoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {isCILoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="relative overflow-x-auto sm:rounded-lg mt-5">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Item
                </th>
                <th scope="col" className="text-center w-12 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {checkListListingData.length > 0 && checkListListingData.map((checklistItem: any, index) => (
                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{checklistItem.checklistItemName}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaArrowsAlt className="fill-gray-500 w-6 h-6" />
                      <FaEdit className="fill-blue-500 w-6 h-6" />
                      <button
                        type="button"
                        onClick={() => {
                          setOpenDelModal(true);
                          setCLItemId(checklistItem.caseChecklistItemId);
                          setCLItemName(checklistItem.checklistItemName);
                        }}
                      >
                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* )
            } */}
    </>
  );
};

export { EditChecklist };
