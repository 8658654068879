import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllCreateSurvey = async (data: any) => {
    try {
        const response = await axiosInstance.post("CreateSurvey", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostCreateSurvey = async (data: any) => {
    try {
        const response = await axiosInstance.post("CreateSurvey", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutCreateSurvey = async ( data: any) => {
    try {
        const response = await axiosInstance.put("CreateSurvey", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteCreateSurvey = async (id: number) => {
    try {
        await axiosInstance.delete(`/CreateSurvey/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CreateSurveyService = {
    GetAllCreateSurvey,
    PostCreateSurvey,
    PutCreateSurvey,
    deleteCreateSurvey
};


export default CreateSurveyService;
