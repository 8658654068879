import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { Heading } from "../../AccountSetupHeading/Heading";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";


function AddExpertStepTwo({ onSubmitStep, initialData, onPreviousStep,titleData, maritalStatusData, userRoleData, onCancel }: any) {

    const expertRegisterByIdData = useSelector((state: RootState) => state.expertRegister.expertRegsterByIdData);

    const validationSchema = object().shape({
    chamberName: string(),
    addressLine1: string(),
    addressLine2: string(),
    champercity: string(),
    champercounty: string(),
    postCode: string(),
    dx: string(),
    website: string(),
    champeremail: string(),
    Phone1: number(),
    //   Phone2: number(),
    // mobile: string(),
    //fax: string(),
    detail: string(),
    

    })

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };
 useEffect(() => {
    if (expertRegisterByIdData !== null) {
      // let dateOfBirth = (expertRegisterByIdData?.dateOfBirth !== null) ? clientByIdData?.dateOfBirth.split("T")[0] : null;
      setValue("chamberName", expertRegisterByIdData?.chamberName);
      setValue("addressLine1", expertRegisterByIdData?.addressLine1);
      setValue("addressLine2", expertRegisterByIdData?.addressLine2);
      setValue("champercity", expertRegisterByIdData?.champercity);
    setValue("champercounty", expertRegisterByIdData?.champercounty);
      setValue("postCode", expertRegisterByIdData?.postCode);
      setValue("dx", expertRegisterByIdData?.dx);
      setValue("website", expertRegisterByIdData?.website);
      setValue("Phone1", expertRegisterByIdData?.Phone1);
      setValue("detail", expertRegisterByIdData?.detail);
      // setValue("dateOfBirth", dateOfBirth);
      // setValue("countryOfBirth", clientByIdData?.countryOfBirth);
      //setValue("placeOfBirth", clientByIdData?.placeOfBirth);
      setValue("champeremail", expertRegisterByIdData?.champeremail);
    }
  }, [expertRegisterByIdData]);
    return (

        <form onSubmit={handleSubmit(handleFormSubmit)}>
            
            <Heading title={"Chamber Details"} />
            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Chamber Name
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('chamberName')}
                        placeholder="Enter Chamber Name"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Address Line 1
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('addressLine1')}
                        placeholder="Enter Address Line 1"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Address Line 2
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('addressLine2')}
                        placeholder="Enter Address Line 2"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    City
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('champercity')}
                        placeholder="Enter City"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    County
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('champercounty')}
                        placeholder="Enter County"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Post Code
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('currentpostCode')}
                        placeholder="Enter Post Code"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    DX
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('dx')}
                        placeholder="Enter DX"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Website
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('website')}
                        placeholder="Enter Website"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Email Address
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('champeremail')}
                        placeholder="Enter Email Address"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Phone 1
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('mobilePhone1')}
                        placeholder="Enter Phone 1"
                    />
                </div>
                {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Phone 2
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('Phone2')}
                        placeholder="Enter Phone 2"
                    />
                </div> */}
                {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Mobile
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('address1')}
                        placeholder="Enter Mobile"
                    />
                </div> */}
                {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Fax
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('fax')}
                        placeholder="Enter Fax"
                    />
                </div> */}
                <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                Details
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Enter Details" {...register('detail')}
                            ></textarea>
                        </div>

                
            </div>

            
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onPreviousStep}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm"> 
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        Previous
                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddExpertStepTwo };
