  import { InputBox, SearchAccount, SelectBox } from "../../../components";
  import { useEffect, useRef, useState } from "react";
  import { date, number, object, string } from "yup";
  import { useForm } from "react-hook-form";
  import { FaLock, FaPlus } from "react-icons/fa";
  import { FaLockOpen } from "react-icons/fa";
  import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { addSuppliersBillEntry, fetchAllSuppliersBillEntryId } from "../../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersBillEntrySlice";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { FcCancel, FcPlus } from "react-icons/fc";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchNextReceiptNumber } from "../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { SearchSupplier } from "../../Modal/SearchSupplier";
import { formatDateISODisplay } from "../../../utils/date-time.utils";
import { fetchAllOfficeSupplier } from "../../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierSlice";
  
  const AddSuppliersBillEntry = ({
    openModal,
    Close,
    isEdit,
    isView,
    suppliersBillEntry
  }: {
    openModal: boolean;
    isEdit: boolean;
    isView: boolean;
    Close: any;
    suppliersBillEntry: any;
  }) => {
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState('center')
    const [isLoading, setIsLoading] = useState(false);
    const [setExistingSuppliersBillEntry] = useState(suppliersBillEntry);
    const dispatch = useAppDispatch();
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [openFromBankAccountModal, setOpenFromBankAccountModal] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0); 
    const { suppliersBillEntryById } = useSelector((state: RootState) => state.suppliersBillEntry);
    const { officeVatRates } = useSelector((state: RootState) => state.officeVatRate);
    const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);
    const [showVatAdjustment, setShowVatAdjustment] = useState(false);
    const [supplierBillEntryFileName, setSupplierBillEntryFileName] = useState('');
    const [documentSupplierBillEntryFile, setDocumentSupplierBillEntryFile] = useState<any>();
    const [fromSupplierBankAccount, setFromSupplierBankAccount] = useState<string>("");
    const [openFromSupplierBankAccountModal, setOpenFromSupplierBankAccountModal] = useState(false);
    //const { receiptNumber } = useSelector((state: RootState) => state.officeBankAccount);
    // [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
    const [service, setService] = useState<string>(""); 
    const paymentTerms = [
      { label: "Immediate", value: 1 },
      { label: "7Days", value: 7 },
      { label: "14Days", value: 14 },
      { label: "30Days", value: 30 },
      { label: "60Days", value: 60 },
      { label: "90Days", value: 90 },
    ];
  
    const mappedPaymentTerms = paymentTerms.map((type: any) => ({
      label: type.label,
      value: type.value,
    }));

    
    const toggleFromBankAccountModal = (index: number) => {
      setSelectedRowIndex(index);
      setOpenFromBankAccountModal(!openFromBankAccountModal);
    };
  
    const toggleVatAdjustment = () => {
      setShowVatAdjustment(!showVatAdjustment);
    };

    
const handleAccountSelect = (selectedAccountType: any) => {
      if (selectedRowIndex !== null) {
        const updatedRows = [...billentrylines];
        updatedRows[selectedRowIndex] = {
          ...updatedRows[selectedRowIndex],
          accountType:  selectedAccountType.accountName, // Update account type
          accountId: selectedAccountType.accountId, // Update account ID
        };
        setBillEntryLines(updatedRows);
      }
      setOpenFromBankAccountModal(false);
    };

    
  
    const [billentrylines, setBillEntryLines] = useState([
      {
        selectedTypeId: "",
        accountType: "",
        accountId: number,
        description: "",
        netAmount: 0,
        grossAmount: 0,
        vatRate: 0,
        vat:0,
        vatAdjustment: 0, // New field for VAT Adjustment
        vatId: 0
     
      },
    ]);
    const addRow = () => {
      setBillEntryLines([
        ...billentrylines,
        {
          selectedTypeId: "",
          accountType: "",
          accountId: number,
          description: "",
          netAmount: 0,
          grossAmount: 0,
          vatRate: 0,
          vat:0,
          vatAdjustment: 0,
          vatId:0
  
        },
      ]);
    };

  const totalNetAmount = billentrylines.reduce((acc, row) => acc + (row.netAmount || 0), 0);
  const totalVatAmount = billentrylines.reduce((acc, row) => acc + ((row.netAmount || 0) * (row.vatRate || 0) / 100), 0);
  const totalGrossAmount = billentrylines.reduce((acc, row) => acc + ((row.netAmount || 0) + ((row.netAmount || 0) * (row.vatRate || 0) / 100) + (row.vatAdjustment || 0)), 0);
  const totalVatAdjustmentAmount = billentrylines.reduce((acc, row) => acc+((row.vatAdjustment ||0 )),0);
   

  useEffect(() => {
    setValue("netAmount", totalNetAmount);
    setValue("vatAmount", totalVatAmount);
    setValue("grossAmount", totalGrossAmount);
    
  }, [totalNetAmount, totalVatAmount, totalGrossAmount]);

    const handleEditClick = () => {
      setIsDisabled(!isDisabled);
    };
    
    const removeRow = (index: number) => {
      setBillEntryLines(billentrylines.filter((_, i) => i !== index));
    };
    
    useEffect(() => {
      if (officeVatRates.length > 0) {
        let data = officeVatRates.map((officeVatRate: any, index) => ({
          value: officeVatRate,
          label: `${officeVatRate.vatType}`,
          vatPercentage: officeVatRate.percentage, 
        }));
        setOfficeVatRateLists(data);
       
      }
    }, [officeVatRates]);

    const handleVatRateChange = (index: number, selectedOption: any) => {
      const updatedRows = [...billentrylines];

      if (selectedOption) {
        updatedRows[index] = {
          ...updatedRows[index],
          vatRate: selectedOption.percentage,
          vatId: selectedOption.vatTypeId,
        };

        let todata = { ...updatedRows[index]};
        let vat = (updatedRows[index].netAmount * updatedRows[index].vatRate / 100).toFixed(2) || 0;
        let grossAmount = (updatedRows[index].netAmount + (updatedRows[index].netAmount * updatedRows[index].vatRate / 100) + (updatedRows[index].vatAdjustment || 0)).toFixed(2)
        
        todata.vat = Number(vat);
        todata.grossAmount = Number(grossAmount);
  
        updatedRows[index] = todata;
        setBillEntryLines(updatedRows);
      }
    };

    const handleInputChange = (index: number, field: string, value: any) => {
      const updatedRows = [...billentrylines];
    
      // Update the specific field with the new value
      let todata = { ...updatedRows[index], [field]: value };
    
      // Recalculate `vat` and `grossAmount` dynamically
      const netAmount = todata.netAmount || 0;
      const vatRate = todata.vatRate || 0;
      const vatAdjustment = todata.vatAdjustment || 0;
    
      const vat = ((netAmount * vatRate) / 100).toFixed(2);
      const grossAmount = (netAmount + (netAmount * vatRate) / 100 + vatAdjustment).toFixed(2);
    
      todata.vat = Number(vat);
      todata.grossAmount = Number(grossAmount);
    
      // Update the row with the recalculated values
      updatedRows[index] = todata;
    
      // Set the updated rows to state
      setBillEntryLines(updatedRows);
    };
    
  
    const validationSchema = object().shape({
      billEntryId: number(),
      billEntryNumber: number(),
      supplierId: number().required("supplier"),
      invoiceDate: string()
        .transform((value, originalValue) => {
          if (originalValue === "" || value === null) return null;
          return new Date(value).toISOString().split("T")[0];
        }), 
        
      invoiceNumber: string().required("Invoice Number is required"),
      details: string(),
      paymentTerm: string().required("paymentTerms is required"),
       netAmount: number().nullable(),
       vatAmount: number().nullable(),
       grossAmount: number().required("grossAmount"),
       accountBalance: string(),
      // entryDate: string()
      // .transform((value, originalValue) => {
      //   if (originalValue === "" || value === null) return null;
      //   return new Date(value).toISOString().split("T")[0];
      // }), 
    });
  
    const {
      register,
      handleSubmit,
      setValue,
      reset,
      getValues,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues:{
        invoiceDate:new Date().toISOString().split("T")[0],
        billEntryNumber: 0,
        billEntryId: 0,
      }
    });
    useEffect(() => {
      setValue("invoiceDate", new Date().toISOString().split("T")[0]);
    }, []);

    const onSubmit = async(data: any) => {
    if (documentSupplierBillEntryFile === undefined) {
       // toast.error("Please select the file");
        // return;
    }

   data.attachmentName = supplierBillEntryFileName;
   const combinedData = {
            ...data, 
            billLines: billentrylines,
          };       
    console.log(JSON.stringify(combinedData, null, 2));
    const formData = new FormData();
    formData.append('data', JSON.stringify(combinedData));
    formData.append('file', documentSupplierBillEntryFile);

      setIsLoading(true);
      try {
        await dispatch(addSuppliersBillEntry(formData)).unwrap();
        Close("success");
        toast.success("Successfully Added Suppliers Bill Entry");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
      };

      const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    const handleFileChange = (event: any) => {
      const file = event.target.files[0];
      if (file) {
          setDocumentSupplierBillEntryFile(file);
          setSupplierBillEntryFileName(file.name);
      } else {
          setSupplierBillEntryFileName('');
      }
  };
  
      // useEffect(() => {
      //     if (suppliersBillEntry) {
      //       setExistingSuppliersBillEntry(suppliersBillEntry);
      //      reset(suppliersBillEntry);
      //     }
      //   }, [suppliersBillEntry, isEdit,  reset, setValue]);

        useEffect(() => {
          if ((suppliersBillEntry != null && isEdit === true) || (suppliersBillEntry != null && isView === true && isEdit === false)) {
            dispatch(fetchAllSuppliersBillEntryId(suppliersBillEntry?.supplierBillEntryId));
            
            setValue('billEntryNumber', suppliersBillEntry?.billEntryNumber)
            setValue('billEntryId', suppliersBillEntry?.supplierBillEntryId)
            setValue('supplierId', (suppliersBillEntry?.fkSupplierId))
            setValue('invoiceDate', formatDateISODisplay(suppliersBillEntry?.invoiceDate))
            setValue('invoiceNumber', suppliersBillEntry?.invoiceNumber)
            setValue('details', suppliersBillEntry?.details)
            // setValue('receivedFromOther', suppliersBillEntry?.fkclientId)
            setValue('paymentTerm', suppliersBillEntry?.paymentTerm)
            setValue('netAmount', suppliersBillEntry?.netAmount)
            setValue('vatAmount', suppliersBillEntry?.vat)
            setValue('grossAmount', suppliersBillEntry?.grossAmount)
            setValue('accountBalance', suppliersBillEntry?.accountBalance)
            setFromSupplierBankAccount(suppliersBillEntry.supplierName);
            // let data={}
            // dispatch(fetchAllOfficeSupplier(data));
          }
        }, [suppliersBillEntry, isEdit, isView, reset, setValue]);

        useEffect(() => {
          if (suppliersBillEntryById != null && suppliersBillEntryById.billEntryLines != null) {
              setBillEntryLines(
                  suppliersBillEntryById.billEntryLines.map((item: any) => {
                      return {
                          selectedTypeId: item.selectedTypeId||"", 
                          accountType: item.accountType || "", 
                          accountId: item.fkAccountId || null, 
                          description: item.description || "",
                          netAmount: item.netAmount, 
                          grossAmount: item.grossAmount, 
                          vatRate: item.vatRate, 
                          vat: item.vat,
                          vatAdjustment: item.vatAdjustment, 
                          vatId: item.vatId,
                          creditCurrency: item.credit || 0, 
                          debitCurrency: item.debit || 0,
                          lineNumber: item.lineNumber || 0,
                          isDeleted: false,
                          fkJournalEntryId: item.fkJournalEntryId || null,
                          journalEntryLineId: item.journalEntryLineId || null,
                      };
                  })
              );
          }
      }, [suppliersBillEntryById, setBillEntryLines]);
       

      useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [dispatch(fetchNextReceiptNumber(OfficeAccountConstant.SupplierBillEntry))];
      
            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });
      
            effectServiceCall.current = true;
        }
      }, [dispatch]);
      

      const toggleFromSupplierBankAccountModal = () => {
        setOpenFromSupplierBankAccountModal(!openFromSupplierBankAccountModal);
      };
      
      
      const handleFromSupplierAccountSelect = (selectedAccountType: any,) => {
       setFromSupplierBankAccount(selectedAccountType.supplierName); // Set the selected account type to display in input field
       setService(selectedAccountType.servicesProducts)
       const updatedRows = billentrylines.map((row) => ({
        ...row,
        accountType: selectedAccountType.servicesProducts, // Set supplier service as account type
      }));
      setBillEntryLines(updatedRows);
       setValue('supplierId', selectedAccountType.supplierId);

    // if (selectedAccountType){
    //   setSelectedFromAccountBalance(selectedAccountType.runningBalance);
    //   setValue("fromBankAvailableBalance",selectedAccountType.runningBalance);
    //  }
    console.log(selectedAccountType.supplierId);
  //  console.log(selectedAccountType.runningBalance);
    setOpenFromSupplierBankAccountModal(false);
  
  };
  


  
    const [isDisabled, setIsDisabled] = useState(true);
    return (
      <>
        {openModal !== false && (
        <form onSubmit={handleSubmit(onSubmit, onError  )} className="mx-12">
          <div className="">
            <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
              <div className="relative flex">
                <input
                  type="text"
                  placeholder="Supplier"
                  value={fromSupplierBankAccount}
                  aria-label="Search"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.supplierId ? "is-invalid" : ""
                  }`}
                  {...register("supplierId")}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.supplierId?.message?.toString()}
                </div>
                <button
                  onClick={toggleFromSupplierBankAccountModal}
                  className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-lg 3xl:text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                >
                  Search
                </button>
                {openFromSupplierBankAccountModal && (
                <SearchSupplier
                openDrawer={openFromSupplierBankAccountModal}
                Close={toggleFromSupplierBankAccountModal}
                onAccountSupplierSelect={handleFromSupplierAccountSelect} 
                
              />
            )}
              </div>
              {isView === false && isEdit === false &&( 
              <div>
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              
            )}
            </div>
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              {/* <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Bill Entry Number
                </label>
                <div className="flex gap-3">
                  <input
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("billEntryNumber")}
                    disabled={isDisabled}
                  />
                  <button
                    type="button"
                    onClick={handleEditClick}
                    className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                  >
                    {isDisabled ? <FaLock /> : <FaLockOpen />}
                  </button>
                </div>
              </div> */}
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Payment Terms <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={mappedPaymentTerms}
                  value={getValues().paymentTerm}
                  defaultInputValue={"Immediate"}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("paymentTerm", selectedOption);
                    }
                  }}
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Invoice Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("invoiceNumber")}
                  placeholder="Enter Invoice Number"
                />
              </div>
              {/* <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Net Amount <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("netAmount")}
                  placeholder="0.00"
                  value={(totalNetAmount || 0).toFixed(2)} 
                  disabled
                />
              </div>
  
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                VAT <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("vatAmount")}
                  placeholder="0.00"
                  value={(totalVatAmount || 0).toFixed(2)} 
                  disabled
                />
              </div>
  
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Gross Amount <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("grossAmount")}
                  placeholder="0.00"
                  value={(totalGrossAmount || 0).toFixed(2)} 
                  disabled
                />
              </div> */}
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Account Balance 
                </label>
                <NumericFormat
                    decimalScale={2}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("accountBalance")}
                  placeholder="Enter Account Balance"
                  onValueChange={(values) => {
                    setValue("accountBalance", values.value);
                  }}
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Invoice Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.invoiceDate ? "is-invalid" : ""
                  }`}
                  {...register("invoiceDate")}
                  placeholder="Enter Invoice Date"
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.invoiceDate?.message?.toString()}
                </div>
              </div>
              {/* <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Entry Date
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.entryDate ? "is-invalid" : ""
                  }`}
                  {...register("entryDate")}
                  placeholder="Enter Entry Date"
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.entryDate?.message?.toString()}
                </div>
              </div> */}
              <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Attach
                  </label>
                  <input className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`} type="file" 
                  onChange={handleFileChange} />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Details
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Details"
                  {...register("details")}
                ></textarea>
              </div>
            </div>
            <div className="relative h-auto sm:rounded-lg mt-5">
              <table className="w-full text-lg 3xl:text-xs text-black text-left rtl:text-right dark:text-gray-400">
                <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Account
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Description
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Net Amount	
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    VAT Rate
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    VAT	
                    </th>
                    {showVatAdjustment && <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    VAT Adj
                    </th>}
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Gross Amount
                    </th>
                    <th scope="col" className="text-center w-12 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                  </tr>
                </thead>
                <tbody>
                  {billentrylines.map((row, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="cursor-pointer px-6 py-3 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      
                        <button
                          type="button"
                          onClick={() => toggleFromBankAccountModal(index)}
                        >
                          <FcPlus />
                        </button>
                        {openFromBankAccountModal &&  (
                <SearchAccount
                  openDrawer={openFromBankAccountModal}
                  Close={() => setOpenFromBankAccountModal(false)}
                  onAccountSelect={handleAccountSelect} type="Office" // Handle account selection
                />
                
              )}
              </td>
              <td className="px-6 py-3 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <InputBox
                dataType="text"
                placeholder="Account Type"
                value={ row.accountType} // Display the selected account type
                onChange={(e) => handleInputChange(index, 'accountType', e.target.value)}
                disabled
              />
            
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <InputBox
                          dataType={"string"}
                          placeholder={"Enter Item name / Description"}
                          value={row.description}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormat
                          value={row.netAmount || 0}
                          className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                          placeholder="Amount"
                          decimalScale={2}
                          onValueChange={(values) => handleInputChange(index, 'netAmount', values.floatValue || 0)}
                        />
                </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="">
                          <div className="mb-2"></div>
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            isMulti={false}
                            name="type"
                            isSearchable={true}
                            options={officeVatRateLists}
                            value={
                              officeVatRateLists.find((option) => option.vatTypeId === row.selectedTypeId) || null
                            }
                            placeholder="Select"
                            onChange={(selectedOption) => handleVatRateChange(index, selectedOption)   }
                          />
                        </div>
                      </td>
        
              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <NumericFormat
                    value={row.vat}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    decimalScale={2}
                    prefix="£"
                  />
                </td>
               {showVatAdjustment && (
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <NumericFormat
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  value={row.vatAdjustment || 0}
                  onValueChange={(values) => handleInputChange(index, 'vatAdjustment', values.floatValue || 0)}
                />
              </td>
            )}

                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <NumericFormat
                      value={row.grossAmount || 0}
                    className={`border-2 border-gray-300  bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    decimalScale={2}
                    prefix="£"
                  />
                </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <button type="button" onClick={() => removeRow(index)}>
                          <FcCancel className="w-6 h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
                <tr className="text-center">
                
        <td colSpan={3} className="px-6 py-3 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">Totals</td> {/* Changed to colSpan={3} */}
        <td className="px-6 py-3 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
          <NumericFormat
            value={totalNetAmount.toFixed(2)|| 0}
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            displayType="text"
            decimalScale={2}
            prefix="£"
          />
        </td>
        <td colSpan={1}></td>
        <td className="px-6 py-3 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
          <NumericFormat
            value={totalVatAmount.toFixed(2) || 0}
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            displayType="text"
            decimalScale={2}
            prefix="£"
          />
        </td>
        {showVatAdjustment && <td>
          <NumericFormat
            value={totalVatAdjustmentAmount.toFixed(2) || 0}
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            displayType="text"
            decimalScale={2}
            prefix="£"
          />
      
          </td>}
        <td className="px-6 py-3 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
          <NumericFormat
            value={totalGrossAmount.toFixed(2) || 0}
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            displayType="text"
            decimalScale={2}
            prefix="£"
          />
        </td>
        <td></td>
      </tr>
                
                </tbody>
              </table>
            </div>



            <div className="flex justify-between mt-5">
            <div className="mb-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                type="button"
                onClick={addRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>
            <div className="mb-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                type="button"
                 onClick={toggleVatAdjustment}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">{showVatAdjustment ? "Remove VAT Adj" : "Adjust VAT"}</span>
              </button>
            </div>
            </div>

            <div className="w-1/2 ml-auto space-y-2 my-5 p-6">
              {" "}
              {/* Container aligned to the right */}
              <div className="flex justify-between items-center">
                <label className="text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Net Amount
                </label>
                <div className="flex items-center">
                  <span className="text-lg 3xl:text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-lg 3xl:text-sm dark:text-white-bg text-right">
                    £{(totalNetAmount || 0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  VAT Amount
                </label>
                <div className="flex items-center">
                  <span className="text-lg 3xl:text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-lg 3xl:text-sm dark:text-white-bg text-right">
                    £{(totalVatAmount || 0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Gross Amount
                </label>
                <div className="flex items-center">
                  <span className="text-lg 3xl:text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-lg 3xl:text-sm dark:text-white-bg text-right">
                    £{(totalGrossAmount || 0).toFixed(2)}
                  </p>
                </div>
              </div>
              
            </div>

            {!isView && (
            <div className="flex col-span-2 gap-2 justify-end">
                        <button
                            type="button"
                            onClick={() => {
                              reset();
                                Close();
                            }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                           // disabled={isLoading}
                            className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
            )}
          </div>
          </form>
        )}
        
      </>
    );
  };
  
  export { AddSuppliersBillEntry };
  