import { HeaderTitle } from "../../components";
import { FiRefreshCcw } from "react-icons/fi";
import { HiOutlineUpload } from "react-icons/hi";
import { BsPersonCircle } from "react-icons/bs";
import ResetPassword from "./ResetPassword";


const MyProfile = () => {
 
  
  return (
    <div>
      <div className="w-full flex flex-col">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-6">
          <HeaderTitle title="My Profile" />
          <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                Email <span className="text-red-600">*</span>
              </label>
              <input
                type="email"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Email"
                required
              />
            </div>
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                First Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter First Name"
                required
              />
            </div>
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                Last Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Last Name"
                required
              />
            </div>
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                Phone Number <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Phone Number"
                required
              />
            </div>
          </div>
        </div>
        {/* <div className="col-span-6">
          <HeaderTitle title="Reset Password" />
          <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                Old Password <span className="text-red-600">*</span>
              </label>
              <input
                type="email"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Old Password"
                required
              />
            </div>
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                New Password <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter New Password"
                required
              />
            </div>
            <div className="flex flex-col items-start">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
                Confirm Password <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Confirm Password"
                required
              />
            </div>
          </div>
        </div> */}
         <div className="col-span-6">
      <ResetPassword />
    </div>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div>
          <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg mb-5">
            Electronic Signature
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div className="grid grid-cols-2 gap-x-3">
              <div
                data-hs-file-upload='{
                        "url": "/upload",
                        "acceptedFiles": "image/*",
                        "maxFiles": 1,
                        "singleton": true
                      }'
              >
                <template data-hs-file-upload-preview="">
                  <div className="size-20">
                    <img
                      className="w-full object-contain rounded-full"
                      data-dz-thumbnail=""
                    />
                  </div>
                </template>

                <div className="block items-center justify-center space-y-5 sm:space-x-5">
                  <div className="group" data-hs-file-upload-previews="">
                    <span className="group-has-[div]:hidden flex shrink-0 justify-center items-center w-full h-24 border-2 border-dotted border-gray-300 text-gray-400 cursor-pointer hover:bg-gray-50 dark:border-neutral-700 dark:text-neutral-600 dark:hover:bg-neutral-700/50">
                      <BsPersonCircle className="size-7" />
                    </span>
                  </div>

                  <div className="flex flex-col justify-center items-start gap-2 w-full sm:w-auto">
                    <button
                      type="button"
                      className="flex items-center text-xs bg-blue-500 text-white-bg p-2 rounded hover:bg-blue-600 transition duration-300"
                      data-hs-file-upload-trigger=""
                    >
                      <HiOutlineUpload className="size-4" />
                      Upload photo
                    </button>

                    <input id="dropzone-file" type="file" className="hidden" />
                  </div>
                </div>
              </div>
              <div className="flex-grow">
                <textarea
                  className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Signature"
                ></textarea>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Token
              </label>
              <button
                type="button"
                className="flex items-center text-xs bg-blue-500 text-white-bg mt-2 px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
              >
                <FiRefreshCcw className="mr-2 h-4 w-4" /> Generate Token
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
      
      <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                type="submit"
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
              >
                Save
              </button>
            </div>
          </div>
    </div>
  );
};

export default MyProfile;
