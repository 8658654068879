import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { addCreateSurvey } from '../../../store/slice/HumanResources/Surveys/CreateSurvey/CreateSurveySlice';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';

function AddSurvey({ openDrawer, Close, createSurvey,isEdit}: { openDrawer: boolean, Close: any, isEdit: Boolean, createSurvey: any }) {
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [createSurveyFileName, setCreateSurveyFileName] = useState('');
    const [documentCreateSurveyFile, setDocumentCreateSurveyFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const validationSchema = object().shape({
        name : string()
            .required('Name is required'),
        description: string(),
        image: string()
            .required('Image is required'),
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        //  console.log(JSON.stringify(data, null, 2));
          if (documentCreateSurveyFile == null) {
              toast.error("Please select the file");
              return;
          }
  
          data.image = createSurveyFileName;
  
          const formData = new FormData();
          formData.append('requestData', JSON.stringify(data));
          formData.append('fileAttached', documentCreateSurveyFile);
  
          console.log(JSON.stringify(data, null, 2));
          setIsLoading(true);
          try {
              await dispatch(addCreateSurvey(data)).unwrap();
              Close();
              toast.success("Successfully Added New Survey");
          } catch (error: any) {
              toast.error(error.toString());
          } finally {
              setIsLoading(false);
          }
      };
      const onError = (errors: any) => {
          console.log("Form submission errors", errors);
      };
      
      const handleFileChange = (event: any) => {
          const file = event.target.files[0];
          if (file) {
              setDocumentCreateSurveyFile(file);
              setCreateSurveyFileName(file.name);
          } else {
              setCreateSurveyFileName('');
          }
      };

       useEffect(() => {
          if (isEdit && createSurvey != null) {
              setValue('name',createSurvey?.name);
              setValue('description',createSurvey?.description );
              setValue('image',createSurvey?.image );
            
          }
      }, [createSurvey, isEdit, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); setOpenModal(Close); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg dark:bg-black p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">Add Survey</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-black">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="space-y-3 w-full">
                <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Image <span className="text-red-500">*</span>
                            </label>
                            <input className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.image ? 'is-invalid' : ''}`}
                             {...register('image')}
                              type="file" multiple
                              onChange={handleFileChange} />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.image?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Name <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.name ? 'is-invalid' : ''}`} {...register('name')}
                                placeholder="Enter Name"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.name?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Description
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Description" {...register('description')}
                            ></textarea>
                        </div>
                        
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                      type='button'
                                      onClick={() =>{
                                          reset();
                                         Close(); 
                                      }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                  {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddSurvey };
