import axios, { AxiosError } from "axios";
import { LocalStorageService } from "./localstorage.service";
import AuthService from "./auth.service";
import { ErrorResult } from "../store/model/errorResult";

//const BACKEND_BASE_URL = process.env.REACT_APP_API_URL;
const keyvalue = process.env.REACT_APP_LOCAL_KEY || 'lait-userinfo';
const axiosInstance = axios.create({
   baseURL: "https://demo-api.legal-software.uk/api/",
// baseURL: "https://localhost:44384/api/",
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    const userinfo = LocalStorageService.getItem<any>(keyvalue)
    if (userinfo) {
      config.headers['Authorization'] = 'Bearer ' + userinfo.token;
    }
    // console.log(userinfo)
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(response => {
  return response
},
  (error: AxiosError) => {
    if (error.response != null) {
      if (error.response.status === 401) {
        AuthService.logout();
        window.location.href = '/login';
        // return Promise.reject(error.response.data);
      } else if (error.response.status === 404) {
        let errorResult: ErrorResult = {
          messages: ['Service Request not found and Please Contact System Adminstrator'],
          errorId: '',
          source: '',
          statusCode: 404,
          supportMessage: '',
          exception: ''
        }
        return Promise.reject(errorResult);
      }

      let result = error.response.data as ErrorResult;
      if (result.messages === null || result.messages.length === 0) {
        result.messages = ['Please Contact System Adminstrator']
      }
      return Promise.reject(result);
    } else {
      let errorResultad: ErrorResult = {
        messages: ['Please Contact System Adminstrator'],
        errorId: '',
        source: '',
        statusCode: 500,
        supportMessage: '',
        exception: ''
      }
      return Promise.reject(errorResultad);
    }
  }
)



export default axiosInstance;