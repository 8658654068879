import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import OfficeNominalLedgerService from "../../../../../services/Accounting/OfficeNominalLedgerService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";

export interface OfficeNominalLedger {
    totalCount: number;
    accounts: OfficeNominalLedgerData[];
    currentPage: number;
    recordPerPage: number;
};

export interface OfficeNominalLedgerGetbyIdData {
    ledgerData: string;
    transactions: []
};
export interface OfficeNominalLedgerData {
    accountId?: number;
    accountCode?: number;
    accountName?: string;
    fkAccountTypeId?: number;
    isEditable?: boolean;
    isActive?: boolean;
    accountTypeId?: number;
    accountType?: string;
    range?: string;
    fkFinancialStatementId?: number;
    toIncrease?: string;
    accountOrder?: number;
    totalCr?: number;
    totalDr?: number;
    runningBalance?: number;
    financialStatement?: string;
}
// Define the state type for Checklist
type OfficeNominalLedgerState = {
    officeNominalLedgerGetbyIdData: OfficeNominalLedgerGetbyIdData;
    officeNominalLedgers: OfficeNominalLedger;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeNominalLedgerState = {
    officeNominalLedgerGetbyIdData: null!,
    officeNominalLedgers: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeNominalLedger = createAsyncThunk(
    "fetchAllOfficeNominalLedger",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeNominalLedgerService.GetAllOfficeNominalLedger(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchOfficeNominalLedgerById = createAsyncThunk(
    "fetchOfficeNominalLedgerById",
    async (id: number, thunkAPI) => {
        try {
            const response = await OfficeNominalLedgerService.GetOfficeNominalLedgerById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const OfficeNominalLedgerSlice = createSlice({
    name: "officeNominalLedger",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeNominalLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeNominalLedger.fulfilled, (state, action: PayloadAction<OfficeNominalLedger>) => {
                state.status = "idle";
                state.officeNominalLedgers = action.payload;
            })
            .addCase(fetchAllOfficeNominalLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchOfficeNominalLedgerById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchOfficeNominalLedgerById.fulfilled, (state, action: PayloadAction<OfficeNominalLedgerGetbyIdData>) => {
                state.status = "idle";
                state.officeNominalLedgerGetbyIdData = action.payload;
            })
            .addCase(fetchOfficeNominalLedgerById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default OfficeNominalLedgerSlice.reducer;