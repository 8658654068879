import { useState } from "react";
import { NewPayment } from "./BankPaymentDetails/NewPayment";
import { AuthorisePayment } from "./BankPaymentDetails/AuthorisePayment";
import { CreatePayment } from "./BankPaymentDetails/CreatePayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaMoneyCheckAlt, FaCheckSquare, FaPlusCircle } from "react-icons/fa";

const BankPayment = () => {


    const [selectedSection, setSelectedSection] = useState('NewPayment');


    const handleButtonClick = (section: any) => {
        setSelectedSection(section);
    };


    return (
        <>
            <div className="w-full flex flex-col gap-3">
                <div className="">
                    {/* <SubHeaderTitle title="Bank Payment" /> */}
                </div>
                <div className="mb-5 w-full">
                    {selectedSection === 'NewPayment' && (
                        <NewPayment />
                    )}
                    {selectedSection === 'AuthorisePayment' && (
                        <AuthorisePayment />
                    )}
                    {selectedSection === 'CreatePayment' && (
                        <CreatePayment />
                    )}
                </div>
            </div>
        </>
    )
}

export { BankPayment };