import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { AddContactStepFour } from "./AddContactStepFour";
import { AddContactStepFive } from "./AddContactStepFive";
import { RootState } from "../../../store/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function AddContactStepThree({ onSubmitStep, initialData, onCancel, onPreviousStep, isLoading }: any) {
    const contactDataById = useSelector((state: RootState) => state.contact.contactDataById);

    const validationSchema = object().shape({
        contactMethod: string(),
        personalNote: string(),

        // contact Four
        personalContAddress1: string()
            .required('Select Address is required'),
        personalContAddress2: string(),
        personalContCity: string(),
        personalContCounty: string(),
        personalContPostCode: string()
            .required('PostCode is required'),
        personalContEmail: string(),
        personalContHomePhone: string(),
        personalContMobile: string()
            .required('Home Phone is required'),

        // AddContactStepFive
        employmentContJobTitle: string(),
        employmentContCompanyName: string(),
        employmentContAddress1: string(),
        employmentContAddress2: string(),
        employmentContCity: string(),
        employmentContCounty: string(),
        employmentContPostCode: string(),
        employmentContEmail: string(),
        employmentContOfficePhone: string(),
        employmentContFax: string(),
    })

    const { register, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
            contactMethod: initialData?.contactMethod || "Personal",
        }
    });

    const handleFormSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        await onSubmitStep(data);
        
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };
    const contactMethod = watch('contactMethod');

    useEffect(()=>{
        if(contactDataById !== null){            
            setValue("contactMethod", contactDataById?.contactMethod);
            setValue("personalNote", contactDataById?.personalNote);

            setValue("personalContAddress1", contactDataById?.personalContAddress1);
            setValue("personalContAddress2", contactDataById?.personalContAddress2);
            setValue("personalContCity", contactDataById?.personalContCity);
            setValue("personalContCounty", contactDataById?.personalContCounty);
            setValue("personalContPostCode", contactDataById?.personalContPostCode);
            setValue("personalContEmail", contactDataById?.personalContEmail);
            setValue("personalContHomePhone", contactDataById?.personalContHomePhone);
            setValue("personalContMobile", contactDataById?.personalContMobile);

            setValue("employmentContJobTitle", contactDataById?.employmentContJobTitle);
            setValue("employmentContCompanyName", contactDataById?.employmentContCompanyName);
            setValue("employmentContAddress1", contactDataById?.employmentContAddress1);
            setValue("employmentContAddress2", contactDataById?.employmentContAddress2);
            setValue("employmentContCity", contactDataById?.employmentContCity);
            setValue("employmentContCounty", contactDataById?.employmentContCounty);
            setValue("employmentContPostCode", contactDataById?.employmentContPostCode);
            setValue("employmentContEmail", contactDataById?.employmentContEmail);
            setValue("employmentContOfficePhone", contactDataById?.employmentContOfficePhone);
            setValue("employmentContFax", contactDataById?.employmentContFax);
        }
    },[contactDataById])
    
    return (
        <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
            <div>
                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Preferred Contact Method</h3>
                <div className="grid grid-cols-3 md:grid-cols-1 grid-rows-1 gap-x-5 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="col-span-2 flex gap-8">
                        <div className="flex items-center">
                            <input id="bordered-radio-1" type="radio" value="Personal"
                                checked={watch('contactMethod') === "Personal"}
                                onChange={() => setValue('contactMethod', "Personal")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Via Personal Contact Details</label>
                        </div>
                        <div className="flex items-center">
                            <input type="radio" value="Employment"
                                checked={watch('contactMethod') === "Employment"}
                                onChange={() => setValue('contactMethod', "Employment")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Via Employment Contact Details</label>
                        </div>
                        <div className="flex items-center">
                            <input type="radio" value="both"
                                checked={watch('contactMethod') === "both"}
                                onChange={() => setValue('contactMethod', "both")}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Via Both Contact Details</label>
                        </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Notes</label>
                        <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                            {...register('personalNote')} />

                    </div>
                </div>
            </div>
            <div>
                {contactMethod === "Personal" && (
                    <AddContactStepFour register={register} setValue={setValue} errors={errors} />
                )}
                {contactMethod === "Employment" && (
                    <AddContactStepFive register={register} setValue={setValue} errors={errors} />
                )}
                {contactMethod === "both" && (
                    <div className=" flex flex-col gap-3">
                        <div>
                            <AddContactStepFour register={register} setValue={setValue} errors={errors} />
                        </div>
                        <div>
                            <AddContactStepFive register={register} setValue={setValue} errors={errors} />
                        </div>
                    </div>
                )}
            </div>

            <div className="">
                <div className="flex flex-row gap-2 items-start justify-between w-full">
                    <button
                        onClick={onPreviousStep}
                        type="button"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        Previous
                    </button>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">

                            <button
                                type="button"
                                onClick={onCancel}
                                className="border border-gray-400 bg-gray-100 text-gray-700 font-medium cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            {/* <button
                                type="submit"
                                className="cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]"
                            >
                                Save
                            </button> */}
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export { AddContactStepThree };
