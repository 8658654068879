import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllOfficeBankDeposit = async (data: any) => {
    try {
        const response = await axiosInstance.post("OABankDepositSearch" , data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetOfficeBankDepositById = async (id: number) => {
    try {
        const response = await axiosInstance.get("OABankDeposit/byid/" + id);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostOfficeBankDeposit = async (data: any) => {
    try {
        const response = await axiosInstance.post("OABankDepositSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeBankDeposit = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeBankDeposit/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeBankDeposit = async (id: number) => {
    try {
        await axiosInstance.delete(`OABankDeposit/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeBankDepositService = {
    GetAllOfficeBankDeposit,
    PostOfficeBankDeposit,
    PutOfficeBankDeposit,
    deleteOfficeBankDeposit,
    GetOfficeBankDepositById,
};


export default OfficeBankDepositService;
