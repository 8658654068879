import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { number, object, string } from 'yup';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { SelectBox } from '../../SelectBox';
import { toast } from 'react-toastify';
import { fetchAllWorkflow } from '../../../store/slice/SystemMaintenance/WorkflowSetupSlice';
interface RiskAssFilterProps {
    FilterName?: string;
    onFilterSelectionSelect?: (data: any) => void;
}

const CaseTypeFilter: React.FC<RiskAssFilterProps> = ({ FilterName, onFilterSelectionSelect }) => {

    const dispatch = useAppDispatch();
    const { areasOfPractice } = useSelector((state: RootState) => state.areasOfPractice);
    const { casetypes } = useSelector((state: RootState) => state.caseType);
    const { CaseSubTypes } = useSelector((state: RootState) => state.caseSubType);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [filteredCaseTypes, setFilteredCaseTypes] = useState<any[]>([]);
    const [filteredCaseSubTypes, setFilteredCaseSubTypes] = useState<any[]>([]);
    const [areaOptionsLists, setAreaOptionsLists] = useState<any[]>([]);
    const [caseTypeOptionsLists, setCaseTypeOptionsLists] = useState<any[]>([]);
    const [subCaseOptionsLists, setSubCaseOptionsLists] = useState<any[]>([]);

    useEffect(() => {
        if (areasOfPractice.length > 0) {
            let data = areasOfPractice.map((area: any) => ({
                value: area.areasOfPracticeId,
                label: area.area,
            }));
            setAreaOptionsLists(data);

        }

    }, [areasOfPractice]);

    useEffect(() => {
        if (filteredCaseTypes != null && filteredCaseTypes.length > 0) {
            let data = filteredCaseTypes.map((casetype: any) => ({
                value: casetype.caseTypeId,
                label: casetype.caseType,
            }));
            setCaseTypeOptionsLists(data);
        }
    }, [filteredCaseTypes]);

    useEffect(() => {
        if (filteredCaseSubTypes != null && filteredCaseSubTypes.length > 0) {
            let data = filteredCaseSubTypes.map((subcase: any) => ({
                value: subcase.caseSubTypeId,
                label: subcase.subCaseType,
            }));
            setSubCaseOptionsLists(data);
        }

    }, [filteredCaseSubTypes]);

    const handleAreaChange = (selectedOption: any) => {
        if (selectedOption) {
            setValue('areasOfPracticeId', selectedOption);
            setFilteredCaseTypes(casetypes.filter((type: any) => type.fkAreasOfPracticeId === selectedOption));
            setFilteredCaseSubTypes([]);
        }
    };

    const handleCaseTypeChange = (selectedOption: any) => {
        if (selectedOption) {
            setValue('caseTypeId', selectedOption);
            setFilteredCaseSubTypes(CaseSubTypes.filter((subType: any) => subType.fkCaseTypeId === selectedOption));
            setValue('caseSubTypeId', undefined);
        }

    };

    const handleSubCaseTypeChange = (selectedOption: any) => {
        setValue('caseSubTypeId', selectedOption);
    };


    const validationSchema = object().shape({
        areasOfPracticeId: number(),
        caseTypeId: number(),
        caseSubTypeId: number(),
        keyword: string(),
    });

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });


    const onSubmit = async (data: any) => {
        // console.log('Form data submitted:', data);
        try {
            await dispatch(fetchAllWorkflow(data)).unwrap();
            toast.success("Successfully search Workflow Request");
            reset();
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3 w-full">
                <div className="grid grid-cols-6 gap-4">
                    <div>
                        <SelectBox
                            className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="areaotionList"
                            options={areaOptionsLists}
                            isSearchable={true}
                            value={getValues().areasOfPracticeId}
                            placeholder="Area of Practice"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={handleAreaChange}
                        />
                    </div>
                    <div>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="caseTypeOptionList"
                            options={caseTypeOptionsLists}
                            value={getValues().caseTypeId}
                            isSearchable={true}
                            placeholder="Case Type"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={handleCaseTypeChange}
                        />
                    </div>
                    <div>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="subCaseTypeOptionList"
                            options={subCaseOptionsLists}
                            value={getValues().caseSubTypeId}
                            isSearchable={true}
                            placeholder="Case Sub Type"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={handleSubCaseTypeChange}
                        />
                    </div>
                    <div className="flex">
                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`} placeholder='Keyword' {...register('keyword')} />
                    </div>
                    <div className='col-start-6 flex justify-end items-end'>
                        <button
                            className={`z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 dark:hover:bg-blue-950 dark:focus:bg-blue-950 bg-hover_bg text-white-bg border-hover_bg ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Search'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export { CaseTypeFilter };