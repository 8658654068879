import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CreditNoteStatusTypeService from "../../../../../services/Accounting/ClientAccounts/Invoice/CreditNoteStatusTypeService";


export interface CreditNoteStatusType {
    creditNoteRefundId: number;

}
// Define the state type for Checklist
type NewCreditNoteType = {
    creditNoteStatusType: CreditNoteStatusType [];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: NewCreditNoteType = {
    creditNoteStatusType: [],
    status: "idle",
    error: null,
};

// Async thunks for CRUD operations
export const fetchAllCreditNoteStatusType = createAsyncThunk(
    "fetchAllCreditNoteStatusType",
    async (_, thunkAPI) => {
        try {
            const response = await CreditNoteStatusTypeService.GetAllCreditNoteStatusType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CreditNoteStatusTypeSlice = createSlice({
    name: "creditNoteStatusType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreditNoteStatusType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCreditNoteStatusType.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.creditNoteStatusType = action.payload;
            })
            .addCase(fetchAllCreditNoteStatusType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            
    }
});

export default CreditNoteStatusTypeSlice.reducer;
