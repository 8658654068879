import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";
import { SearchComponent } from "../../../AutoSelectAddress/SearchInput";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { fetchLocationByPostcode } from "../../../../store/slice/CaseManagement/AddressSlice";
import { SelectAddressComponent } from "../../../AutoSelectAddress/SelectAddress";
import LocationUtils from "../../../../utils/location.utils";


function AddOrganisationClientTwo({ onSubmitStep, initialData, onPreviousStep, }: any) {
    const clientByIdData = useSelector((state: RootState) => state.client.clientByIdData);
    const dispatch = useAppDispatch();
    const [postcode, setPostcode] = useState('');
    const [locationOptionsLists, setLocationOptionsLists] = useState<any[]>([]);
    const { locations } = useSelector((state: RootState) => state.location);
    const [isLoading, setIsLoading] = useState(false);
    const effectServiceCall = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current && postcode) {
            setIsLoading(true);
            dispatch(fetchLocationByPostcode(postcode))
                .unwrap()
                .catch((error) => {
                    console.error("Error fetching location:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch, postcode]);


    useEffect(() => {
        if (locations != null && locations.length > 0) {
            let data = locations.map((location: any) => ({
                value: location.address,
                label: location.address,
            }));
            setLocationOptionsLists(data);
        }
    }, [locations]);

    const onLocationChange = (location: any) => {
        if (location) {
            const obj = LocationUtils.splitAddress(location);
            if (obj != null) {
                setValue('orgCurrentAddLine1', obj.addressLine1)
                setValue('orgCurrentAddLine2', obj.addressLine2)
                setValue('orgCurrentAddCity', obj.city)
                setValue('orgCurrentAddCounty', obj.county)
                setValue('orgCurrentAddPostCode', obj.postcode)
            }
        }
    }

    const validationSchema = object().shape({
        orgCurrentAddLine1: string()
            .required('Select Address is required'),
        orgCurrentAddLine2: string(),

        orgCurrentAddCity: string(),
        orgCurrentAddCounty: string(),
        orgCurrentAddPostCode: string(),
        orgCurrentAddPoboxNumber: string(),
        orgCurrentAddPoboxCounty: string(),
        orgCurrentAddPoboxTown: string(),
        orgCurrentAddPoboxPostCode: string(),
        orgCurrentAddDxNumber: string(),
        orgCurrentAddDxExchange: string(),

        isOrgFutureAddress: boolean(),
        orgFutureAddLine1: string().when('isOrgFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        orgFutureAddLine2: string(),
        orgFutureAddCity: string(),
        orgFutureAddCounty: string(),
        orgFutureAddPostCode: string().when('isOrgFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        orgFutureAddDxNumber: string(),
        orgFutureAddDxExchange: string(),
        orgFutureAddPoboxNumber: string(),
        orgFutureAddPoboxCounty: string(),
        compFutureAddPoboxTown: string(),
        orgFutureAddPoboxPostCode: string(),
        orgFutureAddEffectFrom: string().when('isOrgFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        orgFutureAddIsPreferred: boolean(),
    })

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const isOrgFutureAddress = watch('isOrgFutureAddress', initialData?.isOrgFutureAddress || false);
    const [isComFutureChecked, setIsComFutureChecked] = useState(isOrgFutureAddress || false);
    const isPreferredAddress = watch('orgFutureAddIsPreferred ', initialData?.orgFutureAddIsPreferred || false);
    const [isPreferredAdd, setIsPreferredAdd] = useState(isPreferredAddress || false);

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        //close();
        onSubmitStep(data);
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    const handleIndFutureAddress = (checked: boolean) => {
        setIsComFutureChecked(checked);
        setValue('isOrgFutureAddress', checked, { shouldValidate: true });
    };

    const handlePreferredAddress = (checked: boolean) => {
        setIsPreferredAdd(checked);
        setValue('orgFutureAddIsPreferred', checked, { shouldValidate: true });
    };

    useEffect(() => {
        if (clientByIdData !== null) {
            setValue("orgCurrentAddLine1", clientByIdData?.orgCurrentAddLine1);
            setValue("orgCurrentAddLine2", clientByIdData?.orgCurrentAddLine2);
            setValue("orgCurrentAddCity", clientByIdData?.orgCurrentAddCity);
            setValue("orgCurrentAddCounty", clientByIdData?.orgCurrentAddCounty);
            setValue("orgCurrentAddPostCode", clientByIdData?.orgCurrentAddPostCode);
            setValue("orgCurrentAddPoboxNumber", clientByIdData?.orgCurrentAddPoboxNumber);
            setValue("orgCurrentAddPoboxCounty", clientByIdData?.orgCurrentAddPoboxCounty);
            setValue("orgCurrentAddPoboxTown", clientByIdData?.orgCurrentAddPoboxTown);
            setValue("orgCurrentAddPoboxPostCode", clientByIdData?.orgCurrentAddPoboxPostCode);
            setValue("orgCurrentAddDxNumber", clientByIdData?.orgCurrentAddDxNumber);
            setValue("orgCurrentAddDxExchange", clientByIdData?.orgCurrentAddDxExchange);
            
            setValue("isOrgFutureAddress", ( clientByIdData?.isOrgFutureAddress)? clientByIdData?.isOrgFutureAddress: false);
            // setValue("isOrgFutureAddress", clientByIdData?.isOrgFutureAddress);
            setValue("orgFutureAddLine1", ( clientByIdData?.orgFutureAddLine1)? clientByIdData?.orgFutureAddLine1: "");
            setValue("orgFutureAddLine2", ( clientByIdData?.orgFutureAddLine2)? clientByIdData?.orgFutureAddLine2: "");
            setValue("orgFutureAddCity", ( clientByIdData?.orgFutureAddCity)? clientByIdData?.orgFutureAddCity: "");
            setValue("orgFutureAddCounty", ( clientByIdData?.orgFutureAddCounty)? clientByIdData?.orgFutureAddCounty: "");
            setValue("orgFutureAddPostCode", ( clientByIdData?.orgFutureAddPostCode)? clientByIdData?.orgFutureAddPostCode: "");
            setValue("orgFutureAddDxNumber", ( clientByIdData?.orgFutureAddDxNumber)? clientByIdData?.orgFutureAddDxNumber: "");
            setValue("orgFutureAddDxExchange", ( clientByIdData?.orgFutureAddDxExchange)? clientByIdData?.orgFutureAddDxExchange: "");
            setValue("orgFutureAddPoboxNumber", ( clientByIdData?.orgFutureAddPoboxNumber)? clientByIdData?.orgFutureAddPoboxNumber: "");
            setValue("orgFutureAddPoboxCounty", ( clientByIdData?.orgFutureAddPoboxCounty)? clientByIdData?.orgFutureAddPoboxCounty: "");
            setValue("compFutureAddPoboxTown", ( clientByIdData?.compFutureAddPoboxTown)? clientByIdData?.compFutureAddPoboxTown: "");
            setValue("orgFutureAddPoboxPostCode", ( clientByIdData?.orgFutureAddPoboxPostCode)? clientByIdData?.orgFutureAddPoboxPostCode: "");
            setValue("orgFutureAddEffectFrom", ( clientByIdData?.orgFutureAddEffectFrom)? clientByIdData?.orgFutureAddEffectFrom: "");
            setValue("orgFutureAddIsPreferred", ( clientByIdData?.orgFutureAddIsPreferred)? clientByIdData?.orgFutureAddIsPreferred: false);
        }
    }, [clientByIdData])

    return (
        <div>
            <form onSubmit={handleSubmit(handleFormSubmit, onError)}>

                <div>
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Current Address</h3>
                        <SearchComponent setPostcode={setPostcode} loading={isLoading} />
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                        {locationOptionsLists !== null && locationOptionsLists.length > 0 && (
                            <div className="">
                                <SelectAddressComponent
                                    locationOptionsLists={locationOptionsLists ?? []}
                                    onLocationChange={onLocationChange}
                                    setValue={setValue}
                                />
                            </div>
                        )}

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 1 <span className="text-red-500">*</span>
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.orgCurrentAddLine1 ? 'is-invalid' : ''}`} {...register('orgCurrentAddLine1')} />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.orgCurrentAddLine1?.message?.toString()} </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 2
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgCurrentAddLine2')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                City
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgCurrentAddCity')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgCurrentAddCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Postcode
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 }`} {...register('orgCurrentAddPostCode')} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O DX Address</h3>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgCurrentAddDxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Exchange
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgCurrentAddDxExchange')} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O Box Address</h3>
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgCurrentAddPoboxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Town
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgCurrentAddPoboxTown')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgCurrentAddPoboxCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Post Code
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgCurrentAddPoboxPostCode')} />
                        </div>
                    </div>
                </div>

                <div className="flex gap-5">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Future Address
                    </label>
                    <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                        checked={isComFutureChecked}
                        onChange={(e) => handleIndFutureAddress(e.target.checked)}
                    />
                </div>
                {isComFutureChecked && (
                    <>
                        <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address Line 1 <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.orgFutureAddLine1 ? 'is-invalid' : ''} `} {...register('orgFutureAddLine1')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.orgFutureAddLine1?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address Line 2
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgFutureAddLine2')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    City
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgFutureAddCity')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    County
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgFutureAddCounty')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Postcode <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.orgFutureAddPostCode ? 'is-invalid' : ''} `} {...register('orgFutureAddPostCode')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.orgFutureAddPostCode?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Effect From <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.orgFutureAddEffectFrom ? 'is-invalid' : ''} `} {...register('orgFutureAddEffectFrom')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.orgFutureAddEffectFrom?.message?.toString()}</div>
                            </div>
                            <div className=" flex gap-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Set as preferred contact address
                                </label>
                                <input
                                    type="checkbox"
                                    className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                    checked={isPreferredAdd}
                                    onChange={(e) => handlePreferredAddress(e.target.checked)}
                                />
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="flex items-center justify-between mb-5">
                                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O DX Address</h3>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Number
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgFutureAddDxNumber')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Exchange
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgFutureAddDxExchange')} />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="flex items-center justify-between mb-5">
                                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O Box Address</h3>
                            </div>
                            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Number
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgFutureAddPoboxNumber')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Town
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddPoboxTown')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        County
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgFutureAddPoboxCounty')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Post Code
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgFutureAddPoboxPostCode')} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="">
                    <div className="flex flex-row gap-2 items-start justify-between w-full">
                        <button
                            onClick={onPreviousStep}
                            type="button"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                            Previous
                        </button>

                        <button
                            type="submit"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                            Next
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export { AddOrganisationClientTwo };
