import { useEffect, useRef, useState } from "react";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllOfficeToClientReports } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";

function ReportsOfficeToClient() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [officeToClientReportsDataList, setOfficeToClientReportsDataList] =
    useState<any[]>([]);
  const officeToClientReports = useSelector(
    (state: RootState) => state.clientReports.officeToClientReports
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(10);
  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeAccountLists, setOfficeAccountLists] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    officeBankAccId: 0
  });
  const optiondata = [
    {
      name: "Office Account",
      options: officeAccountLists
    },
  ]

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let searchdata = {
        keyword: filters.searchTerm,
        fromBank: filters.officeBankAccId,
        currentPage: currentPage,
        recordsPerPage: totalPages,
        dateStart: (filters.startDate == "" ? null : filters.startDate),
        dateEnd: (filters.endDate == "" ? null : filters.endDate),
      }
      dispatch(fetchAllOfficeToClientReports(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch, filters]);

  useEffect(() => {
    if (
      officeToClientReports &&
      officeToClientReports.officeToClientReportResult !== null
    ) {
      setOfficeToClientReportsDataList(
        officeToClientReports.officeToClientReportResult
      );
      setTotalRecords(officeToClientReports.totalCount);
    }
  }, [officeToClientReports]);

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  useEffect(() => {
    if (officeBanks.length > 0) {
      // console.log(officeBanks)
      let data = officeBanks.map((officeAccount: any, index) => ({
        value: officeAccount.bankAccountId,
        label: `${officeAccount.institution} - ${officeAccount.bankAccountType}`
        ,
      }));
      data.unshift({ value: 0, label: "All Bank" });
      setOfficeAccountLists(data);
    }
  }, [officeBanks]);

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
            <SubHeaderTitle title="Office to Client" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-lg 3xl:text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-lg 3xl:text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
        </div>
        <div className="w-full">
          <AccountFilterComponent
            onFilterChange={handleFilterChange}
            initialFilters={filters}
            optiondata={optiondata}
          />
        </div>
        {/* <div className="my-5">
          <div className="my-5 text-center bg-sky-50 dark:bg-gray-800 dark:text-white-bg p-6">
            <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
            <p className="text-lg font-semibold">
              Office Bank to Client Bank Transaction Report
            </p>
            <p className="font-semibold">04 Sep 2024</p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Area of Practice
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Case Type
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Case Sub Type
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Client Bank
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Date
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Keyword
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">N/A</p>
            </div>
          </div>
        </div> */}
        <div className="relative overflow-hidden sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Case Reference
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    From Bank (Office)
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    To Bank (Client)
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {officeToClientReportsDataList.map((O2C: any, index) => (
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(O2C.entryDate)}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{O2C.caseReferenceAuto}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <p className="font-semibold">
                        {O2C.accountName} - {O2C.bankAccountType}
                      </p>
                      <p>{O2C.accountNumber}</p>
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <p className="font-semibold">
                        {O2C.toAccountName} - {O2C.toBankAccountType}
                      </p>
                      <p>{O2C.toAccountNumber}</p>
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{O2C.amountTransfer}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Office To Client Reports.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { ReportsOfficeToClient };
