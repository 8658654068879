import { useEffect, useRef, useState } from "react";
import { AddTimeEntry } from "../Modal/AddClientAccounts/AddClientInvoice/AddTimeEntry";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import {
  FaEdit,
  FaEye,
  FaPause,
  FaPlay,
  FaPlus,
  FaSave,
  FaStop,
} from "react-icons/fa";
import {
  addCATimerEntries,
  fetchTimeEntrySearch,
} from "../../store/slice/Accounting/ClientAccounts/Invoice/TimeEntrySlice";

import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { SelectBox } from "../SelectBox";
import { NumericFormatWrapper } from "../InputComponents/NumericFormatWrapper";
import { SearchCaseReferenceDetails } from "../Modal/SearchCaseReferenceDetails";
import { fetchClientCaseBalance } from "../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { fetchUsersDataByBranch } from "../../store/slice/Administrator/UserManagementSlice";
import { fetchAllTimeEntryType } from "../../store/slice/Accounting/AccountSetup/TimeEntryTypeSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { number, object, string } from "yup";
import { toast } from "react-toastify";
import { MdOutlineRefresh } from "react-icons/md";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { Pagination } from "../Pagination";

interface TimeEntryRow {
  elapsedTime: string;
  isRunning: boolean;
  intervalId: ReturnType<typeof setInterval> | null;
  caseReference: string;
  caseId: string;
  staff: string;
  selectedActivityId: number | null;
  units: number;
  rate: number;
  value: number;
  vat: number;
  vatId: number;
  total: number;
  isTimerStarted: boolean;
  caseTimeEntryId: number;
  timeraction: string;
}

function TimeEntry({ isCase, caseData }: { isCase?: boolean, caseData?: any }) {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { timeEntryData, timeEntryId } = useSelector((state: RootState) => state.clientTimeEntry);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [getDataList, setGetDataList] = useState<any>([]);
  const itemsPerPage = 10;
  const [currentFetchDataList, setCurrentFetchDataList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(10);
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState<
    number | null
  >(null);
  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);
  const [timeEntryActivity, setTimeEntryActivity] = useState<any[]>([]);
  const { timeEntryTypes } = useSelector(
    (state: RootState) => state.timeEntryType
  );
  const { officeVatRates } = useSelector(
    (state: RootState) => state.officeVatRate
  );
  const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);
  const [selectedVat, setSelectedVat] = useState<number | null>(null);
  const [selectedUnitMinutes, setSelectedUnitMinutes] = useState(null);
  const [setErrors] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null);
  const [currentRunningRowIndex, setCurrentRunningRowIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempStatus, setTempStatus] = useState<string>("");
  const statusOptions = ["Pending", "In Progress", "Completed"];
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const toggleDrawer = () => { setOpenModal(!openModal); };
  const [submittedRows, setSubmittedRows] = useState<TimeEntryRow[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [rows, setRows] = useState<TimeEntryRow[]>([
    {
      elapsedTime: "00:00:00",
      isRunning: false,
      intervalId: null,
      caseReference: "",
      caseId: "",
      staff: "",
      selectedActivityId: null,
      units: 0,
      rate: 0,
      value: 0,
      vat: 0,
      vatId: 0,
      total: 0,
      isTimerStarted: false,
      caseTimeEntryId: 0,
      timeraction: "",
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        elapsedTime: "00:00:00",
        isRunning: false,
        intervalId: null,
        caseReference: isCase && caseData ? caseData?.caseReferenceAuto : "",
        caseId: isCase && caseData ? caseData?.caseId : "",
        staff: "",
        selectedActivityId: null,
        units: 0,
        rate: 0,
        value: 0,
        vat: 0,
        vatId: 0,
        total: 0,
        isTimerStarted: false,
        caseTimeEntryId: 0,
        timeraction: "",
      },
    ]);
  };

  const validationSchema = object().shape({
    staff: string().required("Staff is required."),
    selectedActivityId: number()
      .required("Activity is required.")
      .typeError("Activity must be a number."),
    caseId: string().required("Case Reference is required."),
    rate: number()
      .required("Rate is required.")
      .positive("Rate must be a positive number.")
      .typeError("Rate must be a valid number."),
    caseTimeEntryId: number(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // caseTimeEntryId: 0,
    },
  });
  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      // dispatch(fetchTimeEntrySearch());
      dispatch(fetchUsersDataByBranch());
      dispatch(fetchAllTimeEntryType())
      // dispatch(fetchClientCaseBalance(Number(caseData?.caseId)));
      // dispatch(fetchTimeEntrySearch({}))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (timeEntryData && timeEntryData.timerEntriesResult) {
      setGetDataList(timeEntryData.timerEntriesResult);
      setTotalRecords(timeEntryData.totalCount);

      
    }
  }, [timeEntryData]);


  useEffect(() => {
    if (branchUsers.length > 0) {
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} `,
      }));
      setBranchUserLists(data);
    }
  }, [branchUsers]);

  useEffect(() => {
    if (timeEntryTypes.length > 0) {
      const data = timeEntryTypes.map((item: any) => ({
        value: item,
        label: item.billingDescription,
      }));
      setTimeEntryActivity(data);
    }
  }, [timeEntryTypes]);

  useEffect(() => {
    if (officeVatRates.length > 0) {
      let data = officeVatRates.map((officeVatRate: any, index) => ({
        value: officeVatRate.vatTypeId,
        // label: `${officeVatRate.vatType}`,
        percentage: officeVatRate.percentage,
      }));
      setOfficeVatRateLists(data);
    }
  }, [officeVatRates]);

  const convertToMinutes = (time: string) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return (hours || 0) * 60 + (minutes || 0) + (seconds || 0) / 60;
  };

  const incrementTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    let totalSeconds =
      (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0) + 1;

    const newHours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const newMinutes = Math.floor(totalSeconds / 60);
    const newSeconds = totalSeconds % 60;

    return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
      2,
      "0"
    )}:${String(newSeconds).padStart(2, "0")}`;
  };

  const calculateUnits = (elapsedTime: string, unitsInMinutes: number) => {
    const elapsedTimeInMinutes = convertToMinutes(elapsedTime);
    if (elapsedTimeInMinutes < unitsInMinutes) {
      return 1; // Return 1 unit if elapsed time is less than unitsInMinutes
    }
    return Math.floor(elapsedTimeInMinutes / unitsInMinutes); // Calculate units after threshold
  };

  const handlePlayPause = async (index: number, state: string) => {
    setCurrentRunningRowIndex(index);
    const newRows = [...rows];
    const row = newRows[index];

    // Validate if the row exists
    if (!row) {
      toast.error("Invalid row index.");
      return;
    }

    // Check if Case Reference, Activity, and Rate are set
    if (!row.caseId) {
      toast.error("Please select a Case Reference.");
      return;
    }
    if (!row.selectedActivityId) {
      toast.error("Please select an Activity.");
      return;
    }
    if (!row.rate) {
      toast.error("Please enter a Rate value.");
      return;
    }

    if (row.isRunning) {
      // Pause the timer
      if (row.intervalId) {
        clearInterval(row.intervalId as unknown as number);
      }
      row.isRunning = false;
      row.intervalId = null;

      // Recalculate units on pause
      if (row.elapsedTime) {
        const selectedActivity = timeEntryTypes.find(
          (activity: any) => activity.timeEntryTypeId === row.selectedActivityId
        );
        if (selectedActivity) {
          row.units = calculateUnits(
            row.elapsedTime,
            selectedActivity.unitsInMinutes
          );
          row.value = row.units * row.rate; // Update value (units * rate)
          row.total = row.value + row.value * (row.vat / 100);
        }
      }
    } else {
      // Start the timer
      const intervalId = setInterval(() => {
        row.elapsedTime = incrementTime(row.elapsedTime || "00:00:00");

        // Update units every second based on elapsed time and unitsInMinutes
        if (row.elapsedTime) {
          const selectedActivity = timeEntryTypes.find(
            (activity: any) =>
              activity.timeEntryTypeId === row.selectedActivityId
          );
          if (selectedActivity) {
            const elapsedTimeInMinutes = convertToMinutes(row.elapsedTime);

            // Set units to 1 if less than unitsInMinutes, otherwise calculate normally
            if (elapsedTimeInMinutes < selectedActivity.unitsInMinutes) {
              row.units = 1; // Keep units as 1 until the threshold is reached
            } else {
              row.units = calculateUnits(
                row.elapsedTime,
                selectedActivity.unitsInMinutes
              );
            }
          }
        }

        setRows([...newRows]); // Update the rows with the new elapsed time and units
      }, 1000);

      row.isRunning = true;
      row.intervalId = intervalId;
      row.isTimerStarted = true;
    }

    setRows(newRows);
    const rowVal = newRows[index];
    // Prepare the data for submission
    const rowData = {
      activityId: rowVal.selectedActivityId,
      elapsedSeconds: convertToSeconds(rowVal.elapsedTime),
      caseTimeEntryId: timeEntryId > 0 ? timeEntryId: rowVal.caseTimeEntryId,
      rate: rowVal.rate,
      fkVatTypeId: rowVal.vatId,
      caseId: rowVal.caseId,
      timeraction: state // Explicitly set to "stop"
      // total: row.rate * row.units + row.rate * row.units * (row.vat / 100), // Optional
    };

    try {
      setIsLoading(true);
      await dispatch(addCATimerEntries(rowData)).unwrap();
      toast.success(`Row ${index + 1} saved successfully`);
    } catch (error: any) {
      toast.error(`Failed to save row ${index + 1}: ${error.toString()}`);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (timeEntryId > 0 && (currentRunningRowIndex != null && currentRunningRowIndex > 0)) {
  //     const newRows = [...rows];
  //     const row = newRows[currentRunningRowIndex];
  //     row.caseTimeEntryId = timeEntryId;
  //     setRows(newRows)
  //     setValue('caseTimeEntryId', timeEntryId)
  //   }

  // }, [timeEntryId])


  const handleStop = async (index: number) => {
    const newRows = [...rows];
    const row = newRows[index];

 
    if (row.intervalId) {
      clearInterval(row.intervalId as unknown as number);
    }
    row.isRunning = false;
    row.intervalId = null;
    row.isTimerStarted = false;

    
    const rowData = {
      activityId: row.selectedActivityId,
      elapsedSeconds: convertToSeconds(row.elapsedTime),
      caseTimeEntryId: timeEntryId > 0 ? timeEntryId: row.caseTimeEntryId,
      rate: row.rate,
      fkVatTypeId: row.vatId,
      caseId: row.caseId,
      timeraction: "stop", 
    };

    

    try {
      setIsLoading(true);
      await dispatch(addCATimerEntries(rowData)).unwrap().then(x => {
      });
      toast.success(`Row ${index + 1} saved successfully`);
      setSubmittedRows([...submittedRows, row]);

    
      setRows(rows.filter((_, index) => index !== index));
      reset();
    } catch (error: any) {
      toast.error(`Failed to save row ${index + 1}: ${error.toString()}`);
    } finally {
      setIsLoading(false);
      setRows(newRows); 
    }
  };



  const handleActivityChange = (selectedOption: any, rowIndex: number) => {
    if (selectedOption) {
  
      const updatedRows = [...rows];

      
      updatedRows[rowIndex].selectedActivityId = selectedOption.timeEntryTypeId;
      updatedRows[rowIndex].vatId = selectedOption.fkVatTypeId;

      setSelectedVat(selectedOption.fkVatTypeId);
      setSelectedUnitMinutes(selectedOption.unitsInMinutes);

    
      const selectedVatType = officeVatRateLists.find(
        (rate) => rate.value === selectedOption.fkVatTypeId
      );

     
      if (selectedVatType) {
       
        updatedRows[rowIndex].vat = selectedVatType.percentage;
      }

     
      if (updatedRows[rowIndex].elapsedTime) {
        const elapsedTimeInMinutes = convertToMinutes(
          updatedRows[rowIndex].elapsedTime
        );
        const calculatedUnits =
          elapsedTimeInMinutes / selectedOption.unitsInMinutes;

        updatedRows[rowIndex].units = calculatedUnits;
      }

     
      setRows(updatedRows);
    }
  };

  const toggleCaseAccountModal = (index: number | null) => {
    setOpenCaseAccountModal(index); 
  };

  const handleCaseSelection = (selectedCase: any, rowIndex: number) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      caseReference: selectedCase.caseReferenceAuto,
      caseId: selectedCase.caseId,
    };
    setRows(updatedRows);
    // dispatch(fetchClientCaseBalance(selectedCase.caseId));
    setOpenCaseAccountModal(null);
  };

  useEffect(() => {
    if (isCase && caseData !== null) {
      const updatedRows = rows.map(row => ({
        ...row,
        caseReference: caseData?.caseReferenceAuto,
        caseId: caseData?.caseId,
      }));
      setRows(updatedRows);
    }
  }, [isCase, caseData]);

  // console.log(caseData);

  // useEffect(() => {
  //   if (isCase && caseData !== null) {
  //     dispatch(fetchClientCaseBalance(caseData?.caseId));
  //   }
  // }, [isCase, caseData, dispatch]);


  const handleInputChange = (index: number, field: string, value: any) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value, 
      };
      return updatedRows;
    });
  };

 
  const convertToSeconds = (time: string): number => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };



  const handleSaveClick = (index: number, formData: any) => {
    const updatedRows = [...rows];

    updatedRows[index] = {
      ...updatedRows[index],
      ...formData,
    };
    setRows(updatedRows);
    setEditingRowIndex(null);

    toast.success(`Row ${index + 1} saved successfully`);
  };

  const handleRevertClick = () => {
    setEditingRowIndex(null); 
  };

  // Function to handle edit click
  const handleEditClick = (index: number, currentStatus: string) => {
    setEditingRowIndex(index);
    setTempStatus(currentStatus);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const onPageChange =(page: number) =>{
    setCurrentPage(page);
    let searchdata= {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchTimeEntrySearch(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
}

  return (
    <>
      <div className="">
        <SubHeaderTitle title="Time Entry" />

        <div className="mb-5">
          <div className="relative overflow-x-auto sm:rounded-lg">
            <div className="my-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                type="button"
                onClick={addRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>
            <form className="mx-auto my-5">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border-gray-400 dark:border-opacity-35">
                    <th
                      scope="col"
                      className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Elapsed time
                    </th>
                    {/* <th
                      scope="col"
                      className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Case Reference
                    </th> */}
                    {/* <th scope="col" className="p-3">Staff</th> */}
                    <th
                      scope="col"
                      className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Activity
                    </th>
                    <th
                      scope="col"
                      className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Units
                    </th>
                    <th
                      scope="col"
                      className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Rate
                    </th>
                    <th
                      scope="col"
                      className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Value
                    </th>
                    <th
                      scope="col"
                      className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      VAT(%)
                    </th>
                    <th
                      scope="col"
                      className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Total
                    </th>
                    <th
                      scope="col"
                      className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      Actions
                    </th>
                    {/* <th
                      scope="col"
                      className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    ></th> */}
                  </tr>
                </thead>
                <tbody className="text-lg 3xl:text-xs">
                  {rows.map((row, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="px-2 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <input
                          type="text"
                          className="border border-gray-300 p-1 w-full"
                          value={row.elapsedTime}
                          readOnly
                          placeholder="00:00:00"
                        />
                      </td>
                      {/* <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="flex flex-col w-full">
                          
                          {!isCase && (
                            <>
                              <button
                                onClick={() => toggleCaseAccountModal(index)}
                                className="w-full rounded border border-primary px-3 py-1 text-xs font-medium text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                                type="button"
                              >
                                Search
                              </button>

                              {openCaseAccountModal === index && (
                                <SearchCaseReferenceDetails
                                  openDrawer={openCaseAccountModal === index}
                                  Close={() => toggleCaseAccountModal(null)} // Close modal by setting to null
                                  onCaseSelectDetail={(selectedCase) =>
                                    handleCaseSelection(selectedCase, index)
                                  }
                                />
                              )}
                            </>
                          )}

                          
                          <input
                            type="search"
                            className={`w-full rounded border border-solid border-neutral-200 bg-transparent bg-clip-padding px-2 py-1 text-xs font-normal text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:border-primary focus:shadow-inset focus:outline-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-600 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300" ${errors.caseId ? "is-invalid" : ""
                              }`}
                            placeholder="Search"
                            aria-label="Search"
                            disabled
                            value={row.caseReference || ""}
                          />
                          {errors.caseId && (
                            <p className="text-xs text-red-500">
                              {errors.caseId.message}
                            </p>
                          )}
                        </div>
                      </td> */}
                      {/* <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          isMulti={false}
                          name="staff"
                          options={branchUserLists}
                          isSearchable={true}
                          placeholder="Select"
                           value={getValues().staff}
                          onChange={(selectedOption) =>
                            handleInputChange(index, "staff", selectedOption)
                          }
                        />
                        {errors.selectedActivityId && (
                        <p className="text-xs text-red-500">{errors.selectedActivityId.message}</p>
                        )}
                    </td> */}
                      <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <SelectBox
                          className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md block w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                          indicator={
                            <svg
                              width="15"
                              className="fill-current mr-2"
                              height="15"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                          }
                          isMulti={false}
                          options={timeEntryActivity}
                          value={row.selectedActivityId}
                          name="frameFiftySix"
                          isSearchable={true}
                          placeholder="Select"
                          shape="round"
                          color="indigo_50"
                          size="xs"
                          onChange={(selectedOption) =>
                            handleActivityChange(selectedOption, index)
                          }
                        />
                        {errors.selectedActivityId && (
                          <p className="text-xs text-red-500">
                            {errors.selectedActivityId.message}
                          </p>
                        )}
                      </td>
                      <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormatWrapper
                          decimalScale={0}
                          className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-16 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right"
                          value={row.units}
                          onValueChange={(values) =>
                            handleInputChange(
                              index,
                              "units",
                              parseFloat(values.value)
                            )
                          }
                          placeholder="Units"
                        />
                      </td>
                      <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormatWrapper
                          decimalScale={2}
                          className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-16 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right"
                          value={row.rate}
                          onValueChange={(values) =>
                            handleInputChange(
                              index,
                              "rate",
                              parseFloat(values.value)
                            )
                          }
                          placeholder="Rate"
                        />
                        {errors.rate && (
                          <p className="text-xs text-red-500">
                            {errors.rate.message}
                          </p>
                        )}
                      </td>
                      <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormatWrapper
                          decimalScale={2}
                          className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-16 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right"
                          value={(row.units * row.rate).toFixed(2)}
                          onValueChange={(values) =>
                            handleInputChange(
                              index,
                              "value",
                              parseFloat(values.value)
                            )
                          }
                          placeholder="units value"
                        />
                      </td>
                      <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormatWrapper
                          decimalScale={2}
                          className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-16 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right"
                          value={row.vat || ""} // Set VAT value from row data
                          onValueChange={(values) =>
                            handleInputChange(
                              index,
                              "vat",
                              parseFloat(values.value)
                            )
                          }
                          placeholder="VAT"
                          disabled
                        />
                      </td>
                      <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormatWrapper
                          decimalScale={2}
                          className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-16 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right"
                          value={
                            row.units * row.rate +
                            (row.units * row.rate * row.vat) / 100
                          }
                          onValueChange={(values) =>
                            handleInputChange(
                              index,
                              "total",
                              parseFloat(values.value)
                            )
                          }
                          placeholder="total"
                        />
                      </td>
                      <td className="p-3 flex items-center space-x-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {!row.isTimerStarted ? (
                          <FaPlay
                            className="fill-green-600 w-4 h-4 cursor-pointer"
                            onClick={() => handlePlayPause(index, 'start')}
                          />
                        ) : (
                          <>
                            {/* Show Pause/Play button based on timer state */}
                            {row.isRunning ? (
                              <FaPause
                                className="fill-blue-600 w-4 h-4 cursor-pointer"
                                onClick={() => handlePlayPause(index, 'pause')}
                              />
                            ) : (
                              <FaPlay
                                className="fill-green-600 w-4 h-4 cursor-pointer"
                                onClick={() => handlePlayPause(index, 'start')}
                              />
                            )}

                            {/* Stop Button */}
                            {isLoading ? (
                              <svg
                                className="animate-spin h-5 w-5 text-white-bg mx-auto"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8v8H4z"
                                ></path>
                              </svg>
                            ) : (
                              <FaStop
                                className="fill-red-600 w-4 h-4 cursor-pointer"
                                onClick={() => handlePlayPause(index, 'stop')}
                              />
                            )}
                          </>
                        )}
                      </td>
                      <td className="p-3 flex items-center space-x-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <RiDeleteBin5Fill
                          className="fill-red-600 w-4 h-4 cursor-pointer"
                          onClick={() => {
                            const newRows = rows.filter((_, i) => i !== index);
                            setRows(newRows);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        </div>


        {!isCase && (
          <>
            <div className="mb-5">
              <div className="">
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                  <SearchInput title="Keyword" onChange={(e) =>
                        (e)
                    } />
                </div>
                <div className="bg-gray-100 p-3 border border-gray-300 rounded mb-5">
                  <div className="grid grid-cols-7 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
                    {/* <CheckBoxFilter title="Areas of Practice" /> */}
                    <CheckBoxFilter title="Case Type" />
                    {/* <CheckBoxFilter title="Case Sub Type" /> */}
                    <CheckBoxFilter title="Approval Status" />
                    <CheckBoxFilter title="Staff" />
                    <CheckBoxFilter title="Status" />
                    <JustifyDateFilter
                      title="Date Between"
                      plannedStartDate={plannedStartDate}
                      plannedEndDate={plannedEndDate}
                      onPlannedStartDateChange={handleStartDateChange}
                      onPlannedEndDateChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </div>
            </div>

        <div className="mb-5">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400 my-5">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                {/* <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th> */}
                <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Elapsed time
                </th>
                <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Reference
                </th>
                <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Staff
                </th>
                <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Activity
                </th>
                <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Units
                </th>
                <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Rate
                </th>
                <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Value
                </th>
                <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  VAT(%)
                </th>
                <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Total
                </th>
                <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Status
                </th>
                <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {getDataList.map((timeEntry: any, index: number) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  {/* <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{index + 1}</td> */}
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.elapsedTime}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.caseReferenceAuto}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.timeEntryStaff}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.billingDescription}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{timeEntry.units}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(timeEntry.unitRate)}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(timeEntry.amount)}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{timeEntry.percentage}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(timeEntry.total)}</td>
                  <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {editingRowIndex === index ? (
                      <select
                        className="border dark:border-gray-400"
                        value={tempStatus}
                        onChange={(e) => setTempStatus(e.target.value)}
                      >
                        {statusOptions.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      timeEntry.status
                    )}
                  </td>
                  <td className="text-center w-12 p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {editingRowIndex === index ? (
                      <>
                        <div className="flex items-center gap-2">
                          <FaSave
                            className="fill-blue-600 w-4 h-4"
                            onClick={() => {
                              const formData = getValues(); // Retrieve current form data from react-hook-form
                              handleSaveClick(index, formData); // Call the save function with row index and form data
                            }}
                          />

                          <MdOutlineRefresh
                            className="fill-blue-800 w-6 h-6 cursor-pointer"
                            onClick={handleRevertClick}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center gap-2">
                          <FaEdit
                            className="fill-blue-500 w-6 h-6 cursor-pointer"
                            onClick={() =>
                              handleEditClick(index, timeEntry.status)
                            }
                          />
                          <RiDeleteBin5Fill className="fill-red-600 w-6 h-6 cursor-pointer" />
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        
        {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No TimeEntry Details found.</p>
            </div>
          )}
          </div>
        {totalRecords > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalRecords={totalRecords}
                            onPageChange={(page) => {onPageChange(page);}}
                        />
                    </div>
                )}
        </>
             )}
      </div>
    </>
  );
}

export { TimeEntry };
