import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { Heading } from "../../AccountSetupHeading/Heading";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { SelectBox } from "../../SelectBox";

function AddExpertStepOne({
  onSubmitStep,
  initialData,
  titleData,
  onCancel,
}: any) {
  const clientByIdData = useSelector(
    (state: RootState) => state.client.clientByIdData
  );
  const expertRegisterByIdData = useSelector((state: RootState) => state.expertRegister.expertRegsterByIdData);
  const [selectedClientType, setSelectedClientType] = useState("individual");
  // console.log(titleData)
  // console.log(maritalStatusData)
  // console.log(userRoleData)

  const validationSchema = object().shape({
    fkTitleId: number(),
    name: string(),
    address1: string(),
    address2: string(),
    city: string(),
    county: string(),
    postCode: string(),
    email: string(),
    Phone1: number(),
    expertType: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialData || {
    },

  });

  const titleOption = titleData
    ? titleData.map((title: any) => ({
        label: title.title,
        value: title.titleId,
        
      }))
    : [];

  const handleFormSubmit = (data: any) => {
    
    const formData = {
      ...data,
      expertRegisterType: selectedClientType, // Include expertType
    };
    onSubmitStep(formData);
    console.log(JSON.stringify(formData, null, 2));
  };
  
  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
};

  useEffect(() => {
    if (expertRegisterByIdData !== null) {
      // let dateOfBirth = (expertRegisterByIdData?.dateOfBirth !== null) ? clientByIdData?.dateOfBirth.split("T")[0] : null;
      setValue("fkTitleId", expertRegisterByIdData?.fkTitleId);
      setValue("name", expertRegisterByIdData?.name);
      setValue("address1", expertRegisterByIdData?.address1);
      setValue("address2", expertRegisterByIdData?.address2);
      setValue("city", expertRegisterByIdData?.city);
      setValue("country", expertRegisterByIdData?.country);
      setValue("postCode", expertRegisterByIdData?.postCode);
      // setValue("dateOfBirth", dateOfBirth);
      // setValue("countryOfBirth", clientByIdData?.countryOfBirth);
      //setValue("placeOfBirth", clientByIdData?.placeOfBirth);
      setValue("email", clientByIdData?.email);
    }
  }, [expertRegisterByIdData]);

  const handleRadioChange = (value: any) => {
    setSelectedClientType(value);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
      <div>
        <Heading title={"Personal Details"} />
        <div className="grid grid-cols-12 md:grid-rows-1 gap-12 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
          <div className="relative col-span-2">
            <img
              className="size-40 rounded-lg object-cover"
              src="/images/header/user2.png"
              alt="user"
            />
            <div className="cursor-pointer bg-hover_bg px-2 py-1 rounded-tr-lg absolute top-0 -right-9 mr-14">
              <button>
                <svg
                  className="w-4 h-4 fill-white-bg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="col-span-5">
            {selectedClientType === "individual" && (
              <div className="grid grid-cols-2 md:grid-rows-1 gap-x-10">
                <div className="">
                  <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Title
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="Title"
                    options={titleOption}
                    isSearchable={true}
                    value={getValues().fkTitleId}
                    placeholder="Title"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("fkTitleId", selectedOption);
                      }
                    }}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    First Names
                  </label>
                  <input
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("name")}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-span-5">
            <div className="flex items-center gap-x-10">
              <div>
                <input
                  id="bordered-radio-1"
                  type="radio"
                  value="individual"
                  name="bordered-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  checked={selectedClientType === "individual"}
                  onChange={(e) => handleRadioChange(e.target.value)}
                />
                <label className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-lg 3xl:text-sm font-medium">
                  Individual
                </label>
              </div>
              <div>
                <input
                  id="bordered-radio-2"
                  type="radio"
                  value="company"
                  name="bordered-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  checked={selectedClientType === "company"}
                  onChange={(e) => handleRadioChange(e.target.value)}
                />
                <label className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-lg 3xl:text-sm font-medium">
                  Company
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Heading title={"Contact Details"} />
      <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Address Line 1
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("address1")}
            placeholder="Enter Address Line 1"
          />
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Address Line 2
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("address2")}
            placeholder="Enter Address Line 2"
          />
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            City
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("city")}
            placeholder="Enter City"
          />
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            County
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("county")}
            placeholder="Enter County"
          />
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Post Code
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("postCode")}
            placeholder="Enter Post Code"
          />
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Email Address
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("email")}
            placeholder="Enter Email Address"
          />
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Phone 1
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("Phone1")}
            placeholder="Enter Phone 1"
          />
        </div>
        {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Phone 2
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('Phone2')}
                        placeholder="Enter Phone 2"
                    />
                </div> */}
        {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Mobile
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('address1')}
                        placeholder="Enter Mobile"
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Fax
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('fax')}
                        placeholder="Enter Fax"
                    />
                </div> */}
      </div>

      <div className="">
        <div className="flex flex-row gap-2 items-end justify-between w-full">
          <button
            type="button"
            onClick={onCancel}
            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]"
          >
            {" "}
            Cancel
            <svg
              className="w-6 h-6 rotate-180 fill-white-bg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
            </svg>
          </button>
          <button
            type="submit"
            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]"
          >
            Next
            <svg
              className="w-6 h-6 fill-white-bg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
            </svg>
          </button>
        </div>
      </div>
    </form>
  );
}

export { AddExpertStepOne };
