import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllBankReconReports = async (data: any) => {
    try {
        const response = await axiosInstance.post("BankReconReports", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllProtectedFundReports = async (data: any) => {
    try {
        const response = await axiosInstance.post("ProtectedFundReports", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllOfficeToClientReports = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeToClientReports", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllClientToOfficeReports = async (data: any) => {
    try {
        const response = await axiosInstance.post("ClientToOfficeReports", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllCaseBalanceReport = async () => {
    try {
        const response = await axiosInstance.post("CaseBalanceReport", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ClientReportsService = {
    GetAllBankReconReports,
    GetAllProtectedFundReports,
    GetAllOfficeToClientReports,
    GetAllCaseBalanceReport,
    GetAllClientToOfficeReports

};

export default ClientReportsService;