import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllSuppliersControlAccount } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersControlAccountSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { SuppliersControlAccountDetails } from "./SuppliersControlAccountDetails";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
function SuppliersControlAccount() {
  const dispatch = useAppDispatch();
  const [openSuppliersModal, setOpenSuppliersModal] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const suppliersControlAccounts = useSelector(
    (state: RootState) => state.suppliersControlAccount.suppliersControlAccounts
  );
  const [suppliersControlGetData, setSuppliersControlGetData] = useState<any[]>(
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const effectServiceCall = useRef(false);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: "",
    startDate: "",
    endDate: "",
    caseType: "",
    documentType: "",
    isArea: false,
    isDateBetween: false,
  });

  useEffect(() => {
    if (!effectServiceCall.current) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
      };
      dispatch(fetchAllSuppliersControlAccount(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch, filters]);

  useEffect(() => {
    if (suppliersControlAccounts && suppliersControlAccounts.listingData) {
      setSuppliersControlGetData(suppliersControlAccounts.listingData);
      setTotalRecords(suppliersControlAccounts.totalRows);
    }
  }, [suppliersControlAccounts]);

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage,
    };
    dispatch(fetchAllSuppliersControlAccount(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  const toggleSuppliersDrawer = () => {
    setOpenSuppliersModal(!openSuppliersModal);
  };

  if (openSuppliersModal) {
    return (
      <SuppliersControlAccountDetails
        openModal={openSuppliersModal}
        Close={toggleSuppliersDrawer}
        isEdit={false}
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Suppliers Control Account" />
        </div>
        <div className="flex flex-row md:flex-col justify-between mt-5 gap-2 w-full md:gap-3">
          <AccountFilterComponent
            onFilterChange={handleFilterChange}
            initialFilters={filters}
          />
        </div>
        <div className="flex items-center mb-4">
          <input
            id="default-radio-1"
            type="radio"
            value=""
            name="default-radio"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label className="ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">
            Do not show zero balances
          </label>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {initialLoading ? <MainAnimation /> : ""}
              <thead className="sticky -top-1 text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Supplier
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Service / Product
                  </th>
                  <th
                    scope="col"
                    className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Current Balance
                  </th>
                  <th
                    scope="col"
                    className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {suppliersControlGetData?.map(
                  (suppliersControlAccount: any, index) => (
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {suppliersControlAccount?.supplierName}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {suppliersControlAccount?.servicesProducts}
                      </td>
                      <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatCurrency(
                          suppliersControlAccount?.runningBalance
                        )}
                      </td>
                      <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <FaEye
                          className="fill-gray-500 w-6 h-6"
                          onClick={toggleSuppliersDrawer}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                No Suppliers Control Account Details found.
              </p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => {
                onPageChange(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { SuppliersControlAccount };
