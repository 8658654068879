import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SearchComponent } from "../../AutoSelectAddress/SearchInput";
import { SelectAddressComponent } from "../../AutoSelectAddress/SelectAddress";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { fetchLocationByPostcode } from "../../../store/slice/CaseManagement/AddressSlice";
import LocationUtils from "../../../utils/location.utils";


function AddClientStepTwo({ onSubmitStep, initialData, onPreviousStep }: any) {
    const dispatch = useAppDispatch();
    const [postcode, setPostcode] = useState('');
    const [locationOptionsLists, setLocationOptionsLists] = useState<any[]>([]);
    const { locations } = useSelector((state: RootState) => state.location);
    const [isLoading, setIsLoading] = useState(false);
    const effectServiceCall = useRef(false);
    const clientByIdData = useSelector((state: RootState) => state.client.clientByIdData);

    useEffect(() => {
        if (!effectServiceCall.current && postcode) {
            setIsLoading(true);
            dispatch(fetchLocationByPostcode(postcode))
                .unwrap()
                .catch((error) => {
                    console.error("Error fetching location:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch, postcode]);


    useEffect(() => {
        if (locations != null && locations.length > 0) {
            let data = locations.map((location: any) => ({
                value: location.address,
                label: location.address,
            }));
            setLocationOptionsLists(data);
        }
    }, [locations]);

    const onLocationChange = (location: any) => {
        if (location) {
            const obj = LocationUtils.splitAddress(location);
            if (obj != null) {
                setValue('currentAddLine1', obj.addressLine1)
                setValue('currentAddLine2', obj.addressLine2)
                setValue('currentAddCity', obj.city)
                setValue('currentAddCounty', obj.county)
                setValue('currentAddPostCode', obj.postcode)
            }
        }
    }

    const validationSchema = object().shape({
        isPreferredPhone: string(),
        mobilePhone: string(),
        homePhone: string(),
        otherPhone: string(),
        currentAddLine1: string().required('Select Address is required'),
        currentAddLine2: string(),
        currentAddCity: string(),
        currentAddCounty: string(),
        currentAddPostCode: string(),
    })

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
        }
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {
        if (clientByIdData !== null) {
            setValue("isPreferredPhone", clientByIdData?.isPreferredPhone);
            setValue("mobilePhone", clientByIdData?.mobilePhone);
            setValue("homePhone", clientByIdData?.homePhone);
            setValue("otherPhone", clientByIdData?.otherPhone);
            setValue("currentAddLine1", clientByIdData?.currentAddLine1);
            setValue("currentAddLine2", clientByIdData?.currentAddLine2);
            setValue("currentAddCity", clientByIdData?.currentAddCity);
            setValue("currentAddCounty", clientByIdData?.currentAddCounty);
            setValue("currentAddPostCode", clientByIdData?.currentAddPostCode);            
        }
    }, [clientByIdData])

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>

            <div>
                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Contact Numbers</h3>
                <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Home
                            </label>
                            <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" {...register('homePhone')} />
                        </div>
                        <div className="flex items-center">
                            <input id="bordered-radio-1" type="radio" value="Home" {...register('isPreferredPhone')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Set as preferred contact number</label>
                        </div>
                    </div>

                    <div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Mobile
                            </label>
                            <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" {...register('mobilePhone')} />
                        </div>
                        <div className="flex items-center">
                            <input id="bordered-radio-1" type="radio" value="Mobile" {...register('isPreferredPhone')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Set as preferred contact number</label>
                        </div>
                    </div>

                    <div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Other
                            </label>
                            <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" {...register('otherPhone')} />
                        </div>
                        <div className="flex items-center">
                            <input id="bordered-radio-1" type="radio" value="Other" {...register('isPreferredPhone')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Set as preferred contact number</label>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-between mb-5">
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Contact Address</h3>
                    <SearchComponent setPostcode={setPostcode} loading={isLoading} />
                </div>

                <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    {locationOptionsLists !== null && locationOptionsLists.length > 0 && (
                        <div className="">
                            <SelectAddressComponent
                                locationOptionsLists={locationOptionsLists ?? []}
                                onLocationChange={onLocationChange}
                                setValue={setValue}
                            />
                        </div>
                    )}
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Address Line 1 <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.currentAddLine1 ? 'is-invalid' : ''}`} {...register('currentAddLine1')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.currentAddLine1?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Address Line 2
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('currentAddLine2')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            City
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('currentAddCity')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            County
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('currentAddCounty')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Postcode <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('currentAddPostCode')} />
                    </div>
                </div>
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-start justify-between w-full">
                    <button
                        onClick={onPreviousStep}
                        type="button"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        Previous
                    </button>
                    <button

                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddClientStepTwo };
