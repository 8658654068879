import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllFixedFeeInvoice = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAFFeeInvoiceSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllFixedFeeInvoiceById = async (id : number) => {
    try {
        const response = await axiosInstance.get(`OAFFeeInvoice/View/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostFixedFeeInvoice = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAFFeeInvoiceSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutFixedFeeInvoice = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`FixedFeeInvoice/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteFixedFeeInvoice = async (id: number) => {
    try {
        await axiosInstance.delete(`OAFFeeInvoice/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const FixedFeeInvoiceService = {
    GetAllFixedFeeInvoice,
    PostFixedFeeInvoice,
    PutFixedFeeInvoice,
    deleteFixedFeeInvoice,
    GetAllFixedFeeInvoiceById
};


export default FixedFeeInvoiceService;
