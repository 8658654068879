import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { FaEdit, FaLock, FaLockOpen, FaSave } from "react-icons/fa";
import {
  addBillPayment,
  fetchAllBillPaymentDueList,
} from "../../../../../store/slice/Accounting/OfficeAccounts/Suppliers/BillPaymentSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { OfficeAccountConstant } from "../../../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { fetchNextReceiptNumber } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";

function AddBillPayment({
  openDrawer,
  Close,
  isEdit,
  isView,
  billPayment,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  billPayment: any;
}) {
  const [title] = useState(!isEdit ? "Add Bill Payment" : "Edit Bill Payment");
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingBillPayment] = useState(billPayment);
  const dispatch = useAppDispatch();
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { receiptNumber } = useSelector(
    (state: RootState) => state.officeBankAccount
  );
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );
  const { authourisedClients } = useSelector(
    (state: RootState) => state.authorisedClient
  );
  const { billpaymentDueList } = useSelector(
    (state: RootState) => state.billPayment
  );
  const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
  const { billPaymentDataById } = useSelector((state: RootState) => state.billPayment);

  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);
  const [billPaymentDueList, setBillPaymentDueList] = useState<any[]>([]);
  const [amounts, setAmounts] = useState<number[]>([]);
  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [
        dispatch(
          fetchNextReceiptNumber(OfficeAccountConstant.SupplierBillPayment)
        ),
        dispatch(fetchAllBillPaymentDueList()),
      ];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);
  // useEffect(()=>{
  //   if(billPaymentDataById!=null && billPaymentDataById.journalLines!=null){

  //     setAmounts(billPaymentDataById.journalLines.map((item:any)=>{
  //       return { account: item.accountType, description: item.description, creditCurrency: item.credit, debitCurrency: item.debit, fkAccountId:item.fkAccountId, lineNumber:item.lineNumber, isDeleted: false, fkJournalEntryId: item.fkJournalEntryId, journalEntryLineId:item.journalEntryLineId }
  //     }));
  //   }
  // },[billPaymentDataById])
   
  // useEffect(() => {
  //   if (receiptNumber != null) {
  //        setValue("billPaymentNumber", receiptNumber)
  //       setAutoReceiptNumber(receiptNumber)
  //   }
  // }, [receiptNumber]);

  useEffect(() => {
    if (billpaymentDueList != null && billpaymentDueList.length > 0) {
      setBillPaymentDueList(billpaymentDueList);
    }
  }, [billpaymentDueList]);

  useEffect(() => {
    if (officeBanks.length > 0) {
      let data = officeBanks.map((officeBank: any, index) => ({
        value: officeBank.bankAccountId,
        label: `${officeBank.institution} - ${officeBank.bankAccountType}`,
      }));
      setOfficeBankLists(data);
      let findObj = officeBanks.find((x) => x.isDefaultAccount === true);
      if (findObj && findObj !== null) {
        setValue("officeBankId", findObj?.bankAccountId);
      }
    }
  }, [officeBanks]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentType,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
      let findObj = clientPaymentTypes.find((x) => x.isActive === true);
      if (findObj && findObj !== null) {
        setValue("transferMethod", findObj?.paymentType);
      }
    }
  }, [clientPaymentTypes]);

  useEffect(() => {
    if (authourisedClients.length > 0) {
      let data = authourisedClients.map((authorisedClient: any) => ({
        value: authorisedClient.userId,
        label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
      }));
      setAuthorisedClientLists(data);
    }
  }, [authourisedClients]);

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    billPaymentNumber: number(),
    supplierBillPaymentId: number(),
    officeBankId: number().required("Office Bank is required"),
    PaymentAmount: number().required("Payment Amount is required"),
    balance: string(),
    transactionDate: string(),
    entryDate: string(),
    authorisedBy: string().required("Authorised By is required"),
    transferMethod: string().required("Transfer Method is required"),
    reference: string(),
    comments: string(),
    billEntryId: number(),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      transactionDate: new Date().toISOString().split("T")[0],
      entryDate: new Date().toISOString().split("T")[0],
    },
  });

  const handlerowEditClick = (index: number) => {
    setEditRowIndex(index);
  };

  const handleAmountChange = (index: number, value: string) => {
    const updatedAmounts = [...amounts];
    updatedAmounts[index] = parseFloat(value) || 0;
    setAmounts(updatedAmounts);
  };

  // Handle save click
  const handleSaveClick = async () => {
    try {
      if (editRowIndex != null) {
        setValue("PaymentAmount", amounts[editRowIndex]);
        setValue(
          "billEntryId",
          billPaymentDueList[editRowIndex].supplierBillEntryId
        );
        const formData = getValues();
        setIsLoading(true);

        await dispatch(addBillPayment(formData)).unwrap();
        Close("success");
        toast.success("Successfully saved Bill Payment details");

        // Reset edit mode
        setEditRowIndex(null);
      }
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {};

  const calculateBalanceDue = (index: number, balance: number) => {
    const enteredAmount = amounts[index] || 0;
    return balance - enteredAmount >= 0 ? balance - enteredAmount : 0;
  };

  useEffect(() => {
    if (
      (billPayment != null && isEdit === true && isView === false ) ||
      (billPayment != null && isView === true && isEdit === false)
    ) {
      console.log(billPayment);
      setValue('billPaymentNumber', billPayment?.billPaymentNumber)
      setValue('supplierBillPaymentId', billPayment?.supplierBillPaymentId)
      setValue("officeBankId", billPayment?.fkOfficeBankId);
      setValue("reference", billPayment?.reference);
      setValue("balance", billPayment?.balance);
      setValue(
        "transactionDate",
        formatDateISODisplay(billPayment?.transactionDate)
      );
      // setValue('receivedFromOther', billPayment?.fkclientId)
      setValue("transferMethod", billPayment?.transferMethod);
      setValue("comments", billPayment?.comments);
    }
  }, [billPayment, isEdit, isView, reset, setValue]);

  // useEffect(() => {
  //   if (isEdit && billPayment) {
  //     setExistingBillPayment(billPayment);
  //     reset(billPayment);
  //   }
  // }, [billPayment, isEdit, reset, setValue]);

  return (
    <>
      {isView === true ? (
        <form onSubmit={handleSubmit(onSubmit)} className="mx-12">
          <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            {/* <div>
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
            Bill Payment Number
          </label>
          <div className="flex gap-3">
            <input
              type="text"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("billPaymentNumber")}
              disabled={isDisabled}
            />
            <button
              type="button"
              onClick={handleEditClick}
              className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
            >
              {isDisabled ? <FaLock /> : <FaLockOpen />}
            </button>
          </div>
        </div> */}
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Office Bank <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={officeBankLists}
                value={getValues().officeBankId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("officeBankId", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.officeBankId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Balance
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("balance")}
                placeholder="Enter Balance"
                disabled
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Date
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("transactionDate")}
              />
            </div>
            {/* <div className="relative">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
            Entry Date
          </label>
          <input
            type="date"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            {...register("entryDate")}
          />
        </div> */}
            {/* <div className="">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
            Authorised By <span className="text-red-500">*</span>
          </label>
          <SelectBox
            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
            indicator={
              <svg
                width="15"
                className="fill-current mr-2"
                height="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            }
            isMulti={false}
            name="frameFiftySix"
            isSearchable={true}
            options={authorisedClientLists}
            placeholder="Select"
            shape="round"
            color="indigo_50"
            size="xs"
            onChange={(selectedOption) => {
              if (selectedOption) {
                setValue("authorisedBy", selectedOption);
              }
            }}
          />
          <div className="font-medium mt-2 text-xs text-red-600">
            {errors?.authorisedBy?.message?.toString()}
          </div>
        </div> */}
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transfer Method <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={clientPaymentTypeLists}
                value={getValues().transferMethod}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("transferMethod", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.transferMethod?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Reference Number
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("reference")}
                placeholder="Enter Reference Number"
              />
            </div>
            <div className="relative col-span-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Comments
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Comments"
                {...register("comments")}
              ></textarea>
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.comments?.message?.toString()}
              </div>
            </div>
          </div>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Supplier
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Due Date
                  </th>
                  {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Bill
              </th> */}
                  <th
                    scope="col"
                    className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Amount Due
                  </th>
                  <th
                    scope="col"
                    className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Payment Amount
                  </th>
                  <th
                    scope="col"
                    className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Balance Due
                  </th>
                </tr>
              </thead>
              <tbody>
                {billPaymentDueList.map((billPayment: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            {(billPayment.invoiceDate!=null)? billPayment.invoiceDate.split('T')[0] : ''}
                            </td> */}
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {billPayment.supplierName}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {billPayment.dueDate != null
                        ? billPayment.dueDate.split("T")[0]
                        : ""}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {billPayment.grossAmount}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {billPayment.amountDue}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <NumericFormatWrapper
                        value={amounts[index] || ""}
                        onValueChange={(values: any) =>
                          handleAmountChange(index, values.value)
                        }
                        decimalScale={2}
                        placeholder="0.00"
                        className="border p-2 rounded w-full text-right"
                      />
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {billPayment.amountDue}
                    </td>
                    {/* <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <div className="flex gap-3 w-full">
                                {editRowIndex === index ? (
                                    <FaSave className="fill-blue-500 w-6 h-6" onClick={handleSaveClick} />
                                    ) : (
                                    <FaEdit className="fill-blue-500 w-6 h-6" onClick={() => handlerowEditClick(index)} />
                                    )}
                                </div>
                            </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      ) : (
        <Modal
          className={`backdrop-blur-sm items-center justify-center`}
          show={openDrawer}
          position={modalPlacement}
          onClose={() => {
            reset();
            Close();
          }}
          initialFocus={emailInputRef}
          size="4xl"
        >
          <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
            <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
              {title}
            </span>
          </Modal.Header>
          <Modal.Body className="bg-white-bg dark:bg-dark_bg">
            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
              <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                {/* <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Bill Payment Number
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("billPaymentNumber")}
                  disabled={isDisabled}
                />
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
            </div> */}
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Office Bank <span className="text-red-500">*</span>
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={officeBankLists}
                    value={getValues().officeBankId}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("officeBankId", selectedOption);
                      }
                    }}
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.officeBankId?.message?.toString()}
                  </div>
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Balance
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("balance")}
                    placeholder="Enter Balance"
                    disabled
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Transaction Date
                  </label>
                  <input
                    type="date"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("transactionDate")}
                  />
                </div>
                {/* <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Entry Date
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("entryDate")}
              />
            </div> */}
                {/* <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Authorised By <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={authorisedClientLists}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("authorisedBy", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.authorisedBy?.message?.toString()}
              </div>
            </div> */}
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Transfer Method <span className="text-red-500">*</span>
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={clientPaymentTypeLists}
                    value={getValues().transferMethod}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("transferMethod", selectedOption);
                      }
                    }}
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.transferMethod?.message?.toString()}
                  </div>
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Reference Number
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("reference")}
                    placeholder="Enter Reference Number"
                  />
                </div>
                <div className="relative col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Comments
                  </label>
                  <textarea
                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Comments"
                    {...register("comments")}
                  ></textarea>
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.comments?.message?.toString()}
                  </div>
                </div>
              </div>
              <div className="relative overflow-x-auto sm:rounded-lg">
                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                  <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th
                        scope="col"
                        className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        Supplier
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        Due Date
                      </th>
                      {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Bill
                  </th> */}
                      <th
                        scope="col"
                        className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        Amount Due
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        Payment Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        Balance Due
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {billPaymentDueList.map((billPayment: any, index) => (
                      <tr
                        key={index}
                        className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {(billPayment.invoiceDate!=null)? billPayment.invoiceDate.split('T')[0] : ''}
                                </td> */}
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {billPayment.supplierName}
                        </td>
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {billPayment.dueDate != null
                            ? billPayment.dueDate.split("T")[0]
                            : ""}
                        </td>
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {billPayment.grossAmount}
                        </td>
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {billPayment.amountDue}
                        </td>
                        <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          <NumericFormatWrapper
                            value={amounts[index] || ""}
                            onValueChange={(values: any) =>
                              handleAmountChange(index, values.value)
                            }
                            decimalScale={2}
                            placeholder="0.00"
                            className="border p-2 rounded w-full"
                          />
                        </td>
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {billPayment.amountDue}
                        </td>
                        <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          <div className="flex gap-3 w-full">
                            {editRowIndex === index ? (
                              <FaSave
                                className="fill-blue-500 w-6 h-6"
                                onClick={handleSaveClick}
                              />
                            ) : (
                              <FaEdit
                                className="fill-blue-500 w-6 h-6"
                                onClick={() => handlerowEditClick(index)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="flex col-span-2 gap-2 justify-end my-5">
            <button
              type="button"
              onClick={() => {
                Close();
              }}
              className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Save"
              )}
            </button>
          </div> */}
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export { AddBillPayment };
