import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { SearchCaseReference } from "../../../SearchCaseReference";
import { addLedgerToLedger, addReverseLedgerToLedger, fetchNextLedgerToLedgerNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/LedgerToLedgerSlice";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";
import { fetchClientCaseBalance } from "../../../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { fetchNextReverseNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientAccountSlice";
import { ClientAccountConstant } from "../../../../../store/model/Accounting/ClientAccount/ClientAccountConst";
import { MainAnimation } from "../../../../loadingAnimation/MainAnimation";

function AddLedgerToLedger({
  openDrawer,
  Close,
  isEdit,
  isView,
  isReverse,
  ledgerToLedger,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  isReverse: boolean;
  ledgerToLedger: any;
}) {

  const [title] = useState(!isEdit ? "Add Ledger to Ledger" : "Edit Ledger to Ledger");
  const dispatch = useAppDispatch();
  const [openFromCaseAccountModal, setOpenFromCaseAccountModal] = useState(false);
  const [openToCaseAccountModal, setOpenToCaseAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFromCaseReference, setSelectedFromCaseReference] = useState<any>();
  const [selectedToCaseReference, setSelectedToCaseReference] = useState<any>();
  // const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
  //const receiptNumbers = useSelector((state: RootState) => state.ledgerToLedger.receiptNumber);
  const [initialLoading, setInitialLoading] = useState(false);
  const { caseClientBanalce } = useSelector((state: RootState) => state.case);
  const [isReverseDisabled, setIsReverseDisabled] = useState(true);
  const reverseReceiptNumber = useSelector((state: RootState) => state.clientAccount.reverseReceiptNumber);
  const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>([]);


  // const effectServiceCall = useRef(false);
  // const errorShownRef = useRef(false);
  // useEffect(() => {
  //   setInitialLoading(true);
  //   if (!effectServiceCall.current) {
  //     const fetchPromises = [
  //       dispatch(fetchNextLedgerToLedgerNumber()),
  //     ];
  //     Promise.all(fetchPromises)
  //       .then(() => {
  //         setInitialLoading(false);
  //       })
  //       .catch((error) => {
  //         if (!errorShownRef.current) {
  //           toast.error("Error fetching data: " + error.message);
  //           errorShownRef.current = true;
  //         }
  //         setInitialLoading(false);
  //       });
  //     effectServiceCall.current = true;
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (receiptNumbers !== null) {
  //     setValue('ledgerToLedgerNumber', receiptNumbers);
  //     setAutoReceiptNumber(receiptNumbers);
  //   }
  // })

  // useEffect(() => {
  //   if (authourisedClients.length > 0) {
  //     let data = authourisedClients.map((authorisedClient: any, index) => ({
  //       value: authorisedClient.userId,
  //       label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
  //     }));
  //     setAuthorisedClientLists(data);

  //   }
  // }, [authourisedClients]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      let data = clientPaymentTypes.map((PaymentType: any) => ({
        value: PaymentType.paymentType,
        label: PaymentType.paymentType,
      }));

      // Add the 'N/A' option manually
      data.push({
        value: "N/A",
        label: "N/A",
      });

      setclientPaymentTypeLists(data);

      // Find the active payment type and set it as the default
      let findObj = clientPaymentTypes.find((x) => x.isActive === true);
      if (findObj && findObj !== null) {
        setValue("transferMethod", findObj?.paymentType);
      }
    }
  }, [clientPaymentTypes]);

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    fkFromLedgerId: number(),
    // .required('From Ledger is required'),
    ledgerToLedgerNumber: number(),
    transactionDate: string().required('Transaction Date is required')
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    // entryDate: string().required('Entry Date is required')
    //   .nullable()
    //   .transform((value, originalValue) => {
    //     if (originalValue === "" || value === null) return null;
    //     return new Date(value).toISOString().split('T')[0];
    //   }),
    fkToLedgerId: number(),
    // .required('To Ledger is required'),
    transactionDescription: string().required(
      "Transaction Description is required"
    ),
    amount: number().required("Amount is required"),
    comments: string(),
    // authorisedBy: string().required("Authorised By is required"),
    transferReference: string(),
    transferMethod: string().required("Transfer Method is required"),
    individualNames: string(),
    toCaseReferenceAuto: string(),
    caseReferenceAuto: string(),
    toLedgerAvailableBalance: string(),
    fromLedgerAvailableBalance: string(),
    reverseReference: number(),
    ledgerToLedgerTransactionId: number()


  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ledgerToLedgerNumber: 0,
      ledgerToLedgerTransactionId: 0,
      transactionDate: new Date().toISOString().split("T")[0]
    }
  });



  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await dispatch(addLedgerToLedger(data)).unwrap();
      reset();
      Close("success");
      toast.success("Successfully Added Ledger to Ledger Transaction");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setValue("transactionDate", new Date().toISOString().split("T")[0]);
  }, []);

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const toggleToCaseAccountModal = () => {
    setOpenToCaseAccountModal(!openToCaseAccountModal);
  };

  const toggleFromCaseAccountModal = () => {
    setOpenFromCaseAccountModal(!openFromCaseAccountModal);
  };

  useEffect(() => {

  }, [caseClientBanalce]);

  useEffect(() => {

  }, [caseClientBanalce]);

  // const handleFromSelection = (selectedCase: any) => {
  //   setSelectedFromCaseReference(selectedCase);
  //   console.log("from" + selectedCase.caseId);
  //   setValue("fkFromLedgerId", selectedCase.caseId);
  //   setOpenFromCaseAccountModal(false);
  // };

  const handleFromSelection = (selectedCases: any) => {
    setSelectedFromCaseReference(selectedCases);
    setValue("fkFromLedgerId", selectedCases.caseId);
    dispatch(fetchClientCaseBalance(selectedCases.caseId));
    setOpenFromCaseAccountModal(false);
    if (selectedCases.caseId > 0) {
      if (caseClientBanalce !== null) {
        setValue("fromLedgerAvailableBalance", caseClientBanalce.closingBalance.toFixed(2))
        // let data = caseClientBanalce.clientList.map((client: any) => ({
        //   value: client.clientId,
        //   label: `${client.clientName}`,

        // }));
        // setFromcaseClientLists(data);
        if (isEdit) {
          setValue("fromLedgerAvailableBalance", ledgerToLedger.fkFromLedgerId)
        }
      }
    }
  };

  // const handleToSelection = (selectedCase: any) => {
  //   setSelectedToCaseReference(selectedCase);
  //   console.log("to" + selectedCase.caseId);
  //   setValue("fkToLedgerId", selectedCase.caseId);
  //   setOpenToCaseAccountModal(false);
  // };

  const handleToSelection = (selectedCase: any) => {
    setSelectedToCaseReference(selectedCase);
    setValue("fkToLedgerId", selectedCase.caseId);
    dispatch(fetchClientCaseBalance(selectedCase.caseId));
    setOpenToCaseAccountModal(false);
    if (selectedCase.caseId > 0) {
      if (caseClientBanalce !== null) {
        setValue("toLedgerAvailableBalance", caseClientBanalce.closingBalance.toFixed(2))
        // let data = caseClientBanalce.clientList.map((client: any) => ({
        //   value: client.clientId,
        //   label: `${client.clientName}`,

        // }));
        // setTocaseClientLists(data);
        if (isEdit) {
          setValue("toLedgerAvailableBalance", ledgerToLedger.fkToLedgerId)
        }
      }
    }
  };

  useEffect(() => {
    if ((ledgerToLedger != null && isEdit === true && isView === false) || (ledgerToLedger != null && isView === true && isEdit === false) || (ledgerToLedger != null && isView === false && isEdit === false && isReverse === true)) {
      // setValue('ledgerToLedgerNumber', ledgerToLedger?.ledgerToLedgerNumber)
      setValue('transactionDate', formatDateISODisplay(ledgerToLedger?.transactionDate))
      setValue('fkToLedgerId', ledgerToLedger?.toLedgerId)
      setValue('fkFromLedgerId', ledgerToLedger?.fromLedgerId)
      setValue('individualNames', ledgerToLedger?.individualNames)
      setValue('transactionDescription', ledgerToLedger?.transactionDetails)
      setValue('comments', ledgerToLedger?.comments)
      setValue('transferReference', ledgerToLedger?.transferReference)
      setValue('amount', ledgerToLedger?.amount)
      setValue('transferMethod', ledgerToLedger?.transferMethod)
      setValue('toLedgerAvailableBalance', ledgerToLedger?.toLedgerAvailableBalance)
      setValue('fromLedgerAvailableBalance', ledgerToLedger?.fromLedgerAvailableBalance)
      setValue('ledgerToLedgerTransactionId', ledgerToLedger?.ledgerToLedgerTransactionId)

      if (ledgerToLedger?.fromLedgerId) {
        setSelectedFromCaseReference({
          caseReferenceAuto: ledgerToLedger.caseReferenceAuto,
        });
      }
      if (ledgerToLedger?.toLedgerId) {
        setSelectedToCaseReference({
          caseReferenceAuto: ledgerToLedger.toCaseReferenceAuto,
        });
      }
      // dispatch(fetchClientCaseBalance(ledgerToLedger?.fkCaseId));
      //   if(ledgerToLedger.toLedgerId > 0){
      //   setSelectedToCaseReference({caseReferenceAuto: ledgerToLedger.caseReferenceAuto})

      // }
      //  if(ledgerToLedger.fromLedgerId > 0){
      //     setSelectedFromCaseReference({caseReferenceAuto: ledgerToLedger.caseReferenceAuto})

      //   }
    }
  }, [ledgerToLedger, isEdit, isView, reset, setValue]);
  const handleReverseEditClick = () => {
    setIsReverseDisabled(!isReverseDisabled);
  };
  useEffect(() => {
    if (isReverse) {
      dispatch(fetchNextReverseNumber(ClientAccountConstant.LedgertoLedger));
    }

  }, [dispatch, isReverse]);

  const [formData, setFormData] = useState<any>({
    reverseReference: number,
    reverseNumber: "",
    reason: "",
  });

  // Handle change in input fields
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleReverseClick = async () => {
    if (isReverse) {
      if (!formData.reverseNumber || !formData.reverseNumber.value.trim()) {
        toast.error("Reverse Receipt Number is required.");
      } else {
        // console.log(formData);
        try {
          setIsLoading(true);
          await dispatch(addReverseLedgerToLedger(formData)).unwrap();

          toast.success("Successfully  reverse of Ledger To ledger details");

        } catch (error: any) {
          toast.error(error.toString());
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <>
      {openDrawer === true ? (
        <>
        {initialLoading ? <MainAnimation /> : ""}
          <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-12">
            <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
              <div className="relative flex gap-1">
                <input
                  type="text"
                  placeholder={selectedFromCaseReference?.caseReferenceAuto}
                  aria-label="Search"
                  value={selectedFromCaseReference?.caseReferenceAuto || "From Ledger"}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  disabled
                />
                <button
                  onClick={toggleFromCaseAccountModal}
                  className="z-[2] inline-block rounded-e border-2 border-primary w-36 px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                  disabled={isView || isReverse}
                >
                  Add Case
                </button>
                {openFromCaseAccountModal && (
                  <SearchCaseReference
                    openDrawer={openFromCaseAccountModal}
                    Close={toggleFromCaseAccountModal}
                    onCaseSelect={handleFromSelection}
                  />
                )}
              </div>

              <div className="relative flex gap-1">
                <input
                  type="text"
                  placeholder={selectedToCaseReference?.caseReferenceAuto}
                  aria-label="Search"
                  value={selectedToCaseReference?.caseReferenceAuto || "To Ledger"}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  disabled={isDisabled}
                />
                <button
                  onClick={toggleToCaseAccountModal}
                  className="z-[2] inline-block rounded-e border-2 border-primary w-36 px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                  disabled={isView || isReverse}
                >
                  Add Case
                </button>
                {openToCaseAccountModal && (
                  <SearchCaseReference
                    openDrawer={openToCaseAccountModal}
                    Close={toggleToCaseAccountModal}
                    onCaseSelect={handleToSelection}
                  />
                )}
              </div>
              {isReverse === false || isView === false && (
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      Close();
                    }}
                    className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              {/* <div>
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Reference
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.ledgerToLedgerNumber ? "is-invalid" : ""
                    } `}
                  value={getValues().ledgerToLedgerNumber}
                  disabled={isDisabled}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.ledgerToLedgerNumber?.message?.toString()}
                </div>
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
            </div> */}
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Available Balance
                </label>
                <NumericFormatWrapper decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Enter Available Balance"
                  defaultValue={0.00}
                  disabled={isDisabled}
                  value={getValues().fromLedgerAvailableBalance}
                  onValueChange={(values: any) => {
                    setValue("fromLedgerAvailableBalance", values.value);
                  }}
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Available Balance
                </label>
                <NumericFormatWrapper decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Enter Available Balance"
                  defaultValue={0.00}
                  disabled={isDisabled}
                  value={getValues().toLedgerAvailableBalance}
                  onValueChange={(values: any) => {
                    setValue("toLedgerAvailableBalance", values.value);
                  }}
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transaction Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? "is-invalid" : ""
                    }`}

                  {...register("transactionDate")}
                  placeholder="Enter Transaction Date"
                  disabled={isView || isReverse}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.transactionDate?.message?.toString()}
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transaction Description <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? "is-invalid" : ""
                    }`}
                  {...register("transactionDescription")}
                  placeholder="Enter Transaction Description"
                  disabled={isView || isReverse}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.transactionDescription?.message?.toString()}
                </div>
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Amount (£) <span className="text-red-500">*</span>
                </label>
                <NumericFormatWrapper decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amount ? "is-invalid" : ""
                  }`}
                  value={getValues().amount}
                  onValueChange={(values: any) => {
                    setValue('amount', Number(values.value))
                  }}
                  placeholder="Enter Amount (£)"
                  disabled={isView || isReverse}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.amount?.message?.toString()}
                </div>
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reference Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transferReference ? "is-invalid" : ""
                    }`}
                  {...register("transferReference")}
                  placeholder="Enter Reference Number"
                  disabled={isView || isReverse}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.transferReference?.message?.toString()}
                </div>
              </div>
              <div className="">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transfer Method <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  options={clientPaymentTypeLists}
                  value={getValues().transferMethod}
                  isSearchable={true}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("transferMethod", selectedOption);
                    }
                  }}
                  isDisabled={isView || isReverse}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.transferMethod?.message?.toString()}
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Comments
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Comments"
                  {...register("comments")}
                  disabled={isView || isReverse}
                ></textarea>
              </div>
            </div>
            {!isView && (
              <div className="flex col-span-2 gap-2 justify-end my-5">
                <button
                  type="button"
                  disabled={isView || isReverse}
                  onClick={() => {
                    Close();
                    reset();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isView || isReverse}
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            )}
          </form>
          {isReverse && (
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              <div>
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Reference
                </label>
                <div className="flex gap-3">
                  R_L2L
                  <input
                    type="text"
                    value={reverseReceiptNumber}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    disabled={isReverseDisabled}
                  />

                  <button
                    type="button"
                    onClick={handleReverseEditClick}
                    className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                  >
                    {isReverseDisabled ? <FaLock /> : <FaLockOpen />}
                  </button>
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Receipt Number
                </label>
                <input
                  type="text"
                  name="reverseNumber"
                  value={formData.reverseNumber}
                  onChange={handleChange}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.reverseReference ? "is-invalid" : ""
                    }`}
                  placeholder="Enter Reverse Reference"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reason
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  placeholder="Reason"
                ></textarea>
              </div>
            </div>
          )}

          <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              {isReverse && (
                <button
                  type="button"
                  onClick={handleReverseClick}
                  disabled={isLoading}
                  className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-lg 3xl:text-sm w-[120px]  ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Reverse"
                  )}
                </button>
              )}
            </div>
          </div>
        </>
      ) : null}

    </>
  );
}

export { AddLedgerToLedger };
