import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddSuppliers } from "./SuppliersDetails/AddSuppliers";
import { useEffect, useRef, useState } from "react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  deleteOfficeSupplier,
  fetchAllOfficeSupplier,
} from "../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierSlice";
import { toast } from "react-toastify";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";

function Suppliers() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const officeSupplierData = useSelector(
    (state: RootState) => state.officeSupplier.officeSupplierData
  );
  const [searchQuery, setSearchQuery] = useState<string>(null!);
  const [officeSupplierDatas, setOfficeSupplierDatas] = useState<any>([]);
  const [officeSupplierGetData, setOfficeSupplierGetData] = useState<any[]>([]);

  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [supplierId, setSupplierId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const effectServiceCall = useRef(false);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: "",
    startDate: "",
    endDate: "",
    caseType: "",
    documentType: "",
    isArea: false,
    isDateBetween: false,
  });

  useEffect(() => {
    let searchdata = {
      keyword: filters.searchTerm,
      currentPage: currentPage,
      recordsPerPage: totalPage,
    };

    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllOfficeSupplier(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [isOnAddedOrUpdate, currentPage, filters]);

  useEffect(() => {
    if (
      officeSupplierData !== null &&
      officeSupplierData.listingData !== null
    ) {
      setOfficeSupplierGetData(officeSupplierData.listingData);
      setTotalRecords(officeSupplierData.totalRows);
    }
  }, [officeSupplierData]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage,
    };
    dispatch(fetchAllOfficeSupplier(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };
  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteOfficeSupplier(supplierId)).unwrap();
      setOfficeSupplierGetData((prevData: any) =>
        prevData.filter((item: any) => item.supplierId !== supplierId)
      );
      setOpenDelModal(false);
      toast.success("Successfully Deleted Supplier");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  useEffect(() => {
    if (searchQuery != null) {
      const searchData = {
        keyword: searchQuery,
        currentPage: currentPage,
        recordsPerPage: totalPage,
      };
      try {
        dispatch(fetchAllOfficeSupplier(searchData));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [searchQuery]);

  if (openModal) {
    return (
      <AddSuppliers
        openModal={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        officeSupplier={officeSupplierDatas}
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={supplierId}
            title="Supplier"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Suppliers"
            buttonName="Suppliers"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between gap-2 w-full md:gap-3">
          <AccountFilterComponent
            onFilterChange={handleFilterChange}
            initialFilters={filters}
          />
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {initialLoading ? <MainAnimation /> : ""}
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Supplier
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Service / Product
                  </th>
                  <th
                    scope="col"
                    className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {officeSupplierGetData.map((officeSupplier: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {officeSupplier.supplierName}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {officeSupplier.servicesProducts}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <div className="flex gap-3 w-full">
                        <FaEdit
                          className="fill-blue-500 w-6 h-6"
                          onClick={() => {
                            setOpenModal(true);
                            setIsEdit(true);
                            setOfficeSupplierDatas(officeSupplier);
                          }}
                        />
                        <RiDeleteBin5Fill
                          className="fill-red-600 w-6 h-6"
                          onClick={() => {
                            setOpenDelModal(true);
                            setSupplierId(officeSupplier.supplierId);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Office supplier Details found.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => {
                onPageChange(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { Suppliers };
