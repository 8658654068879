import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SearchInput } from "../../Filters/SearchInput";
import { fetchCaseContactAndClientByCaseId } from "../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { Pagination } from "../../Pagination";

function SearchCaseClientContact({
  openDrawer,
  caseId,
  Close,
  onCaseSelectDetail,

}: {
  openDrawer: boolean;
  Close: any;
  caseId: number;
  onCaseSelectDetail: (onClientContactDetail: any) => void;
}) {
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [initialLoading, setInitialLoading] = useState(false);
  const caseContactAndClient = useSelector(
    (state: RootState) => state.case.caseContactAndClient
  );
  const [caseClientLists, setClientDetailsLists] = useState<any[]>([]);
  const [caseContactLists, setContactDetailsLists] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedCase, setSelectedCase] = useState<any>();

  useEffect(() => {
    if (caseId !== null && caseId !== undefined) {
      let data = {
        caseId: caseId,
        keyword: "",
      };
      dispatch(fetchCaseContactAndClientByCaseId(data));
    }
  }, [caseId]);

  useEffect(() => {
    if (caseContactAndClient != null) {
      if (Array.isArray(caseContactAndClient.caseClients) && caseContactAndClient?.caseClients.length > 0) {
        setClientDetailsLists(caseContactAndClient.caseClients);
      }

      if (Array.isArray(caseContactAndClient.caseContacts) && caseContactAndClient?.caseContacts.length > 0) {
        setContactDetailsLists(caseContactAndClient.caseContacts);
      }

      // setCaseDetailsLists(caseDetails.caseDetailsList);
    }
  }, [caseContactAndClient]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCaseSelection = (data: any, type: string) => {
    const updatedData = { ...data, cType: type };
    setSelectedCase(updatedData);
  };

  const handleSelectCaseClick = () => {
    if (selectedCase) {
      onCaseSelectDetail(selectedCase);
      Close();
    }
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        Close();
      }}
      initialFocus={emailInputRef}
    >
      <Modal.Header className="bg-white-bg p-5">
        <span className="text-blue_gray-900 text-xl w-auto">
          Case Client Contacts
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg">
        <div className="space-y-6 w-full">
          <div className="relative overflow-x-auto sm:rounded-lg">
            <div className="flex mb-5">
               <input
              type="text"
              onChange={(e) => handleSearchChange(e)} // Directly pass the event
               placeholder="Case Number / Keyword"
              />
            </div>
            {initialLoading ? <MainAnimation /> : ""}
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Names
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Contact Info
                  </th>
                </tr>
              </thead>
              <tbody>
                {caseClientLists && caseClientLists.map((caseList: any, index: number) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <input
                        type="radio"
                        name="selectedCase"
                        onChange={() => handleCaseSelection(caseList, "client")}
                      />
                    </td>
                    {(() => {
                      if (caseList.clientType === "Individual") {
                        return (
                          <>
                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                              {caseList.givenNames} {caseList.lastName}
                            </td>
                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                              <p>{caseList.currentAddLine1}</p>
                              <p>{caseList.currentAddLine2}</p>
                              <p>
                                {caseList.currentAddCity}{" "}
                                {caseList.currentAddPostCode}
                              </p>
                            </td>
                          </>
                        );
                      } else if (caseList.clientType === "Organization") {
                        return (
                          <>
                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                              {caseList.orgName}
                            </td>
                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                              <p>{caseList.orgCurrentAddLine1}</p>
                              <p>{caseList.orgCurrentAddLine2}</p>
                              <p>
                                {caseList.orgCurrentAddCity}{" "}
                                {caseList.orgCurrentAddPostCode}
                              </p>
                            </td>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                              {caseList.companyName}
                            </td>
                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                              <p>{caseList.compCurrentAddLine1}</p>
                              <p>{caseList.compCurrentAddLine2}</p>
                              <p>
                                {caseList.compCurrentAddCity}{" "}
                                {caseList.compCurrentAddPostCode}
                              </p>
                            </td>
                          </>
                        );
                      }

                    })()}
                  </tr>
                ))}

                {caseContactLists &&
                  caseContactLists.map((contactList: any, index: number) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      {(() => {
                        if (contactList.fkContactTypeId === 1) {
                          return (
                            <>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <input
                                  type="radio"
                                  name="selectedCase"
                                  onChange={() => handleCaseSelection(contactList, "contact")}
                                />
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {contactList.givenNames}
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <p>{contactList.personalContAddress1}</p>
                                <p>{contactList.personalContAddress2}</p>
                                {/* <p>
                          {contactList.companyCity}{" "}
                          {contactList.companyPostcode}
                        </p> */}
                              </td>
                            </>
                          )
                        } else if (contactList.fkContactTypeId === 2) {
                          return (
                            <>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <input
                                  type="radio"
                                  name="selectedCase"
                                  onChange={() => handleCaseSelection(contactList, "contact")}
                                />
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {contactList.name}
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <p>{contactList.address1}</p>
                                <p>{contactList.address2}</p>
                                {/* <p>
                                  {contactList.companyCity}{" "}
                                  {contactList.companyPostcode}
                                </p> */}
                              </td>
                            </>
                          )
                        } else if (contactList.fkContactTypeId === 3) {
                          return (
                            <>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <input
                                  type="radio"
                                  name="selectedCase"
                                  onChange={() => handleCaseSelection(contactList, "contact")}
                                />
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {contactList.companyName}
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <p>{contactList.companyAddressLine1}</p>
                                <p>{contactList.companyAddressLine2}</p>
                                {/* <p>
                                  {contactList.companyCity}{" "}
                                  {contactList.companyPostcode}
                                </p> */}
                              </td>
                            </>
                          )
                        } else {
                          return null;
                        }
                      })()}
                    </tr>
                  ))}

              </tbody>
            </table>
          </div>
          <div className="w-full flex flex-col"></div>
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                onClick={() => {
                  Close();
                }}
                type="button"
                className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSelectCaseClick}
                className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-lg 3xl:text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
              >
                Select Contact
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { SearchCaseClientContact };
