import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";






const GetAllProtectedFund = async (data: any) => {
    try {
        const response = await axiosInstance.post("ProtectedFundSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostProtectedFund = async (data: any) => {
    try {
        const response = await axiosInstance.post("ProtectedFund", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostReverseProtectedFund = async (data: any) => {
    try {
        const response = await axiosInstance.post("ProtectedFund", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const PutProtectedFund = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`ProtectedFund/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteProtectedFund = async (id: number) => {
    try {
        await axiosInstance.delete(`/ProtectedFund/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ProtectedFundService = {
    GetAllProtectedFund,
    PostProtectedFund,
    PostReverseProtectedFund,
    PutProtectedFund,
    deleteProtectedFund
};


export default ProtectedFundService;
