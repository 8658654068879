import { useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddLedgerToLedger } from "../Modal/AddClientAccounts/AddClientPostings/AddLedgerToLedger";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteLedgerToLedger,
  fetchAllLedgerToLedger,
} from "../../store/slice/Accounting/ClientAccounts/ClientPosting/LedgerToLedgerSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
function LedgerToLedger() {
  const [openModal, setOpenModal] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const searchLedgerToLedgerResult = useSelector(
    (state: RootState) => state.ledgerToLedger.searchLedgerToLedgerResult
  );
  const [currentLedger, setCurrentLedger] = useState<any[]>([]);
  const [ledgerToLedgerData, setLedgerToLedgerData] = useState<any[]>([]);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [ledgerToLedgerId, setLedgerToLedgerId] = useState<number>(0);
  const [isView, setIsView] = useState(false);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
  });

  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate == "" ? null : filters.startDate),
        dateEnd: (filters.endDate == "" ? null : filters.endDate),
      };
      dispatch(fetchAllLedgerToLedger(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, filters, isOnAddedOrUpdate]);

  useEffect(() => {
    if (
      searchLedgerToLedgerResult !== null &&
      searchLedgerToLedgerResult.ledgerToLedgerResult !== null
    ) {
      setCurrentLedger(searchLedgerToLedgerResult.ledgerToLedgerResult);
      setTotalRecords(searchLedgerToLedgerResult.totalCount);
    }
  }, [searchLedgerToLedgerResult]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage,
    };
    dispatch(fetchAllLedgerToLedger(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteLedgerToLedger(ledgerToLedgerId)).unwrap();
      setCurrentLedger((prevData) =>
        prevData.filter(
          (item) => item.ledgerToLedgerTransactionId !== ledgerToLedgerId
        )
      );
      setOpenDelModal(false);
      toast.success("Successfully Deleted Ledger To Ledger");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
    setIsView(false);
    setIsEdit(false);
  };


  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openModal) {
    return (
      <AddLedgerToLedger
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        isReverse={false}
        ledgerToLedger={ledgerToLedgerData}
      />
    );
  }
  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="ledgerToLedger"
        getData={ledgerToLedgerData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPledgerToLedger"
        getData={ledgerToLedgerData}
        title="Ledger To Ledger"
      />
    );
  }

  return (
    <>
      <div className="-ml-0">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            onDeleteAccept={onDeleteCall}
            id={ledgerToLedgerId}
            title="Ledger To Ledger"
            message="Are you sure?"
          />
        )}

        <div className="mb-5">
          <SubHeaderTitle
            title="Ledger to Ledger"
            buttonName="Ledger to Ledger"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between gap-4 w-full md:gap-3">
          <div className="w-full">
            <AccountFilterComponent
              onFilterChange={handleFilterChange}
              initialFilters={filters}
            />
          </div>
        </div>
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Ref
                </th> */}
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Transaction Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Transaction Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  From Ledger Card
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  To Ledger Card
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentLedger.map((ledgerToLedger: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    L2L {ledgerToLedger.ledgerToLedgerNumber}
                  </td> */}
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(ledgerToLedger.transactionDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {ledgerToLedger.transactionDetails}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p className=" font-bold">
                      {ledgerToLedger.individualNames}
                    </p>
                    <br />
                    <p className="">{ledgerToLedger.caseReferenceAuto}</p>
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p className=" font-bold">
                      {ledgerToLedger.toIndividualNames}
                    </p>
                    <br />
                    <p className="">{ledgerToLedger.toCaseReferenceAuto}</p>
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(ledgerToLedger.amount)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setLedgerToLedgerData(ledgerToLedger);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setIsView(false);
                          setLedgerToLedgerData(ledgerToLedger);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setLedgerToLedgerId(
                            ledgerToLedger.ledgerToLedgerTransactionId
                          );
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                      onClick={() => {
                        setOpenReverseDrawer(true);
                        setLedgerToLedgerData(ledgerToLedger);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                Ledger To Ledger Details not found.
              </p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => {
                onPageChange(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { LedgerToLedger };
