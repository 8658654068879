import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { SelectBoxFilterComp } from "./SelectBoxFilterComp";
import { CaseTypeFilter } from "./CaseTypeComp";

interface AccountFilterComponentProps {
  onFilterChange: (filters: FilterTypeWithEnums) => void;
  initialFilters?: FilterTypeWithEnums;
  optiondata?: { name: string; options: any[] }[];
  isMuliData?: boolean;
}

const AccountFilterComponent: React.FC<AccountFilterComponentProps> = ({
  onFilterChange,
  initialFilters = {} as FilterTypeWithEnums,
  optiondata,
  isMuliData,
}) => {
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    ...initialFilters,
    startDate: initialFilters.startDate,
    endDate: initialFilters.endDate,
  });

  const isInitialRender = useRef(true);

  const handleFiltersChange = (
    newFilters: Partial<FilterTypeWithEnums>,
    additionalFilters?: Partial<FilterTypeWithEnums>
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
      ...(additionalFilters || {}),
    }));
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    //console.log(filters);
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  return (
    <>
      {/* <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-20 3xl:gap-2 md:gap-1 w-full"> */}
      <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
        <div className="flex items-center gap-1">
        {filters.isSelectbox && (
          <SelectBoxFilterComp
            optiondata={optiondata}
            isMuliData={isMuliData}
            onSearchClick={handleFiltersChange}
          />
        )}
        {filters.isArea && (
          <CaseTypeFilter onFilterSubmit={handleFiltersChange} />
        )}
        {filters.isDateBetween && (
          <div className="relative flex">
            <JustifyDateFilter
              title="Date Between"
              plannedStartDate={filters.startDate}
              plannedEndDate={filters.endDate}
              onPlannedStartDateChange={(e) => {
                console.log(e.target.value);
                handleFiltersChange({ startDate: e.target.value });
              }}
              onPlannedEndDateChange={(e) => {
                console.log(e.target.value);
                handleFiltersChange({ endDate: e.target.value });
              }}
            />
          </div>
        )}
        </div>

        <div className="relative flex">
          <SearchInput
            title="Search"
            value={""}
            debounceTime={500}
            minChars={2}
            onChange={(value) => {
              if (value !== filters.searchTerm) {
                handleFiltersChange({ searchTerm: value });
              }
            }}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export { AccountFilterComponent };
