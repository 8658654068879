import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { SearchInput } from '../../Filters/SearchInput';
//import { fetchAllAccount } from '../../../store/slice/Accounting/AccountSetup/AccountSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { MainAnimation } from '../../loadingAnimation/MainAnimation';
import { fetchAllAccountSearchNominalAccount } from '../../../store/slice/Accounting/OfficeAccounts/AccountSearchNominalAccountsSlice';
import { fetchAllFixedFeeInvoice } from '../../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoiceSlice';

function SearchInvoices({ openDrawer, Close, onAccountSelect,  }: { openDrawer: boolean, Close: any, onAccountSelect: (account: any) => void,}) {
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [openModal, setOpenModal] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [modalPlacement, setModalPlacement] = useState('center');
    const searchOfficeFixedFeeInvoiceResult = useSelector((state: RootState) => state.fixedFeeInvoice.searchOfficeFixedFeeInvoiceResult);
    const [accountLists, setAccountLists] = useState<any[]>([]);
    const [filteredAccounts, setFilteredAccounts] = useState<any[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<any | null>(null); // Store full account details
    const [searchKeyword, setSearchKeyword] = useState('');
    const [fixedFeeInvoiceGetData, setFixedFeeInvoiceGetData] = useState<any[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<any[]>([]);
    const [originalData, setOriginalData] = useState([]);

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                keyword: '',
                //BankAccountType: (type) ? type :'office',
                //fkAccountTypeIds: (fkAccountTypeIds)? (fkAccountTypeIds): '1',
            }
            dispatch(fetchAllFixedFeeInvoice(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (searchOfficeFixedFeeInvoiceResult && searchOfficeFixedFeeInvoiceResult.listingData) {
            setOriginalData(searchOfficeFixedFeeInvoiceResult.listingData); 
            setFixedFeeInvoiceGetData(searchOfficeFixedFeeInvoiceResult.listingData);
        }
    }, [searchOfficeFixedFeeInvoiceResult]);
    // console.log(fixedFeeInvoiceData)

    const handleAccountSelection = (fixedFeeInvoices: any) => {
        setSelectedInvoice(fixedFeeInvoices); // Store the entire account object
    };

    const handleSelectAccountClick = () => {
        console.log(selectedInvoice);
        if (selectedInvoice) {
            // Pass selected account details to parent via callback
            onAccountSelect(selectedInvoice);
        } else {
            console.log("No account selected");
        }
        resetModal();
    };

    const resetModal = () => {
        setSelectedAccount(null);
        setOpenModal(false);
    };


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.value.toLowerCase(); // Get the search keyword and convert to lowercase
        setSearchKeyword(keyword); // Update the search query state
      
        if (!keyword) {
          // If the keyword is empty, reset to original data
          setFixedFeeInvoiceGetData(originalData); 
        } else {
          // Filter the account list based on invoiceeName or invoiceAddress
          const filtered = fixedFeeInvoiceGetData.filter(data =>
            data?.invoiceeName?.toLowerCase().includes(keyword) || 
            data?.invoiceAddress?.toLowerCase().includes(keyword)
          );
      
          setFixedFeeInvoiceGetData(filtered); // Update the filtered data state
        }
      };
      
    
    

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openModal}
            position={modalPlacement}
            onClose={resetModal}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg p-5">
                <span className="text-blue_gray-900 text-xl w-auto">Search Account</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg">
                <div className="space-y-6 w-full">
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <div className='flex mb-5'>
                            <input type="search"
                                    value={searchKeyword}
                                    onChange={handleSearchChange} // Correctly binding the event handler
                                    placeholder="Search..." />
                        </div>
                        {initialLoading ? (<MainAnimation />) : (
                            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                                <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Fixed Fee Invoice Details</th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Client Details</th>
                                        
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Select</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fixedFeeInvoiceGetData.map((accountSearchInvoiceAccount) => (
                                        <tr
                                            key={accountSearchInvoiceAccount.accountId}
                                            className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                        >
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                               <p>{accountSearchInvoiceAccount.invoiceReference}</p> 
                                               <p>Invoice Date: {accountSearchInvoiceAccount.invoiceDate.split("T")[0]}</p>
                                               <p>Invoice Amount: £{accountSearchInvoiceAccount.invoiceAmount}</p>
                                            </td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                                <p>{accountSearchInvoiceAccount.invoiceeName}</p>
                                                <p>{accountSearchInvoiceAccount.invoiceAddress}</p>
                                                
                                                </td>
                                            
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                                <input
                                                    type="radio"
                                                    name="account"
                                                    //value={accountSearchInvoiceAccount.accountId}
                                                    onChange={() => handleAccountSelection(accountSearchInvoiceAccount)}
                                                    //hecked={selectedAccount?.invoiceId === accountSearchInvoiceAccount.invoiceId}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">
                            <button
                                onClick={resetModal}
                                type='button'
                                className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                            >
                                Cancel
                            </button>
                            <button
                                  type="button"
                                  onClick={handleSelectAccountClick}
                                className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                            >
                                Select Account
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { SearchInvoices };
