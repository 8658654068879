import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { HeaderTitle } from "../../components";

const ResetPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const validationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required("Old password is required."),
    newPassword: yup
      .string()
      .required("New password is required.")
      .min(8, "New password must be at least 8 characters."),
    confirmPassword: yup
      .string()
      .required("Confirm password is required.")
      .oneOf([yup.ref("newPassword")], "Passwords must match."),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: { oldPassword: string; newPassword: string; confirmPassword: string }) => {
    setIsLoading(true);
    try {
        // await dispatch(addOfficeBankDeposit(data)).unwrap();
      console.log("Password reset data:", {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });

      toast.success("Password reset successfully!");
      reset();
    } catch (error) {
      toast.error("An error occurred while resetting the password.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
        <HeaderTitle title="Reset Password" />
      
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5"
      >
        <div className="flex flex-col items-start">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
            Old Password <span className="text-red-600">*</span>
          </label>
          <input
            type="password"
            className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Old Password"
            {...register("oldPassword")}
          />
          {errors.oldPassword && (
            <span className="text-red-600 text-xs mt-1">
              {errors.oldPassword.message}
            </span>
          )}
        </div>

        <div className="flex flex-col items-start">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
            New Password <span className="text-red-600">*</span>
          </label>
          <input
            type="password"
            className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter New Password"
            {...register("newPassword")}
          />
          {errors.newPassword && (
            <span className="text-red-600 text-xs mt-1">
              {errors.newPassword.message}
            </span>
          )}
        </div>

        <div className="flex flex-col items-start">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-full">
            Confirm Password <span className="text-red-600">*</span>
          </label>
          <input
            type="password"
            className="border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Confirm Password"
            {...register("confirmPassword")}
          />
          {errors.confirmPassword && (
            <span className="text-red-600 text-xs mt-1">
              {errors.confirmPassword.message}
            </span>
          )}
        </div>

        <div className="flex flex-col items-start col-span-2">
          <div className="flex gap-2 justify-end w-full">
            <button
              type="button"
              onClick={() => reset()}
              className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"

            >
              Cancel
            </button>
            <button
              type="submit"
              className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
function addOfficeBankDeposit(data: { oldPassword: string; newPassword: string; confirmPassword: string; }): any {
    throw new Error("Function not implemented.");
}

