import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { toast } from "react-toastify";
import { AddAccounts } from "../Modal/AddAccounts";
import {
  deleteAccount,
  fetchAllAccount,
} from "../../store/slice/Accounting/AccountSetup/AccountSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import DeleteModal from "../Modal/DeleteModal";
import { NewCheckBoxFilter } from "../Filters/CheckBoxFilter/newCheckBoxFilter";

const AccountTypes = [
  { id: 1, label: "Client" },
  { id: 2, label: "Office" },
];

const BankAccount = () => {
  const { accounts, status, error } = useSelector(
    (state: RootState) => state.account
  );
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [bankAccountData, setbankAccountData] = useState<any>([]);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [accountId, setAccountId] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (!effectServiceCall.current || status === "Added") {
      const fetchPromises = [dispatch(fetchAllAccount())];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
    if (status === "failed" && !errorShownRef.current) {
      // toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, status, error]);

  if (initialLoading) {
    return <MainAnimation />;
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(accounts.length / itemsPerPage);

  const toggleDrawer = (message: string) => {
    setIsEdit(false);
    setOpenModal(!openModal);
    if (message == "success") {
      dispatch(fetchAllAccount());
    }
  };

  const handleFilterChange = (filters: number[]) => {
    setSelectedFilters(filters);
  };

  const filterAccounts = (data: any[]) => {
    return data.filter((account: any) => {
      const accountName = account?.accountName?.toLowerCase() || "";
      const accountSortCode = account?.accountSortCode?.toLowerCase() || "";
      const bankAccountCode = account?.bankAccountCode?.toLowerCase() || "";
      const accountType = account?.bankAccountType || "";

      const matchesSearch =
        searchQuery.trim() === "" ||
        accountName.includes(searchQuery.toLowerCase()) ||
        accountSortCode.includes(searchQuery.toLowerCase()) ||
        bankAccountCode.includes(searchQuery.toLowerCase());

      const matchesFilter =
        selectedFilters.length === 0 ||
        (selectedFilters.includes(1) && accountType === "Client") ||
        (selectedFilters.includes(2) && accountType === "Office");

      // Return true if both conditions are met
      return matchesSearch && matchesFilter;
    });
  };

  const filteredAccounts = filterAccounts(accounts);

  // pagination
  const indexOfLastAccount = currentPage * itemsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - itemsPerPage;
  const currentAccount = filteredAccounts.slice(
    indexOfFirstAccount,
    indexOfLastAccount
  );

  const onDeleteCall = () => {
    try {
      dispatch(deleteAccount(accountId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted Account");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  return (
    <div className="w-full mb-5">
      {openDelModal && (
        <DeleteModal
          open={openDelModal}
          onClose={() => setOpenDelModal(false)}
          onDeleteAccept={onDeleteCall}
          id={accountId}
          title="Demo Legal-software.uk"
          message="Are you sure?"
        />
      )}
      <div className="">
        <SubHeaderTitle
          title="Bank Account"
          buttonName="New Bank Account"
          isAdd={true}
          onClickAdd={toggleDrawer}
        />
        {openModal && (
          <AddAccounts
            openDrawer={openModal}
            Close={toggleDrawer}
            isEdit={isEdit}
            account={bankAccountData}
          />
        )}
      </div>
      <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
        <div>
          <SearchInput
            title="Bank Account"
            value={searchQuery}
            onChange={(e: any) => handleSearchChange(e)}
          />
        </div>
        <div>
          <div className="flex gap-4 md:gap-1 w-full">
            <NewCheckBoxFilter
              title="Account Type"
              data={AccountTypes}
              showSearchInput={false}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-1 grid-cols-3 3xl:grid-cols-2 w-full gap-5">
        {currentAccount.map((account: any, index) => (
          <div
            key={index}
            className="border rounded-md p-4 shadow-md dark:border-gray-400 dark:border-opacity-35"
          >
            <div className="flex justify-between items-center bg-gray-100 dark:bg-gray-800">
              <p className={`font-semibold px-6 dark:text-white-bg `}>
                Account Type - {account.bankAccountType}
              </p>
              {/* ${account.bankAccountType === 'Client' ? 'bg-red-500' : 'bg-slate-300'} */}
              <div className="flex gap-4 cursor-pointer px-6 py-3">
                <FaEdit
                  className="fill-blue-500 w-6 h-6"
                  onClick={() => {
                    setOpenModal(true);
                    setIsEdit(true);
                    setbankAccountData(account);
                  }}
                />
                <RiDeleteBin5Fill
                  className="fill-red-600 w-6 h-6"
                  onClick={() => {
                    setOpenDelModal(true);
                    setAccountId(account.bankAccountId);
                  }}
                />
              </div>
            </div>
            <div className="px-6 py-3 space-y-3">
              <div className="grid grid-cols-1 gap-4 max-w-md mx-auto">
                {/* <div className="flex items-center">
                                    <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">Account Code</label>
                                    <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">:</span>
                                    <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg">{account.bankAccountCode}</p>
                                </div> */}

                <div className="flex items-center">
                  <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    Account Name
                  </label>
                  <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    :
                  </span>
                  <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg">
                    {account.accountName}
                  </p>
                </div>

                <div className="flex items-center">
                  <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    Account Number
                  </label>
                  <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    :
                  </span>
                  <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg">
                    {account.accountNumber}
                  </p>
                </div>

                <div className="flex items-center">
                  <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    Short Code
                  </label>
                  <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    :
                  </span>
                  <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg">
                    {account.accountSortCode}
                  </p>
                </div>

                <div className="flex items-center">
                  <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    Active
                  </label>
                  <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                    :
                  </span>
                  <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg">
                    {account.isActive ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export { BankAccount };
