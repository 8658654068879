import axios from 'axios';
import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';


const GetAllWorkflowById = async (id: any) => {
    try {
        const response = await axiosInstance.get("Workflow/" + id);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
};

const GetWorkflowDataById = async (id: any) => {
    try {
        const response = await axiosInstance.get(`workflow/workflowDataById/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
};


const GetAllWorkflow = async (data: any) => {
    try {
        const response = await axiosInstance.post("Workflow/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
};

const GetWorkflowDocument = async (data: any) => {
    try {
        const response = await axiosInstance.post("workflow/document/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
};

const GetAllExistingWorkFlow = async () => {
    try {
        const response = await axiosInstance.get("Workflow/existing");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Add a new Workflow
const PostWorkflowSetup = async (data: any) => {
    try {
        const response = await axiosInstance.post("workflowSetup", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostWorkflowDoc = async (data: any) => {
    try {
        const response = await axiosInstance.post("workflow/step/adddocument", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// Update a Workflow
const PutWorkflow = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Workflow/${id}`, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data || error.message);
            throw new Error(`Failed to update Workflow: ${error.response?.data?.message || error.message}`);
        } else {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }
}

// Delete a Workflow
const deleteWorkflow = async (workflowId: number) => {
    try {
        await axiosInstance.delete(`/workflow/delete/${workflowId}`);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data || error.message);
            throw new Error(`Failed to delete Workflow: ${error.response?.data?.message || error.message}`);
        } else {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }
}

const DeleteWorkflowStep = async (wfId: number, workflowStepId: number) => {
    try {
        await axiosInstance.delete(`/workflow/step/delete/${wfId}/${workflowStepId}`);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data || error.message);
            throw new Error(`Failed to delete Workflow: ${error.response?.data?.message || error.message}`);
        } else {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }
}

const DeleteWorkflowStepDocument = async (stepItemId: number, id: number) => {
    try {
        await axiosInstance.delete(`/workflow/step/document/delete/${stepItemId}/${id}`);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data || error.message);
            throw new Error(`Failed to delete Workflow: ${error.response?.data?.message || error.message}`);
        } else {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }
}

// Exporting all the functions as an object
const WorkflowSetupService = {
    GetAllWorkflowById,
    GetAllWorkflow,
    GetAllExistingWorkFlow,
    PostWorkflowSetup,
    PutWorkflow,
    deleteWorkflow,
    GetWorkflowDocument,
    PostWorkflowDoc,
    GetWorkflowDataById,
    DeleteWorkflowStep,
    DeleteWorkflowStepDocument
};

export default WorkflowSetupService;
