import { object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { IoIosPrint } from "react-icons/io";

const SuppliersControlAccountDetails = ({
  openModal,
  Close,
}: {
  openModal: boolean;
  Close: any;
  quotationRequest?: any;
  isEdit: boolean;
}) => {
  const validationSchema = object().shape({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(addTask(data));
    Close();
  };

  return (
    <>
      {openModal != false && (
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="w-full flex flex-col">
            <div className="flex flex-col gap-9">
              <div className="flex gap-x-2 ietms-center justify-end mb-5">
                <button
                  type="submit"
                  className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-lg 3xl:text-sm"
                >
                  <IoIosPrint className="w-5 h-5" />
                  Print
                </button>
              </div>
            </div>
            <div className="grid md:inline-block grid-cols-2 md:grid-cols-1 gap-y-3 gap-x-5 md:space-y-5 bg-sky-50 dark:bg-gray-900 dark:text-white-bg p-6 rounded-b-md">
              <div className="flex items-center">
                <label className="w-1/4 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Supplier Name
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">Accma</p>
              </div>

              <div className="flex items-center">
                <label className="w-1/4 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Product/Service
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">Accountancy</p>
              </div>

              <div className="flex items-center">
                <label className="w-1/4 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Account Code
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">
                  Accountancy (255)
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/4 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Date Range
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">
                  01 Jan 1970 - 01 Jan 1970
                </p>
              </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg mt-5">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Trans No.
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Details
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Debit
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Credit
                    </th>
                    <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody className="text-lg 3xl:text-xs">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">£ 0.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export { SuppliersControlAccountDetails };
