import axiosInstance from '../../axiosInstance';
import { ErrorResult } from '../../../store/model/errorResult';


const GetCaseCaseFileReview = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/${id}/CaseFileReview`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const FileReviewService = {
    GetCaseCaseFileReview,
};

export default FileReviewService;
