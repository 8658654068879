import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ConflictSearchService from "../../../../services/RiskManagement/ConflictSearch/ConflictSearchService";
import { AddConflictSearchDatas, ConflictSearch } from "../../../model/RiskAssessment/ConflictSearch/conflictSearch";


export interface ConflictSearchResponse {
    currentPage: number,
    recordPerPage:number, 
    totalCount:number, 
    conflictReportResult: ConflictReportResult[];
}

export interface ConflictReportResult {
    caseId: number;
    caseReferenceAuto: string;
    cbDesignation: string;
    cbFirstName: string;
    cbLastName: string;
    cbTitle: string;
    conflictKeyword: string;
    conflictNotes: string;
    conflictReportFile: string;
    conflictReportName: string;
    conflictSearchBy: number;
    conflictSearchDate: string;
    conflictSearchId: number;
    designation: string;
    firstName: string;
    lastName: string;
    title: string;
    searchOption: string;
};


// Define the state type for casetype
type ConflictSearchState = {
    conflictSearchResponse: ConflictSearchResponse;
    conflictSearch: ConflictSearch[];
    addConflictSearchDatas: AddConflictSearchDatas[];
    any: any[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ConflictSearchState = {
    conflictSearchResponse: null!,
    conflictSearch: [],
    addConflictSearchDatas: [],
    any:[],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllConflictSearch = createAsyncThunk(
    "fetchAllConflictSearch",
    async (data:ConflictSearch, thunkAPI) => {
        try {
            const response = await ConflictSearchService.GetAllConflictSearch(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllConflictSearchData = createAsyncThunk(
    "fetchAllConflictSearchData",
    async (data: any, thunkAPI) => {
        try {
            const response = await ConflictSearchService.GetAllConflictSearchData(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addConflictSearch = createAsyncThunk(
    "addConflictSearch",
    async (data: any, thunkAPI) => {
        try {
           // data.fkConditionTypeIds = data.fkConditionTypeIds.toString();
            const response = await ConflictSearchService.PostConflictSearch(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateConflictSearch = createAsyncThunk(
    "updateConflictSearch",
    async ({ id, data }: { id: number, data: ConflictSearch }, thunkAPI) => {
        try {
            const response = await ConflictSearchService.PutConflictSearch(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteConflictSearch = createAsyncThunk(
    "deleteConflictSearch",
    async (QuotationRequestId: number, thunkAPI) => {
        try {
            await ConflictSearchService.deleteConflictSearch(QuotationRequestId);
            return QuotationRequestId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ConflictSearchSlice = createSlice({
    name: "conflictSearch",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllConflictSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllConflictSearch.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.conflictSearch = action.payload;
            })
            .addCase(fetchAllConflictSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllConflictSearchData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllConflictSearchData.fulfilled, (state, action: PayloadAction<ConflictSearchResponse>) => {
                state.status = "idle";
                state.conflictSearchResponse = action.payload;
            })
            .addCase(fetchAllConflictSearchData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addConflictSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addConflictSearch.fulfilled, (state, action: PayloadAction<AddConflictSearchDatas>) => {
                state.status = "idle";
                state.addConflictSearchDatas.push(action.payload);
            })
            .addCase(addConflictSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateConflictSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateConflictSearch.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                if (action.payload != null) {
                    state.conflictSearch = action.payload || [];
                }
            })
            .addCase(updateConflictSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteConflictSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteConflictSearch.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
            })
            .addCase(deleteConflictSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ConflictSearchSlice.reducer;
