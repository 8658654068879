import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";


function AddClientStepThree({ onSubmitStep, onCancel, initialData, onPreviousStep, isLoading }: any) {
    const clientByIdData = useSelector((state: RootState) => state.client.clientByIdData);


    const validationSchema = object().shape({
        jobTitle: string(),
        comapnyName: string(),
        compAddLine1: string(),
        compAddLine2: string(),
        compAddCity: string(),
        compAddCounty: string(),
        compAddPostCode: string(),
        compAddEmail: string(),
        compAddOfficePhone: string(),
        compAddFax: string(),
        isDisability: boolean(),
        disablityDescription: string().when('isDisability', {
            is: true,
            then: (schema) => schema.required('Disability description is required.'),
        }),

        isIndFutureAddress: boolean(),
        futureAddLine1: string().when('isIndFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        futureAddLine2: string(),
        futureAddCity: string(),
        futureAddCounty: string(),
        futureAddPostCode: string().when('isIndFutureAddress', {
            is: true,
            then: (schema) => schema.required('postCode is required.'),
        }),
        futureAddEffectFrom: string().when('isIndFutureAddress', {
            is: true,
            then: (schema) => schema.required('EffectFrom is required.'),
        }),
        futureAddIsPreferred: boolean(),

        notes: string(),
        indIsActive: boolean(),
    })

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
            indIsActive: true,
        }
    });

    const isDisability = watch('isDisability', initialData?.isDisability || false);
    const isIndFutureAddress = watch('isIndFutureAddress', initialData?.isIndFutureAddress || false);
    const isPreferredAddress = watch('futureAddIsPreferred', initialData?.futureAddIsPreferred || false);
    const [isChecked, setIsChecked] = useState(isDisability || false);
    const [isIndFutureChecked, setIsIndFutureChecked] = useState(isIndFutureAddress || false);
    const [employmentContact, setEmploymentContact] = useState(false);
    const [isPreferredAdd, setIsPreferredAdd] = useState(isPreferredAddress || false);

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    const handleDisabilityChange = (checked: boolean) => {
        setIsChecked(checked);
        setValue('isDisability', checked, { shouldValidate: true });
        if (!checked) {
            setValue('disablityDescription', '');
        }
    };

    const handleIndFutureAddress = (checked: boolean) => {
        setIsIndFutureChecked(checked);
        setValue('isIndFutureAddress', checked, { shouldValidate: true });
    };

    const handleEmploymentContact = (checked: boolean) => {
        setEmploymentContact(checked);
    };

    const handlePreferredAddress = (checked: boolean) => {
        setIsPreferredAdd(checked);
        setValue('futureAddIsPreferred', checked, { shouldValidate: true });
    };

    useEffect(() => {
        if (clientByIdData !== null) {
            setValue("jobTitle", clientByIdData?.jobTitle);
            setValue("comapnyName", clientByIdData?.comapnyName);
            setValue("compAddLine1", clientByIdData?.compAddLine1);
            setValue("compAddLine2", clientByIdData?.compAddLine2);
            setValue("compAddCity", clientByIdData?.compAddCity);
            setValue("compAddCounty", clientByIdData?.compAddCounty);
            setValue("compAddPostCode", clientByIdData?.compAddPostCode);
            setValue("compAddEmail", clientByIdData?.compAddEmail);
            setValue("compAddOfficePhone", clientByIdData?.compAddOfficePhone);
            setValue("compAddFax", clientByIdData?.compAddFax);
            setValue("isDisability", clientByIdData?.isDisability);
            setValue("disablityDescription", clientByIdData?.disablityDescription);
            setValue("isIndFutureAddress", clientByIdData?.isIndFutureAddress);
            setValue("futureAddLine1", clientByIdData?.futureAddLine1);
            setValue("futureAddLine2", clientByIdData?.futureAddLine2);
            setValue("futureAddCity", clientByIdData?.futureAddCity);
            setValue("futureAddCounty", clientByIdData?.futureAddCounty);
            setValue("futureAddPostCode", clientByIdData?.futureAddPostCode);
            setValue("futureAddEffectFrom", clientByIdData?.futureAddEffectFrom);
            setValue("futureAddIsPreferred", clientByIdData?.futureAddIsPreferred);
            setValue("notes", clientByIdData?.notes);
            setValue("indIsActive", clientByIdData?.indIsActive);
        }
    }, [clientByIdData])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
            <div>
                {/* <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Employment Contact Details</h3> */}
                <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="flex gap-5 col-span-4">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Employment Contact Details
                        </label>
                        <input
                            type="checkbox"
                            className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                            checked={employmentContact}
                            onChange={(e) => handleEmploymentContact(e.target.checked)}
                        />
                    </div>
                    {employmentContact && (
                        <>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Job Title
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('jobTitle')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Company Name
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('comapnyName')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address Line 1
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compAddLine1')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address Line 2
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('compAddLine2')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    City
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compAddCity')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    County
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compAddCounty')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Postcode
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compAddPostCode')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Email Address
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('compAddEmail')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Office Phone
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compAddOfficePhone')} />
                            </div>
                            {/* <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Fax
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('compAddFax')} />
                    </div> */}
                        </>
                    )}

                </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className=" flex flex-col gap-3">
                    <div className="">
                        <div className="flex gap-5">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Disability
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                checked={isChecked}
                                onChange={(e) => handleDisabilityChange(e.target.checked)}
                            />
                        </div>
                    </div>
                    {isChecked && (
                        <div className="">
                            <textarea
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.disablityDescription ? 'border-red-500' : ''}`}
                                {...register('disablityDescription')}
                            />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.disablityDescription?.message?.toString()}</div>
                        </div>
                    )}
                </div>
            </div>


            <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="flex gap-5 col-span-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Future Address
                    </label>
                    <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                        checked={isIndFutureChecked}
                        onChange={(e) => handleIndFutureAddress(e.target.checked)}
                    />
                </div>
                {isIndFutureChecked && (
                    <>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 1 <span className="text-red-500">*</span>
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.futureAddLine1 ? 'is-invalid' : ''} `} {...register('futureAddLine1')} />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.futureAddLine1?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 2
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('futureAddLine2')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                City
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('futureAddCity')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('futureAddCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Postcode <span className="text-red-500">*</span>
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.futureAddPostCode ? 'is-invalid' : ''} `} {...register('futureAddPostCode')} />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.futureAddPostCode?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Effect From <span className="text-red-500">*</span>
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.futureAddEffectFrom ? 'is-invalid' : ''} `} {...register('futureAddEffectFrom')} />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.futureAddEffectFrom?.message?.toString()}</div>
                        </div>
                        <div className=" flex gap-5">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Set as preferred contact address
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                checked={isPreferredAdd}
                                onChange={(e) => handlePreferredAddress(e.target.checked)}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className="pb-10">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Note
                </label>
                <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('notes')} />
            </div>
            {/* <div className="relative max-w-full flex gap-6">
                <label className="block mb-2">
                    Is Active
                </label>
                <div className='flex gap-5'>
                    <div>
                        <input
                            type="radio"
                            value="true"
                            checked={watch('indIsActive') === true}
                            onChange={() => setValue('indIsActive', true)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                    </div>
                    <div>
                        <input
                            id="bordered-radio-1"
                            type="radio"
                            value="false"
                            checked={watch('indIsActive') === false}
                            onChange={() => setValue('indIsActive', false)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                    </div>
                </div>
            </div> */}
            <div className="">
                <div className="flex flex-row gap-2 items-start justify-between w-full">
                    <button
                        onClick={onPreviousStep}
                        type="button"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        Previous
                    </button>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">

                            <button
                                type="button"
                                onClick={onCancel}
                                className="border border-gray-400 bg-gray-100 text-gray-700 font-medium cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export { AddClientStepThree };
