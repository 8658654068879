import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { boolean, number, object, string, date } from "yup";
import { SelectBox } from "../../SelectBox";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";


function AddUserStepOne({ onSubmitStep, initialData, titleData, maritalStatusData, userRoleData, onCancel }: any) {

    const userByIdData = useSelector((state: RootState) => state.userManage.userByIdData);

    // console.log(titleData)
    // console.log(maritalStatusData)
    // console.log(userRoleData)
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
    const validationSchema = object().shape({
        fkTitleId: number()
            .typeError('Title must be a number')
            .required('Title is required')
            .positive('Title must be a positive number')
            .integer('Title must be an integer'),
        firstName: string()
            .required('First Name is required'),
        middleName: string(),
        lastName: string()
            .required('Last Name is Required'),
        dob: string(),
        sex: boolean()
            .required('Sex is required')
            .typeError('Sex is required'),
        fkMaritalStatusId: number(),
        qualifications: string(),
        address: string(),
        email: string()
            .required('Email Address is required'),
        mobile: string()
            .required('Mobile is required'),
        fkUserRoleId: number()
            .typeError('User Role must be a number')
            .required('User Role is required')
            .positive('User Role must be a positive number')
            .integer('User Role must be an integer'),
        designation: string(),
        niNumber: string(),

    })

    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };
    const handleFormSubmit = (data: any) => {
        data.imageFile = selectedImageFile;
        onSubmitStep(data);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedImageFile(file)
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    const maritalStatusOption = maritalStatusData !== null && maritalStatusData.map((Mstatus: any) => ({
        label: Mstatus.maritalStatus,
        value: Mstatus.maritalStatusId
    }));

    useEffect(() => {
        if (userByIdData !== null) {
            let dateOfBirth = (userByIdData?.dob !== null) ? userByIdData?.dob.split("T")[0] : null;
            setValue("fkTitleId", userByIdData?.fkTitleId);
            setValue("firstName", userByIdData?.firstName);
            setValue("middleName", userByIdData?.middleName);
            setValue("lastName", userByIdData?.lastName);
            setValue("dob", dateOfBirth);
            setValue("sex", userByIdData?.sex);
            setValue("fkMaritalStatusId", userByIdData?.fkMaritalStatusId);
            setValue("qualifications", userByIdData?.qualifications);
            setValue("address", userByIdData?.address);
            setValue("email", userByIdData?.email);
            // setValue("homePhone", userByIdData?.homePhone);
            setValue("mobile", userByIdData?.mobile);
            setValue("fkUserRoleId", userByIdData?.fkUserRoleId);
            setValue("designation", userByIdData?.designation);
            setValue("niNumber", userByIdData?.niNumber);
        }
    }, [userByIdData])

    return (

        <form onSubmit={handleSubmit(handleFormSubmit, onError)} >
            <div>
                {/* <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg mb-1">Personal Details</h3> */}
                <div className="grid grid-cols-12 md:grid-rows-1 gap-12 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-4">
                    <div className="relative col-span-2">
                        <img className="size-40 rounded-lg object-cover" src={selectedImage || "/images/header/user2.png"} alt="user" />
                        <div className="cursor-pointer bg-hover_bg px-2 py-1 rounded-tr-lg absolute top-0 -right-9 mr-14">
                            <button type="button" onClick={() => fileInputRef.current?.click()}>
                                <svg className="w-4 h-4 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg>
                            </button>
                        </div>
                        <input id="dropzone-file" ref={fileInputRef} type="file" className="hidden" onChange={handleFileChange} />
                    </div>
                    <div className="col-span-5">
                        <div className="grid grid-cols-2 md:grid-rows-1 gap-x-10">
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Title <span className="text-red-500">*</span>
                                </label>
                                <select className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkTitleId ? 'is-invalid' : ''}`} {...register('fkTitleId')}>
                                    <option value="">Select</option>
                                    {titleData.map((title: any) => (
                                        <option key={title.titleId} value={title.titleId
                                        }>{title.title}</option>
                                    ))}
                                </select>
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.fkTitleId?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    First Names <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.firstName ? 'is-invalid' : ''}`} {...register('firstName')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.firstName?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    DOB
                                </label>
                                <input type="date" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('dob')} max={new Date().toISOString().split('T')[0]} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Sex <span className="text-red-500">*</span>
                                </label>
                                <select className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.sex ? 'is-invalid' : ''}`} {...register('sex')}>
                                    <option value="">Select</option>
                                    <option value="true" >Male</option>
                                    <option value="false" >Female</option>
                                </select>
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.sex?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address
                                </label>
                                <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('address')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Email Address <span className="text-red-500">*</span>
                                </label>
                                <input type="email" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.email?.message?.toString()}</div>
                            </div>

                        </div>
                    </div>

                    <div className="col-span-5">
                        <div className="grid grid-cols-2 gap-x-10">
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Middle Name
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('middleName')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" ></div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Last Name <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.lastName ? 'is-invalid' : ''}`} {...register('lastName')} />                            <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.lastName?.message?.toString()}</div>
                            </div>

                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Marital Status
                                </label>
                                {/* <select className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    {...register('fkMaritalStatusId')}>
                                    <option value="" >Select</option>
                                    {maritalStatusData.map((marital: any) => (
                                        <option key={marital.maritalStatusId
                                        } value={marital.maritalStatusId
                                        }>{marital.maritalStatus}</option>
                                    ))}
                                </select> */}
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={maritalStatusOption}
                                    isSearchable={true}
                                    value={getValues().fkMaritalStatusId}
                                    placeholder="Marital Status"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setValue('fkMaritalStatusId', selectedOption);
                                        }
                                    }}
                                />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Qualifications
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('qualifications')} />
                            </div>
                            {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                        Home Phone
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('homePhone')} />
                </div> */}
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Mobile <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.mobile ? 'is-invalid' : ''}`} {...register('mobile')} />
                                <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.mobile?.message?.toString()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Employment Details</h3> */}
            <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-4">

                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                        User Role <span className="text-red-500">*</span>
                    </label>
                    <select className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkUserRoleId ? 'is-invalid' : ''}`} {...register('fkUserRoleId')}>
                        <option value="">Select</option>
                        {userRoleData.map((role: any) => (
                            <option key={role.userRoleId} value={role.userRoleId
                            }>{role.userRoleName}</option>
                        ))}
                    </select>
                    <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.fkUserRoleId?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                        Designation
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('designation')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                        NI Number
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('niNumber')} />
                </div>
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[130px]">
                        <FaArrowLeftLong className="w-6 h-6 fill-white-bg" />
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]">
                        Next
                        <FaArrowRightLong className="w-6 h-6 fill-white-bg" />
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddUserStepOne };
