import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllOfficeSupplier = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/SupplierSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeSupplier = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeSupplier = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeSupplier/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeSupplier = async (id: number) => {
    try {
        await axiosInstance.delete(`Supplier/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeSupplierService = {
    GetAllOfficeSupplier,
    PostOfficeSupplier,
    PutOfficeSupplier,
    deleteOfficeSupplier
};


export default OfficeSupplierService;
