import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { Heading } from "../../AccountSetupHeading/Heading";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";


function AddExpertStepThree({ onSubmitStep, initialData,  areasOfPracticeData, onCancel, isLoading , onPreviousStep}: any) {
    const [selectedItems, setSelectedItems] = useState<string[]>([]); // Tracks selected menu items
    const expertRegisterByIdData = useSelector((state: RootState) => state.expertRegister.expertRegsterByIdData);
    const validationSchema = object().shape({
       // chamberName: string(),
       finaldetails: string(),
        feedetails: string(),
        notes: string(),

    })


    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });
    const areaofPractice = areasOfPracticeData ? areasOfPracticeData.map((aop: any) => ({
        label: aop.area,
        value: aop.areasOfPracticeId
    })) : [];
    const handleFormSubmit = (data: any) => {
        // Add selected area IDs to the data object
        const formData = {
          ...data,
          areasOfPracticeIds: selectedItems, // Include selected area IDs
        };
    
        console.log(JSON.stringify(formData, null, 2));
        onSubmitStep(formData); // Pass the updated data to the parent
      };

    const toggleMenuSelection = (menuItem: any) => {
        setSelectedItems((prev) =>
          prev.includes(menuItem.value)
            ? prev.filter((id) => id !== menuItem.value) // Deselect
            : [...prev, menuItem.value] // Select
        );
      };

       useEffect(() => {
          if (expertRegisterByIdData !== null) {
            // let dateOfBirth = (expertRegisterByIdData?.dateOfBirth !== null) ? clientByIdData?.dateOfBirth.split("T")[0] : null;
            setValue("finaldetails", expertRegisterByIdData?.finaldetails);
            setValue("feedetails", expertRegisterByIdData?.feedetails);
            setValue("notes", expertRegisterByIdData?.notes);
           // setValue("champercity", expertRegisterByIdData?.champercity);
            
          }
        }, [expertRegisterByIdData]);
    return (

        <form onSubmit={handleSubmit(handleFormSubmit)}>

            <Heading title={"Area of Practice"} />
            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                {/* <div className="">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                        Chamber Name
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('chamberName')}
                        placeholder="Enter Chamber Name"
                    />
                </div> */}

                <div className="p-5">
                    <h2 className="text-lg font-semibold mb-4">Areas of Practice</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {areaofPractice.map((menu: any) => (
                        <div key={menu.value} className="flex items-center gap-3">
                            <input
                            type="checkbox"
                            id={`area-${menu.value}`}
                            onChange={() => toggleMenuSelection(menu)}
                            checked={selectedItems.includes(menu.value)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`area-${menu.value}`} className="text-gray-800 dark:text-gray-300">
                            {menu.label}
                            </label>
                        </div>
                        ))}
                    </div>
                    </div>
                <div className="relative max-w-full col-span-2">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                        Details
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Enter Details" {...register('finaldetails')}
                    ></textarea>
                </div>
            </div>

            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="relative max-w-full col-span-2">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Fee Details
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Enter Fee Details" {...register('feedetails')}
                    ></textarea>
                </div>
                <div className="relative max-w-full col-span-2">
                    <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                    Fee Notes
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Enter Notes" {...register('notes')}
                    ></textarea>
                </div>
            </div>


            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onPreviousStep}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm"> 
               <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        Previous
                    </button>
                </div>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">

                            <button
                                type="button"
                                onClick={onCancel}
                                className="border border-gray-400 bg-gray-100 text-gray-700 font-medium cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
        </form>
    );
}

export { AddExpertStepThree };
