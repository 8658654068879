// Company

import { yupResolver } from "@hookform/resolvers/yup";
import { error } from "console";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { SelectBox } from "../../../SelectBox";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";


function AddOrganisationContactOne({ onSubmitStep, initialData, contactCategory, onCancel }: any) {
    const contactDataById = useSelector((state: RootState) => state.contact.contactDataById);

    const contactCategoryOption = contactCategory ? contactCategory.map((contactCategories: any) => ({
        label: contactCategories.contactCategory,
        value: contactCategories.contactCategoryId
    })) : [];

    const validationSchema = object().shape({
        fkOrganisationContactsCategoryId: number(),
        nature: string(),
        name: string().required("name is required"),
    })

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {
        if (contactDataById !== null) {
            setValue("fkOrganisationContactsCategoryId", contactDataById?.fkOrganisationContactsCategoryId);
            setValue("nature", contactDataById?.nature);
            setValue("name", contactDataById?.name);
        }
    }, [contactDataById])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
            <div className="grid grid-cols-4 gap-x-10">
                <div className="col-2 col-span-2">
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mt-1 mb-5">Contact Category</h3>
                    <div className=" border dark:text-white-bg dark:border dark:border-gray-400 dark:border-opacity-35 p-6">
                        <div className="grid grid-cols-3">
                            <label className="col-span-1 text-sm font-medium text-gray-900 dark:text-white-bg">Select Category <span className="text-red-500">*</span></label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={contactCategoryOption}
                                isSearchable={true}
                                value={getValues().fkOrganisationContactsCategoryId}
                                placeholder="Contact Category"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkOrganisationContactsCategoryId', selectedOption);
                                    }
                                }}
                            />
                            <div className="col-start-2 font-medium mt-2 text-xs text-left text-red-600" >{errors?.fkOrganisationContactsCategoryId?.message?.toString()}</div>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-span-2">
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mt-1 mb-5">Organization Details</h3>
                    <div className=" border dark:text-white-bg dark:border dark:border-gray-400 dark:border-opacity-35 p-6">
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Name <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.name?.message?.toString()} </div>
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    SRA ID
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`} {...register('nature')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddOrganisationContactOne };
