import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { SearchCaseReference } from '../../../SearchCaseReference';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addOfficeToClient, addReverseOfficeToClient, fetchNextOfficeToClientNumber } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/OfficeToClientSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { formatDateISODisplay } from '../../../../../utils/date-time.utils';
import { NumericFormatWrapper } from '../../../../InputComponents/NumericFormatWrapper';
import { fetchClientCaseBalance } from '../../../../../store/slice/CaseManagement/Case/CaseManagementSlice';
import { fetchNextReverseNumber } from '../../../../../store/slice/Accounting/ClientAccounts/ClientAccountSlice';
import { ClientAccountConstant } from '../../../../../store/model/Accounting/ClientAccount/ClientAccountConst';
import { MainAnimation } from '../../../../loadingAnimation/MainAnimation';


function AddOfficeToClient({ openDrawer, Close, isView, isReverse, officeToClient }: { openDrawer: boolean, Close: any, isView: boolean, isReverse: boolean, officeToClient: any }) {
    const dispatch = useAppDispatch();
    const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    //const receiptNumbers = useSelector((state: RootState) => state.officeToClient.receiptNumber);
    const { officeBanks } = useSelector((state: RootState) => state.officeBank);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
    const [officeAccountLists, setOfficeAccountLists] = useState<any[]>([]);
    const [isReverseDisabled, setIsReverseDisabled] = useState(true);
    const reverseReceiptNumber = useSelector((state: RootState) => state.clientAccount.reverseReceiptNumber);
    const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
    const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>([]);

    // const effectServiceCall = useRef(false);
    // const errorShownRef = useRef(false);
    // useEffect(() => {
    //     setInitialLoading(true);
    //     if (!effectServiceCall.current && !isView) {
    //         const fetchPromises = [
    //             dispatch(fetchNextOfficeToClientNumber()),
    //         ];
    //         Promise.all(fetchPromises)
    //             .then(() => {
    //                 setInitialLoading(false);
    //             })
    //             .catch((error) => {
    //                 if (!errorShownRef.current) {
    //                     toast.error("Error fetching data: " + error.message);
    //                     errorShownRef.current = true;
    //                 }
    //                 setInitialLoading(false);
    //             });
    //         effectServiceCall.current = true;
    //     }
    // }, [dispatch]);





    useEffect(() => {
        if (clientAccounts.length > 0) {
            // console.log(clientAccounts)
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`
                ,
            }));
            setClientAccountLists(data);
            let findObj = clientAccounts.find((x) => x.isDefaultAccount === true);
            if (findObj && findObj !== null) {
                setValue("fkClientBankId", findObj?.bankAccountId)

            }

        }
    }, [clientAccounts]);

    useEffect(() => {
        if (officeBanks.length > 0) {
            // console.log(officeBanks)
            let data = officeBanks.map((officeAccount: any, index) => ({
                value: officeAccount.bankAccountId,
                label: `${officeAccount.institution} - ${officeAccount.bankAccountType}`
                ,
            }));
            setOfficeAccountLists(data);
            let findObject = officeBanks.find((x) => x.isDefaultAccount === true);
            if (findObject && findObject !== null) {
                setValue("fkOfficeBankId", findObject?.bankAccountId)
            }

        }
    }, [officeBanks]);

    useEffect(() => {
        if (clientPaymentTypes.length > 0) {
            let data = clientPaymentTypes.map((PaymentType: any, index) => ({
                value: PaymentType.paymentType,
                label: PaymentType.paymentType,
            }));
            setclientPaymentTypeLists(data);
            let findObj = clientPaymentTypes.find((x) => x.isActive === true);
            if (findObj && findObj !== null) {
                setValue("transferMethod", (findObj?.paymentType))

            }

        }
    }, [clientPaymentTypes]);


    const validationSchema = object().shape({
        fkOfficeBankId: number()
            .required('From Bank Account is required'),
        officeToClientTransferNumber: number(),
        transactionDate: string().required('Transaction Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
            }),
        // entryDate: string().required('Entry Date is required')
        //     .nullable()
        //     .transform((value, originalValue) => {
        //         if (originalValue === "" || value === null) return null;
        //         return new Date(value).toISOString().split('T')[0];
        //     }),
        fkCaseId: number(),

        fkClientBankId: number()
            .required('To Bank Account is required'),
        transactionDescription: string()
            .required('Transaction Description is required'),
        amountTransfer: number()
            .required('Amount is required'),
        comments: string(),
        // fkUserId: string()
        //     .required('Authorised By is required'),
        transferMethodReferenceNumber: string(),
        transferMethod: string()
            .required('Transfer Method is required'),
        officeToClientId: number(),

        reverseReferenceNumber: number(),
        officeToClientTransferId: number()
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            officeToClientTransferNumber: 0,
            officeToClientTransferId: 0,
            transactionDate: new Date().toISOString().split("T")[0]
        }
    });

    useEffect(() => {
        setValue("transactionDate", new Date().toISOString().split("T")[0]);
    }, []);

    // useEffect(() => {
    //     if (receiptNumbers !== null) {
    //         // console.log(receiptNumbers);
    //         setValue('officeToClientTransferNumber', receiptNumbers);
    //         setAutoReceiptNumber(receiptNumbers);
    //     }
    // })

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addOfficeToClient(data)).unwrap();
            Close("success");
            toast.success("Successfully Added office to Client details");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    // const today = new Date().toISOString().split("T")[0];
    // useEffect(() => {
    //   setValue("transactionDate", today);
    // }, [today]);



    const toggleCaseAccountModal = () => {
        setOpenCaseAccountModal(!openCaseAccountModal);
    }

    const handleCaseSelection = (selectedCase: any) => {
        console.log(selectedCase)
        setSelectedCaseReference(selectedCase);
        setValue('fkCaseId', selectedCase?.caseId);
        dispatch(fetchClientCaseBalance(selectedCase.caseId));
        setOpenCaseAccountModal(false);
    };

    const [formData, setFormData] = useState<any>({
        reverseReference: number,
        reverseNumber: 0,
        reason: "",
    });

    // Handle change in input fields
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleReverseClick = async () => {
        if (isReverse) {
            // Check if reverseNumber is empty or only contains whitespace
            if (!formData.reverseNumber || !formData.reverseNumber.value.trim()) {
                toast.error("Reverse Receipt Number is required.");
            } else {
                console.log(formData);
                try {
                    setIsLoading(true);
                    await dispatch(addReverseOfficeToClient(formData)).unwrap();
                    Close("success");
                    toast.success("Successfully saved reverse of office To client details");

                } catch (error: any) {
                    toast.error(error.toString());
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    useEffect(() => {
        if ((officeToClient != null && isView === true) || (officeToClient != null && isView === false && isReverse === true)) {
            setValue('fkClientBankId', officeToClient?.fkClientBankId)
            setValue('transactionDate', formatDateISODisplay(officeToClient?.transactionDate))
            setValue('fkOfficeBankId', officeToClient?.fkOfficeBankId)
            setValue('fkCaseId', officeToClient?.caseId)
            setValue('transactionDescription', officeToClient?.transactionDescription)
            //  setValue('officeToClientTransferNumber', officeToClient?.officeToClientTransferNumber)
            setValue('comments', officeToClient?.comments)
            setValue('transferMethod', officeToClient?.transferMethod)
            setValue('amountTransfer', officeToClient?.amountTransfer)
            setValue('transferMethodReferenceNumber', officeToClient?.transferMethodReferenceNumber)
            setValue('officeToClientId', officeToClient?.officeToClientId)
            setValue('officeToClientTransferId', officeToClient?.officeToClientTransferId)

            setSelectedCaseReference({ caseReferenceAuto: officeToClient.caseReferenceAuto })

            dispatch(fetchClientCaseBalance(officeToClient?.fkCaseId));

        }
    }, [officeToClient, isView, reset, setValue]);

    const handleReverseEditClick = () => {
        setIsReverseDisabled(!isReverseDisabled);
    };

    useEffect(() => {
        if (isReverse) {
            dispatch(fetchNextReverseNumber(ClientAccountConstant.OfficetoClient));
        }

    }, [dispatch, isReverse]);



    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-12" >
                {initialLoading ? <MainAnimation /> : ""}
                <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
                    <div className="relative flex gap-1">
                        <input
                            type="text"
                            placeholder={selectedCaseReference?.caseReferenceAuto || "Select Case"}
                            aria-label="Search"
                            value={selectedCaseReference?.caseReferenceAuto || ""}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkCaseId ? 'is-invalid' : ''}`}

                            disabled
                        />
                        <button
                            onClick={toggleCaseAccountModal}
                            className="z-[2] inline-block rounded-e border-2 border-primary w-36 px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                            data-twe-ripple-init
                            data-twe-ripple-color="white"
                            type="button"
                            disabled={isView}
                        >
                            Add Case
                        </button>
                        {(openCaseAccountModal) &&
                            <SearchCaseReference
                                openDrawer={openCaseAccountModal}
                                Close={toggleCaseAccountModal}
                                onCaseSelect={handleCaseSelection}
                            />
                        }
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.fkCaseId?.message?.toString()}</div>

                    </div>
                    {isReverse === false || isView === false && (
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    Close();
                                }}
                                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    )}
                </div >
                <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                    {/* <div >
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reference <span className="text-red-500">*</span>
                        </label>
                        <div className="flex gap-3">
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                value={getValues().officeToClientTransferNumber}
                                disabled={isDisabled}
                            />
                            <button
                                type="button"
                                onClick={handleEditClick}
                                className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                            >
                                {isDisabled ? <FaLock /> : <FaLockOpen />}
                            </button>
                        </div>
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600" >{errors?.officeToClientTransferNumber?.message?.toString()}</div>
                    </div> */}
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            From Bank Account <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={officeAccountLists}
                            isSearchable={true}
                            value={getValues().fkOfficeBankId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkOfficeBankId', selectedOption);
                                }
                            }}
                            isDisabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600" >{errors?.fkOfficeBankId?.message?.toString()}</div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            To Bank Account <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={clientAccountLists}
                            isSearchable={true}
                            value={getValues().fkClientBankId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkClientBankId', selectedOption);
                                }
                            }}
                            isDisabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600" >{errors?.fkClientBankId?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? 'is-invalid' : ''}`}
                            {...register('transactionDate')}
                            placeholder="Enter Transaction Date"
                            disabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.transactionDate?.message?.toString()}</div>
                    </div>

                    {/* <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Entry Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? 'is-invalid' : ''}`} {...register('entryDate')}
                            placeholder="Enter Date"
                            max={new Date().toISOString().split('T')[0]}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.entryDate?.message?.toString()}</div>
                    </div> */}
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Description <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? 'is-invalid' : ''}`} {...register('transactionDescription')}
                            placeholder="Enter Transaction Description"
                            disabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.transactionDescription?.message?.toString()}</div>
                    </div>

                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Amount (£) <span className="text-red-500">*</span>
                        </label>
                        <NumericFormatWrapper decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amountTransfer ? 'is-invalid' : ''}`}
                            value={getValues().amountTransfer}
                            onValueChange={(values: any) => {
                                setValue('amountTransfer', Number(values.value))
                            }}
                            placeholder="Enter Amount (£)"
                            disabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.amountTransfer?.message?.toString()}</div>
                    </div>
                    {/* <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Authorised By <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={authorisedClientLists}
                            isSearchable={true}
                            value={getValues().fkUserId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkUserId', selectedOption);
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600" >{errors?.fkUserId?.message?.toString()}</div>
                    </div> */}
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transfer Method <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={clientPaymentTypeLists}
                            value={getValues().transferMethod}
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('transferMethod', selectedOption);
                                }
                            }}
                            isDisabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600" >{errors?.transferMethod?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reference Number <span className="text-red-500"></span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transferMethodReferenceNumber ? 'is-invalid' : ''}`} {...register('transferMethodReferenceNumber')}
                            placeholder="Enter Reference Number"
                            disabled={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.transferMethodReferenceNumber?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Comments
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Comments" {...register('comments')}
                            disabled={isView || isReverse}
                        ></textarea>
                    </div>
                </div>
                {!isView && (
                    <div className="flex col-span-2 gap-2 justify-end">
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                            }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
                            disabled={isView || isReverse}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]"
                            disabled={isView || isReverse}
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                )}

            </form >
            {isReverse && (
                <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                    <div>
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reverse Reference
                        </label>
                        <div className="flex gap-3">
                            R_OB2CB
                            <input
                                type="text"
                                value={reverseReceiptNumber}
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                disabled={isReverseDisabled}
                            />

                            <button
                                type="button"
                                onClick={handleReverseEditClick}
                                className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                            >
                                {isReverseDisabled ? <FaLock /> : <FaLockOpen />}
                            </button>
                        </div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reverse Receipt Number
                        </label>
                        <input
                            type="text"
                            name="reverseNumber"
                            value={formData.reverseNumber}
                            onChange={handleChange}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 
                    }`}
                            placeholder="Enter Reverse Reference"
                        />
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reason
                        </label>
                        <textarea
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Reason"
                        ></textarea>
                    </div>
                </div>
            )}

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
                <div className="flex flex-row gap-2 items-end justify-end w-full">
                    {isReverse && (
                        <button
                            type="button"
                            onClick={handleReverseClick}
                            disabled={isLoading}
                            className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-lg 3xl:text-sm w-[120px]  ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                        >
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8H4z"
                                    ></path>
                                </svg>
                            ) : (
                                "Reverse"
                            )}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export { AddOfficeToClient };
