import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllClientPayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAClientPaymentSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostClientPayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAClientPaymentSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutClientPayment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`ClientPayment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteClientPayment = async (id: number) => {
    try {
        await axiosInstance.delete(`OAClientPayment/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ClientPaymentService = {
    GetAllClientPayment,
    PostClientPayment,
    PutClientPayment,
    deleteClientPayment
};


export default ClientPaymentService;
