import { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { AddQuickInvoice } from "../../ClientAccounts/QuickInvoiceDetails/AddQuickInvoice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { CaseClient, CaseDto} from "../../../store/model/CaseManagement/CaseInterface";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchAllClientQuickInvoices } from "../../../store/slice/Accounting/ClientAccounts/ClientQuickInvoicesSlice";
import { useParams } from "react-router-dom";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../../utils/date-time.utils";
import { FaEye } from "react-icons/fa";
import { Pagination } from "../../Pagination";
function Invoice() {
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openIsCaseForm, setOpenIsCaseForm] = useState(false);
  const { referenceNumber } = useParams();
  const [quickInvoiceData, setQuickInvoiceData] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [caseDetails, setCaseDetails] = useState<{
    caseDetailsData: CaseDto | null;
    caseClients: CaseClient | null;
  }>({
    caseDetailsData: null,
    caseClients: null,
  });
  const quickInvoices = useSelector(
    (state: RootState) => state.clientQuickInvoices.quickInvoices
  );
  const [quickInvoicesList, setQuickInvoicesList] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const caseClients = useSelector((state: RootState) => state.case.caseClient);
  const effectServiceCall = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (!effectServiceCall.current) {
      let searchdata = {
        caseId: referenceNumber,
      }
      dispatch(fetchAllClientQuickInvoices(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllClientQuickInvoices(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  useEffect(() => {
    if (quickInvoices && quickInvoices.quickInvoiceResult) {
      setQuickInvoicesList(quickInvoices.quickInvoiceResult);
      setTotalRecords(quickInvoices.totalCount);
    }
  }, [quickInvoices]);

  useEffect(() => {
    const combinedCaseDetails = {
      caseDetailsData: caseDetailsData,
      caseClients: caseClients,
    };

    setCaseDetails(combinedCaseDetails);
  }, [caseDetailsData, caseClients]);

  const toggleDrawer = () => {
    setOpenAddForm(!openAddForm);
    setOpenIsCaseForm(!openIsCaseForm);
  };

  return (
    <>
      {(openAddForm) ? (
        <AddQuickInvoice Close={toggleDrawer} isCase={openIsCaseForm} caseData={caseDetails} isEdit={isEdit} isView={isView} quickInvoice={quickInvoiceData} />
      ) : (
        <>
          {initialLoading ? <MainAnimation /> : ""}
          <div className="flex flex-row md:flex-row items-start justify-between mb-5">
            <h1 className="text-xl font-bold text-left md:text-lg dark:text-white-bg">
              Invoice
            </h1>
            <button
              className="flex items-center bg-blue-500 text-white-bg px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
              type="button"
              onClick={toggleDrawer}
            >
              <FaPlus className="w-4 h-4 fill-white-bg" />
              <span className="text-white-bg mx-2">Add Invoice</span>
            </button>
          </div>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Details
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Sub Total
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Total Vat
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Total Amount
                  </th>
                  <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {quickInvoicesList.map((invoice: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(invoice.entryDate)}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">INV {invoice.invoiceNumber}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(invoice.amount)}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(invoice.tax)}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(invoice.total)}</td>

                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <div className="flex gap-3 w-full">
                        <FaEye className="fill-gray-500 w-6 h-6"
                          onClick={() => {
                            setOpenModal(true);
                            // toggleDrawer();
                            setIsView(true);
                            setIsEdit(false);
                            setOpenIsCaseForm(false);
                            setQuickInvoiceData(invoice);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalRecords === 0 && (
              <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                <p className="text-red-800">No Invoice records found.</p>
              </div>
            )}
            {totalRecords > 0 && (
              <div className="w-full flex flex-col pb-[50px]">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPage}
                  totalRecords={totalRecords}
                  onPageChange={(page) => { onPageChange(page); }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>

  );
}

export default Invoice;
