import React from "react";

interface HeadingProps {
  title?: string;
  icon: JSX.Element;
}
const AddItems: React.FC<HeadingProps> = ({ title, icon }) => {
  return (
    <>
      {/* <button className="flex items-center font-semibold pl-6 py-3 border-t border-b dark:border-gray-400 dark:border-opacity-35 hover:bg-sky-100 dark:hover:bg-gray-700">
        {icon}
        <span className="mx-5 w-[227px] text-left dark:text-white-bg">
          {title}
        </span>
      </button> */}
      <button className={`bg-blue-500 fill-white-bg p-4 3xl:p-2.5 rounded hover:bg-hover_bg active:bg-hover_bg transition duration-300 dark:border-gray-400 dark:border-opacity-35`}>
        {icon}
      </button>
      
    </>
  );
};

export { AddItems };
