import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllOutgoingPost = async (data: any) => {
    try {
        const response = await axiosInstance.post("postregister/outgoing/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOutgoingPost = async (data: any) => {
    try {
        const response = await axiosInstance.post("postregister/outgoing", data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOutgoingPost = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`postregister/outgoing/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOutgoingPost = async (id: number) => {
    try {
        await axiosInstance.delete(`/postregister/Outgoing/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OutgoingPostService = {
    GetAllOutgoingPost,
    PostOutgoingPost,
    PutOutgoingPost,
    deleteOutgoingPost
};


export default OutgoingPostService;
