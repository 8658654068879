import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CreditNoteStatusTypeService from "../../../../../services/Accounting/ClientAccounts/Invoice/CreditNoteStatusTypeService";
import InvoiceStatusTypeService from "../../../../../services/Accounting/ClientAccounts/Invoice/InvoiceStatusTypeService";


export interface InvoiceStatusType {
    creditNoteRefundId: number;

}
// Define the state type for Checklist
type NewInvoiceStatusType = {
    invoiceStatusType: InvoiceStatusType [];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: NewInvoiceStatusType = {
    invoiceStatusType: [],
    status: "idle",
    error: null,
};

// Async thunks for CRUD operations
export const fetchAllInvoiceStatusType = createAsyncThunk(
    "fetchAllInvoiceStatusType",
    async (_, thunkAPI) => {
        try {
            const response = await InvoiceStatusTypeService.GetAllInvoiceStatusType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const InvoiceStatusTypeSlice = createSlice({
    name: "invoiceStatusType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllInvoiceStatusType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllInvoiceStatusType.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.invoiceStatusType = action.payload;
            })
            .addCase(fetchAllInvoiceStatusType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            
    }
});

export default InvoiceStatusTypeSlice.reducer;
