import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CreateSurveyService from "../../../../../services/HumanResources/Surveys/CreateSurvey/CreateSurveyService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";


export interface CreateSurvey {

  createSurveyId: number;
}
// Define the state type for Checklist
type CreateSurveyState = {
    createSurveys: PaginationResponse<CreateSurvey> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CreateSurveyState = {
    createSurveys: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCreateSurvey = createAsyncThunk(
    "fetchAllCreateSurvey",
    async (data: any, thunkAPI) => {
        try {
            const response = await CreateSurveyService.GetAllCreateSurvey(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCreateSurvey = createAsyncThunk(
    "addCreateSurvey",
    async (data: any, thunkAPI) => {
        try {
            const response = await CreateSurveyService.PostCreateSurvey(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCreateSurvey = createAsyncThunk(
    "updateCreateSurvey",
    async (data:any , thunkAPI) => {
        try {
            const response = await CreateSurveyService.PutCreateSurvey( data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCreateSurvey = createAsyncThunk(
    "deleteCreateSurvey",
    async (id: number, thunkAPI) => {
        try {
            await CreateSurveyService.deleteCreateSurvey(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CreateSurveySlice = createSlice({
    name: "createSurvey",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreateSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCreateSurvey.fulfilled, (state, action: PayloadAction<PaginationResponse<CreateSurvey>>) => {
                state.status = "idle";
                state.createSurveys = action.payload;
            })
            .addCase(fetchAllCreateSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCreateSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCreateSurvey.fulfilled, (state, action: PayloadAction<CreateSurvey>) => {
                state.status = "idle";
               // state.createSurveys.push(action.payload);
            })
            .addCase(addCreateSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCreateSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCreateSurvey.fulfilled, (state, action: PayloadAction<CreateSurvey>) => {
                state.status = "idle";
                // const index = state.createSurveys.findIndex(t => t.createSurveyId === action.payload.createSurveyId);
                // if (index !== -1) {
                //     state.createSurveys[index] = action.payload;
                // }
            })
            .addCase(updateCreateSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCreateSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCreateSurvey.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.createSurveys = state.createSurveys.filter(t => t.createSurveyId !== action.payload);
            })
            .addCase(deleteCreateSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CreateSurveySlice.reducer;
