import { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { AddClientToOffice } from "../Modal/AddClientAccounts/AddClientPostings/AddClientToOffice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllClientToOffice } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";

function ClientToOffice() {
  const [openModal, setOpenModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const searchClientToOfficeResult = useSelector(
    (state: RootState) => state.clientToOffice.searchClientToOfficeResult
  );
  const [clientToOfficeData, setClientToOfficeData] = useState<any[]>([]);
  const [currentclientToOffice, setCurrentclientToOffice] = useState<any[]>([]);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    clientBankAccId: 0,
    paymentTypeId: 0
  });

  const optiondata = [
    {
      name: "Client Bank",
      options: clientAccountLists
    },
  ]

  useEffect(() => {
    let searchdata = {
      keyword: filters.searchTerm,
      currentPage: currentPage,
      recordsPerPage: totalPage,
      dateStart: (filters.startDate == "" ? null : filters.startDate),
      dateEnd: (filters.endDate == "" ? null : filters.endDate),
      fromBank: filters.clientBankAccId,
    };
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllClientToOffice(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate, filters]);

  useEffect(() => {
    if (searchClientToOfficeResult && searchClientToOfficeResult.clientToOfficeResult) {
      setCurrentclientToOffice(searchClientToOfficeResult.clientToOfficeResult);
      setTotalRecords(searchClientToOfficeResult.totalCount);
    }
  }, [searchClientToOfficeResult]);

  useEffect(() => {
    if (clientAccounts !== null) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      data.push({ value: 0, label: "Select" });
      setClientAccountLists(data);
    }
  }, [clientAccounts]);


  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
    setIsView(false);
    setIsEdit(false);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllClientToOffice(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openModal) {
    return (
      <AddClientToOffice
        openDrawer={openModal}
        Close={toggleDrawer}
        isView={isView}
        isReverse={false}
        clientToOffice={clientToOfficeData}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="clientToOffice"
        getData={clientToOfficeData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPClientToOffice"
        getData={clientToOfficeData}
        title="Bill Payment"
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Bill Payment"
            buttonName="Bill Payment"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="w-full">
          <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Case Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Transaction Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Payment Types
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentclientToOffice.map((clientToOffice: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">

                    {formatDateOnlyDisplay(clientToOffice.transactionDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>{clientToOffice.individualNames || clientToOffice.organisationNames || clientToOffice.companyNames}</p>
                    <p>{clientToOffice.caseReferenceAuto}</p> <br />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {clientToOffice.transactionDetails}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {clientToOffice.transferMethod}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(clientToOffice.amountTransfer)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setClientToOfficeData(clientToOffice);
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6" 
                       onClick={() => {
                        setOpenReverseDrawer(true);
                        setClientToOfficeData(clientToOffice);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                {" "}
                Bill Payment details Not found.
              </p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => onPageChange(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { ClientToOffice };
