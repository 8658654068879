import React, { useMemo } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  totalRecords,
  onPageChange,
}) => {
  const totalPagesCount = Math.ceil(totalRecords / totalPages);

  const paginationRange = useMemo(() => {
    const range: (number | string)[] = [];
    range.push(1);
    if (currentPage > 4) range.push("...");
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(currentPage + 1, totalPagesCount - 1);
      i++
    ) {
      range.push(i);
    }
    if (currentPage < totalPagesCount - 3) range.push("...");
    if (totalPagesCount > 1) range.push(totalPagesCount);
    return range;
  }, [currentPage, totalPagesCount]);

  return (
    <div className="flex flex-row items-center justify-between md:justify-start w-full h-auto my-3 md:flex-col md:my-0 md:h-36 md:gap-2">
      <div className="flex flex-row gap-3 items-start">
        <span className="font-medium dark:text-white-bg">
          Matching Records:{" "}
          <span className="inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900">
            {totalRecords}
          </span>
        </span>
      </div>

      <div>
        <p className="text-slate-600 dark:text-white-bg">
          Page: <strong className="text-slate-800">{currentPage} </strong> /{" "}
          <strong className="text-slate-800">{totalPagesCount}</strong>
        </p>
      </div>

      <div className="flex md:flex-row items-start justify-start space-x-1 dark:text-gray-800">
        <button
          title="first"
          type="button"
          disabled={currentPage === 1}
          onClick={() => onPageChange(1)}
          className="inline-flex items-center justify-center px-5 h-9 font-semibold border hover:text-white-bg hover:border-blue-500 rounded-l-full shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg"
        >
          First
        </button>

        <button
          title="previous"
          type="button"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          className="inline-flex items-center justify-center w-9 h-9 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900  hover:text-white-bg hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg"
        >
          <IoIosArrowBack className="size-3.5" />
        </button>
        {paginationRange.map((item, index) => (
          // <button
          //     key={index}
          //     onClick={() => typeof item === 'number' && onPageChange(item)}
          //     disabled={currentPage === item || typeof item !== 'number'}
          // >
          //     {item}
          // </button>
          <button
            key={index}
            onClick={() => typeof item === "number" && onPageChange(item)}
            disabled={currentPage === item || typeof item !== "number"}
            type="button"
            title={`Page ${index + 1}`}
            className={`inline-flex items-center justify-center w-9 h-9 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg ${
              currentPage === item
                ? "bg-blue-500 text-white-bg"
                : "hover:bg-blue-500 hover:text-white-bg"
            }`}
          >
            {index + 1}
          </button>
        ))}
        {/* {totalPages > 0 && [...Array(totalPages)].map((_, index) => (
                    <button
                        key={index + 1}
                        type="button"
                        title={`Page ${index + 1}`}
                        onClick={() => onPageChange(index + 1)}
                        className={`inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg ${currentPage === index + 1 ? 'bg-blue-500 text-white' : ''
                            }`}
                    >
                        {index + 1}
                    </button>
                ))} */}

        <button
          title="next"
          type="button"
          disabled={currentPage === totalPagesCount}
          onClick={() => onPageChange(currentPage + 1)}
          className="inline-flex items-center justify-center w-9 h-9 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900 hover:text-white-bg hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg"
        >
          <IoIosArrowForward className="size-3.5" />
        </button>
        <button
          title="last"
          type="button"
          disabled={currentPage === totalPagesCount}
          onClick={() => onPageChange(totalPagesCount)}
          className="inline-flex items-center justify-center px-5 h-9 font-semibold  hover:text-white-bg border hover:border-blue-500 rounded-r-full shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg"
        >
          Last
        </button>
      </div>
    </div>
  );
};

export { Pagination };
