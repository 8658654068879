import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { AddSuppliersBillEntry } from "./SuppliersBillEntryDetails/AddSuppliersBillEntry";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteSuppliersBillEntry, fetchAllSuppliersBillEntry } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersBillEntrySlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { Pagination } from "../Pagination";
import { toast } from "react-toastify";
import DeleteModal from "../Modal/DeleteModal";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
function SuppliersBillEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { suppliersBillEntries, status, error } = useSelector(
    (state: RootState) => state.suppliersBillEntry
  );
  const [suppliersBillEntryData, setSuppliersBillEntryData] = useState<any>([]);

  const [currentSuppliersBillEntry, setCurrentSuppliersBillEntry] = useState<
    any[]
  >([]);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [oASupplierBillEntryData, setOASupplierBillEntryData] = useState<any[]>([]);
  const officeSupplierData = useSelector(
    (state: RootState) => state.officeSupplier.officeSupplierData
  );
 
  const [openDelModal, setOpenDelModal] = useState(false);
  const [itemName, setItemName] = useState<string>();
  const [itemId, setItemId] = useState<number>(0);

  const [filters, setFilters] = useState<FilterTypeWithEnums>({
      searchTerm: '',
      startDate: '',
      endDate: '',
      caseType: '',
      documentType: '',
      userId: 0,
      isArea: false,
      isDateBetween: true,
      isSelectbox: true,
      oASupplierId:0,
    });
  

  const optiondata = [
    {
      name: "Supplier",
      options: supplierList
    },
  ]
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        supplierId: filters.oASupplierId,
      }
      dispatch(fetchAllSuppliersBillEntry(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      // toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, status, filters, isOnAddedOrUpdate, error]);

 



  useEffect(() => {
    if (
      suppliersBillEntries != null &&
      suppliersBillEntries.listingData != null 
    ) {
      setTotalRecords(suppliersBillEntries.totalRecords);
      setOASupplierBillEntryData(suppliersBillEntries.listingData);
    }

  }, [suppliersBillEntries]);

 

  useEffect(() => {
    if (officeSupplierData && officeSupplierData.listingData) {
      let data = officeSupplierData.listingData.map((item: any) => ({
        value: item.supplierId,
        label: `${item.supplierName}`,
      }));
      data.unshift({ value: 0, label: "Supplier" });
      setSupplierList(data);
    }
  }, [officeSupplierData]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllSuppliersBillEntry(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }



  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
  };

 
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OASupplierBillEntry"
        getData={suppliersBillEntryData}
        title="Supplier BillEntry "
      />
    );
  }

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };


  const onDeleteCall = () => {
    try {
      dispatch(deleteSuppliersBillEntry(itemId)).unwrap();
      setCurrentSuppliersBillEntry((prevData: any) => prevData.filter((item: any) => item.supplierBillEntryId !== itemId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted Bill Entry");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };




  if (openModal) {
    return (
      <AddSuppliersBillEntry
        openModal={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        suppliersBillEntry={suppliersBillEntryData}
      />
    );
  }

  return (
    <>
      {openDelModal && (
        <DeleteModal
          open={openDelModal}
          onClose={() => setOpenDelModal(false)}
          id={itemId}
          title={`WorkFlow (${itemName})`}
          message="Are you sure you want to delete?"
          onDeleteAccept={onDeleteCall}
        />
      )}
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Suppliers Bill Entry"
            buttonName="Bill Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
       
          <div className="flex flex-row gap-x-5 md:gap-1 w-full items-center">
           
          <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
           
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {initialLoading ? <MainAnimation /> : ""}
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Bill Entry Number
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Supplier
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Invoice Number
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Due Date
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Gross Amount
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    VAT
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Net Amount
                  </th>
                  <th scope="col" className="text-center px-3 py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {oASupplierBillEntryData.map(
                  (suppliersBillEntry: any, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Bill{suppliersBillEntry.billEntryNumber}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {suppliersBillEntry.supplierName}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {suppliersBillEntry.temporaryInvoiceNumber}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatDateOnlyDisplay(suppliersBillEntry.dueDate)}
                      </td>
                      <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatCurrency(suppliersBillEntry.grossAmount)}
                      </td>
                      <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatCurrency(suppliersBillEntry.vat)}
                      </td>
                      <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatCurrency(suppliersBillEntry.netAmount)}
                      </td>
                      <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="flex gap-3 w-full">
                          <FaEye className="fill-gray-500 w-6 h-6"
                            onClick={() => {
                              setOpenViewDrawer(true);
                              setIsView(true);
                              setIsEdit(false);
                              setSuppliersBillEntryData(suppliersBillEntry);
                            }}
                          />
                          <FaEdit className="fill-blue-500 w-6 h-6"
                            onClick={() => {
                              setOpenModal(true);
                              setIsEdit(true);
                              setSuppliersBillEntryData(suppliersBillEntry);
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setOpenDelModal(true);
                              setItemId(suppliersBillEntry.supplierBillEntryId);
                              setItemName(suppliersBillEntry.billEntryNumber);
                            }}
                          >
                            <MdDelete className="fill-red-600 w-6 h-6" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                No supplier Bill Entry Details found.
              </p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => { onPageChange(page); }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { SuppliersBillEntry };
