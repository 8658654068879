import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BankNewPaymentService from "../../../../../../services/Accounting/ClientAccounts/ClientPostings/BankPayment/BankNewPaymentService";



export interface BankNewPayment {
    clientBankPaymentResult: []
    currentPage: number;
    recordPerPage: number;
    totalCount: number;
};

export interface CreatePaymentData {
    clientPaymentId: number;
    paymentNumber?: number;
    fkTransactionId?: number;
    paymentCreateDate?: string;
    entryDate?: string;
    dueDate?: string;
    fkPriorityTypeId?: number;
    fkClientBankId?: number;
    fkCaseId?: number;
    caseReferenceAuto: string;
    fkContactId?: number;
    payeeType?: string;
    payToId?: number;
    payTo?: string;
    transactionDescription?: string;
    amount?: number;
    comments?: string;
    requestedDate?: string;
    authorisedBy?: number;
    authorisedDate?: string;
    authorisationComments?: string;
    isAuthorisationCancelled?: boolean;
    paymentMethod?: string;
    paymentReference?: string;
    paidToDetails?: string;
    availableBalance?: string;
}

export interface SearchBankPaymentResult {
    clientBankPaymentResult: any[];
    currentPage: number;
    recordPerPage: number;
    totalCount: number;

};

// Define the state type for Checklist
type BankNewPaymentState = {
    createPaymentData: CreatePaymentData[];
    banNewPayments: BankNewPayment;
    searchBankPaymentResult: SearchBankPaymentResult;
    status: "idle" | "loading" | "failed";
    error: string | null;
    any: any
};

// Initial state
const initialState: BankNewPaymentState = {
    searchBankPaymentResult: null!,
    createPaymentData: [],
    banNewPayments: null!,
    status: "idle",
    error: null,
    any: null
};

// Async thunks for CRUD operations
export const fetchAllBankNewPayment = createAsyncThunk(
    "fetchAllBankNewPayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await BankNewPaymentService.GetAllBankNewPayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addBankNewPayment = createAsyncThunk(
    "addBankNewPayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await BankNewPaymentService.PostBankNewPayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addReverseBankNewPayment = createAsyncThunk(
    "addReverseBankNewPayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await BankNewPaymentService.PostReserveBankNewPayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateBankNewPayment = createAsyncThunk(
    "updateBankNewPayment",
    async ({ id, data }: { id: number, data: BankNewPayment }, thunkAPI) => {
        try {
            const response = await BankNewPaymentService.PutBankNewPayment(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBankNewPayment = createAsyncThunk(
    "deleteBankNewPayment",
    async (id: number, thunkAPI) => {
        try {
            await BankNewPaymentService.deleteBankNewPayment(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const BankNewPaymentSlice = createSlice({
    name: "banNewPayment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBankNewPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBankNewPayment.fulfilled, (state, action: PayloadAction<SearchBankPaymentResult>) => {
                state.status = "idle";
                state.searchBankPaymentResult = action.payload;
            })
            .addCase(fetchAllBankNewPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addBankNewPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addBankNewPayment.fulfilled, (state, action: PayloadAction<CreatePaymentData>) => {
                state.status = "idle";
                state.createPaymentData.push(action.payload);
            })
            .addCase(addBankNewPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // reverse
            .addCase(addReverseBankNewPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addReverseBankNewPayment.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(addReverseBankNewPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateBankNewPayment.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(updateBankNewPayment.fulfilled, (state, action: PayloadAction<CreatePaymentData>) => {
            //     state.status = "idle";
            //     const index = state.banNewPayments.findIndex(t => t.newPaymentId === action.payload.newPaymentId);
            //     if (index !== -1) {
            //         state.banNewPayments[index] = action.payload;
            //     }
            // })
            .addCase(updateBankNewPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteBankNewPayment.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(deleteBankNewPayment.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.banNewPayments = state.banNewPayments.filter(t => t.newPaymentId !== action.payload);
            // })
            .addCase(deleteBankNewPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default BankNewPaymentSlice.reducer;
