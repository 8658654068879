import React, { useEffect, useRef, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";

interface SearchInputProps {
  title: string;
  value?: any;
  onChange: (value: string) => void;
  debounceTime?: number;
  minChars?: number;
  className?: string;
}
const SearchInput: React.FC<SearchInputProps> = ({
  title,
  value,
  onChange,
  debounceTime = 500,
  minChars = 2,
  className = "",
}) => {
  const [searchTerm, setSearchTerm] = useState(value || "");
  const effectServiceCall = useRef(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (searchTerm === "" && effectServiceCall.current) {
      onChange("");
      return;
    }

    if (searchTerm.length < minChars) {
      return;
    }

    timerRef.current = setTimeout(() => {
      onChange(searchTerm);
    }, debounceTime);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchTerm, debounceTime, minChars, onChange]);

  return (
    <div className="relative flex items-center">
      <IoSearchSharp className="absolute left-2 text-blue-500 dark:text-neutral-200" />
      <input
        type="search"
        // className="w-full pl-8 relative block flex-auto rounded border border-solid border-blue-500 bg-cyan-50 text-blue-600 placeholder:text-blue-500 focus:ring-2 focus:ring-blue-600 bg-transparent bg-clip-padding px-3 py-[0.3rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary"

        className="w-full pl-8 relative block flex-auto rounded border-2 border-solid border-gray-600 border-opacity-20 bg-transparent bg-clip-padding px-3 py-[0.3rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset motion-reduce:transition-none dark:border-white/10 dark:text-white-bg dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary"
        placeholder={title}
        aria-label="Search"
        value={searchTerm}
        onChange={(e) => {
          effectServiceCall.current = true;
          setSearchTerm(e.target.value);
        }}
      />
    </div>
  );
};

export { SearchInput };
