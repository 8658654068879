import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ContactServices from "../../../services/CaseManagement/ContactServices";

export interface Contact {
    contactType: string;
    fkContactTypeId: number;
    contactPersonal: PersonalContact | null;
    contactCompany: CompanyContact | null;
    contactOrganisation: ContactOrganization | null;
}
export type PersonalContact = {
    personalContactsId: number;
    fkContactId: number;
    fkContactTypeId: number;
    fkPersonalContactsCategoryId: number;
    fkTitleIdPersonal: number;
    givenNames: String;
    lastName: string;
    dateOfBirth: string;
    sex: string;
    fkMaritalStatusId: number;
    fkCountryIdPersonal: number;
    fkIdentificationTypeId: number;
    identificationNumber: string;
    contactMethod: string;
    personalNote: string;

    personalContAddress1: string;
    personalContAddress2: string;
    personalContCity: string;
    personalContCounty: string;
    personalContPostCode: string;
    personalContEmail: string;
    personalContHomePhone: string;
    personalContMobile: string;

    employmentContJobTitle: string;
    employmentContCompanyName: string;
    employmentContAddress1: string;
    employmentContAddress2: string;
    employmentContCity: string;
    employmentContCounty: string;
    employmentContPostCode: string;
    employmentContEmail: string;
    employmentContOfficePhone: string;
    employmentContFax: string;


}

export type CompanyContact = {
    fkCompanyContactsCategoryId: number;
    companyName: string;
    companyNature: string;
    companyEmail: string;
    companyFax: string;
    companyWeb: string;
    companyVat: string;
    companyUtrn: string;
    companyRegNo: string;
    companyContactPhone1: string;
    companyContactPhone2: string;
    companyContactFax: string;

    companyAddressLine1: string;
    companyAddressLine2: string;
    companyCity: string;
    companyCounty: string;
    companyPostcode: string;
    companyPoboxNumber: string;
    companyPoboxCounty: string;
    companyPoboxTown: string;
    companyPoboxPostcode: string;

    companyContact1Title: string;
    companyContact1GivenNames: string;
    companyContact1LastName: string;
    companyContact1JobTitle: string;
    companyContact1Email: string;
    companyContact1DirectLine: string;
    companyContact1Extension: string;
    companyContact1OfficePhone: string;
    companyContact1Mobile: string;

    companyContact2Title: string;
    companyContact2GivenNames: string;
    companyContact2LastName: string;
    companyContact2JobTitle: string;
    companyContact2Email: string;
    companyContact2DirectLine: string;
    companyContact2Extension: string;
    companyContact2OfficePhone: string;
    companyContact2Mobile: string;
    companyNotes: string
}

export type ContactOrganization = {
    name: string;
    nature: string;
    fkOrganisationContactsCategoryId: number;

    address1: string;
    address2: string;
    city: string;
    countyOrganization: string;
    fkCountryIdOrganization: number;
    postCode: string;
    email: string;
    phone: string;
    fax: string;
    website: string;
    orgDxNumber: string;
    orgDxExchange: string;
    orgPoboxNumber: string;
    orgPoboxCounty: string;
    orgPoboxTown: string;
    orgPoboxPostCode: string;

    contPerson1Title: string;
    contPerson1GivenName: string;
    contPerson1LastName: string;
    contPerson1JobTitle: string;
    contPerson1Email: string;
    contPerson1DirectLine: string;
    contPerson1OfficePhone: string;
    contPerson1Mobile: string;
    contPerson1IncludeCompanyName: string;
    contPerson1IncludeJobTitle: string;

    contPerson2Title: string;
    contPerson2GivenName: string;
    contPerson2LastName: string;
    contPerson2JobTitle: string;
    contPerson2Email: string;
    contPerson2DirectLine: string;
    contPerson2OfficePhone: string;
    contPerson2Mobile: string;
    contPerson2IncludeCompanyName: string;
    contPerson2IncludeJobTitle: string;

    contPerson3Title: string;
    contPerson3GivenName: string;
    contPerson3LastName: string;
    contPerson3JobTitle: string;
    contPerson3Email: string;
    contPerson3DirectLine: string;
    contPerson3OfficePhone: string;
    contPerson3Mobile: string;
    contPerson3IncludeCompanyName: string;
    contPerson3IncludeJobTitle: string;

    organisationNote: string;
}

export interface ContactDetailsList {
    contactDetailsList: any[];
    totalRecords: number;
}

export interface ContactDataById {
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    companyAddressLine1?: string | null;
    companyAddressLine2?: string | null;
    companyCity?: string | null;
    companyContact1DirectLine?: string | null;
    companyContact1Email?: string | null;
    companyContact1Extension?: string | null;
    companyContact1GivenNames?: string | null;
    companyContact1JobTitle?: string | null;
    companyContact1LastName?: string | null;
    companyContact1Mobile?: string | null;
    companyContact1OfficePhone?: string | null;
    companyContact1Title?: string | null;
    companyContact2DirectLine?: string | null;
    companyContact2Email?: string | null;
    companyContact2Extension?: string | null;
    companyContact2GivenNames?: string | null;
    companyContact2JobTitle?: string | null;
    companyContact2LastName?: string | null;
    companyContact2Mobile?: string | null;
    companyContact2OfficePhone?: string | null;
    companyContact2Title?: string | null;
    companyContactFax?: string | null;
    companyContactPhone1?: string | null;
    companyContactPhone2?: string | null;
    companyCounty?: string | null;
    companyDxExchange?: string | null;
    companyDxNumber?: string | null;
    companyEmail?: string | null;
    companyFax?: string | null;
    companyIsActive?: boolean | null;
    companyName?: string | null;
    companyNature?: string | null;
    companyNotes?: string | null;
    companyPoboxCounty?: string | null;
    companyPoboxNumber?: string | null;
    companyPoboxPostcode?: string | null;
    companyPoboxTown?: string | null;
    companyPostcode?: string | null;
    companyRegNo?: string | null;
    companyUtrn?: string | null;
    companyVat?: string | null;
    companyWeb?: string | null;
    contPerson1DirectLine?: string | null;
    contPerson1Email?: string | null;
    contPerson1GivenName?: string | null;
    contPerson1IncludeCompanyName?: string | null;
    contPerson1IncludeJobTitle?: string | null;
    contPerson1JobTitle?: string | null;
    contPerson1LastName?: string | null;
    contPerson1Mobile?: string | null;
    contPerson1OfficePhone?: string | null;
    contPerson1Title?: string | null;
    contPerson2DirectLine?: string | null;
    contPerson2Email?: string | null;
    contPerson2GivenName?: string | null;
    contPerson2IncludeCompanyName?: string | null;
    contPerson2IncludeJobTitle?: string | null;
    contPerson2JobTitle?: string | null;
    contPerson2LastName?: string | null;
    contPerson2Mobile?: string | null;
    contPerson2OfficePhone?: string | null;
    contPerson2Title?: string | null;
    contPerson3DirectLine?: string | null;
    contPerson3Email?: string | null;
    contPerson3GivenName?: string | null;
    contPerson3IncludeCompanyName?: string | null;
    contPerson3IncludeJobTitle?: string | null;
    contPerson3JobTitle?: string | null;
    contPerson3LastName?: string | null;
    contPerson3Mobile?: string | null;
    contPerson3OfficePhone?: string | null;
    contPerson3Title?: string | null;
    contactCategory: string;
    contactCategoryCompany?: string | null;
    contactCategoryOrganisation?: string | null;
    contactCategoryPersonal?: string;
    contactCompanyId: number;
    contactId: number;
    contactMethod?: string | null;
    contactTypes: string;
    countyOrganization?: string | null;
    dateCreated: string;
    dateOfBirth: string;
    email?: string | null;
    employmentContAddress1?: string | null;
    employmentContAddress2?: string | null;
    employmentContCity?: string | null;
    employmentContCompanyName?: string | null;
    employmentContCounty?: string | null;
    employmentContEmail?: string | null;
    employmentContFax?: string | null;
    employmentContJobTitle?: string | null;
    employmentContOfficePhone?: string | null;
    employmentContPostCode?: string | null;
    fax?: string | null;
    fkBranchId: number;
    fkCategoryId: number;
    fkCompanyContactsCategoryId: number;
    fkContactId: number;
    fkContactTypeId: number;
    fkCountryIdOrganization?: number | null;
    fkCountryIdPersonal: number;
    fkIdentificationTypeId: number;
    fkMaritalStatusId: number;
    fkOrganisationContactsCategoryId: number;
    fkPersonalContactsCategoryId: number;
    fkTitleIdPersonal: number;
    fkUserId: number;
    givenNames: string;
    identificationNumber: string;
    isActive: boolean;
    isSupplier: boolean;
    lastName: string;
    name?: string | null;
    nature?: string | null;
    orgDxExchange?: string | null;
    orgDxNumber?: string | null;
    orgPoboxCounty?: string | null;
    orgPoboxNumber?: string | null;
    orgPoboxPostCode?: string | null;
    orgPoboxTown?: string | null;
    organisationContactsId: number;
    organisationNote?: string | null;
    personalContAddress1?: string | null;
    personalContAddress2?: string | null;
    personalContCity?: string | null;
    personalContCounty?: string | null;
    personalContEmail?: string | null;
    personalContHomePhone?: string | null;
    personalContMobile?: string | null;
    personalContPostCode?: string | null;
    personalNote?: string | null;
    personalTitle?: string | null;
    phone?: string | null;
    postCode?: string | null;
    sex: number;
    website?: string | null;
}


type ContactsState = {
    contacts: Contact[];
    contactDataById: ContactDataById | null;
    contactDetailsLists: ContactDetailsList;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ContactsState = {
    contacts: [],
    contactDataById: null!,
    contactDetailsLists: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllContacts = createAsyncThunk(
    "fetchAllContacts",
    async (data: any, thunkAPI) => {
        try {
            const response = await ContactServices.GetAllContacts(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchContactById = createAsyncThunk(
    "fetchContactById",
    async (id: number, thunkAPI) => {
        try {
            const response = await ContactServices.GetContactById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addContact = createAsyncThunk(
    "addContact",
    async (data: any, thunkAPI) => {
        try {
            const response = await ContactServices.PostContact(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateContact = createAsyncThunk(
    "updateContact",
    async ({ id, data }: { id: number, data: Contact }, thunkAPI) => {
        try {
            const response = await ContactServices.PutContact(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteContact = createAsyncThunk(
    "deleteContact",
    async (contactId: number, thunkAPI) => {
        try {
            await ContactServices.deleteContact(contactId);
            return contactId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        clearContactDataById: (state) => {
            state.contactDataById = null;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllContacts.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllContacts.fulfilled, (state, action: PayloadAction<ContactDetailsList>) => {
                state.status = "idle";
                state.contactDetailsLists = action.payload;
            })
            .addCase(fetchAllContacts.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchContactById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchContactById.fulfilled, (state, action: PayloadAction<ContactDataById>) => {
                state.status = "idle";
                state.contactDataById = action.payload;
            })
            .addCase(fetchContactById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addContact.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addContact.fulfilled, (state, action: PayloadAction<Contact>) => {
                state.status = "idle";
                state.contacts.push(action.payload);
            })
            .addCase(addContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateContact.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateContact.fulfilled, (state, action: PayloadAction<Contact>) => {
                state.status = "idle";
                // const index = ;
                // state.contacts.findIndex(t => t.personalContactsId === action.payload.personalContactsId);
                // if (index !== -1) {
                //     state.contacts[index] = action.payload;
                // }
            })
            .addCase(updateContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteContact.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteContact.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                // state.contacts = state.contacts.filter(t => t.personalContactsId !== action.payload);
            })
            .addCase(deleteContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export const { clearContactDataById } = contactSlice.actions;
export default contactSlice.reducer;
