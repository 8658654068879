import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseCloseService from "../../../../services/CaseManagement/Case/CaseCloseService";


export interface CaseClosureChecklist {

}

export interface CaseClosedChecklist {

}

// Define the state type for cases
type CaseState = {
    any: any[];
    caseClosureChecklist: CaseClosureChecklist[];
    caseClosedChecklist: CaseClosedChecklist[];
    unbilledFee: string;
    undeletedFee: string;
    unbilledDisbursement: string;
    unDeletedDisbursement: string;
    unpaidInvoiceBalance: number;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CaseState = {
    any: null!,
    caseClosureChecklist: null!,
    caseClosedChecklist: null!,
    unbilledFee: null!,
    undeletedFee: null!,
    unbilledDisbursement: null!,
    unDeletedDisbursement: null!,
    unpaidInvoiceBalance: null!,
    status: "idle",
    error: null
};


export const fetchUnbilledFeeByCaseId = createAsyncThunk(
    "fetchUnbilledFeeByCaseId",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetUnbilledFeeByCaseId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchUndeletedFeeByCaseId = createAsyncThunk(
    "fetchUndeletedFeeByCaseId",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetUndeletedFeeByCaseId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchUnbilledDisbursementByCaseId = createAsyncThunk(
    "fetchUnbilledDisbursementByCaseId",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetUnbilledDisbursementByCaseId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchUnDeletedDisbursementByCaseId = createAsyncThunk(
    "fetchUnDeletedDisbursementByCaseId",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetUnDeletedDisbursementByCaseId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseClosureChecklist = createAsyncThunk(
    "fetchCaseClosureChecklist",
    async (_, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetCaseClosureChecklist();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseClosedChecklist = createAsyncThunk(
    "fetchCaseClosedChecklist",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetCaseClosedChecklist(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchUnpaidInvoiceBalanceByCase = createAsyncThunk(
    "fetchUnpaidInvoiceBalanceByCase",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCloseService.GetUnpaidInvoiceBalanceByCase(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Slice definition
const CaseCloseSlice = createSlice({
    name: "caseClose",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnbilledFeeByCaseId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUnbilledFeeByCaseId.fulfilled, (state, action: PayloadAction<string>) => {
                state.status = "idle";
                state.unbilledFee = action.payload;
            })
            .addCase(fetchUnbilledFeeByCaseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchUndeletedFeeByCaseId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUndeletedFeeByCaseId.fulfilled, (state, action: PayloadAction<string>) => {
                state.status = "idle";
                state.undeletedFee = action.payload;
            })
            .addCase(fetchUndeletedFeeByCaseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchUnbilledDisbursementByCaseId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUnbilledDisbursementByCaseId.fulfilled, (state, action: PayloadAction<string>) => {
                state.status = "idle";
                state.unbilledDisbursement = action.payload;
            })
            .addCase(fetchUnbilledDisbursementByCaseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchUnDeletedDisbursementByCaseId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUnDeletedDisbursementByCaseId.fulfilled, (state, action: PayloadAction<string>) => {
                state.status = "idle";
                state.unDeletedDisbursement = action.payload;
            })
            .addCase(fetchUnDeletedDisbursementByCaseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseClosureChecklist.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseClosureChecklist.fulfilled, (state, action: PayloadAction<CaseClosureChecklist[]>) => {
                state.status = "idle";
                state.caseClosureChecklist = action.payload;
            })
            .addCase(fetchCaseClosureChecklist.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseClosedChecklist.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseClosedChecklist.fulfilled, (state, action: PayloadAction<CaseClosedChecklist[]>) => {
                state.status = "idle";
                state.caseClosedChecklist = action.payload;
            })
            .addCase(fetchCaseClosedChecklist.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchUnpaidInvoiceBalanceByCase.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUnpaidInvoiceBalanceByCase.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.unpaidInvoiceBalance = action.payload;
            })
            .addCase(fetchUnpaidInvoiceBalanceByCase.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default CaseCloseSlice.reducer;
