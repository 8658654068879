import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllFixedFeeInvoicePayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("OACPFIPaymentSearch" , data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostFixedFeeInvoicePayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("OACPFIPaymentSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutFixedFeeInvoicePayment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`FixedFeeInvoicePayment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteFixedFeeInvoicePayment = async (id: number) => {
    try {
        await axiosInstance.delete(`OACPFIPayment/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const FixedFeeInvoicePaymentService = {
    GetAllFixedFeeInvoicePayment,
    PostFixedFeeInvoicePayment,
    PutFixedFeeInvoicePayment,
    deleteFixedFeeInvoicePayment
};


export default FixedFeeInvoicePaymentService;
