import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { InputBox } from '../../../InputBox';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../../../utils/currency-format.utilis';
import { addReconciliation } from '../../../../store/slice/Accounting/ClientAccounts/ReconciliationSlice';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';


function AddBankReconciliation({ bankAccountId, statementDate, Close, isEdit, closingBalance, reconciliation, selectedData }: { bankAccountId: number, statementDate: string | null, closingBalance: any, Close: any, isEdit: boolean, reconciliation: any[], selectedData: any[] }) {

    const dispatch = useAppDispatch();
    const [existingReconciliation, setExistingReconciliation] = useState(reconciliation);
    const [isLoading, setIsLoading] = useState(false);
    const [totalAddAmount, setTotalAddAmount] = useState<number>(0);
    const [totalLessAmount, setTotalLessAmount] = useState<number>(0);
    const [totalDifferenceAmount, setTotalDifferenceAmount] = useState<number>(0);

    useEffect(() => {
        if (selectedData != null) {


           // console.log(selectedData)
        }
    }, [selectedData])

    // useEffect(() => {
    //     if (closingBalance != null) {
    //         const formattedClosingBalance = new Intl.NumberFormat('en-US', {
    //             style: 'decimal',
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //         }).format(closingBalance);

    //         console.log(formattedClosingBalance);
    //     }
    // }, [closingBalance]);

    const validationSchema = object().shape({
        bankReconcileDate: string()
            .required('Bank Reconcile Date is required'),
        bankStatementDate: string()
            .required('Bank Statement is required'),
        bankStatementBalance: number().required('Bank Statement is required'),
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            bankStatementBalance: 0,
        }
    });

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {
        if (statementDate != null) {
            setValue('bankStatementDate', statementDate);
        }
    }, [statementDate]);
    


    const onSubmit = async (data: any) => {
        if (totalDifferenceAmount === 0) {
            setIsLoading(true);
            try {
                data.bankId = bankAccountId;
                data.transactionIds = existingReconciliation.map((line) => line.clientBankTransactionId);
                await dispatch(addReconciliation(data)).unwrap();
                Close();
                toast.success("Successfully Added");
            } catch (error: any) {
                toast.error(error.toString());
            } finally {
                setIsLoading(false);
            }

        } else {
            toast.error("Please select the transactions");
        }

    };

    useEffect(() => {
        if (isEdit && reconciliation) {
            setExistingReconciliation(reconciliation);
            const totaladd = reconciliation.reduce((sum, line) => (!line.isReconciled) ? sum + line.drAmount : sum, 0);
            const totalles = reconciliation.reduce((sum, line) => (!line.isReconciled) ? sum + line.crAmount: sum, 0);
            setTotalAddAmount(totaladd)
            setTotalLessAmount(totalles);

            let diffamount = closingBalance - totaladd + totalles + getValues('bankStatementBalance');
            setTotalDifferenceAmount(diffamount)
        }
    }, [reconciliation, isEdit]);

    const onNumericValueChange = (value: string, property?: string) => {
        if (property === 'bankStatementBalance' && value !== '') {
            setValue('bankStatementBalance', Number(value));
            let diffamount = closingBalance - totalAddAmount + totalLessAmount + Number(value);
            setTotalDifferenceAmount(diffamount)
        }

    };


    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full" >
            <div className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35 p-6 overflow-y-auto space-y-2">
                <InputBox title={'Reconcilliation Date'} dataType='date' registerProps={register('bankReconcileDate')}
                    error={errors.bankReconcileDate?.message} />
                <InputBox title={'Statement Date'} dataType='date' registerProps={register('bankStatementDate')}
                    error={errors.bankStatementDate?.message} />
                <InputBox title={'Statement Balance'} dataType='NumericFormat' name='bankStatementBalance' registerProps={register('bankStatementBalance')}
                    error={errors.bankStatementBalance?.message} onValueChange={onNumericValueChange} />
                <InputBox title={'ADD [0]'} dataType='NumericFormat' placeholder={'£0.00'} value={totalAddAmount.toFixed(2)} />

                <InputBox title={'LESS [0]'} dataType='NumericFormat' placeholder={'£0.00'} value={totalLessAmount.toFixed(2)} />
                <InputBox title={'System Bank Account'} dataType='NumericFormat' placeholder={'£0.00'} value={(closingBalance > 0) ? formatCurrency(closingBalance.toFixed(2)) : '(' + formatCurrency(closingBalance.toFixed(2)) + ')'} />
                <InputBox title={'Difference'} dataType='NumericFormat' placeholder={'£0.00'} value={totalDifferenceAmount.toFixed(2)} />
            </div>
            <div className="flex col-span-2 gap-2 justify-center my-5">
                <button
                    type="submit"
                    disabled={isLoading || totalDifferenceAmount !== 0}
                    className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? (
                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : (
                        'Save'
                    )}
                </button>
            </div>
        </form>
    );
}

export { AddBankReconciliation };
