import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { object } from "yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SearchInput } from "../../Filters/SearchInput";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { searchJournalAccounts } from "../../../store/slice/Accounting/AccountSetup/AccountSlice";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { Pagination } from "../../Pagination";

function SearchAccountType({
  openDrawer,
  Close,
  onSelectAccountType,
  title,
  type,
}: {
  openDrawer: boolean;
  Close: any;
  onSelectAccountType: (selectedAccount: any) => void;
  title: string;
  type?: string

}) {
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [initialLoading, setInitialLoading] = useState(true);
  const accounttypes = useSelector((state: RootState) => state.account.journalaccounts);
  const [caseAccountDataList, setCaseAccountDataList] = useState<any[]>([]);
  const [bankAccountDataList, setBankAccountDataList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedAccountType, setSelectedAccountType] = useState<any>([]);

  // const validationSchema = object().shape({

  // });

  useEffect(() => {
    if (accounttypes != null && accounttypes.bankaccountList.length > 0) {
      // console.log(accounttypes.bankaccountList);
      setBankAccountDataList(accounttypes.bankaccountList);
    }
    if (accounttypes != null && accounttypes.caseList.length > 0) {
      // console.log(accounttypes.caseList);
      setCaseAccountDataList(accounttypes.caseList);
    }
  }, [accounttypes]);

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   setValue,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let data = {
        "bankAccountType": type,
        "keyword": ''
      }
      dispatch(searchJournalAccounts(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  const filterAccountTypesBySearchQuery = () => {
    const combinedAccountDataList = [
      ...caseAccountDataList,
      ...bankAccountDataList,
    ];
  
    return combinedAccountDataList.filter((item: any) =>
      item.bankAccountType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.accountSortCode?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  

  const filteredByAccountType = filterAccountTypesBySearchQuery();

  // pagination
  const indexOfLastAccountType = currentPage * itemsPerPage;
  const indexOfFirstAccountTypes = indexOfLastAccountType - itemsPerPage;
  const currentAccountTypes = filteredByAccountType.slice(
    indexOfFirstAccountTypes,
    indexOfLastAccountType
  );
  const totalPages = Math.ceil(filteredByAccountType.length / itemsPerPage);

  const handleSelectAccountType = () => {
    if (selectedAccountType != null) {
      onSelectAccountType(selectedAccountType);

    }
    Close();
    console.log(selectedAccountType);

  };

  const handleRadioChange = (accountType: any) => {
    const accountTypes = (accountType?.bankAccountType == "Client") ? 'bank' : 'case';
    setSelectedAccountType({
      ...accountType,
      accountTypes,
    });
  };


  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        // reset();
        Close();
      }}
      initialFocus={emailInputRef}
    >
      <Modal.Header className="bg-white-bg p-5">
        <span className="text-blue_gray-900 text-xl w-auto">
          {title}
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg">

        <div className="space-y-6 w-full">
          <div className="relative overflow-x-auto sm:rounded-lg">
            <div className="flex mb-5">
              <SearchInput title={"Keyword"}
                value={searchQuery}
                onChange={(e: any) => setSearchQuery(e)}
              />
            </div>
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {initialLoading ? <MainAnimation /> : ""}
              <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Account Type
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {bankAccountDataList.length > 0 && (
                  bankAccountDataList.map((bankAccount: any, index: number) =>
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <input
                          type="radio"
                          value={bankAccount}
                          checked={selectedAccountType.bankAccountId === bankAccount?.bankAccountId}
                          onChange={() => handleRadioChange(bankAccount)}
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Bank Account
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {bankAccount.accountName}-{bankAccount.bankAccountType}
                        <br />
                        {bankAccount.bankAccountCode}
                        <br />
                        {bankAccount.accountSortCode}
                      </td>
                    </tr>
                  ))}
                {caseAccountDataList.length > 0 && (
                  caseAccountDataList.map((caseAccount: any, index: number) =>
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <input
                          type="radio"
                          value={caseAccount}
                          checked={selectedAccountType.caseId === caseAccount?.caseId}
                          onChange={() => handleRadioChange(caseAccount)}
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Ledger Card
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {caseAccount.caseReferenceAuto}
                        <br />
                        {caseAccount.caseClients}
                        <br />
                        {caseAccount.caseName}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {currentAccountTypes.length > 0 && (
            <div className="w-full flex flex-col pb-[100px]">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                totalRecords={currentAccountTypes.length}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                onClick={() => {
                  // reset();
                  Close();
                }}
                type="button"
                className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSelectAccountType}
                className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-lg 3xl:text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
              >
                Select Account
              </button>
            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal>
  );
}

export { SearchAccountType };
