
import { ErrorResult } from '../../store/model/errorResult';
import axiosInstance from '../axiosInstance';



const GetAllContacts = async (data: any) => {
  try {
    const response = await axiosInstance.post("Contact/search", data);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetContactById = async (id: number) => {
  try {
    const response = await axiosInstance.get(`Contact/${id}`);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Add a new Contact
const PostContact = async (data: any) => {
  try {
    const response = await axiosInstance.post("Contact", data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Update a Contact
const PutContact = async (id: number, data: any) => {
  try {
    const response = await axiosInstance.put(`Contact/${id}`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Delete a Contact
const deleteContact = async (contactId: number) => {
  try {
    await axiosInstance.delete(`/Contact/${contactId}`);
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}
const ContactService = {
  GetAllContacts,
  PostContact,
  PutContact,
  deleteContact,
  GetContactById
};

export default ContactService;
