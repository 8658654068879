import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FixedFeeInvoicePaymentService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/FixedFeeInvoicePaymentService";
import { string } from "yup";




export interface FixedFeeInvoicePayment {
    fixedFeeInvoicePaymentId: number;
    invoicePaymentAmount: number;
    fixedFeeInvoiceId: number;
    officeBankAccountId: number;
    paymentReference: string;
    invoicePaymentDate: string;
    invoiceNotesTitleId: number;
    invoiceNotes: string;
    invoiceNoticeTitleId: number;
    invoiceNotice: string;
    paymentType: string;
    paymentNotes: string;
  
};

export interface FixedFeeInvoicePaymentData{
    listingData:[]
    page: number,
    totalRows: number
}
export interface SearchFixedFeeInvoicePaymentResult{
    listingData:[]
    page: number,
    totalRows: number
}

// Define the state type for Checklist
type FixedFeeInvoicePaymentState = {
    searchFixedFeeInvoicePaymentResult: SearchFixedFeeInvoicePaymentResult
    fixedFeeInvoicePayments: FixedFeeInvoicePayment[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    fixedFeeInvoicePaymentData: FixedFeeInvoicePaymentData,
    
};

// Initial state
const initialState: FixedFeeInvoicePaymentState = {
    searchFixedFeeInvoicePaymentResult: null!,
    fixedFeeInvoicePayments: [],
    status: "idle",
    error: null,
    fixedFeeInvoicePaymentData: null!,
};

// Async thunks for CRUD operations
export const fetchAllFixedFeeInvoicePayment = createAsyncThunk(
    "fetchAllFixedFeeInvoicePayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await FixedFeeInvoicePaymentService.GetAllFixedFeeInvoicePayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addFixedFeeInvoicePayment = createAsyncThunk(
    "addFixedFeeInvoicePayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await FixedFeeInvoicePaymentService.PostFixedFeeInvoicePayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateFixedFeeInvoicePayment = createAsyncThunk(
    "updateFixedFeeInvoicePayment",
    async ({ id, data }: { id: number, data: FixedFeeInvoicePayment }, thunkAPI) => {
        try {
            const response = await FixedFeeInvoicePaymentService.PutFixedFeeInvoicePayment(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteFixedFeeInvoicePayment = createAsyncThunk(
    "deleteFixedFeeInvoicePayment",
    async (id: number, thunkAPI) => {
        try {
            await FixedFeeInvoicePaymentService.deleteFixedFeeInvoicePayment(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const FixedFeeInvoicePaymentSlice = createSlice({
    name: "fixedFeeInvoicePayment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFixedFeeInvoicePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFixedFeeInvoicePayment.fulfilled, (state, action: PayloadAction<SearchFixedFeeInvoicePaymentResult>) => {
                state.status = "idle";
                state.searchFixedFeeInvoicePaymentResult = action.payload;
            })
            .addCase(fetchAllFixedFeeInvoicePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addFixedFeeInvoicePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addFixedFeeInvoicePayment.fulfilled, (state, action: PayloadAction<FixedFeeInvoicePayment>) => {
                state.status = "idle";
                state.fixedFeeInvoicePayments.push(action.payload);
            })
            .addCase(addFixedFeeInvoicePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateFixedFeeInvoicePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateFixedFeeInvoicePayment.fulfilled, (state, action: PayloadAction<FixedFeeInvoicePayment>) => {
                state.status = "idle";
                const index = state.fixedFeeInvoicePayments.findIndex(t => t.fixedFeeInvoicePaymentId === action.payload.fixedFeeInvoicePaymentId);
                if (index !== -1) {
                    state.fixedFeeInvoicePayments[index] = action.payload;
                }
            })
            .addCase(updateFixedFeeInvoicePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteFixedFeeInvoicePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFixedFeeInvoicePayment.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.fixedFeeInvoicePayments = state.fixedFeeInvoicePayments.filter(t => t.fixedFeeInvoicePaymentId !== action.payload);
            })
            .addCase(deleteFixedFeeInvoicePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default FixedFeeInvoicePaymentSlice.reducer;
