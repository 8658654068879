import { useEffect, useRef, useState } from "react";
import { AddCreditNote } from "../Modal/AddClientAccounts/AddClientInvoice/AddCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import { deleteCACreditNote, fetchCreditNoteSearch } from "../../store/slice/Accounting/ClientAccounts/Invoice/CACreditNoteSlice";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { RiDeleteBin5Fill } from "react-icons/ri";
import DeleteModal from "../Modal/DeleteModal";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";

function CreditNote() {
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const cacreditNoteData = useSelector((state: RootState) => state.clientCreditNote.cacreditNoteData);
  const [getDataList, setGetDataList] = useState<any[]>([]);
  const [totalPages] = useState<number>(10);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [creditNoteDatas, setCreditCardDatas] = useState<any[]>([]);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [cACreditNoteId, setCACreditNoteId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { creditNoteStatusType } = useSelector((state: RootState) => state.creditNoteStatusType);
  const [statusList, setStatusList] = useState<any[]>([]);
   const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    statusId: 0
  });
  const optiondata = [
    {
      name: "Status",
      options: statusList
    },
  ]

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
    setIsEdit(false);
    setIsView(false);
  };
  const toggleViewDrawer = () => {
    setOpenViewModal(!openViewModal);
  };

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentStatusId: filters.statusId,
        currentPage: currentPage,
        recordsPerPage: totalPages,
        dateStart: (filters.startDate == "" ? null : filters.startDate),
        dateEnd: (filters.endDate == "" ? null : filters.endDate),
      }
      dispatch(fetchCreditNoteSearch(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, filters, isOnAddedOrUpdate]);

  useEffect(() => {
    if (cacreditNoteData && cacreditNoteData.creditNoteResult !== null) {
      setGetDataList(cacreditNoteData.creditNoteResult);
      setTotalRecords(cacreditNoteData.totalCount);
    }
  }, [cacreditNoteData]);

  useEffect(() => {
    if (creditNoteStatusType !== null) {
      let data = creditNoteStatusType.map((status: any, index) => ({
        value: status.creditNoteStatusTypeId,
        label: `${status.status}`,
      }));
      data.unshift({ value: 0, label: "Select" });
      setStatusList(data);
    }
  }, [creditNoteStatusType]);


  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };


  const onDeleteCall = () => {
    try {
      dispatch(deleteCACreditNote(cACreditNoteId)).unwrap();
      setGetDataList((prevData) => prevData.filter(item => item.creditNoteId !== cACreditNoteId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted client Account credit Note");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPages
    }
    dispatch(fetchCreditNoteSearch(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  if (openModal) {
    return (
      <AddCreditNote
        openDrawer={openModal}
        Close={toggleDrawer}
        modalType="Add" isView={isView} isEdit={isEdit} cACreditNote={creditNoteDatas} />
    );
  }

  if (openViewModal) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewModal}
        drawerContent="CIcreditNote"
        getData={creditNoteDatas}
        title="BankReceipt"
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={cACreditNoteId}
            title="Credit Note"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          {initialLoading ? <MainAnimation /> : ""}
          <SubHeaderTitle title="Credit Note" buttonName="Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <div className="w-full">
            <AccountFilterComponent
              onFilterChange={handleFilterChange}
              initialFilters={filters}
              optiondata={optiondata}
            />
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Reference
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit Amount
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit Note Description
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Reason
                </th>
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Status
                                </th> */}
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {getDataList.map((disb: any, index) => (
                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(disb.creditNoteDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>{disb.caseClients}</p><br />
                    <p>{disb.caseReferenceAuto}</p>
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(disb.creditAmount)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {disb.description}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {disb.reason}
                  </td>
                  {/* <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                         {disb.total}
                                    </td> */}
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <FaEye className="fill-gray-500 w-6 h-6"
                      onClick={() => {
                        setOpenViewModal(true);
                        // setIsView(true)
                        // setIsEdit(false);
                        setCreditCardDatas(disb);
                      }}
                    />
                    <FaEdit className="fill-blue-500 w-6 h-6"
                      onClick={() => {
                        setOpenModal(true);
                        setIsEdit(true);
                        setIsView(false);
                        setCreditCardDatas(disb);
                      }}
                    />
                    <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                      onClick={() => {
                        setOpenDelModal(true);
                        setCACreditNoteId(disb.creditNoteId);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No CreditNote found.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              onPageChange={(page) => { onPageChange(page); }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { CreditNote };
