import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllCreditNoteStatusType = async () => {
  try {
    const response = await axiosInstance.get("CreditNote/statustype");
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const CreditNoteStatusTypeService = {
  GetAllCreditNoteStatusType,
};

export default CreditNoteStatusTypeService;
