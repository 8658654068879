import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddFixedFeeInvoicePayment } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoicePayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteFixedFeeInvoicePayment, fetchAllFixedFeeInvoicePayment } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoicePaymentSlice";
import { toast } from "react-toastify";

import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import DeleteModal from "../Modal/DeleteModal";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { Pagination } from "../Pagination";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
function FixedFeeInvoicePayment() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [fixedFeeInvoicePaymentDatas, setFixedFeeInvoicePaymentDatas] =
    useState<any>([]);
  const [fixedFeeInvoicePaymentGetData, setFixedFeeInvoicePaymentGetData] =
    useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const searchFixedFeeInvoicePaymentResult = useSelector(
    (state: RootState) =>
      state.fixedFeeInvoicePayment.searchFixedFeeInvoicePaymentResult
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [fixedFeePaymentId, setFixedFeePaymentId] = useState<number>(0);
  const effectServiceCall = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const { invoiceStatusType } = useSelector((state: RootState) => state.invoiceStatusType);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '', 
    endDate: '',   
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    statusId:0,
  });

  const optiondata = [
    {
      name: "Status",
      options: statusList
    },
    
  ]

  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        invoiceStatusTypeId: filters.statusId,
      }
      dispatch(fetchAllFixedFeeInvoicePayment(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      setOnAddedOrUpdate(false);
      effectServiceCall.current = true;
    }
  }, [dispatch, isOnAddedOrUpdate,filters]);

  useEffect(() => {
    if (searchFixedFeeInvoicePaymentResult !== null && searchFixedFeeInvoicePaymentResult.listingData !== null && searchFixedFeeInvoicePaymentResult.totalRows !== null) {
      setFixedFeeInvoicePaymentGetData(searchFixedFeeInvoicePaymentResult.listingData);
      setTotalRecords(searchFixedFeeInvoicePaymentResult.totalRows);
    }
  }, [searchFixedFeeInvoicePaymentResult]);

  useEffect(() => {
        if (invoiceStatusType !== null) {
          let data = invoiceStatusType.map((status: any, index) => ({
            value: status.invoiceStatusTypeId,
            label: `${status.invoiceStatus}`,
          }));
          data.unshift({ value: 0, label: "Select" });
          setStatusList(data);
        }
      }, [invoiceStatusType]);
  

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllFixedFeeInvoicePayment(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }
  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };


  

  const onDeleteCall = () => {
    try {
      dispatch(deleteFixedFeeInvoicePayment(fixedFeePaymentId)).unwrap();
      setFixedFeeInvoicePaymentGetData((prevData) => prevData.filter(item => item.fixedFeeInvoicePaymentId !== fixedFeePaymentId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted fixedFeeInvoicePayment");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OAFixedFeeInvoicePayment"
        getData={fixedFeeInvoicePaymentDatas}
        title="Fixedfee Invoice Payment "
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={fixedFeePaymentId}
            title="FixedFee Payment"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle title="Fixed Fee Invoice Payment" buttonName="Payment" isAdd={true} onClickAdd={toggleDrawer} />
          <AddFixedFeeInvoicePayment openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} isView={isView} fixedFeeInvoicePayment={fixedFeeInvoicePaymentDatas} />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
        <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
        </div>
          {/* <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Status" />
            </div>
          
        </div> */}
        <div className="relative overflow-hidden sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <div className="overflow-x-auto h-[calc(100vh-10px)]">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Invoicee (s)
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Invoice
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Total
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Amount Paid
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-center w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {fixedFeeInvoicePaymentGetData.map((fixedFeeInvoicePayment: any, index) => (

                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(fixedFeeInvoicePayment.invoicePaymentDate)}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {fixedFeeInvoicePayment.invoiceeName}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <a href="" className="hover:text-blue-500"> {fixedFeeInvoicePayment.invoiceName}</a>
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatCurrency(fixedFeeInvoicePayment.totalAmount)}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatCurrency(fixedFeeInvoicePayment.invoicePaymentAmount)}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {fixedFeeInvoicePayment.status}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <div className="flex gap-3 w-full">
                        <FaEye className="fill-gray-500 w-6 h-6"
                          onClick={() => {
                            setOpenViewDrawer(true);
                            setIsView(true);
                            setIsEdit(false);
                            setFixedFeeInvoicePaymentDatas(fixedFeeInvoicePayment);
                          }}
                        />
                        <FaEdit className="fill-blue-500 w-6 h-6"
                          onClick={() => {
                            setOpenModal(true);
                            setIsEdit(true);
                            setFixedFeeInvoicePaymentDatas(fixedFeeInvoicePayment);
                          }}
                        />
                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                          onClick={() => {
                            setOpenDelModal(true);
                            setFixedFeePaymentId(fixedFeeInvoicePayment.fixedFeeInvoicePaymentId);
                          }}
                        />
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>

            {totalRecords === 0 && (
              <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                <p className="text-red-800">No Fixed FeeInvoice Payment Details found.</p>
              </div>
            )}
            {totalRecords > 0 && (
              <div className="w-full flex flex-col pb-[100px]">
                <Pagination
                  currentPage={currentPage} 
                  totalPages={totalPage}
                  totalRecords={totalRecords}
                  onPageChange={(page) => { onPageChange(page); }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export { FixedFeeInvoicePayment };
