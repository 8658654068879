import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllProtectedFundReports } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";

function ReportsProtectedFunds() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const protectedFundReports = useSelector(
    (state: RootState) => state.clientReports.protectedFundReports
  );
  const [protectedFundDataList, setprotectedFundDataList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const [totalPages] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(10);
  const undertakingType = [
    { value: "", label: "Select" },
    { value: "Given", label: "Given" },
    { value: "Taken", label: "Taken" },
  ];
   const [filters, setFilters] = useState<FilterTypeWithEnums>({
      searchTerm: '',
      startDate: '',
      endDate: '',
      caseType: '',
      documentType: '',
      isArea: false,
      isDateBetween: true,
      isSelectbox: true,
      undertakeType: '',
      clientBankAccId: 0
    });
    const optiondata = [
      {
        name: "Client Bank",
        options: clientAccountLists
      },
      {
        name: "Undertaking Type",
        options: undertakingType
      },
    ]

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let searchdata = {
        keyword: filters.searchTerm,
        undertakeType: filters.undertakeType,
        bankAccountId: filters.clientBankAccId,
        currentPage: currentPage,
        recordsPerPage: totalPages,
        dateFrom: (filters.startDate == "" ? null : filters.startDate),
        dateTo: (filters.endDate == "" ? null : filters.endDate),
      }
      dispatch(fetchAllProtectedFundReports(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch, filters]);

  useEffect(() => {
    if (
      protectedFundReports &&
      protectedFundReports.clientPostingProtectedFundResult !== null
    ) {
      setprotectedFundDataList(
        protectedFundReports.clientPostingProtectedFundResult
      );
      setTotalRecords(protectedFundReports.totalCount);
    }
  }, [protectedFundReports]);

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  useEffect(() => {
    if (clientAccounts !== null) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      data.unshift({ value: 0, label: "All Accounts" });
      setClientAccountLists(data);
    }
  }, [clientAccounts]);

  return (
    <>
      <div className="overflow-y-auto">
        <div className="flex items-center justify-between mb-5">
          <div>
            <SubHeaderTitle title="Protected Funds" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-lg 3xl:text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-lg 3xl:text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
        </div>
        <div className="w-full">
          <AccountFilterComponent
            onFilterChange={handleFilterChange}
            initialFilters={filters}
            optiondata={optiondata}
          />
        </div>
        {/* <div className="my-5">
          <div className="my-5 text-center bg-sky-50 dark:bg-gray-800 dark:text-white-bg p-6">
            <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
            <p className="text-lg font-semibold">Protected Client Funds</p>
            <p className="font-semibold">04 Sep 2024</p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Client Bank
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Date
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Undertaking Type
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Status
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
          </div>
        </div> */}
        <div className="relative overflow-hidden sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Bank
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Case Reference
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Type
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Is Released
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {protectedFundDataList.map((PF: any, index) => (
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(PF.protectedDateTime)}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{PF.accountName}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{PF.caseReferenceAuto}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{PF.paymentForFrom}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{PF.protectedFundType}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {PF.isReleased === false ? "No" : "Yes"}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{PF.protectedAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Office To Client Reports.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { ReportsProtectedFunds };
