import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchAllBranch } from "../../../store/slice/Administrator/BranchManagementSlice";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";



function AddBranchStepThree({ onSubmitStep, initialData, onPreviousStep, isLoading, onCancel }: any) {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { branches } = useSelector((state: RootState) => state.branch);

    const validationSchema = yup.object().shape({
        otherdetailsVatNumber: yup.string(),
        otherdetailsCrn: yup.string(),
        otherdetailsLandRegNo: yup.string(),
        otherdetailsDirectDebit: yup.boolean(),
        otherdetailsOiscRegNo: yup.string(),
        otherdetailsFeeAccNo: yup.string(),
        isIpRestricted: yup.boolean().required("IP Restriction is required"),
        allowedIps: yup.string().test(
            'allowedIps',
            'Allowed IPs is required',
            function (value) {
                const { isIpRestricted } = this.parent;
                if (isIpRestricted) {
                    return !!value;
                }
                return true;
            }
        ),
        isActive: yup.boolean(),
    });

    const { register, handleSubmit, watch, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {
            isIpRestricted: false,
        },
    });
    const isIpRestricted = watch("isIpRestricted");

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllBranch());
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);

    useEffect(() => {
        const id = searchParams.get("id");
        if (id != null && branches) {
            const branchUpdate = branches.find((branch: any) => branch.branchId === Number(id));
            if (branchUpdate) {
                reset(branchUpdate);
            } else {
                console.warn(`branches with ID ${id} not found.`);
            }
        }
    }, [searchParams, reset, branches]);

    return (

        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div>
                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Other Details</h3>
                <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            VAT Number
                        </label>
                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('otherdetailsVatNumber')} />

                    </div>
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            CRN
                        </label>
                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('otherdetailsCrn')} />

                    </div>
                    <div className=" flex flex-col gap-3">
                        <div>
                            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                Land Reg No
                            </label>
                            <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('otherdetailsLandRegNo')} />
                        </div>
                        <div>
                            <input type="checkbox" className="h-4 w-4 text-blue-600 rounded-sm focus:ring-blue-500 border-2 border-black dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" {...register('otherdetailsDirectDebit')}
                                onChange={(e) => setValue('otherdetailsDirectDebit', e.target.checked)} ></input>
                            <label className="ms-3 font-medium text-gray-900 dark:text-white-bg">Direct Debit Agreement</label>
                        </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            OISC Reg No
                        </label>
                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('otherdetailsOiscRegNo')} />

                    </div>
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Fee Account No
                        </label>
                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('otherdetailsFeeAccNo')} />

                    </div>

                </div>

                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">IP Restrictions</h3>
                <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="col-span-2">
                        <div className="flex flex-row space-x-10">
                            <div>
                                <input
                                    type="radio"
                                    value="true"
                                    {...register('isIpRestricted')}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">Allow Single/Multiple IPs</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    value="false"
                                    {...register('isIpRestricted')}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">No IP Restriction</label>
                            </div>

                        </div>
                    </div>
                    {isIpRestricted === "true" && (
                        <div className="">
                            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                Allowed IPs
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.allowedIps ? 'border-red-500' : ''}`}
                                {...register('allowedIps')}
                            />
                            {errors.allowedIps && <span className="text-red-500">{errors?.allowedIps?.message?.toString()}</span>}
                        </div>
                    )}
                    <div className="">
                        <div className="flex flex-row space-x-10">
                            <label className="text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">Is Active</label>
                            <div>
                                <input
                                    type="radio"
                                    value="true"
                                    {...register('isActive')}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                            </div>

                            <div>
                                <input
                                    id="bordered-radio-1"
                                    type="radio"
                                    value="false"
                                    {...register('isActive')}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex flex-row gap-2 items-start justify-between w-full">
                <button
                    onClick={onPreviousStep}
                    type="button"
                    className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[140px]">
                    <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                    Previous
                </button>
                <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                    <div className="flex flex-row gap-2 items-end justify-end w-full">

                        <button
                            type="button"
                            onClick={onCancel}
                            className="border border-gray-400 bg-gray-100 text-gray-700 font-medium cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center text-lg 3xl:text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export { AddBranchStepThree };
