import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeToClientService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/OfficeToClientService";
import { GetOfficeToClient, OfficeToClient, SearchOfficeToClientResult } from "../../../../model/Accounting/ClientAccount/OfficeToClientInterFace";




// Define the state type for Checklist
type OfficeToClientState = {
     searchOfficeToClientResult:SearchOfficeToClientResult; 
    officeToClients: OfficeToClient[];
    getOfficeToClient: GetOfficeToClient;
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber : number | null;
    any: any;
};

// Initial state
const initialState: OfficeToClientState = {
    searchOfficeToClientResult: null!,
    receiptNumber: null!,
    getOfficeToClient: null!,
    officeToClients: [],
    status: "idle",
    error: null,
    any: null,
};

// Async thunks for CRUD operations
export const fetchAllOfficeToClient = createAsyncThunk(
    "fetchAllOfficeToClient",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeToClientService.GetAllOfficeToClient(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchNextOfficeToClientNumber = createAsyncThunk(
    "fetchNextOfficeToClientNumber",
    async (_, thunkAPI) => {
        try {
            const response = await OfficeToClientService.GetOfficeToClientNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOfficeToClient = createAsyncThunk(
    "addOfficeToClient",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeToClientService.PostOfficeToClient(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addReverseOfficeToClient = createAsyncThunk(
    "addReverseOfficeToClient",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeToClientService.PostReverseOfficeToClient(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOfficeToClient = createAsyncThunk(
    "updateOfficeToClient",
    async ({ id, data }: { id: number, data: OfficeToClient }, thunkAPI) => {
        try {
            const response = await OfficeToClientService.PutOfficeToClient(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteOfficeToClient = createAsyncThunk(
    "deleteOfficeToClient",
    async (id: number, thunkAPI) => {
        try {
            await OfficeToClientService.deleteOfficeToClient(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeToClientSlice = createSlice({
    name: "officeToClient",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeToClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeToClient.fulfilled, (state, action: PayloadAction<SearchOfficeToClientResult>) => {
                state.status = "idle";
                state.searchOfficeToClientResult = action.payload;
            })
            .addCase(fetchAllOfficeToClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchNextOfficeToClientNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextOfficeToClientNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextOfficeToClientNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addOfficeToClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOfficeToClient.fulfilled, (state, action: PayloadAction<OfficeToClient>) => {
                state.status = "idle";
                state.officeToClients.push(action.payload);
            })
            .addCase(addOfficeToClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addReverseOfficeToClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addReverseOfficeToClient.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(addReverseOfficeToClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOfficeToClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOfficeToClient.fulfilled, (state, action: PayloadAction<OfficeToClient>) => {
                state.status = "idle";
                const index = state.officeToClients.findIndex(t => t.officeToClientId === action.payload.officeToClientId);
                if (index !== -1) {
                    state.officeToClients[index] = action.payload;
                }
            })
            .addCase(updateOfficeToClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteOfficeToClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOfficeToClient.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.officeToClients = state.officeToClients.filter(t => t.officeToClientId !== action.payload);
            })
            .addCase(deleteOfficeToClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default OfficeToClientSlice.reducer;
