import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../SelectBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchUsersDataByBranch } from "../../../store/slice/Administrator/UserManagementSlice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { addTrainingRecord } from "../../../store/slice/HumanResources/HumanResources/TrainingRecordSlice";
import { formatDateISODisplay } from "../../../utils/date-time.utils";
import { number, object, string } from "yup";

function AddTrainingRecords({
  openDrawer,
  Close,
  isEdit,
  trainingRecord,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  trainingRecord: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Training Records" : "Edit Training Records"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);
  const [trainingRecordFileName, setTrainingRecordFileName] = useState("");
  const [documentTrainingRecordFile, setDocumentTrainingRecordFile] = useState<any>(null);
  const [existingTrainingRecord, setExistingTrainingRecord] = useState(trainingRecord);

  const duration = [
    { label: "Hours", value: 1 },
    { label: "Days", value: 2 },
    { label: "Weeks", value: 3 },
    { label: "Months", value: 4 },
    { label: "Years", value: 5 },
  ];

  const mappedDuration = duration.map((type: any) => ({
    label: type.label,
    value: type.value,

  }));

  const validationSchema = object().shape({
    staffId: number().required("Staff is required"),
    staffName: string(),
    courseTitle: string().required("Course Title is required"),
    courseProvider: string().required("Course Provider is required"),
    totalCost: number(),
    description: string(),
    durationType: number().required("Duration is required"),
    startDate: string().required("Start Date is required")
    .transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    endDate: string().required("End Date is required")
    .transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    reference: string().required("Reference is required"),
    certificateName: string(),
    notes: string(),
    paidBy: string(),
    duration: number(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:{
      startDate: new Date().toISOString().split("T")[0],
    }
  });
  useEffect(() => {
    setValue("startDate", new Date().toISOString().split("T")[0]);
  }, []);

  const onSubmit = async (data: any) => {
    //  console.log(JSON.stringify(data, null, 2));
    if (documentTrainingRecordFile == null) {
      toast.error("Please select the file");
      return;
    }

    data.certificateName = trainingRecordFileName;


    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', documentTrainingRecordFile);

    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addTrainingRecord(formData)).unwrap();
      Close("success");
      toast.success("Successfully Added Training Record");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  useEffect(() => {
    if (isEdit && trainingRecord != null) {
        setValue('staffId',trainingRecord?.staffName);
        setValue('courseTitle',trainingRecord?.courseTitle );
        setValue('courseProvider',trainingRecord?.courseProvider );
        setValue('totalCost',trainingRecord?.totalCost);
        setValue('description',trainingRecord?.description);
        setValue('durationType',trainingRecord?.durationType);
        setValue('reference',trainingRecord?.reference);
        setValue('notes',trainingRecord?.notes);
        setValue('paidBy',trainingRecord?.paidBy);
        setValue('duration',trainingRecord?.duration);
        setValue('startDate',formatDateISODisplay(trainingRecord.startDate));
        setValue('endDate',formatDateISODisplay(trainingRecord.endDate));
        // setExistingTrainingRecord(trainingRecord);
        //reset(trainingRecord);
    }
}, [trainingRecord, isEdit, setValue]);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentTrainingRecordFile(file);
      setTrainingRecordFileName(file.name);
    } else {
      setTrainingRecordFileName("");
    }
  };

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchUsersDataByBranch());
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (branchUsers.length > 0) {
      console.log(branchUsers);
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName}[${branchUser.designation}] `,
      }));
      setBranchUserLists(data);
      let findObj = branchUsers.find((x) => x.userId === 1);
      if(findObj && findObj!==null) {
        setValue("staffId", findObj?.userId)
      }
    }
  }, [branchUsers]);

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          {title}
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Staff <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={branchUserLists}
                value={getValues().staffId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("staffId", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.staffId?.message?.toString()}
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3 gap-y-4">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Course Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.courseTitle ? "is-invalid" : ""
                }`}
                {...register("courseTitle")}
                placeholder="Enter Course Title"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.courseTitle?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Course Provider <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.courseProvider ? "is-invalid" : ""
                }`}
                {...register("courseProvider")}
                placeholder="Enter Course Provider"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.courseProvider?.message?.toString()}
              </div>
            </div>
            </div>
            <div className="relative max-w-full col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Description
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Description"
                {...register("description")}
              ></textarea>
            </div>
            <div className="relative max-w-full col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Total Cost
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                {...register("totalCost")}
                placeholder="Enter Total Cost"
              />
            </div>
            <div className="flex space-x-10">
              <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Paid By
              </label>
              <div>
                <input
                  id="company"
                  type="radio"
                  value="Company"
                  {...register("paidBy", { required: true })}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="company"
                  className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-sm font-medium"
                >
                  Company
                </label>
              </div>
              <div>
                <input
                  id="personal"
                  type="radio"
                  value="Personal"
                  {...register("paidBy", { required: true })}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="personal"
                  className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-sm font-medium"
                >
                  Personal
                </label>
              </div>
              <div>
                <input
                  id="other"
                  type="radio"
                  value="Other"
                  {...register("paidBy", { required: true })}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="other"
                  className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-sm font-medium"
                >
                  Other
                </label>
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Duration <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={mappedDuration}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("durationType", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.durationType?.message?.toString()}
              </div>

              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.duration ? "is-invalid" : ""
                }`}
                {...register("duration")}
                placeholder="Enter Duration Value"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.duration?.message?.toString()}
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3 gap-y-4">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Start Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.startDate ? "is-invalid" : ""
                }`}
                {...register("startDate")}
                placeholder="Enter Start Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.startDate?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                End Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.endDate ? "is-invalid" : ""
                }`}
                {...register("endDate")}
                placeholder="Enter End Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.endDate?.message?.toString()}
              </div>
            </div></div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3 gap-y-4">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Reference <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.reference ? "is-invalid" : ""
                }`}
                {...register("reference")}
                placeholder="Enter Reference"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.reference?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Certificate
              </label>
              <input
                className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.certificateName ? 'is-invalid' : ''}`}  
                {...register("certificateName")}
                type="file"
                multiple
                onChange={handleFileChange}
              />
            </div>
            </div>
            <div className="relative max-w-full col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Notes
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Notes"
                {...register("notes")}
              ></textarea>
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddTrainingRecords };
