import { object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const JournalEntryDetails = ({
  openModal,
  Close,
}: {
  openModal: boolean;
  Close: any;
  quotationRequest?: any;
  isEdit: boolean;
}) => {
  const validationSchema = object().shape({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(addTask(data));
    Close();
  };

  return (
    <>
      {openModal != false && (
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="w-full flex flex-col">
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-10 gap-y-1 w-full my-5 ">
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Journal Entry
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  placeholder="Journal Entry Id"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Date
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  placeholder="Enter Date"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Description
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  placeholder="Enter Description"
                />
              </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg">
              <table className="w-full text-lg 3xl:text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Account
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Debit
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Credit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">1</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {" "}
                      AW/RES/PUR/1/SMITH Anthony Smith
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> Opening Balance</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> £ 0.00</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> £ 300.00</td>
                  </tr>
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">2</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {" "}
                      RT/RES/PUR/2/FOSTER Smantha Foster,Samuel Foster, ,
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> Opening Balance</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> £ 0.00</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> £ 500.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export { JournalEntryDetails };
