import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";
import { SelectBox } from "../../../SelectBox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";


function AddComClientThree({ onSubmitStep, initialData, onPreviousStep, titleName, onCancel, isLoading }: any) {
    const clientByIdData = useSelector((state: RootState) => state.client.clientByIdData);
    const [contactPersonOne, setContactPersonOne] = useState(false);
    const [contactPersonTwo, setContactPersonTwo] = useState(false);
    const titleOption = titleName ? titleName.map((title: any) => ({
        label: title.title,
        value: title.titleId
    })) : [];

    const validationSchema = object().shape({       

        compContact1Title: string(),
        compContact1GivenNames: string(),
        compContact1LastName: string(),
        compContact1JobTitle: string(),
        compContact1Email: string(),
        compContact1DirectLine: string(),
        compContact1Extension: string(),
        compContact1OfficePhone: string(),
        compContact1Mobile: string(),

        compContact2Title: string(),
        compContact2GivenNames: string(),
        compContact2LastName: string(),
        compContact2JobTitle: string(),
        compContact2Email: string(),
        compContact2DirectLine: string(),
        compContact2Extension: string(),
        compContact2OfficePhone: string(),
        compContact2Mobile: string(),

        compIncludeCompanyName: string(),
        compIncludeJobTitle: string(),

        compNotes: string(),
        compIsActive: boolean(),
    })

    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
            compIsActive: true,
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        //close();
        onSubmitStep(data);
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    const handleCheckboxChange = (field: string, checked: boolean) => {
        const currentValue = watch(field);
        if (currentValue !== (checked ? 1 : 0)) {
            setValue(field, checked ? 1 : 0);
        }
    };

    useEffect(() => {
        if (clientByIdData !== null) {
            setValue("compContact1Title", clientByIdData?.compContact1Title);
            setValue("compContact1GivenNames", clientByIdData?.compContact1GivenNames);
            setValue("compContact1LastName", clientByIdData?.compContact1LastName);
            setValue("compContact1JobTitle", clientByIdData?.compContact1JobTitle);
            setValue("compContact1Email", clientByIdData?.compContact1Email);
            setValue("compContact1DirectLine", clientByIdData?.compContact1DirectLine);
            setValue("compContact1Extension", clientByIdData?.compContact1Extension);
            setValue("compContact1OfficePhone", clientByIdData?.compContact1OfficePhone);
            setValue("compContact1Mobile", clientByIdData?.compContact1Mobile);

            setValue("compContact2Title", clientByIdData?.compContact2Title);
            setValue("compContact2GivenNames", clientByIdData?.compContact2GivenNames);
            setValue("compContact2LastName", clientByIdData?.compContact2LastName);
            setValue("compContact2JobTitle", clientByIdData?.compContact2JobTitle);
            setValue("compContact2Email", clientByIdData?.compContact2Email);
            setValue("compContact2DirectLine", clientByIdData?.compContact2DirectLine);
            setValue("compContact2Extension", clientByIdData?.compContact2Extension);
            setValue("compContact2OfficePhone", clientByIdData?.compContact2OfficePhone);
            setValue("compContact2Mobile", clientByIdData?.compContact2Mobile);

            setValue("compIncludeCompanyName", clientByIdData?.compIncludeCompanyName);
            setValue("compIncludeJobTitle", clientByIdData?.compIncludeJobTitle);
            setValue("compNotes", clientByIdData?.compNotes);
            setValue("compIsActive", clientByIdData?.compIsActive);
        }
    }, [clientByIdData])

    const handleContactOne = (checked: boolean) => {
        setContactPersonOne(checked);
    };

    const handleContactTwo = (checked: boolean) => {
        setContactPersonTwo(checked);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(handleFormSubmit, onError)}>

                <div>
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Company Contacts</h3>
                    <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                        <div className="flex gap-5 col-span-4 items-center">
                            <label className="block text-lg font-medium text-gray-900 dark:text-white-bg">
                                Contact Person 1
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                checked={contactPersonOne}
                                onChange={(e) => handleContactOne(e.target.checked)}
                            />
                        </div>
                        {contactPersonOne && (
                            <>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Title
                                    </label>
                                    <SelectBox
                                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                        indicator={
                                            <svg
                                                width="15"
                                                className="fill-current mr-2"
                                                height="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                            </svg>
                                        }
                                        isMulti={false}
                                        name="Title"
                                        options={titleOption}
                                        isSearchable={true}
                                        value={getValues().compContact1Title}
                                        placeholder="Title"
                                        shape="round"
                                        color="indigo_50"
                                        size="xs"
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setValue('compContact1Title', selectedOption);
                                            }
                                        }}
                                    />
                                    <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.fkTitleIdPersonal?.message?.toString()}</div>
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Given Names
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1GivenNames')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Last Name
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1LastName')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Job Title
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1JobTitle')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Email Address
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1Email')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Direct Line
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1DirectLine')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Extension
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1Extension')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Office Phone
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1OfficePhone')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Mobile
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact1Mobile')} />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                        <div className="flex gap-5 col-span-4">
                            <label className="block text-lg font-medium text-gray-900 dark:text-white-bg">
                                Contact Person 2
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                checked={contactPersonTwo}
                                onChange={(e) => handleContactTwo(e.target.checked)}
                            />
                        </div>
                        {contactPersonTwo && (
                            <>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Title
                                    </label>
                                    <SelectBox
                                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                        indicator={
                                            <svg
                                                width="15"
                                                className="fill-current mr-2"
                                                height="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                            </svg>
                                        }
                                        isMulti={false}
                                        name="Title"
                                        options={titleOption}
                                        isSearchable={true}
                                        value={getValues().compContact2Title}
                                        placeholder="Title"
                                        shape="round"
                                        color="indigo_50"
                                        size="xs"
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setValue('compContact2Title', selectedOption);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Given Names
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2GivenNames')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Last Name
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2LastName')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Job Title
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2JobTitle')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Email Address
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2Email')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Direct Line
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2DirectLine')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Extension
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2Extension')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Office Phone
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2OfficePhone')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Mobile
                                    </label>
                                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compContact2Mobile')} />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                        <div className=" flex gap-5">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Include Company Name
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                onChange={(e) => handleCheckboxChange('compIncludeCompanyName', e.target.checked)}
                            />
                        </div>
                        <div className="flex gap-5">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Include Job Title
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                onChange={(e) => handleCheckboxChange('CompIncludeJobTitle', e.target.checked)}
                            />
                        </div>
                    </div>

                    <div className=" mb-5">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Note
                        </label>
                        <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compNotes')} />
                    </div>
                    {/* <div className="relative max-w-full flex gap-6">
                        <label className="block mb-2">
                            Is Active
                        </label>
                        <div className='flex gap-5'>
                            <div>
                                <input
                                    type="radio"
                                    value="true"
                                    checked={watch('compIsActive') === true}
                                    onChange={() => setValue('compIsActive', true)}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                            </div>
                            <div>
                                <input
                                    id="bordered-radio-1"
                                    type="radio"
                                    value="false"
                                    checked={watch('compIsActive') === false}
                                    onChange={() => setValue('compIsActive', false)}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="">
                    <div className="flex flex-row gap-2 items-start justify-between w-full">
                        <button
                            onClick={onPreviousStep}
                            type="button"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                            Previous
                        </button>
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">

                                <button
                                    type="button"
                                    onClick={onCancel}
                                    className="border border-gray-400 bg-gray-100 text-gray-700 font-medium cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export { AddComClientThree };
