import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetAllOfficeToClient = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeToClientSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetOfficeToClientNumber = async () => {
    try {
        const response = await axiosInstance.get("OfficeToClientNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeToClient = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeToClient", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostReverseOfficeToClient = async (data: any) => {
    try {
        const response = await axiosInstance.post("ReverseOfficeToClient", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeToClient = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeToClient/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeToClient = async (id: number) => {
    try {
        await axiosInstance.delete(`/OfficeToClient/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeToClientService = {
    GetAllOfficeToClient,
    PostOfficeToClient,
    PostReverseOfficeToClient,
    PutOfficeToClient,
    deleteOfficeToClient,
    GetOfficeToClientNumber
};


export default OfficeToClientService;
