import { TimeEntry } from "../../ClientInvoice/TimeEntry";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchTimeEntrySearch } from "../../../store/slice/Accounting/ClientAccounts/Invoice/TimeEntrySlice";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../../utils/currency-format.utilis";
import { Pagination } from "../../Pagination";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
function CaseTimeEntry() {
  const { referenceNumber } = useParams();
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const [caseDetails, setCaseDetails] = useState<any>();
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { timeEntryData } = useSelector((state: RootState) => state.clientTimeEntry);
  const [getDataList, setGetDataList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);


  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let searchdata = {
        caseId: referenceNumber,
      }
      dispatch(fetchTimeEntrySearch(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (timeEntryData && timeEntryData?.timerEntriesResult.length > 0) {
      setGetDataList(timeEntryData?.timerEntriesResult);
      setTotalRecords(timeEntryData?.totalCount);
    }
  }, [timeEntryData]);



  useEffect(() => {
    if (caseDetailsData !== null) {
      setCaseDetails(caseDetailsData);
    }
  }, [caseDetailsData]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchTimeEntrySearch(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  return (
    <>
      {initialLoading ? <MainAnimation /> : ""}
      <div className="flex flex-row md:flex-row items-start justify-between mb-5">
        <TimeEntry isCase={true} caseData={caseDetails} />
      </div>
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400 my-5">
          <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Elapsed time
              </th>
              <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Staff
              </th>
              <th scope="col" className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Activity
              </th>
              <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Units
              </th>
              <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Rate
              </th>
              <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Value
              </th>
              <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                VAT(%)
              </th>
              <th scope="col" className="text-right p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Total
              </th>
            </tr>
          </thead>
          <tbody className="text-lg 3xl:text-xs">
            {getDataList.map((timeEntry: any, index: number) => (
              <tr
                key={index}
                className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{index + 1}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.elapsedTime}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.timeEntryStaff}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{timeEntry.billingDescription}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{timeEntry.units}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(timeEntry.unitRate)}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(timeEntry.amount)}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{timeEntry.percentage}</td>
                <td className="p-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right">{formatCurrency(timeEntry.total)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalRecords === 0 && (
          <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
            <p className="text-red-800">No Time Entry records found.</p>
          </div>
        )}
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[50px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => { onPageChange(page); }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CaseTimeEntry;
