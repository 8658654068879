import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { AddOfficeRefundCreditNote } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddOfficeRefundCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteRefundCreditNote, fetchAllRefundCreditNote } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/RefundCreditNoteSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit, FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import DeleteModal from "../Modal/DeleteModal";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { Pagination } from "../Pagination";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";

function OfficeRefundCreditNote() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [refundCreditNoteData, setRefundCreditNoteData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const searchOfficeRefundCreditNoteResult = useSelector(
    (state: RootState) => state.refundCreditNote.searchOfficeRefundCreditNoteResult
  );
  const [currentRefundCreditNote, setCurrentRefundCreditNote] = useState<any[]>(
    []
  );
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [refundCreditNoteId, setRefundCreditNoteId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const { creditNoteStatusType } = useSelector((state: RootState) => state.creditNoteStatusType);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '', 
    endDate: '',   
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    statusId: 0
  });

  const optiondata = [
    {
      name: "Status",
      options: statusList
    },
  ]

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        // currentStatusId: filters.statusId,
      }
      dispatch(fetchAllRefundCreditNote(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }


  }, [dispatch, isOnAddedOrUpdate,filters]);

  useEffect(() => {
    if (searchOfficeRefundCreditNoteResult !== null && searchOfficeRefundCreditNoteResult.creditNoteRefunds !== null && searchOfficeRefundCreditNoteResult.totalRows !== null) {
      setCurrentRefundCreditNote(searchOfficeRefundCreditNoteResult.creditNoteRefunds);

      setTotalRecords(searchOfficeRefundCreditNoteResult.totalRows);
    }
  }, [searchOfficeRefundCreditNoteResult]);

  useEffect(() => {
    if (creditNoteStatusType !== null) {
      let data = creditNoteStatusType.map((status: any, index) => ({
        value: status.creditNoteStatusTypeId,
        label: `${status.status}`,
      }));
      data.unshift({ value: 0, label: "Select" });
      setStatusList(data);
    }
  }, [creditNoteStatusType]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllRefundCreditNote(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  // useEffect(() => {
  //   // console.log(authourisedClients);
  //   if (searchOfficeCreditNoteResult != null && searchOfficeCreditNoteResult.listingData.length > 0) {
  //     let data = searchOfficeCreditNoteResult.listingData.map((creditNote: any) => ({
  //       value: creditNote.creditNoteNumber,
  //       label: `${creditNote.invoiceeName}`,
  //     }));
  //     setOfficeCreditNoteLists(data);
  //   }
  // }, [searchOfficeCreditNoteResult]);

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };
  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteRefundCreditNote(refundCreditNoteId)).unwrap();
      setCurrentRefundCreditNote((prevData) => prevData.filter(item => item.refundCreditNoteId !== refundCreditNoteId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted Office Refund CreditNote");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OARefundCreditNote"
        getData={refundCreditNoteData}
        title="Office Refund CreditNote"
      />
    );
  }



  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={refundCreditNoteId}
            title="Office Refund CreditNote"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle title="Refund Credit Note" buttonName="Refund Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
          <AddOfficeRefundCreditNote openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} isView={isView} refundCreditNote={refundCreditNoteData} />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
        <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
        </div>
          {/* <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Status" />
            </div>
          </div> */}
        
        <div className="relative overflow-x-auto sm:rounded-lg my-6">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Invoicee (s)
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit Note
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Refund Amount
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Status
                </th>
                <th scope="col" className="px-3 text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentRefundCreditNote.map((refundCreditNote: any, index) => (


                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(refundCreditNote.refundDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {refundCreditNote.invoiceeName}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <a href="">
                      {refundCreditNote.creditNoteDocumentName}
                    </a>
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(refundCreditNote.refundAmount)}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {refundCreditNote.status}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setRefundCreditNoteData(refundCreditNote);
                        }}
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setRefundCreditNoteData(refundCreditNote);
                        }}
                      />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setRefundCreditNoteId(refundCreditNote.creditNoteRefundId);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Office Refund Credit Note Details found.</p>
            </div>
          )}

          {totalRecords > 0 && (
            <div className="w-full flex flex-col pb-[100px]">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPage}
                totalRecords={totalRecords}
                onPageChange={(page) => { onPageChange(page); }}
              />
            </div>
          )}

        </div>
      </div>
    </>
  );
}

export { OfficeRefundCreditNote };
