
import React from "react";
import Select, { Props as SelectProps } from "react-select";

type SelectOptionType = { value: string; label: string };

const variants = {
  outline: {
    indigo_50: "border-gray-300 bg-gray-100 border dark:bg-gray-700 dark:border-gray-600 text-lg 3xl:text-sm dark:bg-gray-700 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-lg 3xl:text-sm",
  },
} as const;

const shapes = { round: "rounded-[4px]" } as const;
const sizes = { xs: "p-0" } as const;

export type ExtendedSelectProps = Omit<SelectProps, "getOptionLabel"> &
  Partial<{
    placeholder: string;
    className: string;
    options: SelectOptionType[];
    isSearchable: boolean;
    placeholderClassName: string;
    isMulti: boolean;
    onChange: (option: any) => void;
    value: any;
    errors: string[];
    indicator: React.ReactElement;
    getOptionLabel: (e: any) => string;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
    children: any;
  }>;

const SelectBox = React.forwardRef<any, ExtendedSelectProps>(
  (
    {
      children,
      placeholder = "Select",
      className = "",
      options = [],
      isSearchable = false,
      placeholderClassName = "",
      isMulti = false,
      onChange,
      value = "",
      errors = [],
      indicator,
      shape = "round",
      size = "xs",
      variant = "outline",
      color = "indigo_50",
      ...restProps
    },
    ref,
  ) => {
    const [selectedVal, setSelectedVal] = React.useState(value);

    const handleChange = (data: any) => {
      setSelectedVal(data);
      if (isMulti) {
        onChange?.(data?.map((d: any) => d.value) || []);
      } else {
        onChange?.(data?.value);
      }
    };

    React.useEffect(() => {
      setSelectedVal(value);
    }, [value]);

    const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;



    const customStyles = {
      menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
      control: (provided: any, state: any) => ({
        ...provided,
        borderColor: isDarkTheme ? 'border-gray-600' : 'border-gray-300',
        backgroundColor: isDarkTheme ? 'bg-gray-700' : 'bg-gray-100',
        '&:hover': {
          borderColor: isDarkTheme ? 'border-gray-600' : 'border-gray-300',
        },
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#000000', // Set placeholder color to black
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? isDarkTheme
            ? 'bg-gray-300'
            : 'ring-blue-500'
          : isDarkTheme
          ? 'bg-gray-400'
          : 'ring-blue-500',
          borderColor: isDarkTheme ? 'border-gray-600' : 'border-gray-300',
          
        color: "#000000", // text-blue-600
      }),
      singleValue: (provided: any) => ({
        ...provided,
        borderColor: isDarkTheme ? 'border-gray-600' : 'border-gray-300',
        color: "#000000", // text-blue-600
      }),
    };

    return (
      <>
        <Select
          ref={ref}
          options={options}
          className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && (variants as any)[variant]?.[color]) || ""}`}
          placeholder={
            <div className={placeholderClassName}>{placeholder}</div>
          }
          isSearchable={isSearchable}
          isMulti={isMulti}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          value={options.find((op: any) => op.value === selectedVal)}
          onChange={handleChange}
          menuPortalTarget={document.body}
          styles={customStyles}
          {...restProps}
        />
        {children}
      </>
    );
  },
);

export { SelectBox };



// import React from "react";
// import Select, { Props as SelectProps } from "react-select";

// type SelectOptionType = { value: string; label: string };

// const variants = {
//   outline: {
//     indigo_50: "border-blue-500 bg-blue-50 text-blue-600 hover:bg-blue-100 placeholder:text-gray-400 text-lg 3xl:text-sm",
//   },
// } as const;

// const shapes = { round: "rounded-[7px]" } as const;
// const sizes = { xs: "p-0" } as const;



//   export type ExtendedSelectProps = Omit<SelectProps, "getOptionLabel"> &
//   Partial<{
//     placeholder: string;
//     className: string;
//     options: SelectOptionType[];
//     isSearchable: boolean;
//     placeholderClassName: string;
//     isMulti: boolean;
//     onChange: (option: any) => void;
//     value: any;
//     errors: string[];
//     indicator: React.ReactElement;
//     getOptionLabel: (e: any) => string;
//     shape: keyof typeof shapes;
//     variant: keyof typeof variants;
//     size: keyof typeof sizes;
//     color: string;
//     children: any;
//   }>;

// const SelectBox = React.forwardRef<any, ExtendedSelectProps>(
//   (
//     {
//       children,
//       placeholder = "Select",
//       className = "",
//       options = [],
//       isSearchable = false,
//       placeholderClassName = "",
//       isMulti = false,
//       onChange,
//       value = "",
//       errors = [],
//       indicator,
//       shape = "round",
//       size = "xs",
//       variant = "outline",
//       color = "indigo_50",
//       ...restProps
//     },
//     ref,
//   ) => {
//     const [selectedVal, setSelectedVal] = React.useState(value);

//     const handleChange = (data: any) => {
//       setSelectedVal(data);
//       if (isMulti) {
//         onChange?.(data?.map((d: any) => d.value) || []);
//       } else {
//         onChange?.(data?.value);
//       }
//     };

//     React.useEffect(() => {
//       setSelectedVal(value);
//     }, [value]);

//     const customStyles = {
//       menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
//       control: (provided: any, state: any) => ({
//         ...provided,
//         borderColor: "#3b82f6",
//         backgroundColor: "#ecfeff", //  bg-blue-50 #f2f6fa
//         "&:hover": {
//           borderColor: "#3B82F6", // border-blue-500
//         },
//       }),
//       placeholder: (provided: any) => ({
//         ...provided,
//         color: "#3b82f6", // border-blue-500
//       }),
//       option: (provided: any, state: any) => ({
//         ...provided,
//         backgroundColor: state.isFocused ? "#BFDBFE" : "#EFF6FF", // hover:bg-blue-100
//         color: "#1E3A8A", // text-blue-600
//       }),
//       singleValue: (provided: any) => ({
//         ...provided,
//         color: "#1E3A8A", // text-blue-600
//       }),
//     };

//     return (
//       <>
//         <Select
//           ref={ref}
//           options={options}
//           className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && (variants as any)[variant]?.[color]) || ""}`}
//           placeholder={
//             <div className={placeholderClassName}>{placeholder}</div>
//           }
//           isSearchable={isSearchable}
//           isMulti={isMulti}
//           components={{
//             IndicatorSeparator: () => null,
//             ...(indicator && { DropdownIndicator: () => indicator }),
//           }}
//           value={options.find((op: any) => op.value === selectedVal)}
//           onChange={handleChange}
//           menuPortalTarget={document.body}
//           styles={customStyles}
//           {...restProps}
//         />
//         {children}
//       </>
//     );
//   },
// );

// export { SelectBox };
