import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import { IoIosArrowUp } from "react-icons/io";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { MainAnimation } from "../../../loadingAnimation/MainAnimation";
import { HeaderTitle } from "../../../HeaderTitle";
import { Heading } from "../../../AccountSetupHeading/Heading";
import { SearchInput } from "../../../Filters/SearchInput";
import { FaFileWord } from "react-icons/fa";
import { addWorkflowDoc, fetchAllWorkflowDocument} from "../../../../store/slice/SystemMaintenance/WorkflowSetupSlice";
import { toast } from "react-toastify";
import { WorkflowDoc } from "../../../../store/model/SystemMaintenance/WorkFlowInterFace";

function AddDocumentsToWorkflow({
  openDrawer,
  Close,
  stepId,
  wFlowId,
  areaPracticeId,
  caseTypeId,
  subCaseTypeId,
  isEdit,
  docuId
}: {
  openDrawer: boolean;
  Close: any;
  stepId: number;
  wFlowId: number;
  areaPracticeId: number | null;
  caseTypeId: number | null;
  subCaseTypeId: number | null;
  isEdit: boolean;
  docuId: number | null;
}) {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [areaId, setAreaId] = useState<any>();
  const [areasPracticeId, setAreasPracticeId] = useState<any>();
  const [subCaseId, setsubCaseId] = useState<number | null>();
  const [caseId, setCaseId] = useState<any>();
  const [selectedCaseIndex, setSelectedCaseIndex] = useState<number>();
  const [selectedAreaIndex, setSelectedAreaIndex] = useState<number>();
  const [openAddCaseTypeModal, setOpenAddCaseTypeModal] = useState(false);

  const [initialLoading, setInitialLoading] = useState(false);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const workflowDocument = useSelector((state: RootState) => state.workflow.workflowDocument);
  const [wFDocumentData, setWFDocumentData] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!effectServiceCall.current) {

      setAreaId(areaPracticeId);
      setCaseId(caseTypeId);
      setsubCaseId(subCaseTypeId)
      const data = {
        stepId: stepId,
        PracticeId:areaId,
        CaseTypeId:caseId,
        SubcaseTypeId: subCaseId
      };
      console.log(data)
      const fetchPromises = [dispatch(fetchAllWorkflowDocument(data))];
      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (workflowDocument !== null && workflowDocument.listingData.length > 0) {
      setWFDocumentData(workflowDocument.listingData);
    }
  }, [workflowDocument])


  const validationSchema = object().shape({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    areasOfPractice,
    status: areasStatus,
    error: areasError,
  } = useSelector((state: RootState) => state.areasOfPractice);
  const {
    casetypes,
    status: caseTypeStatus,
    error: caseTypeError,
  } = useSelector((state: RootState) => state.caseType);
  const {
    CaseSubTypes,
    status: caseSubTypeStatus,
    error: caseSubTypeError,
  } = useSelector((state: RootState) => state.caseSubType);
  // const isLoading =
  //   areasStatus === "loading" ||
  //   caseTypeStatus === "loading" ||
  //   caseSubTypeStatus === "loading";
  const isError = areasError || caseTypeError || caseSubTypeError;

  //   if (isLoading) {
  //     return <MainAnimation />;
  //   }

  const toggleOpen = (areasOfPracticeId: number, index: number) => {
    setSelectedAreaIndex(index);
    setSelectedCaseIndex(0);
    setAreaId(areasOfPracticeId);
    // setOpenItems(prevState => ({
    //     ...prevState,
    //     [areasOfPracticeId]: !prevState[areasOfPracticeId]
    // }));
  };

  const toggleOpenSubItem = (caseTypeId: number, subIndex: number) => {
    // console.log('Toggling sub-item:', caseTypeId);
    setSelectedCaseIndex(subIndex);
    setCaseId(caseTypeId);
    // setOpenSubItems(prevState => ({
    //     ...prevState,
    //     [caseTypeId]: !prevState[caseTypeId]
    // }));
  };
  const handleRowClick = (doc: Document) => {
    setSelectedDocument(doc);
  };

  const handleAddClick = async () => {
    const data: WorkflowDoc = {
      documentId: selectedDocument.documentId,
      stepId: stepId,
      workFlowId: wFlowId
    };
    console.log(data)
    setIsLoading(true);
    try {
      await dispatch(addWorkflowDoc(data)).unwrap();
      toast.success("Successfully Added FS Addition");
    } catch (error) {
      toast.error("Failed to add Addition");
    } finally {
      // Close();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        setOpenModal(Close);
      }}
      initialFocus={emailInputRef}
      size="4xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Internal Server
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form>
          <div className="grid grid-cols-2 gap-5 h-[500px] overflow-y-scroll">
            <div className="grid grid-cols-1 gap-2 md:gap-0 w-full">
              <label className="text-sm font-medium text-gray-800 dark:text-white-bg bg-gray-200 dark:bg-gray-900 p-2">
                Areas of Practice & Case Types
              </label>
              <div className="my-1">
                <div className="relative flex w-[390px]">
                  <input
                    type="search"
                    className="relative m-0 -me-0.5 block flex-auto rounded border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary"
                    placeholder="Search Document"
                    aria-label="Search"
                  />
                </div>
              </div>
              <div className="flex flex-row w-full h-[500px] overflow-y-scroll">
                <ul className="list-none w-full">
                  {areasOfPractice.map((area, index) => {
                    const caseTypesCount = casetypes.filter(
                      (caseType) =>
                        caseType.fkAreasOfPracticeId === area.areasOfPracticeId
                    ).length;
  
                    return (
                      <li
                        key={area.areasOfPracticeId}
                        className="mb-2 fill-black dark:fill-black space-y-1"
                      >
                        <div
                          className={`flex justify-between items-center px-3 py-1 md:w-full w-[380px] dark:text-white-bg cursor-pointer rounded-sm ${selectedAreaIndex === index
                            ? "bg-blue-500 text-white-bg dark:bg-gray-900 dark:hover:bg-gray-800"
                            : "hover:bg-gray-100 dark:hover:bg-gray-900"
                            }`}
                          onClick={() =>
                            toggleOpen(area.areasOfPracticeId, index)
                          }
                        >
                          <div className="flex gap-2 items-center cursor-pointer md:items-start">
                            <span
                              className={`transition ${selectedAreaIndex == index ? "rotate-180" : ""
                                }`}
                            >
                              <IoIosArrowUp className="h-4 w-4" />
                            </span>
                            <span className="w-px h-5"></span>
                            <span className="w-52 md:w-32 lg:w-24 font-semibold md:mr-2 sm:text-xs md:text-sm lg:text-sm text-sm">
                              {area.area}
                            </span>
                          </div>
                          <div className="flex md:gap-x-3 lg:gap-x-2 gap-0.5">
                            <div
                              className="w-fit bg-none hover:bg-gray-200 rounded-lg hover:fill-white-bg "
                              onClick={() => {
                                setOpenAddCaseTypeModal(!openAddCaseTypeModal);
                                setAreasPracticeId(area.areasOfPracticeId);
                              }}
                            ></div>
                            <span className="bg-gray-200 py-1 px-2.5 w-7 rounded-md text-xs font-medium text-black">
                              {caseTypesCount}
                            </span>
                          </div>
                        </div>
                        {casetypes
                          .filter(
                            (caseType) =>
                              caseType.fkAreasOfPracticeId ===
                              area.areasOfPracticeId
                          )
                          .map((caseType, subIndex) => (
                            <div key={caseType.caseTypeId}>
                              <div
                                className={`flex justify-between items-center px-3 py-1 md:w-full w-[380px] dark:text-white-bg cursor-pointer rounded-sm ml-2 mt-2 ${selectedCaseIndex == subIndex  
                                  ? "bg-green-500 text-white-bg dark:bg-gray-900 dark:hover:bg-gray-800"
                                  : "hover:bg-gray-100 dark:hover:bg-gray-900"
                                  }`}
                                onClick={() =>
                                  toggleOpenSubItem(
                                    caseType.caseTypeId,
                                    subIndex
                                  )
                                }
                              >
                                <div className="flex gap-2 items-center cursor-pointer">
                                  <span
                                    className={`transition ${selectedCaseIndex == subIndex
                                      ? "rotate-180"
                                      : ""
                                      }`}
                                  >
                                    <IoIosArrowUp className="h-4 w-4" />
                                  </span>
                                  <span className="w-px h-5 bg-gray-300"></span>
                                  <span className="w-52 font-semibold text-sm">
                                    {caseType.caseType}
                                  </span>
                                </div>
                              </div>
                              {selectedCaseIndex == subIndex && (
                                <ul className="relative ml-12 mt-1">
                                  {CaseSubTypes.filter(
                                    (casesubType) =>
                                      casesubType.fkCaseTypeId ===
                                      caseType.caseTypeId
                                  ).map((casesubType) => (
                                    <li
                                      key={casesubType.caseSubTypeId}
                                      className="mb-2"
                                    >
                                      <div className="flex items-center px-3 py-1 hover:bg-gray-100 dark:text-white-bg  dark:hover:bg-gray-800 w-full cursor-pointer rounded-sm text-black">
                                        <span className="font-semibold text-sm">
                                          {casesubType.subCaseType}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            
            <div className="space-y-2 ">
              <label className="flex items-center justify-between text-sm font-medium text-gray-800 dark:text-white-bg bg-gray-200 dark:bg-gray-900 p-2">
                Letters / Forms / Documents
              </label>
              <div className="flex items-center justify-between my-2">
                <div className="relative flex w-[250px]">
                  <input
                    type="search"
                    className="relative m-0 -me-0.5 block flex-auto rounded border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary w-full"
                    placeholder="Search Document"
                    aria-label="Search"
                  />
                </div>
                <button className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-sm w-[120px]"
                        type="button">
                        <span className="text-white-bg mx-2">Add</span>
                    </button>
              </div>
              <div className="relative overflow-x-auto sm:rounded-lg my-5">
                {initialLoading ? <MainAnimation /> : ""}
                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                  <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th scope="col" className="px-6 py-3">
                        All Document
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {wFDocumentData.map((doc, index) => (
                      <tr
                        key={index}
                        className={`border-2 dark:border dark:border-gray-400 dark:border-opacity-35 ${selectedDocument === doc ? 'bg-gray-300' : ''
                          }`}
                        onClick={() => handleRowClick(doc)}
                      >
                        <td className="flex items-center gap-4 px-6 py-3">
                          <span>
                            <FaFileWord className="size-5 fill-blue-500" />
                          </span>
                          {doc.documentName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                onClick={() => {
                  reset();
                  setOpenModal(Close);
                }}
                type="button"
                className="cursor-pointer h-10 rounded-md text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleAddClick}
                className={`bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
  
}

export { AddDocumentsToWorkflow };
