import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LexcelCqsColpCofaGdprService from "../../../../services/OfficeManagement/OfficeInformation/LexcelCqsColpCofaGdprService";
import { ChapterTypeEnum } from "../../../model/OfficeManagement/OfficeInformation/OfficeChapterEnum";

export interface CQS {

 // cQSId: number;
  chapterType: ChapterTypeEnum;
 cQSId: number;
  chapterName: string;
  attachmentName: string;
  attachmentFile: string; 
}
// Define the state type for Checklist
type CQSState = {
    cQSes: PaginationResponse<CQS> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CQSState = {
    cQSes: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCQS = createAsyncThunk(
    "fetchAllCQS",
    async (chapterType: ChapterTypeEnum, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.GetAllLexcelCqsColpCofaGdpr({chapterType});
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCQS = createAsyncThunk(
    "addCQS",
    async (data: any, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PostLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCQS = createAsyncThunk(
    "updateCQS",
    async ({ id, data }: { id: number, data: CQS }, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PutLexcelCqsColpCofaGdpr(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCQS = createAsyncThunk(
    "deleteCQS",
    async (id: number, thunkAPI) => {
        try {
            await LexcelCqsColpCofaGdprService.deleteLexcelCqsColpCofaGdpr(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CQSSlice = createSlice({
    name: "cQS",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCQS.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCQS.fulfilled, (state, action: PayloadAction<PaginationResponse<CQS>>) => {
                state.status = "idle";
                state.cQSes = action.payload;
            })
            .addCase(fetchAllCQS.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCQS.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCQS.fulfilled, (state, action: PayloadAction<CQS>) => {
                state.status = "idle";
               // state.cQSs.push(action.payload);
            })
            .addCase(addCQS.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCQS.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCQS.fulfilled, (state, action: PayloadAction<CQS>) => {
                state.status = "idle";
                // const index = state.cQSs.findIndex(t => t.cQSId === action.payload.cQSId);
                // if (index !== -1) {
                //     state.cQSs[index] = action.payload;
                // }
            })
            .addCase(updateCQS.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCQS.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCQS.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.cQSs = state.cQSs.filter(t => t.cQSId !== action.payload);
            })
            .addCase(deleteCQS.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CQSSlice.reducer;
