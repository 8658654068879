import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddBankReceipt } from "../Modal/AddClientAccounts/AddClientPostings/AddBankReceipt";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteBankReceipt,
  fetchAllBankReceipt,
} from "../../store/slice/Accounting/ClientAccounts/ClientPosting/BankReceiptSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { fetchAllClientAccount } from "../../store/slice/Accounting";
import { fetchUsersDataByBranch } from "../../store/slice/Administrator/UserManagementSlice";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";

function BankReceipt() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [, setIsReverse] = useState(false);
  const [bankReceiptData, setBankReceiptData] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const bankRecpits = useSelector(
    (state: RootState) => state.bankReceipt.searchBankReciptResult
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recepitDatas, setRecepitDatas] = useState<any[]>([]);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [bankReceiptId, setBankReceiptId] = useState<number>(0);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const [staffList, setStaffList] = useState<any[]>([]);
  const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
  const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const [paymentTypeLists, setPaymentTypeLists] = useState<any[]>([]);

  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    userId: 0,
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    clientBankAccId: 0,
    paymentTypeId: 0
  });

  const optiondata = [
    {
      name: "Client Bank",
      options: clientAccountLists
    },
    {
      name: "Staff",
      options: staffList
    },
    {
      name: "Pyment Type",
      options: paymentTypeLists
    },
  ]


  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        userId: filters.userId,
        bankAccountId: filters.clientBankAccId,
        paymentTypeId: filters.paymentTypeId,

      }
      dispatch(fetchAllBankReceipt(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, filters, isOnAddedOrUpdate]);


  useEffect(() => {
    if (bankRecpits !== null && bankRecpits.bankRecriptResult !== null) {
      setRecepitDatas(bankRecpits.bankRecriptResult);
      setTotalRecords(bankRecpits.totalCount);
    }
  }, [bankRecpits]);


  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsView(false);
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const toggleReverseDrawer = () => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllBankReceipt(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  const onDeleteCall = () => {
    try {
      dispatch(deleteBankReceipt(bankReceiptId)).unwrap();
      setRecepitDatas((prevData) => prevData.filter(item => item.clientReceiptId !== bankReceiptId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted BankReceipt");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  useEffect(() => {
    if (branchUsers !== null) {
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} `,
      }));
      data.unshift({ value: 0, label: "Select" });
      setStaffList(data);
    }
  }, [branchUsers]);

  useEffect(() => {
    if (clientAccounts !== null) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      data.unshift({ value: 0, label: "Select" });
      setClientAccountLists(data);
    }
  }, [clientAccounts]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {

      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentTypeId,
        label: PaymentType.paymentType,
      }));
      data.unshift({ value: 0, label: "Select" });
      setPaymentTypeLists(data);
    }
  }, [clientPaymentTypes]);

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openModal) {
    return (
      <AddBankReceipt
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        isReverse={false}
        bankReceipt={bankReceiptData}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="bankReceipt"
        getData={bankReceiptData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPBankReciept"
        getData={bankReceiptData}
        title="BankReceipt"
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={bankReceiptId}
            title="Bank Receipt"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Bank Receipt"
            buttonName="Receipt"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-col md:flex-col justify-between gap-7 w-full md:gap-3">
          {/* <div className="flex w-72">
            <SearchInput title="Keyword" />
          </div> */}
          <div className="w-full">
            <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
          </div>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Client and Case Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Payment Method
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {recepitDatas.map((bankReceipt: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(bankReceipt.receiptDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>{bankReceipt.individualNames || bankReceipt.organisationNames || bankReceipt.companyNames}</p>
                    <p>{bankReceipt.caseReferenceAuto}</p> <br />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{bankReceipt.transactionDescription}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {bankReceipt.paymentType}
                    <br />
                    {formatDateOnlyDisplay(bankReceipt.entryDate)}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(bankReceipt.amount)}</td>

                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {(bankReceipt.isCancelled === false && bankReceipt.isReconciled === false) && (
                      <div className="flex gap-3 w-full">
                        <FaEye
                          className="fill-gray-500 w-6 h-6"
                          onClick={() => {
                            setOpenViewDrawer(true);
                            setBankReceiptData(bankReceipt);
                          }}
                        />
                        <FaEdit
                          className="fill-blue-500 w-6 h-6"
                          onClick={() => {
                            setOpenModal(true);
                            setIsEdit(true);
                            setIsView(false);
                            setBankReceiptData(bankReceipt);
                          }}
                        />
                        <RiDeleteBin5Fill
                          className="fill-red-600 w-6 h-6"
                          onClick={() => {
                            setOpenDelModal(true);
                            setBankReceiptId(bankReceipt.clientReceiptId);
                          }}
                        />
                        {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                            onClick={() => {
                              setOpenReverseDrawer(true);
                              setBankReceiptData(bankReceipt);
                            }}
                          /> */}
                      </div>
                    )}

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank New Receipts found.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => { onPageChange(page); }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { BankReceipt };
