import { useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddJournalEntry } from "../Modal/AddClientAccounts/AddClientPostings/AddJournalEntry";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { deleteJournalEntry, fetchAllJournalEntry } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/JournalEntrySlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { JournalEntryDetails } from "./JournalEntryDetails";
import DeleteModal from "../Modal/DeleteModal";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
function JournalEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [openJournalModal, setOpenJournalModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const searchJournalEntryResult = useSelector(
    (state: RootState) => state.journalEntry.searchJournalEntryResult
  );

  const [journalEntryData, setJournalEntryData] = useState<any>([]);
  const [currentJournals, setCurrentJournals] = useState<any[]>([]);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [journalEntryId, setJournalEntryId] = useState<number>(0);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: true,
    isSelectbox: false,
  });


  const effectServiceCall = useRef(false);
  useEffect(() => {
    setInitialLoading(true)
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate == "" ? null : filters.startDate),
        dateEnd: (filters.endDate == "" ? null : filters.endDate),
      }
      dispatch(fetchAllJournalEntry(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate, filters]);

  useEffect(() => {
    if (searchJournalEntryResult && searchJournalEntryResult.journalEntryResult !== null) {
      setCurrentJournals(searchJournalEntryResult.journalEntryResult);
      setTotalRecords(searchJournalEntryResult.totalCount);
    }
  }, [searchJournalEntryResult]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true)
    }
    setIsEdit(false);
    setIsView(false);
    setOpenModal(!openModal);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setInitialLoading(true);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllJournalEntry(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  const onDeleteCall = () => {
    try {
      dispatch(deleteJournalEntry(journalEntryId)).unwrap();
      setCurrentJournals((prevData) => prevData.filter(item => item.journalEntryId !== journalEntryId));
      setOpenDelModal(false)
      toast.success("Successfully Deleted Journal Entry");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleJournalDrawer = () => {
    setOpenJournalModal(!openJournalModal);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openModal) {
    return (
      <AddJournalEntry
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        journalEntry={journalEntryData}
      />
    );
  }

  if (openJournalModal) {
    return (
      <JournalEntryDetails
        openModal={openJournalModal}
        Close={toggleJournalDrawer}
        isEdit={false}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPJournalEntry"
        getData={journalEntryData}
        title="JournalEntry"
      />
    );
  }
  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={journalEntryId}
            title="JournalEntry"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Journal Entry"
            buttonName="Journal Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between gap-2 w-full md:gap-3">
          <div className="w-full">
            <AccountFilterComponent onFilterChange={handleFilterChange} initialFilters={filters} />
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Journal Entry Id
                </th> */}
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Staff
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  JournalEntry Description
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Number of Accounts
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Total
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentJournals.map((journalEntry: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">JE{journalEntry.journalEntryId}</td> */}
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(journalEntry.journalEntryDate)
                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {journalEntry.firstName} {journalEntry.lastName}
                  </td>
                  <td className="px-6 py-3 w-[250px] border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {journalEntry.journalEntryDescription}
                  </td>
                  <td className="px-6 py-3 w-[250px] border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {journalEntry.totalEntries}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(journalEntry.total)}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setJournalEntryData(journalEntry);
                        }}
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setIsView(false);
                          setJournalEntryData(journalEntry);
                        }}
                      />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setJournalEntryId(journalEntry.journalEntryId);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">Journal Entry Details not found.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => { onPageChange(page); }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { JournalEntry };
