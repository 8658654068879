import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddPartnershipAccounts } from "../Modal/AccountSetup/AddPartnershipAccounts";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deletePartnerShipAccount, fetchAllPartnerShipAccount } from "../../store/slice/Accounting/AccountSetup/PartnerShipAccountSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MdDelete } from "react-icons/md";
import DeleteModal from "../Modal/DeleteModal";
import { NewRadioButtonFilter } from "../Filters/RadioButtonFilter";
// import { NewCheckBoxFilter } from "../Filters/CheckBoxFilter/newCheckBoxFilter";

const isActiveStatus = [
    { id: 1, label: "Active" },
    { id: 2, label: "InActive" },
  ];
  

function PartnershipAccounts() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { partnerShipAccounts, status, error } = useSelector((state: RootState) => state.partnerShipAccount);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [partnerShipAccountData, setPartnerShipAccountData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [openDelModal, setOpenDelModal] = useState(false);
    const [partnerShipAccountId, setPartnerShipAccountId] = useState<number>(0);
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);



    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };
    const handleRadioButtonChange = (status: number | null) => {
        setSelectedStatus(status);
        console.log("Selected Status:", status);
      }
        


    const filterPartnerShipAccounts = (data: any) => {
        return data.filter((partnerShipAccount: any) => {
            console.log(partnerShipAccount)
          // Handle potential undefined values using optional chaining
          const disbursmentTypess = partnerShipAccount?.partnerName?.toLowerCase() || "";
          const taxCode = partnerShipAccount?.partnershipPercentage?.toLowerCase() || "";
          const isActiveStatus = partnerShipAccount?.isActive ? 1 : 2;
         
          // Check if the fee type or VAT code matches the search query
          const matchesSearch =
            searchQuery.trim() === "" ||
            disbursmentTypess.includes(searchQuery.toLowerCase()) ||
            taxCode.includes(searchQuery.toLowerCase()) ;
      
          // Check if the fee type matches the selected filter (active/inactive)
          const matchesStatus =
            selectedStatus === null || selectedStatus === isActiveStatus;
      
          return matchesSearch && matchesStatus;
        });
      };
    

    const filteredPartnerShipAccount = filterPartnerShipAccounts(partnerShipAccounts);


    // pagination
    const indexOfLastPartnerShipAccount = currentPage * itemsPerPage;
    const indexOfFirstPartnerShipAccount = indexOfLastPartnerShipAccount - itemsPerPage;
    const currentPartnerShipAccount = filteredPartnerShipAccount.slice(indexOfFirstPartnerShipAccount, indexOfLastPartnerShipAccount);


    // Calculate the total number of pages

    const totalPages = Math.ceil(partnerShipAccounts.length / itemsPerPage);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current || status === "Added") {
            dispatch(fetchAllPartnerShipAccount())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    if (initialLoading) {
        return <MainAnimation />;
    }

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    const onDeleteCall = () => {
        try {
          dispatch(deletePartnerShipAccount(partnerShipAccountId)).unwrap();
          setOpenDelModal(false)
          toast.success("Successfully Deleted parnerShip Account");
        } catch (error: any) {
          toast.error(error.toString());
        }
      };

    return (
        <>
            <div className="">
            {openDelModal && (
            <DeleteModal
                open={openDelModal}
                onClose={() => setOpenDelModal(false)}
                onDeleteAccept={onDeleteCall}
                id={partnerShipAccountId}
                title="demo.laitlegal.uk.says"
                message="Are you sure?"
            />
            )}
                <div className="mb-5">
                    <SubHeaderTitle title="Partnership Accounts" buttonName="Partnership Account" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddPartnershipAccounts openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} partnerShipAccount={partnerShipAccountData} />
                    }
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Search Partner ShipAccount" value={searchQuery} onChange={(e: any) =>
                    handleSearchChange(e)
                } />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                        <NewRadioButtonFilter
                            title="Is Active"
                            data={isActiveStatus}
                            showSearchInput={false}
                            onFilterChange={handleRadioButtonChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden sm:rounded-lg my-5">
                    <div className="overflow-x-auto max-h-96">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="sticky top-0 text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Partner Name
                                </th>
                                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Percentage
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Is Active
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPartnerShipAccount.map((partnerShipAccount: any, index: number) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {partnerShipAccount.partnerName}
                                    </td>
                                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {partnerShipAccount.partnershipPercentage}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {partnerShipAccount.isActive === true ? "Active" : "InActive"}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setPartnerShipAccountData(partnerShipAccount) }} />
                                        <MdDelete
                                        className="fill-red-600 w-6 h-6"
                                            onClick={() => {
                                            setOpenDelModal(true);
                                            setPartnerShipAccountId(partnerShipAccount.feeTypeId);
                                            }}
                                            />
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    </div>
                    {currentPartnerShipAccount.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No PartnerShip Accounts found.</p>
                        </div>
                    )}
                </div>
                {currentPartnerShipAccount.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalRecords={filteredPartnerShipAccount.length}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { PartnershipAccounts };