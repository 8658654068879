import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
// import { FaLock } from "react-icons/fa";
// import { FaLockOpen } from "react-icons/fa";
import { addClientPayment } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/ClientPaymentSlice";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
// import { SearchCaseReference } from "../../../SearchCaseReference";
import { fetchCaseClient } from "../../../../../store/slice/Accounting";
import { NumericFormat } from "react-number-format";
import { fetchNextReceiptNumber } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { SearchCaseReferenceDetails } from "../../../SearchCaseReferenceDetails";
import { fetchClientCaseBalance } from "../../../../../store/slice/CaseManagement/Case/CaseManagementSlice";

function AddClientPayment({
  openDrawer,
  Close,
  isEdit,
  isView,
  clientPayment,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  clientPayment: any;
}) {

  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [initialLoading, setInitialLoading] = useState(true);
  //const { receiptNumber } = useSelector((state: RootState) => state.officeBankAccount);

  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);
  const { disbursementTypes } = useSelector((state: RootState) => state.disbursementType);
  const [disbursementTypeLists, setDisbursementTypeLists] = useState<any[]>([]);

  useEffect(() => {
    if (officeBanks.length > 0) {
      let data = officeBanks.map((officeBank: any, index) => ({
        value: officeBank.bankAccountId,
        label: `${officeBank.accountName}`,
      }));
      setOfficeBankLists(data);
      let findObj = officeBanks.find((x) => x.isDefaultAccount === true);
      if (findObj && findObj !== null) {

        setValue("bankAccountId", findObj?.bankAccountId)
      }
    }
  }, [officeBanks]);

  useEffect(() => {
    if (disbursementTypes.length > 0) {
      console.log(disbursementTypes);
      let data = disbursementTypes.map((disbursementType: any, index) => ({
        value: disbursementType.disbursementTypeId,
        label: `${disbursementType.disbursementType}`
        ,
      }));
      setDisbursementTypeLists(data);
      let findObj = disbursementTypes.find((x) => x.isActive === true);
      if (findObj && findObj !== null) {
        setValue("caseDisbursementId", findObj?.disbursementTypeId)
      }

    }
  }, [disbursementTypes]);

  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [dispatch(fetchNextReceiptNumber(OfficeAccountConstant.ClientPayment))];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (receiptNumber != null) {
  //     setValue("clientPaymentNumber", receiptNumber)
  //     setAutoReceiptNumber(receiptNumber)
  //   }
  // }, [receiptNumber]);



  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    clientPaymentId: number(),
    clientPaymentNumber: number(),
    bankAccountId: number().required("Office Bank Account is required"),
    ledgerCardId: string(),
    transactionDate: string().required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split('T')[0];
      }),
    entryDate: string()
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split('T')[0];
      }),
    caseDisbursementId: number(),
    description: string().required(
      "Transaction Description is required"
    ),
    amount: string().required("Amount is required"),
    comments: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      clientPaymentId: 0,
      transactionDate: new Date().toISOString().split("T")[0],
    }
  });

  useEffect(() => {
    setValue("transactionDate", new Date().toISOString().split("T")[0]);
  }, []);

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addClientPayment(data)).unwrap();
      Close("success");
      reset();
      toast.success("Successfully Added office client payment");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };


  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  useEffect(() => {
    if ((clientPayment !== null && isEdit === true && isView === false) || (clientPayment != null && isEdit === false && isView === true)) {
      setValue('clientPaymentNumber', clientPayment?.clientPaymentNumber)
      setValue('bankAccountId', (clientPayment?.fkBankAccountId))
      setValue('transactionDate', (clientPayment?.transactionDate).split("T")[0])
      setValue('caseDisbursementId', clientPayment?.fkCaseDisbursementId)
      setValue('description', clientPayment?.description)
      setValue('amount', (clientPayment?.amount))
      setValue('comments', clientPayment?.comments)
      // setValue('balance', clientPayment?.description)
      setValue('clientPaymentId', clientPayment?.clientPaymentId)
      setValue('ledgerCardId', clientPayment?.fkLedgerCardId)
      setSelectedCaseReference({ caseReferenceAuto: clientPayment.caseReferenceAuto });
      dispatch(fetchClientCaseBalance(clientPayment?.fkCaseId));
    }
  }, [clientPayment, isView, isEdit, setValue]);

  // useEffect(() => {
  //   if (isEdit && clientPayment) {
  //     setExistingClientPayment(clientPayment);
  //     reset(clientPayment);
  //   }
  // }, [clientPayment, isEdit, reset, setValue]);

  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };
  const handleCaseSelection = (selectedCase: any) => {
    setSelectedCaseReference(selectedCase);
    setValue("ledgerCardId", selectedCase.caseId);
    dispatch(fetchCaseClient(selectedCase.caseId));
    setOpenCaseAccountModal(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-12">
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <div className="relative flex">
            <input
              type="text"
              // className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300"
              placeholder={selectedCaseReference?.caseReferenceAuto}
              aria-label="Search"
              value={selectedCaseReference?.caseReferenceAuto || ""}
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.ledgerCardId ? 'is-invalid' : ''}`} {...register('ledgerCardId')}

            // disabled
            />
            <button
              onClick={toggleCaseAccountModal}
              className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-lg 3xl:text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
              data-twe-ripple-init
              data-twe-ripple-color="white"
              type="button"
            >
              Search
            </button>
            {openCaseAccountModal &&
              <SearchCaseReferenceDetails
                openDrawer={openCaseAccountModal}
                Close={toggleCaseAccountModal}
                onCaseSelectDetail={handleCaseSelection}
              />
            }
          </div>
          {!isView && (
            <div>
            <button
              type="button"
              onClick={() => {
                Close();
                reset();
              }}
              className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          )}
        </div>
        <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
          {/* <div>
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Payment Number
            </label>
            <div className="flex gap-3">
              <input
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("clientPaymentNumber")}
                disabled={isDisabled}
              />
              <button
                type="button"
                onClick={handleEditClick}
                className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
              >
                {isDisabled ? <FaLock /> : <FaLockOpen />}
              </button>
            </div>
          </div> */}
          <div className="">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Office Bank Account <span className="text-red-500">*</span>
            </label>
            <SelectBox
              className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              isSearchable={true}
              options={officeBankLists}
              value={getValues().bankAccountId}
              placeholder="Select"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setValue("bankAccountId", selectedOption);
                }
              }}
            />
            <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
              {errors?.bankAccountId?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Transaction Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? "is-invalid" : ""
                }`}
              {...register("transactionDate")}

              placeholder="Enter Transaction Date"
            />
            <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
              {errors?.transactionDate?.message?.toString()}
            </div>
          </div>
          {/* <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Entry Date
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("entryDate")}
              max={new Date().toISOString().split('T')[0]}
              placeholder="Enter Date"
            />
          </div> */}
          <div className="">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Disbursment
            </label>
            <SelectBox
              className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              isSearchable={true}
              options={disbursementTypeLists}
              value={getValues().caseDisbursementId}
              placeholder="Select"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setValue("caseDisbursementId", selectedOption);
                }
              }}
            />
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Transaction Description <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.description ? "is-invalid" : ""
                }`}
              {...register("description")}
              placeholder="Enter Transaction Description"
            />
            <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
              {errors?.description?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Amount (£) <span className="text-red-500">*</span>
            </label>
            <NumericFormat decimalScale={2}
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amount ? "is-invalid" : ""
                }`}
              {...register("amount")}
              value={getValues().amount}
              //placeholder="0.00"
              onValueChange={(values) => {
                setValue('amount', (values.value))
              }}
            />
            <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
              {errors?.amount?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Comments
            </label>
            <textarea
              className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              placeholder="Comments"
              {...register("comments")}
            ></textarea>
          </div>
        </div>
        {!isView && (
        <div className="flex col-span-2 gap-2 justify-end my-5">
          <button
            type="button"
            onClick={() => {
              Close();
            }}
            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            ) : (
              'Save'
            )}
          </button>


        </div>
        )}
      </form>
    </>
  );
}

export { AddClientPayment };
