import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeJournalEntryService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/OfficeJournalEntryService";

export interface OfficeJournalEntry {
  journalEntryId?: number;
  journalEntryNumber: number;
  journalEntryDescription: string;
  journalEntryDate: string;
  journalEntryLines: JournalEntryLines[];
}


export interface JournalEntryLines {
  accountId: number;
  description: string;
  debitAmount: number;
  creditAmount: number;
}
export interface SearchOfficeJournalEntryResult {
  listingData: any[];
  currentPage: number;
  page: number;
  totalRows: number;

};

// export interface OfficeJournalEntrieData {
//   listingData: [];
//   page: number;
//   totalRows: number;
// }

// Define the state type for Checklist
type OfficeJournalEntryState = {
  OfficeJournalEntries: OfficeJournalEntry[];
  searchOfficeJournalEntryResult: SearchOfficeJournalEntryResult;
  // officeJournalEntrieData: OfficeJournalEntrieData;
  status: "idle" | "loading" | "failed";
  error: string | null;
  OfficejournalEntryDataById: any | null;
};

// Initial state
const initialState: OfficeJournalEntryState = {
  searchOfficeJournalEntryResult: null!,
  OfficeJournalEntries: [],
  // officeJournalEntrieData: null!,
  status: "idle",
  error: null,
  OfficejournalEntryDataById: null,
};

// Async thunks for CRUD operations
export const fetchAllOfficeJournalEntry = createAsyncThunk(
  "fetchAllOfficeJournalEntry",
  async (data: any, thunkAPI) => {
    try {
      const response =
        await OfficeJournalEntryService.GetAllOfficeJournalEntry(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchOfficeJournalEntryById = createAsyncThunk(
  "fetchOfficeJournalEntryById",
  async (id: number, thunkAPI) => {
    try {
      const response = await OfficeJournalEntryService.GetOfficeJournalEntryById(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const addOfficeJournalEntry = createAsyncThunk(
  "addOfficeJournalEntry",
  async (data: any, thunkAPI) => {
    try {
      const response = await OfficeJournalEntryService.PostOfficeJournalEntry(
        data
      );
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateOfficeJournalEntry = createAsyncThunk(
  "updateOfficeJournalEntry",
  async ({ id, data }: { id: number; data: OfficeJournalEntry }, thunkAPI) => {
    try {
      const response = await OfficeJournalEntryService.PutOfficeJournalEntry(
        id,
        data
      );
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteOfficeJournalEntry = createAsyncThunk(
  "deleteOfficeJournalEntry",
  async (id: number, thunkAPI) => {
    try {
      await OfficeJournalEntryService.deleteOfficeJournalEntry(id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice definition
const OfficeJournalEntrySlice = createSlice({
  name: "officeJournalEntry",
  initialState,
  reducers: {
    clearJournalEntry: (state) => {
      state.OfficejournalEntryDataById = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOfficeJournalEntry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchAllOfficeJournalEntry.fulfilled,
        (state, action: PayloadAction<SearchOfficeJournalEntryResult>) => {
          state.status = "idle";
          state.searchOfficeJournalEntryResult = action.payload;
        }
      )
      .addCase(fetchAllOfficeJournalEntry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchOfficeJournalEntryById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOfficeJournalEntryById.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "idle";
        state.OfficejournalEntryDataById = action.payload;
      }
      )
      .addCase(fetchOfficeJournalEntryById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(addOfficeJournalEntry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        addOfficeJournalEntry.fulfilled,
        (state, action: PayloadAction<OfficeJournalEntry>) => {
          state.status = "idle";
          state.OfficeJournalEntries.push(action.payload);
        }
      )
      .addCase(addOfficeJournalEntry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateOfficeJournalEntry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updateOfficeJournalEntry.fulfilled,
        (state, action: PayloadAction<OfficeJournalEntry>) => {
          state.status = "idle";
          const index = state.OfficeJournalEntries.findIndex(
            (t) => t.journalEntryId === action.payload.journalEntryId
          );
          if (index !== -1) {
            state.OfficeJournalEntries[index] = action.payload;
          }
        }
      )
      .addCase(updateOfficeJournalEntry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteOfficeJournalEntry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        deleteOfficeJournalEntry.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.status = "idle";
          state.OfficeJournalEntries = state.OfficeJournalEntries.filter(
            (t) => t.journalEntryId !== action.payload
          );
        }
      )
      .addCase(deleteOfficeJournalEntry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { clearJournalEntry } = OfficeJournalEntrySlice.actions;
export default OfficeJournalEntrySlice.reducer;
