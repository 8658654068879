import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ClientService from "../../../services/CaseManagement/ClientService";

export interface Client {
    clientType: string;
    clientPersonal: IndividualClient | null;
    clientCompany: CompanyClient | null;
    clientOrganisation: OrgClient | null;
};

export type IndividualClient = {
    fkTitleId: number;
    givenNames: string;
    lastName: string;
    sex: string;
    fkMaritalStatusId: number;
    nationality: string;
    dob: string;
    countryOfBirth: string;
    placeOfBirth: string;
    email: string;
    email2: string;
    indNiNumber: string;

    isPreferredPhone: string;
    mobilePhone: string;
    homePhone: string;
    otherPhone: string;

    currentAddLine1: string;
    currentAddLine2: string;
    currentAddCity: string;
    currentAddCounty: string;
    currentAddPostCode: string;

    jobTitle: string;
    comapnyName: string;
    compAddLine1: string;
    compAddLine2: string;
    compAddCity: string;
    compAddCounty: string;
    compAddPostCode: string;
    compAddEmail: string;
    compAddOfficePhone: string;
    compAddFax: string;

    isDisability: boolean;
    disablityDescription: string;
    notes: string;

    isIndFutureAddress: boolean;
    futureAddLine1: string;
    futureAddLine2: string;
    futureAddCity: string;
    futureAddCounty: string;
    futureAddPostCode: string;
    futureAddEffectFrom: string;
    futureAddIsPreferred: boolean;

    indIsActive: boolean;
}

export type CompanyClient = {
    companyName: string;
    companyNature: string;
    compEmailAddress: string;
    compFax: string;
    compWebsite: string;
    companyVat: string;
    compUtrn: string;
    compVatRegNo: string;
    compContactPhone1: string;
    compContactPhone2: string;
    compContactFax: string;

    compCurrentAddLine1: string;
    compCurrentAddLine2: string;
    compCurrentAddCity: string;
    compCurrentAddCounty: string;
    compCurrentAddPostCode: string;
    compCurrentAddDxNumber: string;
    compCurrentAddDxExchange: string;
    compCurrentAddPoboxNumber: string;
    compCurrentAddPoboxCounty: string;
    compCurrentAddPoboxTown: string;
    compCurrentAddPoboxPostCode: string;


    compContact1Title: string;
    compContact1GivenNames: string;
    compContact1LastName: string;
    compContact1JobTitle: string;
    compContact1Email: string;
    compContact1DirectLine: string;
    compContact1Extension: string;
    compContact1OfficePhone: string;
    compContact1Mobile: string;

    compContact2Title: string;
    compContact2GivenNames: string;
    compContact2LastName: string;
    compContact2JobTitle: string;
    compContact2Email: string;
    compContact2DirectLine: string;
    compContact2Extension: string;
    compContact2OfficePhone: string;
    compContact2Mobile: string;


    compIncludeCompanyName: string;
    compIncludeJobTitle: string;
    compNotes: string;


    isCompFutureAddress: boolean;
    compFutureAddLine1: string;
    compFutureAddLine2: string;
    compFutureAddCity: string;
    compFutureAddCounty: string;
    compFutureAddPostCode: string;
    compFutureAddDxNumber: string;
    compFutureAddDxExchange: string;
    compFutureAddPoboxNumber: string;
    compFutureAddPoboxCounty: string;
    compFutureAddPoboxTown: string;
    compFutureAddPoboxPostCode: string;
    compFutureAddEffectFrom: string;
    compFutureAddIsPreferred: boolean;

    compIsActive: boolean


}

export type OrgClient = {
    orgName: string;
    orgNature: string;
    orgEmail: string;
    orgFax: string;
    orgWebsite: string;
    orgSwitchboardPhone: string;
    orgPreferredPhone: string;
    orgDepartmentPhone: string;
    orgOtherPhone: string;

    orgCurrentAddLine1: string;
    orgCurrentAddLine2: string;
    orgCurrentAddCity: string;
    orgCurrentAddCounty: string;
    orgCurrentAddPostCode: string;
    orgCurrentAddDxNumber: string;
    orgCurrentAddDxExchange: string;
    orgCurrentAddPoboxNumber: string;
    orgCurrentAddPoboxCounty: string;
    orgCurrentAddPoboxTown: string;
    orgCurrentAddPoboxPostCode: string;

    isOrgFutureAddress: boolean;
    orgFutureAddLine1: string;
    orgFutureAddLine2: string;
    orgFutureAddCity: string;
    orgFutureAddCounty: string;
    orgFutureAddPostCode: string;
    orgFutureAddDxNumber: string;
    orgFutureAddDxExchange: string;
    orgFutureAddPoboxNumber: string;
    orgFutureAddPoboxCounty: string;
    orgFutureAddPoboxTown: string;
    orgFutureAddPoboxPostCode: string;
    orgFutureAddEffectFrom: string;
    orgFutureAddIsPreferred: boolean;

    contact1Title: number;
    contact1GivenNames: string;
    contact1LastName: string;
    contact1JobTitle: string;
    contact1Email: string;
    contact1DirectLine: string;
    contact1Extension: string;
    contact1OfficePhone: string;
    contact1Mobile: string;

    contact2Title: string;
    contact2GivenNames: string;
    contact2LastName: string;
    contact2JobTitle: string;
    contact2Email: string;
    contact2DirectLine: string;
    contact2Extension: string;
    contact2OfficePhone: string;
    contact2Mobile: string;

    includeCompanyName: boolean;
    includeJobTitle: boolean;
    orgNotes: string;
    orgIsActive: boolean;
}

export interface ClientDetailsList {
    clientDetailsList: any[];
    totalRecords: number;
}

export interface ClientByIdData {
    clientCompanyId: number;
    clientId: number;
    clientType: string;
    comapnyName: string;
    compAddCity: string;
    compAddCounty: string;
    compAddEmail: string;
    compAddFax: string;
    compAddLine1: string;
    compAddLine2: string;
    compAddOfficePhone: string;
    compAddPostCode: string;
    compContact1DirectLine: string | null;
    compContact1Email: string | null;
    compContact1Extension: string | null;
    compContact1GivenNames: string | null;
    compContact1JobTitle: string | null;
    compContact1LastName: string | null;
    compContact1Mobile: string | null;
    compContact1OfficePhone: string | null;
    compContact1Title: number;
    compContact2DirectLine: string | null;
    compContact2Email: string | null;
    compContact2Extension: string | null;
    compContact2GivenNames: string | null;
    compContact2JobTitle: string | null;
    compContact2LastName: string | null;
    compContact2Mobile: string | null;
    compContact2OfficePhone: string | null;
    compContact2Title: number;
    compContactFax: string | null;
    compContactPhone1: string | null;
    compContactPhone2: string | null;
    compCurrentAddCity: string | null;
    compCurrentAddCounty: string | null;
    compCurrentAddDxExchange: string | null;
    compCurrentAddDxNumber: string | null;
    compCurrentAddLine1: string | null;
    compCurrentAddLine2: string | null;
    compCurrentAddPoboxCounty: string | null;
    compCurrentAddPoboxNumber: string | null;
    compCurrentAddPoboxPostCode: string | null;
    compCurrentAddPoboxTown: string | null;
    compCurrentAddPostCode: string | null;
    compEmailAddress: string | null;
    compFax: string | null;
    compFutureAddCity: string | null;
    compFutureAddCounty: string | null;
    compFutureAddDxExchange: string | null;
    compFutureAddDxNumber: string | null;
    compFutureAddEffectFrom: string | null;
    compFutureAddIsPreferred: string | null;
    compFutureAddLine1: string | null;
    compFutureAddLine2: string | null;
    compFutureAddPoboxCounty: string | null;
    compFutureAddPoboxNumber: string | null;
    compFutureAddPoboxPostCode: string | null;
    compFutureAddPoboxTown: string | null;
    compFutureAddPostCode: string | null;
    compIncludeCompanyName: string | null;
    compIncludeJobTitle: string | null;
    compIsActive: boolean | null;
    compNotes: string | null;
    compUtrn: string | null;
    compVatRegNo: string | null;
    compWebsite: string | null;
    companyName: string | null;
    companyNature: string | null;
    contact1DirectLine: string | null;
    contact1Email: string | null;
    contact1Extension: string | null;
    contact1GivenNames: string | null;
    contact1JobTitle: string | null;
    contact1LastName: string | null;
    contact1Mobile: string | null;
    contact1OfficePhone: string | null;
    contact1Title: number;
    contact2DirectLine: string | null;
    contact2Email: string | null;
    contact2Extension: string | null;
    contact2GivenNames: string | null;
    contact2JobTitle: string | null;
    contact2LastName: string | null;
    contact2Mobile: string | null;
    contact2OfficePhone: string | null;
    contact2Title: number;
    countryOfBirth: number;
    currentAddCity: string;
    currentAddCounty: string;
    currentAddDxExchange: string | null;
    currentAddDxNumber: string | null;
    currentAddLine1: string;
    currentAddLine2: string;
    currentAddPoboxCounty: string | null;
    currentAddPoboxNumber: string | null;
    currentAddPoboxPostCode: string | null;
    currentAddPoboxTown: string | null;
    currentAddPostCode: string;
    dateTimeCreated: string;
    disablityDescription: string | null;
    dob: string | null;
    email: string;
    email2: string;
    fkBranchId: number;
    fkClientId: number;
    fkMaritalStatusId: number;
    fkTitleId: number;
    fkUserId: number;
    futureAddCity: string | null;
    futureAddCounty: string | null;
    futureAddDxExchange: string | null;
    futureAddDxNumber: string | null;
    futureAddEffectFrom: string | null;
    futureAddIsPreferred: string | null;
    futureAddLine1: string | null;
    futureAddLine2: string | null;
    futureAddPoboxCounty: string | null;
    futureAddPoboxNumber: string | null;
    futureAddPoboxPostCode: string | null;
    futureAddPoboxTown: string | null;
    futureAddPostCode: string | null;
    givenNames: string;
    homePhone: string;
    includeCompanyName: string | null;
    includeJobTitle: string | null;
    indIsActive: boolean;
    indNiNumber: string;
    individualClientId: number;
    isActive: boolean;
    isArchived: boolean;
    isCompFutureAddress: boolean | null;
    isDisability: boolean | null;
    isIndFutureAddress: boolean | null;
    isOrgFutureAddress: boolean | null;
    isPreferredPhone: string;
    jobTitle: string;
    lastName: string;
    mobilePhone: string;
    nationality: number;
    notes: string;
    orgCurrentAddCity: string | null;
    orgCurrentAddCounty: string | null;
    orgCurrentAddDxExchange: string | null;
    orgCurrentAddDxNumber: string | null;
    orgCurrentAddLine1: string | null;
    orgCurrentAddLine2: string | null;
    orgCurrentAddPoboxCounty: string | null;
    orgCurrentAddPoboxNumber: string | null;
    orgCurrentAddPoboxPostCode: string | null;
    orgCurrentAddPoboxTown: string | null;
    orgCurrentAddPostCode: string | null;
    orgDepartmentPhone: string | null;
    orgEmail: string | null;
    orgFax: string | null;
    orgFutureAddCity: string | null;
    orgFutureAddCounty: string | null;
    orgFutureAddDxExchange: string | null;
    orgFutureAddDxNumber: string | null;
    orgFutureAddEffectFrom: string | null;
    orgFutureAddIsPreferred: string | null;
    orgFutureAddLine1: string | null;
    orgFutureAddLine2: string | null;
    orgFutureAddPoboxCounty: string | null;
    orgFutureAddPoboxNumber: string | null;
    orgFutureAddPoboxPostCode: string | null;
    orgFutureAddPoboxTown: string | null;
    orgFutureAddPostCode: string | null;
    orgIsActive: boolean | null;
    orgName: string | null;
    orgNature: string | null;
    orgNotes: string | null;
    orgOtherPhone: string | null;
    orgPreferredPhone: string | null;
    orgSwitchboardPhone: string | null;
    orgWebsite: string | null;
    organisationClientId: number;
    otherPhone: string;
    placeOfBirth: string;
    preferredAddress: string | null;
    sex: boolean;
    title: string;

    companyEmail: string;
    companyFax: string;
    companyWeb: string;
    companyVat: string;
    companyUtrn: string;
    companyRegNo: string;
    companyContactPhone1: string;
    companyContactPhone2: string;
    companyContactFax: string;
    companyNotes: string;
    companyContact1Title?: number;
    companyContact1GivenNames?: string;
    companyContact1LastName?: string;
    companyContact1JobTitle?: string;
    companyContact1Email?: string;
    companyContact1DirectLine?: string;
    companyContact1Extension?: string;
    companyContact1OfficePhone?: string;
    companyContact1Mobile?: string;

    companyContact2Title?: number;
    companyContact2GivenNames?: string;
    companyContact2LastName?: string;
    companyContact2JobTitle?: string;
    companyContact2Email?: string;
    companyContact2DirectLine?: string;
    companyContact2Extension?: string;
    companyContact2OfficePhone?: string;
    companyContact2Mobile?: string;

    companyAddressLine1: string;
    companyAddressLine2?: string;
    companyCity?: string;
    companyCounty?: string;
    companyPostcode?: string;
    companyPoboxNumber?: string;
    companyPoboxCounty?: string;
    companyPoboxTown?: string;
    companyPoboxPostcode?: string;   


}

// Define the state type for Client
type ClientsState = {
    clients: Client[];
    newClientId: number | null;
    clientByIdData: ClientByIdData | null;
    clientDetailsLists: ClientDetailsList;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ClientsState = {
    newClientId: null!,
    clients: [],
    clientByIdData: null!,
    clientDetailsLists: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllClients = createAsyncThunk(
    "fetchAllClients",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientService.GetAllClients(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchClientById = createAsyncThunk(
    "fetchClientById",
    async (id: number, thunkAPI) => {
        try {
            const response = await ClientService.GetClientById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addClient = createAsyncThunk(
    "addClient",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientService.PostClient(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateClient = createAsyncThunk(
    "updateClient",
    async ({ id, data }: { id: number, data: Client }, thunkAPI) => {
        try {
            const response = await ClientService.PutClient(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteClient = createAsyncThunk(
    "deleteClient",
    async (clientId: number, thunkAPI) => {
        try {
            await ClientService.deleteClient(clientId);
            return clientId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        clearClientById: (state) => {
            state.clientByIdData = null;
          },
        clearClientId: (state) => {
            state.newClientId = null;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllClients.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllClients.fulfilled, (state, action: PayloadAction<ClientDetailsList>) => {
                state.status = "idle";
                state.clientDetailsLists = action.payload;
            })
            .addCase(fetchAllClients.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchClientById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchClientById.fulfilled, (state, action: PayloadAction<ClientByIdData>) => {
                state.status = "idle";
                state.clientByIdData = action.payload;
            })
            .addCase(fetchClientById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addClient.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.newClientId = action.payload;
            })
            .addCase(addClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateClient.fulfilled, (state, action: PayloadAction<Client>) => {
                state.status = "idle";
                // const index = state.clients.findIndex(t => t.id === action.payload.id);
                // if (index !== -1) {
                //     state.clients[index] = action.payload;
                // }
            })
            .addCase(updateClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteClient.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteClient.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                // state.clients = state.clients.filter(t => t.id !== action.payload);
            })
            .addCase(deleteClient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});
export const { clearClientById } = clientSlice.actions;
export const { clearClientId } = clientSlice.actions;
export default clientSlice.reducer;
