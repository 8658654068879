import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchAuditTrailDetails } from "../../../store/slice/Accounting/OfficeAccounts/OfficeReports/AuditTrailSlice";
import { formatDateOnlyDisplay } from "../../../utils/date-time.utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";


function AuditView({
    openCaseDrawer,
    auditData
}: {
    openCaseDrawer: boolean;
    auditData?: any;
}) {
    const dispatch = useAppDispatch();
    const effectServiceCall = useRef(false);
    const auditTrailDetails = useSelector((state: RootState) => state.auditTrail.auditTrailDetails);
    const [auditTrailItems, setAuditTrailItems] = useState<any[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    // useEffect(() => {
    //     if (auditData !== null) {
    //         console.log(auditData);
    //     }
    // }, [auditData]);

    // useEffect(() => {
    //     if (!effectServiceCall.current && (auditData !== null && auditData.transactionId)) {
    //         dispatch(fetchAuditTrailDetails(Number(auditData.transactionId)))
    //         effectServiceCall.current = true;
    //     }
    // }, [dispatch]);

    useEffect(() => {
        if (!effectServiceCall.current) {
            if (!effectServiceCall.current && (auditData !== null && auditData.transactionId)) {
                // var id = Number(auditData.transactionId)
                dispatch(fetchAuditTrailDetails(auditData.transactionId))
                    .then(() => setInitialLoading(false))
                    .catch(() => setInitialLoading(false));
                effectServiceCall.current = true;
            }

        }
    }, [dispatch]);

    useEffect(() => {
        if (auditTrailDetails !== null && auditTrailDetails.length > 0) {
            setAuditTrailItems(auditTrailDetails);
        }
    })
    return (
        <>
            <div className="flex flex-col gap-9 px-5">
                <div>
                <button className="w-36 py-3 text-xs px-3 font-medium text-white-bg bg-hover_bg  rounded hover:bg-green-600"
                // onClick={() => {
                // }}
                >
                    View Transaction
                </button>
                </div>
                {initialLoading ? <MainAnimation /> : ""}
                {auditTrailItems.map((auditTrail: any, index) => (
                    <div className={`grid md:inline-block grid-cols-2 md:grid-cols-1 gap-y-3 gap-x-5 md:space-y-5  dark:text-white-bg p-6 rounded-b-md ${auditTrail?.transactionType === 'Add'
                        ? 'bg-green-200'
                        : auditTrail?.transactionType === 'Edit'
                            ? 'bg-blue-200'
                            : auditTrail?.transactionType === 'Delete'
                                ? 'bg-red-200'
                                : ''
                        }`}>
                        <div className="flex items-center">
                            <label className="w-1/4 uppercase text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                Name
                            </label>
                            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                :
                            </span>
                            <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">{auditTrail?.firstName} {" "}{auditTrail?.lastName}</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/4 uppercase text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                Account
                            </label>
                            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                :
                            </span>
                            <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">
                                {auditTrail?.accTransactionType}
                            </p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/4 uppercase text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                Account Type
                            </label>
                            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                :
                            </span>
                            <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">
                                {auditTrail?.transactionSubType}
                            </p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/4 uppercase text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                Transaction Amount
                            </label>
                            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                :
                            </span>
                            <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">
                                £ {auditTrail?.transactionAmount < 0
                                    ? `(${Math.abs(auditTrail.transactionAmount)}.00)`
                                    : `${auditTrail?.transactionAmount}.00`}
                            </p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/4 uppercase text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                Date
                            </label>
                            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                                :
                            </span>
                            <p className="w-3/4 text-lg 3xl:text-sm dark:text-white-bg">
                                {formatDateOnlyDisplay(auditTrail?.transactionDate)}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export { AuditView };
