import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { boolean, number, object, string } from "yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useRef } from "react";
import { fetchAllRole } from "../../../store/slice/Administrator/RoleManagementSlice";


function AddRoleStepOne({ onSubmitStep, initialData, onCancel }: any) {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { roles } = useSelector((state: RootState) => state.role);
    const roleById = useSelector((state: RootState) => state.role.roleById);


    const validationSchema = object().shape({
        userRoleName: string()
            .required('User Role Name is required'),
        // rank: number()
        //     .typeError('Rank must be a number')
        //     .required('Rank is required')
        //     .positive('Rank must be a positive number')
        //     .integer('Rank must be an integer'),
        description: string(),
        isAreasOfPracticeInvolved: boolean(),

    })

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
            isAreasOfPracticeInvolved: true
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllRole());
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);

    useEffect(() => {
        const id = searchParams.get("id");
        if (id != null && roles) {
            const roleUpdate = roles.find((role: any) => role.userRoleId === Number(id));
            if (roleUpdate) {
                reset(roleUpdate);
            } else {
                console.warn(`roles with ID ${id} not found.`);
            }
        }
    }, [searchParams, setValue, roles]);

    useEffect(() => {
        if (roleById !== null) {
            setValue("userRoleName", roleById?.userRoleName);
            setValue("description", roleById?.description);
            setValue("isAreasOfPracticeInvolved", roleById?.isAreasOfPracticeInvolved);
        }
    }, [roleById])


    return (
        <form onSubmit={handleSubmit(handleFormSubmit,onError)}>
            <div>
                <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg mb-5">Role Details</h3>
                <div className="grid grid-cols-3 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            User Role Name <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.userRoleName ? 'is-invalid' : ''}`} {...register('userRoleName')} />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.userRoleName?.message?.toString()}</div>
                    </div>
                    {/* <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Rank <span className="text-red-500">*</span>
                        </label>
                        <input type="number" className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.rank ? 'is-invalid' : ''}`} {...register('rank')} />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-left text-red-600" >{errors?.rank?.message?.toString()}</div>
                    </div> */}

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Role Description
                        </label>
                        <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `}
                            {...register('description')} />
                    </div>
                    <div className="flex flex-row space-x-2">
                        <label className="text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">Is Areas of Practice Involved?</label>
                        <div>
                            <input
                                type="radio"
                                value="true"
                                checked={watch("isAreasOfPracticeInvolved") === true}
                                onChange={() => setValue("isAreasOfPracticeInvolved", true)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                value="false"
                                checked={watch("isAreasOfPracticeInvolved") === false}
                                onChange={() => setValue("isAreasOfPracticeInvolved", false)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                        </div>

                    </div>
                </div>
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button" onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddRoleStepOne };
