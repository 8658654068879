import React, { useRef, useState } from "react";
import useOutsideClick from "../../Dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { DatePickerWrapper } from "../../InputComponents/DatePickerWrapper";

interface JustifyDateFilterProps {
  title: string;
  plannedStartDate: string | null;
  plannedEndDate: string | null;
  onPlannedStartDateChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onPlannedEndDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const JustifyDateFilter: React.FC<JustifyDateFilterProps> = ({
  title,
  plannedStartDate,
  plannedEndDate,
  onPlannedStartDateChange,
  onPlannedEndDateChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  return (
    <div
      className={`relative dark:bg-gray-900 rounded ${
        // isOpen
        //   ? "text-hover_bg bg-hover_bg bg-opacity-15 border border-hover_bg dark:bg-gray-900 rounded"
        //   : "bg-hover_bg bg-opacity-15 border border-hover_bg border-opacity-15 dark:bg-gray-900 rounded "
        isOpen
          ? "bg-gray-100  border border-blue-500 focus:ring-2 focus:ring-blue-600 dark:bg-gray-900 rounded"
          : "bg-gray-100 border-2 border-gray-500 border-opacity-20 dark:bg-gray-900 rounded "
      }`}
      ref={dropdownRef}
    >
      {/* Dropdown Header */}
      <div
        className="flex flex-row md:flex-cols cursor-pointer items-center gap-1 p-2 dark:text-gray-300 fill-hover_bg transition dark:border dark:border-gray-400 dark:border-opacity-35 rounded"
        onClick={toggleDropdown}
      >
        {/* <FaCalendarAlt className={`${isOpen ? "fill-hover_bg" : ""}`} /> */}
        <span className="text-lg 3xl:text-sm w-40 3xl:w-32 lg:w-44">
          {title}
        </span>
        <span className={`transition ${isOpen ? "rotate-180" : ""}`}>
          <IoIosArrowDown className="h-4 w-4" />
        </span>
      </div>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="z-50 p-4 absolute end-0 top-full mt-2 bg-white-bg dark:bg-gray-900 w-fit rounded border border-gray-200 dark:border-opacity-35 shadow">
          <div date-rangepicker="true" className="flex items-center end-0">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                name="plannedStartDate"
                value={plannedStartDate || ""}
                onChange={onPlannedStartDateChange}
                type="date"
                className="bg-gray-50 border border-gray-300 text-gray-400 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select start date"
              />
            </div>
            <span className="mx-4 text-gray-500">to</span>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>

              <input
                name="plannedEndDate"
                value={plannedEndDate || ""}
                onChange={onPlannedEndDateChange}
                type="date"
                className="bg-gray-50 border border-gray-300 text-gray-400 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select end date"
              />
            </div>
          </div>
        </div>
      )}
    </div>

// <div 
//   className={`relative border rounded border-solid border-blue-500 ${
//     isOpen
//       ? "text-blue-600 bg-blue-50"
//       : "bg-blue-50"
//   }`}
//   ref={dropdownRef}
// >
//   {/* Dropdown Header */}
//   <div
//     className="flex flex-row md:flex-cols cursor-pointer items-center gap-1 p-1.5 text-blue-600 fill-blue-600 transition rounded"
//     onClick={toggleDropdown}
//   >
//     <span className="text-lg 3xl:text-sm w-40 3xl:w-32 lg:w-44">{title}</span>
//     <span className={`transition ${isOpen ? "rotate-180" : ""}`}>
//       <IoIosArrowDown className="h-4 w-4" />
//     </span>
//   </div>

//   {/* Dropdown Content */}
//   {isOpen && (
//     <div className="z-50 p-4 absolute end-0 top-full mt-2 w-fit bg-white-bg rounded border border-blue-500">
//       <div date-rangepicker="true" className="flex items-center end-0">
//         <div className="relative">
//           <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//             <svg
//               className="w-4 h-4 text-gray-400"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 20 20"
//             >
//               <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
//             </svg>
//           </div>
//           <input
//             name="plannedStartDate"
//             value={plannedStartDate || ""}
//             onChange={onPlannedStartDateChange}
//             type="date"
//             className="bg-blue-50 border border-blue-500 text-gray-400 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
//             placeholder="Select start date"
//           />
//         </div>
//         <span className="mx-4 text-gray-400">to</span>
//         <div className="relative">
//           <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
//             <svg
//               className="size-4 text-gray-400"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 20 20"
//             >
//               <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
//             </svg>
//           </div>
//           <input
//             name="plannedEndDate"
//             value={plannedEndDate || ""}
//             onChange={onPlannedEndDateChange}
//             type="date"
//             className="bg-blue-50 border border-blue-500 text-gray-400 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
//             placeholder="Select end date"
//           />
//         </div>
//       </div>
//     </div>
//   )}
// </div>
  );
};

export { JustifyDateFilter };
