import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { number, object, string } from "yup";
import { RootState } from "../../../store/store";
import { fetchUsersDataByBranch } from "../../../store/slice/Administrator/UserManagementSlice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SelectBox } from "../../SelectBox";
import { fetchAllRiskTypeStatus } from "../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { useParams } from "react-router-dom";
import { Heading } from "../../AccountSetupHeading/Heading";
import { ConflictSearch } from "../../../store/model/RiskAssessment/ConflictSearch/conflictSearch";
import { fetchAllConflictSearch } from "../../../store/slice/RiskManagement/ConflictSearch/ConflictSearchSlice";
import { toast } from "react-toastify";


function AddCaseConflictSearch({ toggleDrawer }: { toggleDrawer: any }) {
    const { referenceNumber } = useParams();
    const [selectedRiskType, setSelectedRiskType] = useState('na');
    const usersDataByBranch = useSelector((state: RootState) => state.userManage.branchUsers);
    const riskTypeStatus = useSelector((state: RootState) => state.case.riskTypeStatus);
    const { conflictSearch } = useSelector((state: RootState) => state.conflictSearch);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [branchUserOptionsLists, setBranchUserOptionsLists] = useState<any[]>([]);
    const [statusOptionsLists, setStatusOptionsLists] = useState<any[]>([]);

    const [keyword, setKeyword] = useState<string>('');
    const [clientSelection, setClientSelection] = useState<string[]>([]);
    const [contactSelection, setContactSelection] = useState<string[]>([]);
    const [caseSelection, setCaseSelection] = useState<string[]>([]);
    const [conflictSelectData, setConflictSelectData] = useState<any[]>([]);
    const [searchClientTypes, setSearchClientTypes] = useState<any>();
    const [searchContactTypes, setSearchContactTypes] = useState<any>();
    const [searchCaseTypes, setSearchCaseTypes] = useState<any>();
    const allClientOptions = ['name', 'email', 'address'];
    const allContactOptions = ['name', 'email', 'address'];
    const allCaseOptions = ['name', 'details', 'notes', 'address'];


    const handleCheckboxChange = (type: string, value: string, isChecked: boolean) => {
        if (type === 'client') {
            setSearchClientTypes(type);
            setClientSelection(isChecked
                ? [...clientSelection, value]
                : clientSelection.filter(item => item !== value));
        } else if (type === 'contact') {
            setSearchContactTypes(type);
            setContactSelection(isChecked
                ? [...contactSelection, value]
                : contactSelection.filter(item => item !== value));
        } else if (type === 'case') {
            setSearchCaseTypes(type);
            setCaseSelection(isChecked
                ? [...caseSelection, value]
                : caseSelection.filter(item => item !== value));
        }
    };

    const handleSelectAll = (type: string) => {
        if (type === 'client') {
            setClientSelection(clientSelection.length === allClientOptions.length
                ? []
                : allClientOptions);
        } else if (type === 'contact') {
            setContactSelection(contactSelection.length === allContactOptions.length
                ? []
                : allContactOptions);
        } else if (type === 'case') {
            setCaseSelection(caseSelection.length === allCaseOptions.length
                ? []
                : allCaseOptions);
        }
    };


    const handleSearch = async () => {
        if (keyword === '') {
            alert('Please enter conflict search keyword');
            return;
        }

        if (!clientSelection.length && !contactSelection.length && !caseSelection.length) {
            alert('Please select at least one search parameter of Client, Contact, or Case');
            return;
        }

        let requestBody: ConflictSearch = {
            keyWord: keyword,
            contactList: contactSelection,
            clientList: clientSelection,
            caseList: caseSelection,
            caseId: referenceNumber
        }
        console.log(JSON.stringify(requestBody, null, 2));
        setIsLoading(true);
        try {
            await dispatch(fetchAllConflictSearch(requestBody));
            toast.success("Successfully Get Data");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectConflictData = (conflic: any, isChecked: boolean) => {
        if (isChecked) {
            setConflictSelectData((prev) => [...prev, conflic]);
        } else {
            setConflictSelectData((prev) =>
                prev.filter((item) => item.caseReferenceAuto !== conflic.caseReferenceAuto)
            );
        }
    };


    const riskType = [
        { label: 'Low', value: 'Low' },
        { label: 'Medium', value: 'Medium' },
        { label: 'High', value: 'High' }
    ];

    const riskTypeOptionList = riskType.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    const effectServiceCall = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchUsersDataByBranch());
                await dispatch(fetchAllRiskTypeStatus());
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setInitialLoading(false);
            }
        };

        if (!effectServiceCall.current) {
            fetchData();
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (usersDataByBranch != null) {
            const data = usersDataByBranch.map((branchUser: any) => ({
                value: branchUser.userId,
                label: branchUser.firstName + " " + branchUser.lastName + "(" + branchUser.designation + ")",
            }));
            setBranchUserOptionsLists(data);
        }

        if (riskTypeStatus != null) {
            const dataList = riskTypeStatus.map((status: any) => ({
                value: status.riskAssessmentStatusTypeId,
                label: status.riskAssessmentStatus,
            }));
            setStatusOptionsLists(dataList);
        }
    }, [usersDataByBranch]);


    const handleRiskTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRiskType(event.target.value);
        reset();
        setValue('assessmentType', event.target.value);
    };


    const validationSchema = object().shape({
        notes: string(),
        assessmentType: string(),
        riskType: string(),
        riskScore: string(),
        fkCaseId: string(),
        completedOn: string()
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        completedBy: number(),
        currentStatus: number(),
    });

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            assessmentType: 'na',
            fkCaseId: referenceNumber,
        }
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));

        // setIsLoading(true);
        // try {
        //     await dispatch(addCaseRiskAssessment({ id: referenceNumber, data })).unwrap();
        //     Close();
        //     toast.success("Successfully Added Case Risk");
        // } catch (error: any) {
        //     toast.error(error.toString());
        // } finally {
        //     setIsLoading(false);
        // }
    };



    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-start-1 col-span-2">
                    <div className=" flex flex-col border dark:text-white-bg dark:border dark:border-gray-400 dark:border-opacity-35 p-6 gap-5">
                        <input type="hidden" {...register('fkCaseId')} value={referenceNumber} />
                        <div className="flex flex-row items-center space-x-10">
                            <div>
                                <input
                                    type="radio"
                                    value='na'
                                    checked={selectedRiskType === 'na'}
                                    onChange={handleRiskTypeChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Not Applicable</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    value='onscreen'
                                    checked={selectedRiskType === 'onscreen'}
                                    onChange={handleRiskTypeChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">On Screen</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    value='upload'
                                    checked={selectedRiskType === 'upload'}
                                    onChange={handleRiskTypeChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Upload</label>
                            </div>
                        </div>
                        {selectedRiskType === 'na' && (
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Notes
                                </label>
                                <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`}
                                    {...register('notes')} />
                            </div>
                        )}

                        {selectedRiskType === 'onscreen' && (
                            <div className="">
                                <div className="cs_fields cs_onscreen marginTop15">
                                    <div id="cs_onscreen_form" className="flex flex-col gap-5">
                                        <div className="">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                                Conflict Search
                                            </label>
                                            <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-56 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                                value={keyword}
                                                onChange={(e) => setKeyword(e.target.value)} />
                                        </div>

                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Heading title={"Client"} select={<button type="button" onClick={() => handleSelectAll('client')}>Select All</button>} />
                                            </div>
                                            <div className="col-span-2">
                                                <ul className="grid grid-cols-4 items-center w-full text-sm font-medium text-gray-900 bg-white-bg rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input type="checkbox"
                                                                checked={clientSelection.includes('name')}
                                                                onChange={(e) => handleCheckboxChange('client', 'name', e.target.checked)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={clientSelection.includes('email')}
                                                                onChange={(e) => handleCheckboxChange('client', 'email', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email Address</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={clientSelection.includes('address')}
                                                                onChange={(e) => handleCheckboxChange('client', 'address', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Postal Address</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Heading title={"Contacts"} select={<button type="button" onClick={() => handleSelectAll('contact')}>Select All</button>} />
                                            </div>
                                            <div className="col-span-2">
                                                <ul className="grid grid-cols-4 items-center w-full text-sm font-medium text-gray-900 bg-white-bg rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={contactSelection.includes('name')}
                                                                onChange={(e) => handleCheckboxChange('contact', 'name', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={contactSelection.includes('email')}
                                                                onChange={(e) => handleCheckboxChange('contact', 'email', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email Address</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={contactSelection.includes('address')}
                                                                onChange={(e) => handleCheckboxChange('contact', 'address', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Postal Address</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Heading title={"Case"} select={<button type="button" onClick={() => handleSelectAll('case')}>Select All</button>} />
                                            </div>
                                            <div className="col-span-2">
                                                <ul className="grid grid-cols-4 items-center w-full text-sm font-medium text-gray-900 bg-white-bg rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={caseSelection.includes('name')}
                                                                onChange={(e) => handleCheckboxChange('case', 'name', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={caseSelection.includes('details')}
                                                                onChange={(e) => handleCheckboxChange('case', 'details', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email Address</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full ">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={caseSelection.includes('notes')}
                                                                onChange={(e) => handleCheckboxChange('case', 'notes', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Postal Address</label>
                                                        </div>
                                                    </li>
                                                    <li className="w-full dark:border-gray-600">
                                                        <div className="flex items-center ps-3">
                                                            <input
                                                                type="checkbox"
                                                                checked={caseSelection.includes('address')}
                                                                onChange={(e) => handleCheckboxChange('case', 'address', e.target.checked)}
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Property Address</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Submit Button */}
                                        <div className="form-group">
                                            <div className="col-md-12 text-right marginTop15">
                                                <button
                                                    type="button"
                                                    onClick={handleSearch}
                                                    className={`bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                >
                                                    {isLoading ? (
                                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                                        </svg>
                                                    ) : (
                                                        'Search Conflict'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {conflictSearch.length > 0 && (
                                    <div className="relative overflow-x-auto sm:rounded-lg my-5">
                                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                                            <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                                    <th scope="col" className="px-6 py-3">
                                                        Type
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Case Reference
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Description
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Mark
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {conflictSearch.map((conflic: any, index) => (
                                                    <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                                        <td className="px-6 py-3">
                                                            {conflic.type} : {conflic.typeField}
                                                        </td>
                                                        <td className="px-6 py-3">
                                                            {conflic?.caseReferenceAuto}
                                                        </td>
                                                        <td className="px-6 py-3">
                                                            {conflic.description}
                                                        </td>
                                                        <td className="px-6 py-3">
                                                            <div className="flex items-center ps-3">
                                                                <input type="checkbox"
                                                                    onChange={(e) => handleSelectConflictData(conflic, e.target.checked)}
                                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <div className=" grid grid-cols-2 gap-5">
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed On
                                        </label>
                                        <input type="date" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('completedOn')} max={new Date().toISOString().split('T')[0]} />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed By
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={branchUserOptionsLists}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('completedBy', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Notes
                                </label>
                                <textarea {...register('notes')} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`} />
                            </div>
                        )}

                        {selectedRiskType === 'upload' && (
                            <div className="">
                                <div className=" grid grid-cols-2 gap-5">
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Attachment
                                        </label>
                                        <button type="button" className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                                            Select RA
                                        </button>
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed On
                                        </label>
                                        <input type="date" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('completedOn')} max={new Date().toISOString().split('T')[0]} />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed By
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={branchUserOptionsLists}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('completedBy', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Notes
                                </label>
                                <textarea {...register('notes')} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`} />
                            </div>
                        )}

                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                        toggleDrawer();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export { AddCaseConflictSearch };