import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllSuppliersBillEntry = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/billentry/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllSuppliersBillEntryId = async (billEntryId: any) => {
    try {
        const response = await axiosInstance.get(`Supplier/billentry/${billEntryId}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostSuppliersBillEntry = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/billentry/add", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }  
            }
        );
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutSuppliersBillEntry = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`SuppliersBillEntry/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteSuppliersBillEntry = async (id: number) => {
    try {
        await axiosInstance.delete(`Supplier/billentry/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const SuppliersBillEntryService = {
    GetAllSuppliersBillEntry,
    GetAllSuppliersBillEntryId,
    PostSuppliersBillEntry,
    PutSuppliersBillEntry,
    deleteSuppliersBillEntry
};


export default SuppliersBillEntryService;
