import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllOfficeBank = async (data: any) => {
    try {
        const response = await axiosInstance.get("AccountSetUp/officeaccounts", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeBankService = {
    GetAllOfficeBank,
    
};


export default OfficeBankService;
