import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { addLawSociety } from "../../../../store/slice/OfficeManagement/OfficeInformation/LawSocietySlice";
import { getValue } from "@testing-library/user-event/dist/utils";

function AddLowSociety({
  openDrawer,
  Close,
  isEdit,
  lawSociety,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  lawSociety: any;
}) {
  const [title] = useState(
    !isEdit ? "Add New Low Society" : "Edit Low Society"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [createLawSocietyFileName1, setCreateLawSocietyFileName1] =
    useState("");
  const [documentCreateLawSocietyFile1, setDocumentCreateLawSocietyFile1] =
    useState<any>(null);
  const [createLawSocietyFileName2, setCreateLawSocietyFileName2] =
    useState("");
  const [documentCreateLawSocietyFile2, setDocumentCreateLawSocietyFile2] =
    useState<any>(null);

  const [createLawSocietyFileName3, setCreateLawSocietyFileName3] =
    useState("");
  const [documentCreateLawSocietyFile3, setDocumentCreateLawSocietyFile3] =
    useState<any>(null);

  const [certificateFileName, setCertificateFileName] = useState("");
  const [certificateFile, setCertificateFile] = useState<any>(null);

  const [applicationFileName, setApplicationFileName] = useState("");
  const [applicationFile, setApplicationFile] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const validationSchema = object().shape({
    year: string().required("Year is required"),
    application: string().required("Application is required"),
    commentsOnApplication: string(),
    document1: string(),
    document2: string(),
    document3: string(),
    certificate: string(),
    commentsOnCertificate: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    if (
      !documentCreateLawSocietyFile1 ||
      !documentCreateLawSocietyFile2 ||
      !documentCreateLawSocietyFile3 ||
      !certificateFile ||
      !applicationFile
    ) {
      toast.error("Please select all required files");
      return;
    }

    // Append file names to data object
    data.document1 = createLawSocietyFileName1;
    data.document2 = createLawSocietyFileName2;
    data.document3 = createLawSocietyFileName3;
    data.application = applicationFileName;
    data.certificate = certificateFileName;

    const formData = new FormData();
    formData.append("requestData", JSON.stringify(data));
    formData.append("fileAttached1", documentCreateLawSocietyFile1);
    formData.append("fileAttached2", documentCreateLawSocietyFile2);
    formData.append("fileAttached3", documentCreateLawSocietyFile3);
    formData.append("certificateFile", certificateFile);
    formData.append("applicationFile", applicationFile);

    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addLawSociety(formData)).unwrap();
      Close("success");
      toast.success("Successfully Added New Law Society");
      // Optionally reset file inputs
      setCreateLawSocietyFileName1("");
      setDocumentCreateLawSocietyFile1(null);
      setCreateLawSocietyFileName2("");
      setDocumentCreateLawSocietyFile2(null);
      setCreateLawSocietyFileName3("");
      setDocumentCreateLawSocietyFile3(null);
      setCertificateFileName("");
      setCertificateFile(null);
      setApplicationFileName("");
      setApplicationFile(null);
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

   useEffect(() => {
          if (( lawSociety !==null && isEdit=== true)) {
            //setValue('bankDepositNumber', officeBankDeposit?.bankDepositNumber)
            setValue('year', (lawSociety?.year))
            setValue('application', lawSociety?.application)
            setValue('commentsOnApplication', lawSociety?.commentsOnApplication)
            setValue('document1', lawSociety?.document1)
            setValue('document2', (lawSociety?.document2))
            setValue('document3', lawSociety?.document3)
           setValue('commentsOnCertificate', lawSociety?.commentsOnCertificate)
            setValue('certificate', lawSociety?.certificate)
  
          }
        }, [lawSociety, isEdit, setValue]);
  

  const handleFileChange = (
    event: any,
    fileSetter: Function,
    nameSetter: Function
  ) => {
    const file = event.target.files[0];
    if (file) {
      fileSetter(file);
      nameSetter(file.name);
    } else {
      nameSetter("");
    }
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          {title}
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto">
          <div className="space-y-3 w-full">
            <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white-bg bg-gray-200 dark:bg-gray-900 p-2">
              Application
            </label>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Year <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.year ? "is-invalid" : ""
                  }`}
                  {...register("year")}
                  placeholder="Enter year"
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.year?.message?.toString()}
                </div>
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Application <span className="text-red-500">*</span>
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${
                    errors.application ? "is-invalid" : ""
                  }`}
                  {...register("application")}
                  type="file"
                  multiple
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setApplicationFile,
                      setApplicationFileName
                    )
                  }
                />
                <div className="font-medium mt-2 text-xs text-left text-red-600">
                  {errors?.application?.message?.toString()}
                </div>
              </div>
              <div className="relative max-w-full col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Comments on Application
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Comments on Application"
                  {...register("commentsOnApplication")}
                ></textarea>
              </div>
            </div>
            <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white-bg bg-gray-200 dark:bg-gray-900 p-2">
              Documents
            </label>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Document 1
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`}
                  {...register("document1")}
                  type="file"
                  multiple
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setDocumentCreateLawSocietyFile1,
                      setCreateLawSocietyFileName1
                    )
                  }
                />
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Document 2
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`}
                  {...register("document2")}
                  type="file"
                  multiple
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setDocumentCreateLawSocietyFile2,
                      setCreateLawSocietyFileName2
                    )
                  }
                />
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Document 3
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`}
                  {...register("document3")}
                  type="file"
                  multiple
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setDocumentCreateLawSocietyFile3,
                      setCreateLawSocietyFileName3
                    )
                  }
                />
              </div>
            </div>
            <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white-bg bg-gray-200 dark:bg-gray-900 p-2">
              Certificate
            </label>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Certificate
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400`}
                  {...register("certificate")}
                  type="file"
                  multiple
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setCertificateFile,
                      setCertificateFileName
                    )
                  }
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Comments on Certificate
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Comments on Certificate"
                  {...register("commentsOnCertificate")}
                
                ></textarea>
              </div>
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddLowSociety };
