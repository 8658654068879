import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";





const GetAllOfficeReconciliation = async (data: any) => {
    try {
        const response = await axiosInstance.post("OABankReconciliationSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeReconciliation = async (data: any) => {
    try {
        const response = await axiosInstance.post("OABankReconciliation/create", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeReconciliation = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeReconciliation/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const OfficeReconciliationService = {
    GetAllOfficeReconciliation,
    PostOfficeReconciliation,
    PutOfficeReconciliation,
    
};


export default OfficeReconciliationService;
