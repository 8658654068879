import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuditTrailService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/AuditTrailService";

export interface AuditTrail {
  totalCount: number;
  results: [];
}

export interface AuditTrailDetails {
}

// Define the state type for Checklist
type AuditTrailState = {
  auditTrails: AuditTrail;
  auditTrailDetails: AuditTrailDetails[];
  status: "idle" | "loading" | "failed";
  error: string | null;
};

// Initial state
const initialState: AuditTrailState = {
  auditTrails: null!,
  auditTrailDetails: [],
  status: "idle",
  error: null,
};

// Async thunks for CRUD operations
export const fetchAllAuditTrail = createAsyncThunk(
  "fetchAllAuditTrail",
  async (data: any, thunkAPI) => {
    try {
      const response = await AuditTrailService.GetAllAuditTrail(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchAuditTrailDetails = createAsyncThunk(
  "fetchAuditTrailDetails",
  async (id: number, thunkAPI) => {
    try {
      const response = await AuditTrailService.GetAuditTrailDetails(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice definition
const AuditTrailSlice = createSlice({
  name: "auditTrail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAuditTrail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchAllAuditTrail.fulfilled,
        (state, action: PayloadAction<AuditTrail>) => {
          state.status = "idle";
          state.auditTrails = action.payload;
        }
      )
      .addCase(fetchAllAuditTrail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchAuditTrailDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchAuditTrailDetails.fulfilled,
        (state, action: PayloadAction<AuditTrailDetails[]>) => {
          state.status = "idle";
          state.auditTrailDetails = action.payload;
        }
      )
      .addCase(fetchAuditTrailDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default AuditTrailSlice.reducer;
