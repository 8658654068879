import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ClientReportsService from "../../../../../../services/Accounting/ClientAccounts/ClientReport/ClientReportsService";

export interface BankReconReports {
    bankReconReportsResult: [];
    currentPage: number;
    recordPerPage: number;
    totalCount: number;

}
export interface ProtectedFundReports {
    clientPostingProtectedFundResult: [];
    totalCount: number
    recordPerPage: number
    currentPage: number
}

export interface OfficeToClientReports {
    officeToClientReportResult: [];
    totalCount: number
    recordPerPage: number
    currentPage: number
}

export interface ClientToOfficeReports {
    clientToOfficeReportResult: [];
    totalCount: number
    recordPerPage: number
    currentPage: number
}

export interface CaseBalanceReport {
    officeToClientReportResult: [];
    totalCount: number
    recordPerPage: number
    currentPage: number
}

// Define the state type for Checklist
type ClientReports = {
    caseBalanceReport: CaseBalanceReport;
    clientToOfficeReports: ClientToOfficeReports
    officeToClientReports: OfficeToClientReports;
    bankReconReports: BankReconReports;
    protectedFundReports: ProtectedFundReports;
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber: number | null;
};

// Initial state
const initialState: ClientReports = {
    caseBalanceReport: null!,
    clientToOfficeReports: null!,
    officeToClientReports: null!,
    protectedFundReports: null!,
    bankReconReports: null!,
    status: "idle",
    error: null,
    receiptNumber: null
};

// Async thunks for CRUD operations

export const fetchAllBankReconReports = createAsyncThunk(
    "fetchAllBankReconReports",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientReportsService.GetAllBankReconReports(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllProtectedFundReports = createAsyncThunk(
    "fetchAllProtectedFundReports",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientReportsService.GetAllProtectedFundReports(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllOfficeToClientReports = createAsyncThunk(
    "fetchAllOfficeToClientReports",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientReportsService.GetAllOfficeToClientReports(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllC2OReports = createAsyncThunk(
    "fetchAllC2OReports",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientReportsService.GetAllClientToOfficeReports(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllCaseBalanceReport = createAsyncThunk(
    "fetchAllCaseBalanceReport",
    async (_, thunkAPI) => {
        try {
            const response = await ClientReportsService.GetAllCaseBalanceReport();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ClientReportsSlice = createSlice({
    name: "clientReports",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBankReconReports.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBankReconReports.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.bankReconReports = action.payload;
            })
            .addCase(fetchAllBankReconReports.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllProtectedFundReports.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllProtectedFundReports.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.protectedFundReports = action.payload;
            })
            .addCase(fetchAllProtectedFundReports.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllOfficeToClientReports.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeToClientReports.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.officeToClientReports = action.payload;
            })
            .addCase(fetchAllOfficeToClientReports.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllC2OReports.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllC2OReports.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.clientToOfficeReports = action.payload;
            })
            .addCase(fetchAllC2OReports.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllCaseBalanceReport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCaseBalanceReport.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.caseBalanceReport = action.payload;
            })
            .addCase(fetchAllCaseBalanceReport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default ClientReportsSlice.reducer;
