import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddTimeEntryTypes } from "../Modal/AccountSetup/AddTimeEntryTypes";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  deleteTimeEntryType,
  fetchAllTimeEntryType,
} from "../../store/slice/Accounting/AccountSetup/TimeEntryTypeSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import DeleteModal from "../Modal/DeleteModal";
import { NewRadioButtonFilter } from "../Filters/RadioButtonFilter";

const isActiveStatus = [
  { id: 1, label: "Active" },
  { id: 2, label: "InActive" },
];

function TimeEntryTypes() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { timeEntryTypes, status, error } = useSelector(
    (state: RootState) => state.timeEntryType
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [timeEntryTypeData, setTimeEntryTypeData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [timeEntryTypeId, setTimeEntryTypeId] = useState<number>(0);
  const itemsPerPage = 10;
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleRadioButtonChange = (status: number | null) => {
    setSelectedStatus(status);
    console.log("Selected Status:", status);
  };

  const filterTimeEntryTypes = (data: any[]) => {
    return data.filter((timeEntryType: any) => {
      const timeEntryTypess = timeEntryType?.timeEntryType?.toLowerCase() || "";
      const vatCode = timeEntryType?.vatType?.toLowerCase() || "";
      const isActiveStatus = timeEntryType?.isActive ? 1 : 2;

      const matchesSearch =
        searchQuery.trim() === "" ||
        timeEntryTypess.includes(searchQuery.toLowerCase()) ||
        vatCode.includes(searchQuery.toLowerCase());

      const matchesStatus =
        selectedStatus === null || selectedStatus === isActiveStatus;

      return matchesSearch && matchesStatus;
    });
  };

  const filteredTimeEntryType = filterTimeEntryTypes(timeEntryTypes);

  const indexOfLastTimeEntryType = currentPage * itemsPerPage;
  const indexOfFirstTimeEntryType = indexOfLastTimeEntryType - itemsPerPage;
  const currentTimeEntryType = filteredTimeEntryType.slice(
    indexOfFirstTimeEntryType,
    indexOfLastTimeEntryType
  );

  const totalPages = Math.ceil(timeEntryTypes.length / itemsPerPage);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || status === "Added") {
      dispatch(fetchAllTimeEntryType())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }

    if (status === "failed" && !errorShownRef.current) {
      // toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, status, error]);

  const toggleDrawer = () => {
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteTimeEntryType(timeEntryTypeId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted TimeEntry Types");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            onDeleteAccept={onDeleteCall}
            id={timeEntryTypeId}
            title="demo.laitlegal.uk.says"
            message="Are you sure?"
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Time Entry Types"
            buttonName="Time Entry Type"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          {openModal && (
            <AddTimeEntryTypes
              openDrawer={openModal}
              Close={toggleDrawer}
              isEdit={isEdit}
              timeEntryType={timeEntryTypeData}
            />
          )}
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <div>
          <SearchInput
            title="Keyword"
            value={searchQuery}
            onChange={(e: any) => handleSearchChange(e)}
          />
          </div>
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <NewRadioButtonFilter
                title="Is Active"
                data={isActiveStatus}
                showSearchInput={false}
                onFilterChange={handleRadioButtonChange}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {initialLoading ? <MainAnimation /> : ""}
              <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Type
                  </th>
                  {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Code
                                </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    VAT Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Is Active
                  </th>
                  <th
                    scope="col"
                    className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Edit
                  </th>
                  <th
                    scope="col"
                    className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {currentTimeEntryType.map((timeEntryType: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {timeEntryType.timeEntryType}
                    </td>
                    {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {timeEntryType.timeEntryCode}
                                    </td> */}
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {timeEntryType?.vatType}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {timeEntryType.isActive == 1 ? "Active" : "InActive"}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setTimeEntryTypeData(timeEntryType);
                        }}
                      />
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                      <MdDelete
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setTimeEntryTypeId(timeEntryType.timeEntryTypeId);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {currentTimeEntryType.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No TimeEntryType found.</p>
            </div>
          )}
        </div>
        {currentTimeEntryType.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={filteredTimeEntryType.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { TimeEntryTypes };
