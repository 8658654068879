import React from "react";
import { renderRoutes } from "./generate-routes";
import AddCentral from "../pages/OfficeManagement/CentralDiary/AddCentralDiary";
import ImportantDatesAndTasks from "../pages/SystemMaintenance/SystemMaintenance/ImportantDatesAndTasks";
import { AddSystemRiskAssessment } from "../pages/SystemMaintenance/SystemMaintenance/SystemRiskAssessment/AddSystemRiskAssessment";
import { EditChecklist } from "../pages/SystemMaintenance/SystemMaintenance/Checklist/EditChecklist";
import Invoice from "../components/EditCase/Invoice";
import { BankAccount, CaseTypesIncomeLedgers, DisbursementTypes, FeeTypes, FinancialYear, FixedFeeInvoiceFormat, General, InvoiceNotices, NominalLedgerSetup, PartnershipAccounts, QuotationNotices, TimeEntryTypes, TimeEntryUnitPrices, VATExIncomeAccounts, VatPeriod } from "../components";
import CaseTimeEntry from "../components/EditCase/TimeEntry";
import MyProfile from "../pages/MyProfile";


// Layouts
const AnonymousLayout = React.lazy(() => import("../layouts/AnonymousLayout"));
const MainLayout = React.lazy(() => import("../layouts/MainLayout"));


// Pages
// const CreateUser = React.lazy(() => import("../pages/CreateUser"));
// const ListUsers = React.lazy(() => import("../pages/ListUsers"));
const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Register"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Test = React.lazy(() => import("../pages/test"));

// administrator pages---------------------------------------------------------------------------------------->
const RoleManagement = React.lazy(() => import("../pages/Administrator/RoleManagement"));
const AddRole = React.lazy(() => import("../pages/Administrator/RoleManagement/AddRole"));
const UserManagement = React.lazy(() => import("../pages/Administrator/UserManagement"));
const AddUser = React.lazy(() => import("../pages/Administrator/UserManagement/AddUser"));
const CompanyProfile = React.lazy(() => import("../pages/Administrator/CompanyProfile"));
const AddBranchForm = React.lazy(() => import("../pages/Administrator/CompanyProfile/AddBranchForm"));
const OutLookAPI = React.lazy(() => import("../pages/Administrator/OutLookAPI"));
const Landregistryaccount = React.lazy(() => import("../pages/Administrator/Landregistryaccount"));
const HMRCStampDuty = React.lazy(() => import("../pages/Administrator/HMRCStampDuty"));
const OutlookEmailSetup = React.lazy(() => import("../pages/Administrator/OutlookEmailSetup"));
const HMRCAPI = React.lazy(() => import("../pages/Administrator/HMRCAPI"));
const FormatEmailBody = React.lazy(() => import("../pages/Administrator/FormatEmailBody"));

// Office_Management pages------------------------------------------------------------------------------------>
const CentralDiary = React.lazy(() => import("../pages/OfficeManagement/CentralDiary"));
const Timesheets = React.lazy(() => import("../pages/OfficeManagement/Timesheets"));

// OfficeInformation
const LawSociety = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/LawSociety"));
const IndemnityInsurance = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/IndemnityInsurance"));
const LEXCELOfficeManual = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/LEXCEL_OfficeManual"));
const CQS = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/CQS"));
const COLPCOFA = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/COLPCOFA"));
const GuideGDPR = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/GuideGDPR"));
const ExpertRegister = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/ExpertRegister"));
const AddExpert = React.lazy(() => import("../pages/OfficeManagement/OfficeInformation/ExpertRegister/AddExpert/index"));

// postregister page
const IncomingPost = React.lazy(() => import("../pages/OfficeManagement/PostRegister/IncomingPost"));
const OutgoingPost = React.lazy(() => import("../pages/OfficeManagement/PostRegister/OutgoingPost"));

// Case_Management ------------------------------------------------------------------------------------------>
// Contact
const Contacts = React.lazy(() => import("../pages/CaseManagement/Contact"));
const AddContact = React.lazy(() => import("../pages/CaseManagement/Contact/AddContact/AddContact"));
// Clients
const Clients = React.lazy(() => import("../pages/CaseManagement/Client"));
const AddClient = React.lazy(() => import("../pages/CaseManagement/Client/AddClient"));
// Case page
const CaseManagement = React.lazy(() => import("../pages/CaseManagement/Cases/CaseManagement"));
const AddCase = React.lazy(() => import("../pages/CaseManagement/Cases/CaseManagement/AddCase"));
const EditCase = React.lazy(() => import("../pages/CaseManagement/Cases/CaseManagement/EditCase"));
const ArchivedManagement = React.lazy(() => import("../pages/CaseManagement/Cases/ArchivedManagement"));
const WarehousedManagement = React.lazy(() => import("../pages/CaseManagement/Cases/WarehousedManagement"));
const CaseDetails= React.lazy(() => import("../components/EditCase/CaseDetails/index"));
const Permission= React.lazy(() => import("../components/EditCase/Permission/index"));
const RiskAssesment= React.lazy(() => import("../components/EditCase/RiskAssesment/index"));
const AML= React.lazy(() => import("../components/EditCase/AML/index"));
const CaseConflictSearch = React.lazy(() => import("../components/EditCase/ConflictSearch/index"));
const Client= React.lazy(() => import("../components/EditCase/Client/index"));
const Contact= React.lazy(() => import("../components/EditCase/Contact/index"));
const Correspondence= React.lazy(() => import("../components/EditCase/Correspondence/index"));
const FileReview= React.lazy(() => import("../components/EditCase/FileReview/index"));
const PostRegister= React.lazy(() => import("../components/EditCase/PostRegister/index"));
const MoneyLaundering= React.lazy(() => import("../components/EditCase/MoneyLaundering/index"));
const LedgerCard= React.lazy(() => import("../components/EditCase/LedgerCard/index"));
const PaymentAuthorities= React.lazy(() => import("../components/EditCase/PaymentAuthorities/index"));
const GivenToClients= React.lazy(() => import("../components/EditCase/GivenToClients/index"));
const CaseStatus= React.lazy(() => import("../components/EditCase/CaseStatus/index"));
const CaseClosures = React.lazy(() => import("../components/EditCase/CaseClosure/index"));
const ClientPostings= React.lazy(() => import("../components/ClientAccounts/ClientPostings"));
const LedgerCards= React.lazy(() => import("../components/ClientAccounts/LedgerCards"));
const ClientInvoice= React.lazy(() => import("../components/ClientAccounts/ClientInvoice"));
const QuickInvoice= React.lazy(() => import("../components/ClientAccounts/QuickInvoice"));
const BankReconciliation= React.lazy(() => import("../components/ClientAccounts/BankReconciliation"));
const FinancialStatement= React.lazy(() => import("../components/ClientAccounts/FinancialStatement"));
const ClientReports= React.lazy(() => import("../components/ClientAccounts/ClientReports"));
const ClientBankAccount= React.lazy(() => import("../components/ClientAccounts/ClientBankAccount"));
const TitleDocument= React.lazy(() => import("../components/EditCase/TitleDocument"));
const  SearchDocuments= React.lazy(() => import("../components/EditCase/SearchDocuments"));
const  GeneralSettings= React.lazy(() => import("../components/OfficeAccounts/GeneralSettings"));
const  ChartOfAccounts= React.lazy(() => import("../components/OfficeAccounts/ChartOfAccounts"));
const  NominalLedgers= React.lazy(() => import("../components/OfficeAccounts/NominalLedgers"));
const  OfficeBankAccounts= React.lazy(() => import("../components/OfficeAccounts/OfficeBankAccounts"));
const OfficePostings= React.lazy(() => import("../components/OfficeAccounts/OfficePostings"));
const OfficeBankReconciliation= React.lazy(() => import("../components/OfficeAccounts/OfficeBankReconciliation"));
const OfficeReports= React.lazy(() => import("../components/OfficeAccounts/OfficeReports"));
const OfficeSuppliers= React.lazy(() => import("../components/OfficeAccounts/OfficeSuppliers"));
const OfficeVATReturns= React.lazy(() => import("../components/OfficeAccounts/OfficeVATReturns"));
const Budget= React.lazy(() => import("../components/OfficeAccounts/Budget"));
const YearEnd= React.lazy(() => import("../components/OfficeAccounts/YearEnd"));
const ActiveCases= React.lazy(() => import("../components/Reports/GeneralReportsDetails/ActiveCases"));
const  OpenedAndClosedCases= React.lazy(() => import("../components/Reports/GeneralReportsDetails/OpenedAndClosedCases"));
const  ActiveCasesDetails= React.lazy(() => import("../components/Reports/GeneralReportsDetails/ActiveCasesDetails"));
const  ClosedCasesDetails= React.lazy(() => import("../components/Reports/GeneralReportsDetails/ClosedCasesDetails"));
const  ComplianceReport= React.lazy(() => import("../components/Reports/GeneralReportsDetails/ComplianceReport"));
const  IdleCases= React.lazy(() => import("../components/Reports/GeneralReportsDetails/IdleCases"));
const TimeSheets= React.lazy(() => import("../components/ClientInvoice/TimeSheets"));


// Tasks
const Tasks = React.lazy(() => import("../pages/CaseManagement/Tasks"));

// Finance and Accounting-------------------------------------------------------------------------------------->
// Accountings
const AccountsSetup = React.lazy(() => import("../components/AccountSetup"));
const ClientAccounts = React.lazy(() => import("../pages/FinanceAccounting/Accountings/ClientAccounts"));
const OfficeAccounts = React.lazy(() => import("../pages/FinanceAccounting/Accountings/OfficeAccounts"));

// Reports
const GeneralReports = React.lazy(() => import("../pages/FinanceAccounting/Reports/GeneralReports"));
const ClientAccountReports = React.lazy(() => import("../pages/FinanceAccounting/Reports/ClientAccountReports"));

// RiskManagement page----------------------------------------------------------------------------------------->
// RiskAssessment
const RiskAssessment = React.lazy(() => import("../pages/RiskManagement/RiskAssessment"));
// ConflictSearch
const ConflictSearch = React.lazy(() => import("../pages/RiskManagement/ConflictSearch"));
// QuotationManagement
const QuotationManagement = React.lazy(() => import("../pages/RiskManagement/QuotationManagement"));

// HumanResources----------------------------------------------------------------------------------->
// HumanResource
const TrainingRecords = React.lazy(() => import("../pages/HumanResources/HumanResources/TrainingRecords"));
const StaffAttendance = React.lazy(() => import("../pages/HumanResources/HumanResources/StaffAttendance"));
const LeaveRequests = React.lazy(() => import("../pages/HumanResources/HumanResources/LeaveRequests"));
const LeaveManagement = React.lazy(() => import("../pages/HumanResources/HumanResources/LeaveManagement"));

// Surveys
const CreateSurvey = React.lazy(() => import("../pages/HumanResources/Surveys/CreateSurvey"));
const SurveyResults = React.lazy(() => import("../pages/HumanResources/Surveys/SurveyResults"));

// SystemMaintenance
// SystemMaintenance
const CaseType = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CaseType"));
const CaseReferenceFormat = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CaseReferenceFormat"));
const AddCaseReference = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CaseReferenceFormat/AddCaseReference"));
const CaseReferenceClientFormat = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CaseReferenceClientFormat"));
const DocumentCategorys = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/DocumentCategorys"));
const DocumentManagement = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/DocumentManagement"));
const AddDocument = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/DocumentManagement/AddDocument"));
const Checklist = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/Checklist"));
const WorkflowSetup = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/WorkflowSetup"));
const constantDatesAndTasks = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/ImportantDatesAndTasks"));
const CaseClosure = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CaseClosure"));
const MergeFields = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/MergeFields"));
const ChangeCorrespondanceDate = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/ChangeCorrespondanceDate"));
const ChangeCase = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/ChangeCases"));
const  QuotationRequest  = React.lazy(() => import("../pages/RiskManagement/RiskAssessment/RiskAssessmentDetails/QuotationRequest"));
const  Assessment  = React.lazy(() => import("../pages/RiskManagement/RiskAssessment/RiskAssessmentDetails/Assessment"));
const  QuotationGeneration  = React.lazy(() => import("../pages/RiskManagement/RiskAssessment/RiskAssessmentDetails/QuotationGeneration"));
const  GeneratedQuotation  = React.lazy(() => import("../pages/RiskManagement/RiskAssessment/RiskAssessmentDetails/GeneratedQuotation"));
const  AddWorkflowSetup  = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/WorkflowSetup/AddWorkflowSetup"));
const  CreateQuestions  = React.lazy(() => import("../pages/HumanResources/Surveys/CreateSurvey/CreateQuestions"));
const  QuestionOrder  = React.lazy(() => import("../pages/HumanResources/Surveys/CreateSurvey/QuestionOrder"));
const  PreviewAndPublish  = React.lazy(() => import("../pages/HumanResources/Surveys/CreateSurvey/PreviewAndPublish"));
const  Survey  = React.lazy(() => import("../pages/HumanResources/Surveys/CreateSurvey/Survey"));
const  ViewSurveyResults  = React.lazy(() => import("../pages/HumanResources/Surveys/SurveyResults/ViewSurveyResults"));
const  AnalyseSurveyResults  = React.lazy(() => import("../pages/HumanResources/Surveys/SurveyResults/AnalyseSurveyResults"));
const  AddConflictSearch  = React.lazy(() => import("../pages/RiskManagement/ConflictSearch/AddConflictSearch"));
const SystemRiskAssessment = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/SystemRiskAssessment"));
const  AddQuotationManagement  = React.lazy(() => import("../pages/RiskManagement/QuotationManagement/AddQuotationManagement"));
const CustomFieldForCaseType = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CustomFieldForCaseType"));
const  AddCustomFieldForCaseType  = React.lazy(() => import("../pages/SystemMaintenance/SystemMaintenance/CustomFieldForCaseType/AddCustomFieldForCaseType"));
const  FeeEarner  = React.lazy(() => import("../components/Reports/ClientAccountReportsDetails/FeeEarner"));
const  ClientTimeSheets  = React.lazy(() => import("../components/Reports/ClientAccountReportsDetails/ClientTimeSheets"));


export const routes = [
  {
    layout: AnonymousLayout,
    isPublic: true,
    routes: [
      {
        name: "login",
        title: "Login page",
        component: Login,
        path: "/login",
        isPublic: true,
      },
      {
        name: "register",
        title: "Register page",
        component: Register,
        path: "/register",
        isPublic: true,
      },
    ],
  },
  {
    layout: MainLayout,
    isPublic: false,
    routes: [
      {
        name: "dashboard",
        title: "dashboard page",
        component: Dashboard,
        path: "/",
      },
      {
        name: "dashboard",
        title: "dashboard page",
        component: Dashboard,
        path: "/dashboard",
      },
      {
        name: "myprofile",
        title: "myprofile page",
        component: MyProfile,
        path: "/myprofile",
      },
      {
        name: "administrator",
        title: "administrator page",
        routes: [
          {
            name: "roleManagement",
            title: "roleManagement page",
            component: RoleManagement,
            path: "/administrator/roleManagement",
            routes: [
              {
                name: "addRole",
                title: "addRole page",
                component: AddRole,
                path: "/administrator/roleManagement/addRole",
              },
              {
                name: "updateRole",
                title: "updateRole page",
                component: AddRole,
                path: "/administrator/roleManagement/updateRole/:referenceNumber",
              },
            ],
          },
          {
            name: "usermanagement",
            title: "usermanagement page",
            component: UserManagement,
            path: "/administrator/usermanagement",
            routes: [
              {
                name: "addUser",
                title: "addUser page",
                component: AddUser,
                path: "/administrator/usermanagement/adduser",
              },
              {
                name: "updateUser",
                title: "updateUser page",
                component: AddUser,
                path: "/administrator/usermanagement/updateuser/:referenceNumber",
              },
            ],
          },
          {
            name: "companyprofile",
            title: "companyprofile page",
            component: CompanyProfile,
            path: "/administrator/companyprofile",
            routes: [
              {
                name: "addBranch",
                title: "addBranch page",
                component: AddBranchForm,
                path: "/administrator/CompanyProfile/addBranch",
              },
              {
                name: "updateBranch",
                title: "updateBranch page",
                component: AddBranchForm,
                path: "/administrator/CompanyProfile/updatebranch",
              },
            ],
          },
          {
            name: "outLookAPI",
            title: "outLookAPI page",
            component: OutLookAPI,
            path: "/administrator/outlookAPI",
          },
          {
            name: "landregistryaccount",
            title: "landregistryaccount page",
            component: Landregistryaccount,
            path: "/administrator/landregistryaccount",
          },
          {
            name: "HMRCStampDuty",
            title: "HMRCStampDuty page",
            component: HMRCStampDuty,
            path: "/administrator/HMRCStampDuty",
          },
          {
            name: "OutlookEmailSetup",
            title: "OutlookEmailSetup page",
            component: OutlookEmailSetup,
            path: "/administrator/OutlookEmailSetup",
          },
          {
            name: "HMRCAPI",
            title: "HMRCAPI page",
            component: HMRCAPI,
            path: "/administrator/HMRCAPI",
          },
          {
            name: "FormatEmailBody",
            title: "FormatEmailBody page",
            component: FormatEmailBody,
            path: "/administrator/FormatEmailBody",
          },
        ],
      },
      {
        name: "OfficeManagement",
        title: "OfficeManagement page",
        routes: [
          {
            name: "centraldiary",
            title: "centraldiary page",
            component: CentralDiary,
            path: "/officemanagement/centraldiary",
            // routes:[
            //   {
            //     name: "AddCentralDiary",
            //     title: "AddCentralDiary page",
            //     component: AddCentral,
            //     path: "/officemanagement/centraldiary/addcentraldiary",
            //   },
            // ]
          },
          {
            name: "OfficeInformation",
            title: "officeInformation page",
            routes: [
              {
                name: "lawsociety",
                title: "lawsociety page",
                component: LawSociety,
                path: "/officemanagement/officeInformation/lawsociety",
              },
              {
                name: "IndemnityInsurance",
                title: "IndemnityInsurance page",
                component: IndemnityInsurance,
                path: "/officemanagement/officeInformation/indemnityinsurance",
              },
              {
                name: "LEXCELOfficeManual",
                title: "LEXCELOfficeManual page",
                component: LEXCELOfficeManual,
                path: "/officemanagement/officeInformation/LEXCELOfficeManual",
              },
              {
                name: "CQS",
                title: "CQS page",
                component: CQS,
                path: "/officemanagement/officeInformation/CQS",
              },
              {
                name: "COLPCOFA",
                title: "COLPCOFA page",
                component: COLPCOFA,
                path: "/officemanagement/officeInformation/colpcofa",
              },
              {
                name: "GuideGDPR",
                title: "GuideGDPR page",
                component: GuideGDPR,
                path: "/officemanagement/officeInformation/guidegdpr",
              },
              {
                name: "ExpertRegister",
                title: "ExpertRegister page",
                component: ExpertRegister,
                path: "/officemanagement/officeInformation/expertregister",
                routes: [
                  {
                    name: "addExpert",
                    title: "addExpert page",
                    component: AddExpert,
                    path: "/officemanagement/officeInformation/expertregister/addExpert",
                  },
                  {
                    name: "updateExpert",
                    title: "updateExpert page",
                    component: AddExpert,
                    path: "/officemanagement/officeInformation/expertregister/updateExpert/:referenceNumber",
                  },
                ],
              },
            ],
          },
          {
            name: "postregister",
            title: "postregister page",
            routes: [
              {
                name: "IncomingPost",
                title: "IncomingPost page",
                component: IncomingPost,
                path: "/officemanagement/postregister/incomingpost",
              },
              {
                name: "OutgoingPost",
                title: "OutgoingPost page",
                component: OutgoingPost,
                path: "/officemanagement/postregister/outgoingpost",
              },
            ],
          },
          // {
          //   name: "Timesheets",
          //   title: "Timesheets page",
          //   component: Timesheets,
          //   path: "/officemanagement/timesheets",
          // },
        ],
      },
      {
        name: "CaseManagement",
        title: "CaseManagement page",
        routes: [
          {
            name: "Contacts",
            title: "Contacts page",
            component: Contacts,
            path: "/caseManagement/contacts",
            routes: [
              {
                name: "addcontact",
                title: "addcontact page",
                component: AddContact,
                path: "/caseManagement/contacts/addcontact",
              },
              {
                name: "updatecontact",
                title: "updatecontact page",
                component: AddContact,
                path: "/caseManagement/contacts/updatecontact/:referenceNumber",
              },
            ],
          },
          {
            name: "Clients",
            title: "Clients page",
            component: Clients,
            path: "/caseManagement/clients",
            routes: [
              {
                name: "addclient",
                title: "addclient page",
                component: AddClient,
                path: "/caseManagement/clients/addclient",
              },
              {
                name: "updateclient",
                title: "updateclient page",
                component: AddClient,
                path: "/caseManagement/clients/updateclient/:referenceNumber",
              },
            ],
          },
          {
            name: "Case",
            title: "Case page",
            routes: [
              {
                name: "CaseManagement",
                title: "CaseManagement page",
                component: CaseManagement,
                path: "/caseManagement/case/casemanagement",
              },
              {
                name: "addcase",
                title: "addcase page",
                component: AddCase,
                path: "/caseManagement/case/casemanagement/addcase",
              },
              {
                name: "editcase",
                title: "editcase page",
                component: EditCase,
                path: "/caseManagement/case/casemanagement/editcase/:referenceNumber",
                componentroutes: [
                  {
                    name: "casedetails",
                    title: "casedetails page",
                    component: CaseDetails,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/casedetails",
                  },
                  {
                    name: "permission",
                    title: "permission page",
                    component: Permission,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/permission",
                  },
                  {
                    name: "riskassesment",
                    title: "riskassesment page",
                    component: RiskAssesment,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/riskassesment",
                  },
                  {
                    name: "aml",
                    title: "aml page",
                    component: AML,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/aml",
                  },
                  {
                    name: "client",
                    title: "client page",
                    component: Client,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/client",
                  },
                  {
                    name: "conflictsearchs",
                    title: "conflictsearchs page",
                    component: CaseConflictSearch,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/conflictsearchs",
                  },
                  {
                    name: "contact",
                    title: "contact page",
                    component: Contact,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/contact",
                  },
                  {
                    name: "correspondence",
                    title: "correspondence page",
                    component: Correspondence,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/correspondence",
                  },
                  {
                    name: "filereview",
                    title: "filereview page",
                    component: FileReview,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/filereview",
                  },
                  {
                    name: "postregister",
                    title: "postregister page",
                    component: PostRegister,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/postregister",
                  },
                  {
                    name: "moneylaundering",
                    title: "moneylaundering page",
                    component: MoneyLaundering,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/moneylaundering",
                  },
                  {
                    name: "searchDocuments",
                    title: "searchDocuments page",
                    component: SearchDocuments,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/searchDocuments",
                  },
                  {
                    name: "titleDocument",
                    title: "titleDocument page",
                    component: TitleDocument,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/titleDocument",
                  },
                  {
                    name: "ledgercard",
                    title: "ledgercard page",
                    component: LedgerCard,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/ledgercard",
                  },
                  {
                    name: "invoice",
                    title: "invoice page",
                    component: Invoice,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/invoice",
                  },
                  {
                    name: "timeentry",
                    title: "timeentry page",
                    component: CaseTimeEntry,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/timeentry",
                  },
                  {
                    name: "paymentauthorities",
                    title: "paymentauthorities page",
                    component: PaymentAuthorities,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/paymentauthorities",
                  },
                  {
                    name: "giventoclients",
                    title: "giventoclients page",
                    component: GivenToClients,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/giventoclients",
                  },
                  {
                    name: "casestatus",
                    title: "casestatus page",
                    component: CaseStatus,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/casestatus",
                  },
                  {
                    name: "caseclosures",
                    title: "caseclosures page",
                    component: CaseClosures,
                    path: "/caseManagement/case/casemanagement/editcase/:referenceNumber/caseclosures",
                  },
                ],
              },
              {
                name: "ArchivedManagement",
                title: "ArchivedManagement page",
                component: ArchivedManagement,
                path: "/caseManagement/case/archivedmanagement",
              },
              {
                name: "WarehousedManagement",
                title: "WarehousedManagement page",
                component: WarehousedManagement,
                path: "/caseManagement/case/warehousedmanagement",
              },
            ],
          },
          {
            name: "tasks",
            title: "tasks page",
            component: Tasks,
            path: "/caseManagement/case/tasks",
          },
        ],
      },
      {
        name: "FinanceAndAccounting",
        title: "FinanceAndAccounting page",
        routes: [
          {
            name: "Accountings",
            title: "Accountings page",
            routes: [
              {
                name: "AccountsSetup",
                title: "AccountsSetup page",
                component: AccountsSetup,
                path: "/financeandaccounting/accountings/accountssetup",
                componentroutes:[
                  {
                    name:"general",
                    title:"general page",
                    component:General,
                    path: "/financeandaccounting/accountings/accountssetup/general",                 
                  },
                  {
                    name:"VatPeriod",
                    title:"VatPeriod page",
                    component:VatPeriod,
                    path: "/financeandaccounting/accountings/accountssetup/vatperiod",                 
                  },
                  {
                    name:"FinancialYear",
                    title:"FinancialYear page",
                    component:FinancialYear,
                    path: "/financeandaccounting/accountings/accountssetup/financialyear",                 
                  },
                  {
                    name:"BankAccounts",
                    title:"BankAccount page",
                    component:BankAccount,
                    path: "/financeandaccounting/accountings/accountssetup/bankaccount",                 
                  },
                  {
                    name:"NominalLedgerSetup",
                    title:"NominalLedgerSetup page",
                    component:NominalLedgerSetup,
                    path: "/financeandaccounting/accountings/accountssetup/nominalledgersetup",                 
                  },
                  {
                    name:"CaseTypesIncomeLedgers",
                    title:"CaseTypesIncomeLedgers page",
                    component:CaseTypesIncomeLedgers,
                    path: "/financeandaccounting/accountings/accountssetup/casetypesincomeledgers",                 
                  },
                  {
                    name:"FeeType",
                    title:"FeeType page",
                    component:FeeTypes,
                    path: "/financeandaccounting/accountings/accountssetup/feetypes",                 
                  },
                  {
                    name:"DisbursementType",
                    title:"DisbursementType page",
                    component:DisbursementTypes,
                    path: "/financeandaccounting/accountings/accountssetup/disbursementtypes",                 
                  },
                  {
                    name:"InvoiceNotice",
                    title:"InvoiceNotice page",
                    component:InvoiceNotices,
                    path: "/financeandaccounting/accountings/accountssetup/invoicenotices",                 
                  },
                  {
                    name:"QuotationNotice",
                    title:"QuotationNotice page",
                    component:QuotationNotices,
                    path: "/financeandaccounting/accountings/accountssetup/quotationnotices",                 
                  },
                  {
                    name:"VATExIncomeAccount",
                    title:"VATExIncomeAccount page",
                    component:VATExIncomeAccounts,
                    path: "/financeandaccounting/accountings/accountssetup/vatexincomeaccounts",                 
                  },
                  {
                    name:"TimeEntryType",
                    title:"TimeEntryType page",
                    component:TimeEntryTypes,
                    path: "/financeandaccounting/accountings/accountssetup/timeentrytypes",                 
                  },
                  {
                    name:"TimeEntryUnitPrice",
                    title:"TimeEntryUnitPrice page",
                    component:TimeEntryUnitPrices,
                    path: "/financeandaccounting/accountings/accountssetup/timeentryunitprices",                 
                  },
                  {
                    name:"PartnershipAccounts",
                    title:"PartnershipAccounts page",
                    component:PartnershipAccounts,
                    path: "/financeandaccounting/accountings/accountssetup/partnershipaccounts",                 
                  },
                  {
                    name:"FixedFeeInvoiceFormat",
                    title:"FixedFeeInvoiceFormat page",
                    component:FixedFeeInvoiceFormat,
                    path: "/financeandaccounting/accountings/accountssetup/fixedfeeinvoiceformat",                 
                  },
                ]
              },
              {
                name: "ClientAccounts",
                title: "ClientAccounts page",
                component: ClientAccounts,
                path: "/financeandaccounting/accountings/clientaccounts",
                componentroutes: [
                  {
                    name: "clientbankaccount",
                    title: "clientbankaccount page",
                    component: ClientBankAccount,
                    path: "/financeandaccounting/accountings/clientaccounts/clientbankaccount",
                  },
                  {
                    name: "clientpostings",
                    title: "clientpostings page",
                    component: ClientPostings,
                    path: "/financeandaccounting/accountings/clientaccounts/clientpostings",
                  },
                  {
                    name: "ledgercards",
                    title: "ledgercards page",
                    component: LedgerCards,
                    path: "/financeandaccounting/accountings/clientaccounts/ledgercards",
                  },
                  {
                    name: "clientinvoice",
                    title: "clientinvoice page",
                    component: ClientInvoice,
                    path: "/financeandaccounting/accountings/clientaccounts/clientinvoice",
                  },
                  {
                    name: "quickinvoice",
                    title: "quickinvoice page",
                    component: QuickInvoice,
                    path: "/financeandaccounting/accountings/clientaccounts/quickinvoice",
                  },
                  {
                    name: "bankreconciliation",
                    title: "bankreconciliation page",
                    component: BankReconciliation,
                    path: "/financeandaccounting/accountings/clientaccounts/bankreconciliation",
                  },
                  {
                    name: "financialstatement",
                    title: "financialstatement page",
                    component: FinancialStatement,
                    path: "/financeandaccounting/accountings/clientaccounts/financialstatement",
                  },
                  {
                    name: "clientreports",
                    title: "clientreports page",
                    component: ClientReports,
                    path: "/financeandaccounting/accountings/clientaccounts/clientreports",
                  },
                ],
              },
              {
                name: "OfficeAccounts",
                title: "OfficeAccounts page",
                component: OfficeAccounts,
                path: "/financeandaccounting/accountings/officeaccounts",
                componentroutes:[
                  {
                    name: "GeneralSettings",
                    title: "GeneralSettings page",
                    component: GeneralSettings,
                    path: "/financeandaccounting/accountings/officeaccounts/generalsettings",
                  },
                  {
                    name: "ChartOfAccounts",
                    title: "ChartOfAccounts page",
                    component: ChartOfAccounts,
                    path: "/financeandaccounting/accountings/officeaccounts/chartofaccounts",
                  },
                  {
                    name: "NominalLedgers",
                    title: "NominalLedgers page",
                    component: NominalLedgers,
                    path: "/financeandaccounting/accountings/officeaccounts/nominalledgers",
                  },
                  {
                    name: "OfficeBankAccounts",
                    title: "OfficeBankAccounts page",
                    component: OfficeBankAccounts,
                    path: "/financeandaccounting/accountings/officeaccounts/officebankaccounts",
                  },
                  {
                    name: "OfficePostings",
                    title: "OfficePostings page",
                    component: OfficePostings,
                    path: "/financeandaccounting/accountings/officeaccounts/officepostings",
                  },
                  {
                    name: "OfficeBankReconciliation",
                    title: "OfficeBankReconciliation page",
                    component: OfficeBankReconciliation,
                    path: "/financeandaccounting/accountings/officeaccounts/officebankreconciliation",
                  },
                  {
                    name: "OfficeReports",
                    title: "OfficeReports page",
                    component: OfficeReports,
                    path: "/financeandaccounting/accountings/officeaccounts/officereports",
                  },
                  {
                    name: "OfficeSuppliers",
                    title: "OfficeSuppliers page",
                    component: OfficeSuppliers,
                    path: "/financeandaccounting/accountings/officeaccounts/officesuppliers",
                  },
                  {
                    name: "OfficeVATReturns",
                    title: "OfficeVATReturns page",
                    component: OfficeVATReturns,
                    path: "/financeandaccounting/accountings/officeaccounts/officevatreturns",
                  },
                  {
                    name: "Budget",
                    title: "Budget page",
                    component: Budget,
                    path: "/financeandaccounting/accountings/officeaccounts/budget",
                  },
                  {
                    name: "YearEnd",
                    title: "YearEnd page",
                    component: YearEnd,
                    path: "/financeandaccounting/accountings/officeaccounts/yearend",
                  },
                ]
              },
            ],
          },
          {
            name: "Reports",
            title: "Reports page",
            routes: [
              {
                name: "GeneralReports",
                title: "GeneralReports page",
                component: GeneralReports,
                path: "/financeandaccounting/reports/generalreports",
                componentroutes:[
                  {
                    name: "ActiveCases",
                    title: "ActiveCases page",
                    component: ActiveCases,
                    path: "/financeandaccounting/reports/generalreports/activecases",
                  },
                  {
                    name: "OpenedAndClosedCases",
                    title: "OpenedAndClosedCases page",
                    component: OpenedAndClosedCases,
                    path: "/financeandaccounting/reports/generalreports/openedandclosedcases",
                  },
                  {
                    name: "ActiveCasesDetails",
                    title: "ActiveCasesDetails page",
                    component: ActiveCasesDetails,
                    path: "/financeandaccounting/reports/generalreports/activecasesdetails",
                  },
                  {
                    name: "ClosedCasesDetails",
                    title: "ClosedCasesDetails page",
                    component: ClosedCasesDetails,
                    path: "/financeandaccounting/reports/generalreports/closedcasesdetails",
                  },
                  {
                    name: "ComplianceReport",
                    title: "ComplianceReport page",
                    component: ComplianceReport,
                    path: "/financeandaccounting/reports/generalreports/compliancereport",
                  },
                  {
                    name: "IdleCases",
                    title: "IdleCases page",
                    component: IdleCases,
                    path: "/financeandaccounting/reports/generalreports/idlecases",
                  },
                ]
              },
              {
                name: "ClientAccountReports",
                title: "ClientAccountReports page",
                component: ClientAccountReports,
                path: "/financeandaccounting/reports/clientaccountreports",
                componentroutes:[
                  {
                    name: "TimeSheets",
                    title: "TimeSheets page",
                    component: ClientTimeSheets,
                    path: "/financeandaccounting/reports/clientaccountreports/timesheets",
                  },
                  {
                    name: "FeeEarner",
                    title: "FeeEarner page",
                    component: FeeEarner,
                    path: "/financeandaccounting/reports/clientaccountreports/feeearner",
                  },
                ]
              },
            ],
          },
        ],
      },
      {
        name: "RiskManagement",
        title: "RiskManagement page",
        routes: [
          {
            name: "RiskAssessment",
            title: "RiskAssessment page",
            component: RiskAssessment,
            path: "/riskmanagement/riskassessment",
            componentroutes: [
              {
                name: "QuotationRequest",
                title: "QuotationRequest page",
                component: QuotationRequest,
                path: "/riskmanagement/riskassessment/quotationRequest",
              },
              {
                name: "assessment",
                title: "assessment page",
                component: Assessment,
                path: "/riskmanagement/riskassessment/assessment",
              },
              {
                name: "QuotationGeneration",
                title: "QuotationGeneration page",
                component: QuotationGeneration,
                path: "/riskmanagement/riskassessment/quotationgeneration",
              },
              {
                name: "GeneratedQuotation",
                title: "GeneratedQuotation page",
                component: GeneratedQuotation,
                path: "/riskmanagement/riskassessment/generatedquotation",
              },
            ],
          },
          {
            name: "ConflictSearch",
            title: "ConflictSearch page",
            component: ConflictSearch,
            path: '/riskmanagement/conflictsearch'
          },
          {
            name: 'AddConflictSearch',
            title: 'AddConflictSearch page',
            component: AddConflictSearch,
            path: '/riskmanagement/addconflictsearch'

          },
          {
            name: "QuotationManagement",
            title: "QuotationManagement page",
            component: QuotationManagement,
            path: "/riskmanagement/quotationmanagement",
            routes:[
              {
                name: "AddQuotationManagement",
                title: "AddQuotationManagement page",
                component: AddQuotationManagement,
                path: "/riskmanagement/quotationmanagement/addQuotationmanagement",
              },
            ]
          },
        ],
      },
      {
        name: "HumanResource",
        title: "HumanResource page",
        routes: [
          {
            name: "HumanResources",
            title: "HumanResources page",
            routes: [
              {
                name: "TrainingRecords",
                title: "TrainingRecords page",
                component: TrainingRecords,
                path: "/humanresource/humanresources/trainingrecords",
              },
              {
                name: "StaffAttendance",
                title: "StaffAttendance page",
                component: StaffAttendance,
                path: "/humanresource/humanresources/staffattendance",
              },
              {
                name: "LeaveRequests",
                title: "LeaveRequests page",
                component: LeaveRequests,
                path: "/humanresource/humanresources/leaverequests",
              },
              {
                name: "LeaveManagement",
                title: "LeaveManagement page",
                component: LeaveManagement,
                path: "/humanresource/humanresources/leavemanagement",
              },
            ],
          },
          {
            name: "Surveys",
            title: "Surveys page",
            routes: [
              {
                name: "CreateSurvey",
                title: "CreateSurvey page",
                component: CreateSurvey,
                path: "/humanresource/surveys/createsurvey",
                componentroutes: [
                  {
                    name: "Survey",
                    title: "Survey page",
                    component: Survey,
                    path: "/humanresource/surveys/createsurvey/survey",
                  },
                  {
                    name: "CreateQuestions",
                    title: "CreateQuestions page",
                    component: CreateQuestions,
                    path: "/humanresource/surveys/createsurvey/createquestions",
                  },
                  {
                    name: "QuestionOrder",
                    title: "QuestionOrder page",
                    component: QuestionOrder,
                    path: "/humanresource/surveys/createsurvey/questionorder",
                  },
                  {
                    name: "PreviewAndPublish",
                    title: "PreviewAndPublish page",
                    component: PreviewAndPublish,
                    path: "/humanresource/surveys/createsurvey/previewandpublish",
                  },
                ],
              },
              {
                name: "SurveyResults",
                title: "SurveyResults page",
                component: SurveyResults,
                path: "/humanresource/surveys/surveyresults",
                componentroutes: [
                  {
                    name: "ViewSurveyResults",
                    title: "ViewSurveyResults page",
                    component: ViewSurveyResults,
                    path: "/humanresource/surveys/surveyresults/viewsurveyresults",
                  },
                  {
                    name: "AnalyseSurveyResults",
                    title: "AnalyseSurveyResults page",
                    component: AnalyseSurveyResults,
                    path: "/humanresource/surveys/surveyresults/analysesurveyresults",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "SystemManagement",
        title: "SystemManagement page",
        routes: [
          {
            name: "SystemMaintenance",
            title: "SystemMaintenance page",
            routes: [
              {
                name: "CaseType",
                title: "CaseType page",
                component: CaseType,
                path: "/systemmanagement/SystemMaintenance/casetype",
              },
              {
                name: "RiskAssessment",
                title: "RiskAssessment page",
                component: SystemRiskAssessment,
                path: "/systemmanagement/systemmaintenance/systemriskassessment",
              },
              {
                name: "RiskAssessment question",
                title: "RiskAssessment question  page",
                component: AddSystemRiskAssessment,
                path: "/systemmanagement/systemmaintenance/systemriskassessment/addquestion/:referenceNumber",
              },
              {
                name: "CaseReferenceFormat",
                title: "CaseReferenceFormat page",
                component: CaseReferenceFormat,
                path: "/systemmanagement/systemmaintenance/casereferenceformat",
                routes: [
                  {
                    name: "addcasereferenceformat",
                    title: "addcasereferenceformat page",
                    component: AddCaseReference,
                    path: "/systemmanagement/systemmaintenance/addcasereferenceformat",
                  },
                  {
                    name: "updatecasereferenceformat",
                    title: "updatecasereferenceformat page",
                    component: AddCaseReference,
                    path: "/systemmanagement/systemmaintenance/updatecasereferenceformat",
                  },
                  // {
                  //   name: 'updateBranch',
                  //   title: 'updateBranch page',
                  //   component: AddBranchForm,
                  //   path: '/administrator/CompanyProfile/updatebranch'
                  // }
                ],
              },
              {
                name: "CaseReferenceClientFormat",
                title: "CaseReferenceClientFormat page",
                component: CaseReferenceClientFormat,
                path: "/systemmanagement/systemmaintenance/casereferenceclientFormat",
              },
              {
                name: "DocumentCategorys",
                title: "DocumentCategorys page",
                component: DocumentCategorys,
                path: "/systemmanagement/systemmaintenance/documentcategorys",
              },
              {
                name: "DocumentManagement",
                title: "DocumentManagement page",
                component: DocumentManagement,
                path: "/systemmanagement/systemmaintenance/documentmanagement",
                routes: [
                  {
                    name: "AddDocument",
                    title: "AddDocument page",
                    component: AddDocument,
                    path: "/systemmanagement/systemmaintenance/documentmanagement/documentadd",
                  },
                ],
              },
              {
                name: "Checklist",
                title: "Checklist page",
                component: Checklist,
                path: "/systemmanagement/systemmaintenance/checklist",
                routes: [
                  {
                    name: "editchecklist",
                    title: "editchecklist page",
                    component: EditChecklist,
                    path: "/systemmanagement/systemmaintenance/updatechecklist/:referenceNumber",
                  },
                ]
              },
              {
                name: "WorkflowSetup",
                title: "WorkflowSetup page",
                component: WorkflowSetup,
                path: "/systemmanagement/systemmaintenance/workflowsetup",
                routes: [
                  {
                    name: "addWorkflowSetup",
                    title: "addWorkflowSetup page",
                    component: AddWorkflowSetup,
                    path: "/systemmanagement/systemmaintenance/addWorkflow",
                  },
                  {
                    name: "editWorkflowSetup",
                    title: "editWorkflowSetup page",
                    component: AddWorkflowSetup,
                    path: "/systemmanagement/systemmaintenance/updateWorkflow/:referenceNumber",
                  },
                ],
              },
              {
                name: "constantDatesAndTasks",
                title: "constantDatesAndTasks page",
                component: constantDatesAndTasks,
                path: "/systemmanagement/systemmaintenance/constantDatesAndTasks",
              },
              {
                name: "CaseClosure",
                title: "CaseClosure page",
                component: CaseClosure,
                path: "/systemmanagement/systemmaintenance/caseclosureareas",
              },
              {
                name: "CustomFieldForCaseType",
                title: "CustomFieldForCaseType page",
                component: CustomFieldForCaseType,
                path: "/systemmanagement/systemmaintenance/customfieldForcaseType",
                routes: [
                  {
                    name: "addcustomfieldForcaseType",
                    title: "addcustomfieldForcaseType page",
                    component: AddCustomFieldForCaseType,
                    path: "/systemmanagement/systemmaintenance/addcustomfieldForcaseType",
                  },
                ]
              },
              {
                name: "MergeFields",
                title: "MergeFields page",
                component: MergeFields,
                path: "/systemmanagement/systemmaintenance/mergefields",
              },
              {
                name: "ImportantDatesAndTasks",
                title: "ImportantDatesAndTasks page",
                component: ImportantDatesAndTasks,
                path: "/systemmanagement/systemmaintenance/importantdatesandtasks",
              },
              {
                name: "ChangeCorrespondanceDate",
                title: "ChangeCorrespondanceDate page",
                component: ChangeCorrespondanceDate,
                path: "/systemmanagement/systemmaintenance/changecorrespondancedate",
              },
              {
                name: "ChangeCase",
                title: "ChangeCase page",
                component: ChangeCase,
                path: "/systemmanagement/systemmaintenance/changecase",
              },
            ],
          },
        ],
      },
      {
        name: "test",
        title: "test page",
        component: Test,
        path: "/test",
      },
      // P@$$word34@
      // {
      //   name: 'users',
      //   title: 'Users',
      //   hasSiderLink: true,
      //   routes: [
      //     {
      //       name: 'list-users',
      //       title: 'List of users',
      //       hasSiderLink: true,
      //       component: ListUsers,
      //       path: '/users'
      //     },
      //     {
      //       name: 'create-user',
      //       title: 'Add user',
      //       hasSiderLink: true,
      //       component: CreateUser,
      //       path: '/users/new'
      //     }
      //   ]
      // }
    ],
  },
];
// Just add this line
export const Routes = renderRoutes(routes);
