import { Datepicker } from 'flowbite';
import type { DatepickerOptions } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import React, { useEffect, useRef } from 'react';

interface DatePickerWrapperProps {
    value?: Date | null;
    onChange: (date: Date | null) => void;
    required?: boolean;
}

const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({ value, onChange, required }) => {
    const [error, setError] = React.useState<string | null>(null);
    const datepickerInstance  = useRef<Datepicker | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            const options: DatepickerOptions = {
                format: 'dd/mm/yyyy',
                autohide: true,
                onShow: () => {},
                onHide: () => {},
            };

            const instanceOptions: InstanceOptions = {
                id: 'datepicker-custom-example',
                override: true,
            };

            datepickerInstance.current = new Datepicker(inputRef.current, options, instanceOptions);
            if (value) {
                onChange(value)
                datepickerInstance.current.setDate(value);
            }

            inputRef.current.addEventListener('changeDate', handleDateChange);
            return () => {
                if (inputRef.current) {
                    inputRef.current.removeEventListener('changeDate', handleDateChange);
                }
                datepickerInstance.current?.destroy();
            };
        }
    }, []);

    useEffect(() => {
        if (datepickerInstance.current) {
            if (value) {
                onChange(value)
                datepickerInstance.current.setDate(value);
                setError(null);
            } else {
                datepickerInstance.current.setDate(null);
            }
        }
    }, [value]);

    const handleDateChange  = () => {
        try {
            const selectedDate = datepickerInstance.current?.getDate() || null;
            if (required && !selectedDate) {
                setError('Date is required');
            } else {
                setError(null);
            }

            if(selectedDate){
                onChange(new Date(selectedDate.toString()));
            }
        } catch (error) {
            console.log('Error handling date change:', error);
            setError('An unexpected error occurred');
        }
    };


    // const options: DatepickerOptions = {
    //     defaultDatepickerId: null,
    //     autohide: false,
    //     format: 'dd/mm/yyyy',
    //     maxDate: null,
    //     minDate: null,
    //     orientation: 'bottom',
    //     buttons: false,
    //     autoSelectToday: 0,
    //     title: null,
    //     rangePicker: false,
    //     onShow: () => {},
    //     onHide: () => {},
    // };
    const handleIconClick = () => {
        datepickerInstance.current?.show();
    };


    return (
        <div>
           <div className="relative max-w-sm">
            <input  ref={inputRef} id="datepicker-custom" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" readOnly/>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={handleIconClick}>
                    <svg
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                </div>
            </div>
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export { DatePickerWrapper };