import { Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from "react-icons/hi";

interface DeleteModalProps {
    open: boolean;
    onClose: any;
    title?: string;
    message?: string;
    id: number;
    onDeleteAccept: () => void;
}

const DeleteModal = ({
    open,
    onClose,
    id,
    title = "",
    message = "Are you sure?",
    onDeleteAccept
}: DeleteModalProps) => {

    return (<Modal className={`backdrop-blur-sm items-center justify-center`}
        show={open}
        position={'center'}
        size="md"
        onClick={onClose}>
        <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
            <span className="dark:text-white-bg text-xl w-auto">{title}</span>
        </Modal.Header>
        <Modal.Body className="bg-white-bg dark:bg-dark_bg">
            {/* <div className="text-center">            
            <p className="mb-5 text-base text-black-700 dark:text-black-300">
                {message}
            </p>
            <div className="flex justify-end gap-4">
                <button
                    type="button"
                    className="px-6 py-2 text-sm font-semibold text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                    onClick={onClose}>
                    No
                </button>

                <button type="button" className="px-6 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700"
                   onClick={onDeleteAccept}>
                    Yes
                </button>
            </div>
        </div> */}
            <div className="text-center">
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    {message}
                </h3>
                <div className="flex justify-center gap-4 ">
                    <button className='px-6 py-2 text-sm font-semibold text-white-bg bg-red-600 rounded-md hover:bg-red-700' onClick={onDeleteAccept}>
                       Yes, I'm sure
                    </button>
                    <button className='px-6 py-2 text-sm font-semibold text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600' onClick={onClose}>
                        No, cancel
                    </button>
                </div>
            </div>
        </Modal.Body>
    </Modal>

    );
};

export default DeleteModal;
