import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';

interface InputBoxProps {
    title?: string;
    name?: string;
    dataType?: string;
    placeholder?: string;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onValueChange?: (value: string, property?:string) => void;
    registerProps?: any; 
    error?: string;
}

const InputBox: React.FC<InputBoxProps> = ({ title,name, dataType, placeholder, value, required = false, disabled, onChange,onValueChange, registerProps,
    error, }) => {

    const handleValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
    };

    return (
        <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                {title} {required && <span className="text-red-500">*</span>}
            </label>

            {dataType === "NumericFormat" ? (
                <NumericFormat
                    value={value}
                    className={`border border-gray-300 bg-gray-100 text-sm rounded-md block w-full p-4 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white-bg ${
                        error ? "border-red-500" : "border-gray-300"
                    }`}
                    thousandSeparator={true}
                    prefix="£ "
                    displayType={disabled ? "text" : "input"}
                    {...registerProps} 
                    onValueChange={(values) => {
                        const { value } = values;
                        onValueChange?.(value, name);
                    }}
                />
            ) : (
                <input
                    name={name}
                    type={dataType}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleValidation}
                    disabled={disabled}
                    className={`border border-gray-300 bg-gray-100 text-sm rounded-md block w-full p-4 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white-bg ${
                        error ? "border-red-500" : "border-gray-300"
                    }`}
                    {...registerProps} 
                />
            )}
          
            {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
        </div>
    );
};

export { InputBox };


// import React from 'react';

// interface InputBoxProps {
//     title?: string;
//     dataType?: string;
//     placeholder?: string;
//     value?: string;
//     disabled?: boolean;
//     onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

// }
// const InputBox: React.FC<InputBoxProps> = ({  title, dataType, placeholder, disabled, value, onChange }) => {

//     return (
//         <div className="relative max-w-full col-span-2">
//             <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
//                 {title}
//             </label>
//             <input
//                 type={dataType}
//                 placeholder={placeholder}
//                 value={value} // Use value prop
//                 onChange={onChange} // Handle change event
//                 disabled={disabled} // Set disabled based on prop
//                 className={`border border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} 
//             />
//         </div>
//     )
// }

// export { InputBox };