import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";
import OfficeSupplierReFundCreditNoteService from "../../../../../services/Accounting/OfficeAccount/Suppliers/OASupplierRefundCreditNoteService";
import { OfficeSupplierCreditNote } from "./OfficeSupplierCreditNoteSlice";

export interface OfficeSupplierRefundCreditNote{
    lisitingData: ListingData[];
    refundCreditNoteId: number;

   
   }
   
   
   export interface ListingData {
       supplierCreditNoteId: number;
       supplierCreditNumber: number;
       fkSupplierId: number;
       invoice: string;
       netAmount: number;
       vat: number;
       grossAmount: number;
       description: string;
       accountBalance: string;
       transactionDescription: string;
       transactionDate: string;
       entryDate: string;
     fkSupplierBillEntryId: number;
     creditNoteDate: string; // ISO date string format
     creditNoteLineItems: CreditNoteLineItem[];
      
     
   };
   
   export interface CreditNoteLineItem{
       lineId: number;
       accountId: number;
       description: string;
       netAmount: number;
       fkVatTypeId: number;
       vat: number;
       grossAmount: number;
       lineOrder: number;
   }

   export interface SearchOfficeSupplierRefundCreditNoteResult {
    creditNoteRefunds: any[];
    currentPage: number;
    recordPerPage: number;
    totalRows: number

};
   // Define the state type for Checklist
   type OfficeSupplierRefundCreditNoteState = {
       officeSupplierRefundCreditNotes: OfficeSupplierRefundCreditNote | null;
       searchOfficeSupplierRefundCreditNoteResult: SearchOfficeSupplierRefundCreditNoteResult | null;
       status: "idle" | "loading" | "failed";
       error: string | null;
   };
   
   // Initial state
   const initialState: OfficeSupplierRefundCreditNoteState = {
       officeSupplierRefundCreditNotes: null,
       searchOfficeSupplierRefundCreditNoteResult: null,
       status: "idle",
       error: null
   };
   
   // Async thunks for CRUD operations
   export const fetchAllOfficeSupplierRefundCreditNote = createAsyncThunk(
       "fetchAllOfficeSupplierRefundCreditNote",
       async (data: any, thunkAPI) => {
           try {
               const response = await OfficeSupplierReFundCreditNoteService.GetAllOfficeSupplierReFundCreditNote(data);
               return response;
           } catch (error: any) {
               return thunkAPI.rejectWithValue(error.message);
           }
       }
   );
   
   export const addOfficeSupplierRefundCreditNote = createAsyncThunk(
       "addOfficeSupplierRefundCreditNote",
       async (data: any, thunkAPI) => {
           try {
               const response = await OfficeSupplierReFundCreditNoteService.PostOfficeSupplierReFundCreditNote(data);
               return response;
           } catch (error: any) {
               return thunkAPI.rejectWithValue(error.message);
           }
       }
   );
   
   export const updateOfficeSupplierRefundCreditNote = createAsyncThunk(
       "updateOfficeSupplierRefundCreditNote",
       async ({ id, data }: { id: number, data: OfficeSupplierRefundCreditNote }, thunkAPI) => {
           try {
               const response = await OfficeSupplierReFundCreditNoteService.PutOfficeSupplierReFundCreditNote(id, data);
               return response;
           } catch (error: any) {
               return thunkAPI.rejectWithValue(error.message);
           }
       }
   );
   
   export const deleteOfficeSupplierRefundCreditNote = createAsyncThunk(
       "deleteOfficeSupplierRefundCreditNote",
       async (id: number, thunkAPI) => {
           try {
               await OfficeSupplierReFundCreditNoteService.deleteOfficeSupplierReFundCreditNote(id);
               return id;
           } catch (error: any) {
               return thunkAPI.rejectWithValue(error.message);
           }
       }
   );
   
   // Slice definition
   const OfficeSupplierRefundCreditNoteSlice = createSlice({
       name: "officeSupplierRefundCreditNote",
       initialState,
       reducers: {},
       extraReducers: (builder) => {
           builder
               .addCase(fetchAllOfficeSupplierRefundCreditNote.pending, (state) => {
                   state.status = "loading";
               })
               .addCase(fetchAllOfficeSupplierRefundCreditNote.fulfilled, (state, action: PayloadAction<SearchOfficeSupplierRefundCreditNoteResult>) => {
                   state.status = "idle";
                   state.searchOfficeSupplierRefundCreditNoteResult = action.payload;
               })
               .addCase(fetchAllOfficeSupplierRefundCreditNote.rejected, (state, action) => {
                   state.status = "failed";
                   state.error = action.payload as string;
               })
               .addCase(addOfficeSupplierRefundCreditNote.pending, (state) => {
                   state.status = "loading";
               })
               .addCase(addOfficeSupplierRefundCreditNote.fulfilled, (state, action: PayloadAction<PaginationResponse<OfficeSupplierRefundCreditNote>>) => {
                   state.status = "idle";
                   //state.officeSupplierCreditNotes.push(action.payload);
               })
               .addCase(addOfficeSupplierRefundCreditNote.rejected, (state, action) => {
                   state.status = "failed";
                   state.error = action.payload as string;
               })
               .addCase(updateOfficeSupplierRefundCreditNote.pending, (state) => {
                   state.status = "loading";
               })
               .addCase(updateOfficeSupplierRefundCreditNote.fulfilled, (state, action: PayloadAction<OfficeSupplierRefundCreditNote>) => {
                   state.status = "idle";
                   // const index = state.officeSupplierCreditNotes.findIndex(t => t.officeSupplierCreditNoteId === action.payload.officeSupplierCreditNoteId);
                   // if (index !== -1) {
                   //     state.officeSupplierCreditNotes[index] = action.payload;
                   // }
               })
               .addCase(updateOfficeSupplierRefundCreditNote.rejected, (state, action) => {
                   state.status = "failed";
                   state.error = action.payload as string;
               })
               .addCase(deleteOfficeSupplierRefundCreditNote.pending, (state) => {
                   state.status = "loading";
               })
               .addCase(deleteOfficeSupplierRefundCreditNote.fulfilled, (state, action: PayloadAction<number>) => {
                   state.status = "idle";
                  // state.officeSupplierCreditNotes = state.officeSupplierCreditNotes.filter(t => t.officeSupplierCreditNoteId !== action.payload);
               })
               .addCase(deleteOfficeSupplierRefundCreditNote.rejected, (state, action) => {
                   state.status = "failed";
                   state.error = action.payload as string;
               });
       }
   });
   
   export default OfficeSupplierRefundCreditNoteSlice.reducer;
   