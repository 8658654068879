import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";



const GetAllBillPaymentDueList = async () => {
    try {
        const response = await axiosInstance.get("Supplier/billpayment/duelist");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllBillPaymentDataById = async (id: number) => {
    try {
        const response = await axiosInstance.get(`Supplier/billpayment/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllBillPayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/billpayment/search",data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostBillPayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/billpayment/add", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutBillPayment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`BillPayment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteBillPayment = async (id: number) => {
    try {
        await axiosInstance.delete(`Supplier/billpayment/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const BillPaymentService = {
    GetAllBillPayment,
    PostBillPayment,
    PutBillPayment,
    deleteBillPayment,
    GetAllBillPaymentDueList,
    GetAllBillPaymentDataById
};


export default BillPaymentService;
