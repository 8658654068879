import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseCorrespondenceServices from "../../../../services/CaseManagement/Case/CaseCorrespondenceServices";


export interface CaseCorrespondenceDate {
    caseId: number;
    corrId: number;
    newDate: string;
}
export interface CorrespondenceDataByCase {

}
export interface CorrespondenceDataBy{
    caseImportWfId: number;
    dateImported: string;
    designation: string;
    firstName: string;
    fkBranchId: number;
    fkCaseId: number;
    fkWfId: number;
    lastName: string;
    userImported: number;
}

export interface Unimportworkflows {

}
export interface Deletedworkflows {

}

// Define the state type for cases
type CaseState = {
    any: any[];
    caseCorrespondenceDate: CaseCorrespondenceDate[];
    correspondenceDataByCase: CorrespondenceDataByCase[];
    unimportworkflows: Unimportworkflows[] | null;
    deletedworkflows: Deletedworkflows[] | null;
    correspondenceDataBy: CorrespondenceDataBy;
    hasCorrespondence: boolean | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
    correspondenceImport: boolean;
};

// Initial state
const initialState: CaseState = {
    caseCorrespondenceDate: [],
    unimportworkflows: [],
    deletedworkflows: [],
    correspondenceDataByCase: [],
    correspondenceDataBy: null!,
    any: null!,
    hasCorrespondence: null!,
    correspondenceImport: null!,
    status: "idle",
    error: null
};


export const fetchCaseCorrespondenceById = createAsyncThunk(
    "fetchCaseCorrespondenceById",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseCorrespondenceById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseCorrespondenceBy = createAsyncThunk(
    "fetchCaseCorrespondenceBy",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseCorrespondenceBy(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseworkflowimport = createAsyncThunk(
    "fetchCaseworkflowimport",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseworkflowimport(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCaseCorrespondenceDate = createAsyncThunk(
    "addCaseCorrespondenceDate",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.PostCaseCorrespondenceDate(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const fetchCaseWorkflowExists = createAsyncThunk(
    "fetchCaseWorkflowExists",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseWorkflowExists(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchUnimportworkflows = createAsyncThunk(
    "fetchUnimportworkflows",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetUnimportworkflows(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchDeletedworkflows = createAsyncThunk(
    "fetchDeletedworkflows",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetDeletedworkflows(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const caseworkflowimportOnly = createAsyncThunk(
    "caseworkflowimportOnly",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.CaseworkflowimportOnly(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const caseworkflowDeleteOnly = createAsyncThunk(
    "caseworkflowDeleteOnly",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.CaseworkflowDeleteOnly(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Slice definition
const CaseCorrespondenceSlice = createSlice({
    name: "caseCorrespondence",
    initialState,
    reducers: {
        clearCaseCorrDetails: (state) => {
            state.hasCorrespondence = null;
            state.deletedworkflows = null;
            state.unimportworkflows = null;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCaseCorrespondenceById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrespondenceById.fulfilled, (state, action: PayloadAction<CorrespondenceDataByCase[]>) => {
                state.status = "idle";
                state.correspondenceDataByCase = action.payload;
            })
            .addCase(fetchCaseCorrespondenceById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseCorrespondenceBy.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrespondenceBy.fulfilled, (state, action: PayloadAction<CorrespondenceDataBy>) => {
                state.status = "idle";
                state.correspondenceDataBy = action.payload;
            })
            .addCase(fetchCaseCorrespondenceBy.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseworkflowimport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseworkflowimport.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.status = "idle";
                state.correspondenceImport = action.payload;
            })
            .addCase(fetchCaseworkflowimport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseWorkflowExists.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseWorkflowExists.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.status = "idle";
                state.hasCorrespondence = action.payload;
            })
            .addCase(fetchCaseWorkflowExists.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCaseCorrespondenceDate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCaseCorrespondenceDate.fulfilled, (state, action: PayloadAction<CaseCorrespondenceDate>) => {
                state.status = "idle";
                state.caseCorrespondenceDate.push(action.payload);
            })
            .addCase(addCaseCorrespondenceDate.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(caseworkflowimportOnly.pending, (state) => {
                state.status = "loading";
            })
            .addCase(caseworkflowimportOnly.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(caseworkflowimportOnly.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(caseworkflowDeleteOnly.pending, (state) => {
                state.status = "loading";
            })
            .addCase(caseworkflowDeleteOnly.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(caseworkflowDeleteOnly.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchUnimportworkflows.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUnimportworkflows.fulfilled, (state, action: PayloadAction<Unimportworkflows[]>) => {
                state.status = "idle";
                state.unimportworkflows = action.payload;
            })
            .addCase(fetchUnimportworkflows.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchDeletedworkflows.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchDeletedworkflows.fulfilled, (state, action: PayloadAction<Deletedworkflows[]>) => {
                state.status = "idle";
                state.deletedworkflows = action.payload;
            })
            .addCase(fetchDeletedworkflows.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export const { clearCaseCorrDetails } = CaseCorrespondenceSlice.actions;
export default CaseCorrespondenceSlice.reducer;
