import axiosInstance from '../../axiosInstance';
import { ErrorResult } from '../../../store/model/errorResult';


const GetCaseCorrespondenceById = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/${id}/CaseCorrespondence`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetUnimportworkflows = async (id: number) => {
    try {
        const response = await axiosInstance.get(`caseworkflow/unimportworkflows/get/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetDeletedworkflows = async (id: number) => {
    try {
        const response = await axiosInstance.get(`caseworkflow/deletedworkflows/get/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseCorrespondenceBy = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/correspondenceBy/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
// app.MapGet("/api/case/correspondenceBy/{id}",

const PostCaseCorrespondenceDate = async (data: any) => {
    try {
        const response = await axiosInstance.post(`case/CaseCorrDateSave`, data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseWorkflowExists = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/${id}/CaseWorkflowExists`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseworkflowimport = async (data: any) => {
    try {
        const response = await axiosInstance.post("caseworkflow/import", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CaseworkflowimportOnly = async (data: any) => {
    try {
        const response = await axiosInstance.post("caseworkflow/importonly", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CaseworkflowDeleteOnly = async (data: any) => {
    try {
        const response = await axiosInstance.post("caseworkflow/deleteonly", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const CaseCorrespondenceServices = {
    GetCaseCorrespondenceById,
    GetCaseWorkflowExists,
    PostCaseCorrespondenceDate,
    GetCaseworkflowimport,
    GetCaseCorrespondenceBy,
    GetUnimportworkflows,
    GetDeletedworkflows,
    CaseworkflowimportOnly,
    CaseworkflowDeleteOnly
};

export default CaseCorrespondenceServices;
