import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { SelectBox } from "../../SelectBox";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function AddContactStepOne({ onSubmitStep, initialData, contactType, contactCategory, titleName, country, onCancel, maritalStatusData }: any) {
    const contactDataById = useSelector((state: RootState) => state.contact.contactDataById);

    const contactCategoryOption = contactCategory ? contactCategory.map((contactCategories: any) => ({
        label: contactCategories.contactCategory,
        value: contactCategories.contactCategoryId
    })) : [];

    const titleOption = titleName ? titleName.map((title: any) => ({
        label: title.title,
        value: title.titleId
    })) : [];

    const countryOption = country ? country.map((countries: any) => ({
        label: countries.nationality,
        value: countries.countryId
    })) : [];

    const validationSchema = object().shape({
        fkPersonalContactsCategoryId: number()
            .required('PersonalContactsCategory is required')
            .typeError('PersonalContactsCategory is required'),
        fkTitleIdPersonal: number()
            .required('Title is required')
            .typeError('Title is required'),
        givenNames: string()
            .required('Given Names is required'),
        lastName: string()
            .required('Last Name is required'),
        dateOfBirth: string()
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        sex: number(),
        fkMaritalStatusId: number().nullable()
            .transform((value, originalValue) => originalValue === '' ? null : value),
        fkCountryIdPersonal: number().nullable()
            .transform((value, originalValue) => originalValue === '' ? null : value),
    })

    // useEffect(() => {
    //     setValue('fkContactTypeId', contactType.find((conType: any) => conType.contactType === "Individual")?.contactTypeId);
    // }, [contactType, setValue]);

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
            // fkContactTypeId: contactType.find((conType: any) => conType.contactType === "Individual")?.contactTypeId,
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(()=>{
        if(contactDataById !== null){
            let dateOfBirth = (contactDataById?.dateOfBirth !== null) ? contactDataById?.dateOfBirth.split("T")[0] : null;
            setValue("fkPersonalContactsCategoryId", contactDataById?.fkPersonalContactsCategoryId);
            setValue("fkTitleIdPersonal", contactDataById?.fkTitleIdPersonal);
            setValue("givenNames", contactDataById?.givenNames);
            setValue("lastName", contactDataById?.lastName);
            setValue("dateOfBirth", dateOfBirth);
            setValue("sex", contactDataById?.sex);
            setValue("fkMaritalStatusId", contactDataById?.fkMaritalStatusId);
            setValue("fkCountryIdPersonal", contactDataById?.fkCountryIdPersonal);
        }
    },[contactDataById])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
            <div className="grid grid-cols-4 gap-x-10">
                <div className="col-start col-span-2">
                    <div className=" border dark:text-white-bg dark:border dark:border-gray-400 dark:border-opacity-35 p-6">
                        <div className="grid grid-cols-3">
                            <label className="col-span-1 text-sm font-medium text-gray-900 dark:text-white-bg">Select Category <span className="text-red-500">*</span></label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={contactCategoryOption}
                                isSearchable={true}
                                value={getValues().fkPersonalContactsCategoryId}
                                placeholder="Contact Category"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkPersonalContactsCategoryId', selectedOption);
                                    }
                                }}
                            />
                            <div className="col-start-2 font-medium mt-2 text-xs text-left text-red-600" >{errors?.fkPersonalContactsCategoryId?.message?.toString()}</div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Personal Details</h3>
            <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Title <span className="text-red-500">*</span>
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="Title"
                        options={titleOption}
                        isSearchable={true}
                        value={getValues().fkTitleIdPersonal}
                        placeholder="Title"
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setValue('fkTitleIdPersonal', selectedOption);
                            }
                        }}
                    />
                    <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.fkTitleIdPersonal?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Given Names <span className="text-red-500">*</span>
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.givenNames ? 'is-invalid' : ''}`} {...register('givenNames')} />
                    <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.givenNames?.message?.toString()} </div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Last Name <span className="text-red-500">*</span>
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.lastName ? 'is-invalid' : ''}`}
                        {...register('lastName')} />
                    <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.lastName?.message?.toString()} </div>
                </div>
                {/* <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        DOB <span className="text-red-500">*</span>
                    </label>
                    <input type="date" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.dateOfBirth ? 'is-invalid' : ''}`} {...register('dateOfBirth')} max={new Date().toISOString().split('T')[0]} />

                    <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.dateOfBirth?.message?.toString()} </div>
                </div> */}
                {/* <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Sex <span className="text-red-500">*</span>
                    </label>
                    <select className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.sex ? 'is-invalid' : ''}`} {...register('sex')}>
                        <option value="">Select</option>
                        <option value={0} >Male</option>
                        <option value={1} >Female</option>
                    </select>
                    <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.sex?.message?.toString()} </div>
                </div> */}
                {/* <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Marital Status</label>
                    <select className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('fkMaritalStatusId')}>
                        <option value="" >Select</option>
                        {maritalStatusData.map((marital: any) => (
                            <option key={marital.maritalStatusId
                            } value={marital.maritalStatusId
                            }>{marital.maritalStatus}</option>
                        ))}
                    </select>
                </div> */}
                {/* <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Nationality</label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="Country"
                        options={countryOption}
                        isSearchable={true}
                        placeholder="Country"
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setValue('fkCountryIdPersonal', selectedOption);
                            }
                        }}
                    />
                </div> */}
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddContactStepOne };
