
import { ErrorResult } from '../../../store/model/errorResult';
import axiosInstance from '../../axiosInstance';



const GetAllConflictSearch = async (data: any) => {
    try {
        const response = await axiosInstance.post("ConflictSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllConflictSearchData = async (data: any) => {
    try {
        const response = await axiosInstance.post("ConflictSearch/ConflictData", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const PostConflictSearch = async (data: any) => {
    try {
        const response = await axiosInstance.post("ConflictSearchData", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutConflictSearch = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`QuotationRequest/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteConflictSearch = async (caseSubTypeId: number) => {
    try {
        await axiosInstance.delete(`QuotationRequest/${caseSubTypeId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const ConflictSearchService = {
    GetAllConflictSearch,
    GetAllConflictSearchData,
    PostConflictSearch,
    PutConflictSearch,
    deleteConflictSearch,
};

export default ConflictSearchService;
