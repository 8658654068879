import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { SelectBox } from "../../../SelectBox";
import { SelectAddressComponent } from "../../../AutoSelectAddress/SelectAddress";
import { SearchComponent } from "../../../AutoSelectAddress/SearchInput";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { fetchLocationByPostcode } from "../../../../store/slice/CaseManagement/AddressSlice";
import LocationUtils from "../../../../utils/location.utils";


function AddOrganisationContactTwo({ onSubmitStep, initialData, country, onCancel }: any) {
    const dispatch = useAppDispatch();
    const [postcode, setPostcode] = useState('');
    const [locationOptionsLists, setLocationOptionsLists] = useState<any[]>([]);
    const { locations } = useSelector((state: RootState) => state.location);
    const [isLoading, setIsLoading] = useState(false);
    const effectServiceCall = useRef(false);
    const contactDataById = useSelector((state: RootState) => state.contact.contactDataById);

    useEffect(() => {
        if (!effectServiceCall.current && postcode) {
            setIsLoading(true);
            dispatch(fetchLocationByPostcode(postcode))
                .unwrap()
                .catch((error) => {
                    console.error("Error fetching location:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch, postcode]);

    const countryOption = country ? country.map((countries: any) => ({
        label: countries.nationality,
        value: countries.countryId
    })) : [];

    const validationSchema = object().shape({
        address1: string()
            .required("address Name Required"),
        address2: string(),
        city: string(),
        countyOrganization: string(),
        fkCountryIdOrganization: number(),
        postCode: string().required("postCode is required"),
        email: string().required("email is required"),
        phone: string().required("phone is required"),
        fax: string(),
        website: string(),
        orgDxNumber: string(),
        orgDxExchange: string(),
        orgPoboxNumber: string(),
        orgPoboxCounty: string(),
        orgPoboxTown: string(),
        orgPoboxPostCode: string(),
    })

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {
        if (locations != null && locations.length > 0) {
            let data = locations.map((location: any) => ({
                value: location.address,
                label: location.address,
            }));
            setLocationOptionsLists(data);
        }
    }, [locations]);

    const onLocationChange = (location: any) => {
        if (location) {
            const obj = LocationUtils.splitAddress(location);
            if (obj != null) {
                setValue('address1', obj.addressLine1)
                setValue('address2', obj.addressLine2)
                setValue('city', obj.city)
                setValue('countyOrganization', obj.county)
                setValue('postCode', obj.postcode)
            }
            console.log(obj)
        }
    }

    useEffect(() => {
        if (contactDataById !== null) {
            setValue("address1", contactDataById?.address1);
            setValue("address2", contactDataById?.address2);
            setValue("city", contactDataById?.city);
            setValue("countyOrganization", contactDataById?.countyOrganization);
            setValue("fkCountryIdOrganization", ( contactDataById?.fkCountryIdOrganization)? contactDataById?.fkCountryIdOrganization: 0);
            setValue("postCode", contactDataById?.postCode);
            setValue("email", contactDataById?.email);
            setValue("phone", contactDataById?.phone);
            setValue("fax", ( contactDataById?.fax)? contactDataById?.fax: '');
            setValue("website", contactDataById?.website);
            setValue("orgDxNumber", contactDataById?.orgDxNumber);
            setValue("orgDxExchange", contactDataById?.orgDxExchange);
            setValue("orgPoboxNumber", contactDataById?.orgPoboxNumber);
            setValue("orgPoboxCounty", contactDataById?.orgPoboxCounty);
            setValue("orgPoboxTown", contactDataById?.orgPoboxTown);
            setValue("orgPoboxPostCode", contactDataById?.orgPoboxPostCode);
        }
    }, [contactDataById])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
            <div>
                <div className="flex items-center justify-between mb-5">
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Contact Details</h3>
                    <SearchComponent setPostcode={setPostcode} loading={isLoading} />
                </div>
                <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    {locationOptionsLists !== null && locationOptionsLists.length > 0 && (
                        <div className="">
                            <SelectAddressComponent
                                locationOptionsLists={locationOptionsLists ?? []}
                                onLocationChange={onLocationChange}
                                setValue={setValue}
                            />
                        </div>
                    )}
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Address Line 1 <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.address1 ? 'is-invalid' : ''}`} {...register('address1')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.address1?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Address Line 2
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('address2')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            City
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('city')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            County
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('countyOrganization')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Country
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="Country"
                            options={countryOption}
                            isSearchable={true}
                            value={getValues().fkCountryIdOrganization}
                            placeholder="Country"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkCountryIdOrganization', selectedOption);
                                }
                            }}
                        />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Postcode <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.postCode ? 'is-invalid' : ''}`} {...register('postCode')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.postCode?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Email <span className="text-red-500">*</span>
                        </label>
                        <input type="email" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.email ? 'is-invalid' : ''}`} {...register('email')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.email?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Phone <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.phone ? 'is-invalid' : ''}`} {...register('phone')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.email?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Website
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('website')} />
                    </div>
                </div>

                <div>
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">DX Address</h3>
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgDxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Exchange
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgDxExchange')} />
                        </div>
                    </div>
                </div>

                <div>
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O Box Address</h3>
                    <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgPoboxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Town
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('orgPoboxTown')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('orgPoboxCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Post Code
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('orgPoboxPostCode')} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddOrganisationContactTwo };
