import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LeaveManagementTypeService from "../../../../services/HumanResources/HumanResources/LeaveManagementTypesService";

export interface LeaveManagementType {
  leaveTypeId: number;
  leaveType: string;
  description: string | null;

}
// Define the state type for Checklist
type LeaveManagementTypeState = {
    leaveManagementTypes: LeaveManagementType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LeaveManagementTypeState = {
    leaveManagementTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllLeaveManagementType = createAsyncThunk(
    "fetchAllLeaveManagementType",
    async (data: any, thunkAPI) => {
        try {
            const response = await LeaveManagementTypeService.GetAllLeaveManagementType(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
// Slice definition
const LeaveManagementTypeSlice = createSlice({
    name: "leaveManagementType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllLeaveManagementType.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchAllLeaveManagementType.fulfilled, (state, action: PayloadAction<any[]>) => {
          state.status = "idle";
          state.leaveManagementTypes = action.payload;
        })
        .addCase(fetchAllLeaveManagementType.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.payload as string;
        })
    }
});

export default LeaveManagementTypeSlice.reducer;