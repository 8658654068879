import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CACreditNoteService from "../../../../../services/Accounting/ClientAccounts/Invoice/CACreditNoteService";
import { deleteCreditNote } from "../../OfficeAccounts/OfficePostings/OfficeCreditNoteSlice";

export interface CACreditNote {
    creditNoteId: number;

}

export interface CreditNoteResult {
    creditNoteId: number;
    creditNoteNumber: number;
    fkInvoiceId: number;
    fkCaseId: number;
    fkTransactionId: number;
    fkBranchId: number;
    creditNoteDate: Date;
    description: string;
    reason: string;
    memo: string;
    fkInvoiceNoticeId: number;
    creditNoteNotice: string;
    currentStatusId: number;
    entryDateTime: Date;
    enteredBy: number;
    creditNoteName: string;
    reGeneratedInvoiceName: string;
    creditAmount: number;
    vat: number;
    totalAmount: number;
    fkIncomeAccountId: number;
    fkDisbursementAccountId: number;
    fkVatOutputAccountId: number;
    remainingCredit: number;
    totalRefunds: number;
    invoiceNumber: number;
    invoiceTotal: number;
    invoiceTo: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postcode: string;
    transactionDate: Date;
    dueDate: Date;
    caseReferenceAuto: string;
    caseNumber: number;
    caseName: string;
    invoiceStatus: string;
    casePersonResponsible: string;
}

export interface CACreditNoteData {
    creditNoteResult: [];
    totalCount: number;
    currentPage: number;
    recordPerPage: number;
}
// export interface creditNoteResult{
//     creditNoteResult: number
// }
// Define the state type for Checklist
type NewCreditNote = {
    cACreditNote: CACreditNote[];
    cacreditNoteData: CACreditNoteData;
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber : number | null;
};

// Initial state
const initialState: NewCreditNote = {
    cACreditNote: [],
    cacreditNoteData: null!,
    status: "idle",
    error: null,
    receiptNumber: null,
};

// Async thunks for CRUD operations

export const fetchNextCreditNoteNumber = createAsyncThunk(
    "fetchNextCreditNoteNumber",
    async (_, thunkAPI) => {
        try {
            const response = await CACreditNoteService.GetNextCreditNoteNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCreditNoteSearch = createAsyncThunk(
    "fetchCreditNoteSearch",
    async (data: any, thunkAPI) => {
        try {
            const response = await CACreditNoteService.GetCreditNoteServiceSearch(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const addCACreditNote = createAsyncThunk(
    "addCACreditNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await CACreditNoteService.PostCreditNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCACreditNote = createAsyncThunk(
    "deleteCACreditNote",
    async (id: number, thunkAPI) => {
        try {
            await CACreditNoteService.deleteCreditNote(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CACreditNoteSlice = createSlice({
    name: "clientCreditNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreditNoteSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCreditNoteSearch.fulfilled, (state, action: PayloadAction<CACreditNoteData>) => {
                state.status = "idle";
                state.cacreditNoteData = action.payload;
            })
            .addCase(fetchCreditNoteSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchNextCreditNoteNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextCreditNoteNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextCreditNoteNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCACreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCACreditNote.fulfilled, (state, action: PayloadAction<CACreditNote>) => {
                state.status = "idle";
                state.cACreditNote.push(action.payload);
            })
            .addCase(addCACreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCreditNote.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.cACreditNote = state.cACreditNote.filter(t => t.creditNoteId !== action.payload);
            })
            .addCase(deleteCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CACreditNoteSlice.reducer;
