import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { SearchAccount } from "../../../SearchAccount";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { addOfficeFundTransfer } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/OfficeFundTransferSlice";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { fetchAllAccount } from "../../../../../store/slice/Accounting/AccountSetup/AccountSlice";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";
import { getValue } from "@testing-library/user-event/dist/utils";
import { fetchAllAccountSearchNominalAccount } from "../../../../../store/slice/Accounting/OfficeAccounts/AccountSearchNominalAccountsSlice";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { fetchNextReceiptNumber } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { MainAnimation } from "../../../../loadingAnimation/MainAnimation";

function AddFundTransfer({
  openDrawer,
  Close,
  isEdit,
  isView,
  officeFundTransfer,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean,
  officeFundTransfer: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Fund Transfer" : "Edit Fund Transfer"
  );

  const [openFromBankAccountModal, setOpenFromBankAccountModal] = useState(false);
  const [openToBankAccountModal, setOpenToBankAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [fromBankAccount, setFromBankAccount] = useState<string>(""); // Display selected account type in input field
  const [toBankAccount, setToBankAccount] = useState<string>("");
  const { receiptNumber } = useSelector((state: RootState) => state.officeBankAccount);
  //const [autoReferenceNumber, setAutoReferenceNumber] = useState<number>();
  const [selectedFromAccountBalance, setSelectedFromAccountBalance] = useState<number>(0);
  const [selectedToAccountBalance, setSelectedToAccountBalance] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  
  const toggleFromBankAccountModal = () => {
    setOpenFromBankAccountModal(!openFromBankAccountModal);
  };
  const toggleToBankAccountModal = () => {
    setOpenToBankAccountModal(!openToBankAccountModal);
  };

  

  const handleFromAccountSelect = (selectedAccountType: any,) => {
    setFromBankAccount(selectedAccountType.accountName);
    setValue('fromBankId', selectedAccountType.accountId);
    if (selectedAccountType && selectedAccountType.runningBalance!=null) {
      setSelectedFromAccountBalance(selectedAccountType.runningBalance);
    }
    setOpenFromBankAccountModal(false);
  };

  const handleToAccountSelect = (selectedAccountType: any,) => {
    setToBankAccount(selectedAccountType.accountName);
    setValue('toBankId', selectedAccountType.accountId);
    if (selectedAccountType && selectedAccountType.runningBalance!=null) {
      setSelectedToAccountBalance(selectedAccountType.runningBalance);
    }
    setOpenToBankAccountModal(false);
  };

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    accountToAccountTransactionId: number(),
    fromBankAvailableBalance: string(),
    toBankAvailableBalance: string(),
    transactionDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    transactionDescription: string().required(
      "Transaction Description is required"
    ),
    entryDate: string().transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    referenceNumber: number(),
    memo: string(),
    amount: number().required("Amount is required"),
    toBankId: number(),
    fromBankId: number(),
    toBankAccount: string(),
    fromBankAccount: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:{
      accountToAccountTransactionId: 0,
      transactionDate: new Date().toISOString().split("T")[0],
      referenceNumber: 0 
    }
  });

  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [dispatch(fetchNextReceiptNumber(OfficeAccountConstant.FundTransfer))];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    setValue("transactionDate", new Date().toISOString().split("T")[0]);
  }, []);

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));

    setIsLoading(true);
    try {
      await dispatch(addOfficeFundTransfer(data)).unwrap();
      Close("success");
      toast.success("Successfully Added Office fund transfer");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  // useEffect(() => {
  //   if (receiptNumber != null) {
  //     setValue("referenceNumber", receiptNumber);
  //     setAutoReferenceNumber(receiptNumber);
  //   }
  // }, [receiptNumber]);

  useEffect(() => {
      if ((officeFundTransfer != null && isEdit === true) || (officeFundTransfer != null && isView === true && isEdit === false)) {
        setValue('transactionDate', formatDateISODisplay(officeFundTransfer?.transactionDate))
        // setValue('fromBankAccount', officeFundTransfer?.fromBankAccount)
        //setValue('toBankAccount', officeFundTransfer?.toBankAccount)
        setValue('accountToAccountTransactionId', officeFundTransfer?.accountToAccountTransactionId)
        setValue('memo', officeFundTransfer?.memo)
        setValue('amount', officeFundTransfer?.amount)
        setValue('transactionDescription', officeFundTransfer?.transactionDetails)
        setValue('referenceNumber', officeFundTransfer?.referenceNumber)
        setToBankAccount(officeFundTransfer?.toAccountName);
        setFromBankAccount(officeFundTransfer?.accountName);
       //dispatch(fetchAllAccountSearchNominalAccount(officeFundTransfer?.fromBankId))
        
      }
    }, [officeFundTransfer, isEdit, isView,  reset, setValue]);
     
    if (initialLoading) {
      return <MainAnimation />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto my-5">
        <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
          <div className="relative flex">
            <input
              type="search"
              className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:bg-gray-800 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300"
              placeholder="From Bank Account"
              {...register('fromBankId')}
              value={fromBankAccount}
              aria-label="Search"
              disabled
            />
            {errors?.fromBankId && <div className="text-red-600">{errors.fromBankId.message}</div>}
            <button
              onClick={toggleFromBankAccountModal}
              className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-lg 3xl:text-sm font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              data-twe-ripple-init
              data-twe-ripple-color="white"
              type="button"
            >
              Search
            </button>
            
            {openFromBankAccountModal && (
              <SearchAccount
                openDrawer={openFromBankAccountModal}
                Close={toggleFromBankAccountModal}
                onAccountSelect={handleFromAccountSelect} type="Office" fkAccountTypeIds=""// Pass the callback function to child
              />
            )}
            
          </div>
          <div className="relative flex">
            <input
              type="search"
              value={toBankAccount}
              className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:bg-gray-800 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300"
              placeholder="To Bank Account"
              {...register('toBankId')}
              aria-label="Search"
              disabled
            />
            {errors?.toBankId && <div className="text-red-600">{errors.toBankId.message}</div>}
            <button
              onClick={toggleToBankAccountModal}
              className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-lg 3xl:text-sm font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              data-twe-ripple-init
              data-twe-ripple-color="white"
              type="button"
            >
              Search
            </button>
            {openToBankAccountModal && (
              <SearchAccount
                openDrawer={openToBankAccountModal}
                Close={toggleToBankAccountModal}
                onAccountSelect={handleToAccountSelect} type="Office" fkAccountTypeIds="" // Pass the callback function to child
              />
            )}
          </div>
          {(isView === false && isEdit === false) &&(
          <div>
            <button
              type="button"
              onClick={() => {
                Close();
              }}
              className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          )}
        </div>
        

        <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
        {(isView === false && isEdit === false) &&(
          <div>
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              From BankAccount Available Balance{" "}
              <span className="text-red-500">*</span>
            </label>
            <div className="flex gap-3">
              <input
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fromBankAvailableBalance ? "is-invalid" : ""}`}

                value={selectedFromAccountBalance}
                disabled={isDisabled}
                
              />
              
            </div>
          </div>
          )}
           {(isView === false && isEdit === false) &&(
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              To BankAccount Available Balance
            </label>
            <input
              type="text"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              value={selectedToAccountBalance}
              disabled={isDisabled}
              
            />
          </div>
           )}
            {/* <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Reference
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-sm rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("referenceNumber")}
              value={getValues().referenceNumber}
              placeholder="Enter Reference Number"
              disabled
            />
          </div> */}
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Amount
            </label>
            <NumericFormatWrapper
              decimalScale={2}
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-sm rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("amount")}
              value={getValues().amount}
              placeholder="Enter transaction amount"
              onValueChange={(values: any) => {
                setValue("amount", Number(values.value));
              }}
              
            />
            <div className="font-medium mt-2 text-lg 3xl:text-sm text-red-600">
              {errors?.amount?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Transaction Description <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-sm rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? "is-invalid" : ""}`}
              {...register("transactionDescription")}
              //value={getValues().transactionDescription}
              placeholder="Enter Describtion"
            />
            <div className="font-medium mt-2 text-lg 3xl:text-sm text-red-600">
              {errors?.transactionDescription?.message?.toString()}
            </div>
          </div>
         
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Transaction Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-sm rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? "is-invalid" : ""
                }`}
              {...register("transactionDate")}
            
              placeholder="Enter transaction date"
            />
            <div className="font-medium mt-2 text-lg 3xl:text-sm text-red-600">
              {errors?.transactionDate?.message?.toString()}
            </div>
          </div>
          {/* <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Entry Date
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-sm rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              {...register("entryDate")}
              max={new Date().toISOString().split("T")[0]}
              placeholder="Enter Entry Date"
            />
          </div> */}
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Memo
            </label>
            <textarea
              className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-sm w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              placeholder="Memo"
              {...register("memo")}
            ></textarea>
          </div>
        </div>
        {(isView === false) &&( 
        <div className="flex col-span-2 gap-2 justify-end my-5">
          <button
            type="button"
            onClick={() => {
              reset();
              Close();
            }}
            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
          >
            Cancel
          </button>
          <button
            type="submit"
           // disabled={isLoading}
            className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-lg 3xl:text-sm w-[120px] ${isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white-bg mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            ) : (
              "Save"
            )}
          </button>
        </div>
        )}
      </form>
    </>
  );
}

export { AddFundTransfer };