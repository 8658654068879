import { useEffect, useRef, useState } from "react";
import { AddBillPayment } from "../Modal/AddOfficeAccounts/AddSuppliers/AddBillPayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteBillPayment, fetchAllBillPayment } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/BillPaymentSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import DeleteModal from "../Modal/DeleteModal";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { Pagination } from "../Pagination";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
function BillPayment() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [billPaymentData, setBillPaymentData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { billPayments, status, error } = useSelector(
    (state: RootState) => state.billPayment
  );
  const [currentBillPayment, setCurrentBillPayment] = useState<any[]>([]);
 const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [billPaymentId, setBillPaymentId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [oASupplierBillPaymentData, setOASupplierBillPaymentData] = useState<any[]>([]);
  const officeSupplierData = useSelector(
    (state: RootState) => state.officeSupplier.officeSupplierData
  );
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    userId: 0,
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    oASupplierId:0,
    
  });

  const optiondata = [
    {
      name: "Supplier",
      options: supplierList
    },
  ]

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        supplierId: filters.oASupplierId,
      }
      dispatch(fetchAllBillPayment(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, isOnAddedOrUpdate,filters, status, error]);

  useEffect(() => {
    if (
      billPayments != null &&
      billPayments.listingData != null 
    ) {
      // const totalPages = billPayments.totalRecords;
      setTotalRecords(billPayments.totalRows);
      setOASupplierBillPaymentData(billPayments.listingData);
    }
  }, [billPayments]);


  useEffect(() => {
    if (officeSupplierData && officeSupplierData.listingData) {
      let data = officeSupplierData.listingData.map((item: any) => ({
        value: item.supplierId,
        label: `${item.supplierName}`,
      }));
      data.unshift({ value: 0, label: "Supplier" });

      setSupplierList(data);

    }
  }, [officeSupplierData]);
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllBillPayment(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };
  const onDeleteCall = () => {
    try {
      dispatch(deleteBillPayment(billPaymentId)).unwrap();
      setCurrentBillPayment((prevData: any) => prevData.filter((item: any) => item.supplierBillPaymentId !== billPaymentId));
      setOpenDelModal(false);
      toast.success("Successfully Deleted Bill Payment");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OABillPayment"
        getData={billPaymentData}
        title="Bill Payment "
      />
    );
  }
  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={billPaymentId}
            title="Bill Payment"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Bill Payment"
            buttonName="Bill Payment"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          {openModal ? (
            <AddBillPayment
              openDrawer={openModal}
              Close={toggleDrawer}
              isEdit={isEdit}
              isView={isView}
              billPayment={billPaymentData}
            />
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <div className="flex flex-row gap-x-5 md:gap-1 w-full items-center">
            <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden sm:rounded-lg my-5">
        <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Supplier
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Invoice
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Bank
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-center w-12 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {oASupplierBillPaymentData.map((billPayment: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(billPayment.transactionDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {billPayment.supplierName}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {billPayment.invoiceNumber}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {billPayment.bankAccountCode}[{billPayment.accountName}]
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(billPayment.paymentAmount)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setBillPaymentData(billPayment);
                        }}
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setBillPaymentData(billPayment);
                        }}
                      />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setBillPaymentId(billPayment.supplierBillPaymentId);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {totalRecords === 0 && (
          <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
            <p className="text-red-800">No Bill Payment Details found.</p>
          </div>
        )}
      </div>
      {totalRecords > 0 && (
        <div className="w-full flex flex-col pb-[100px]">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPage}
            totalRecords={totalRecords}
            onPageChange={(page) => { onPageChange(page); }}
          />
        </div>
      )}
    </>
  );
}

export { BillPayment };
