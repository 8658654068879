import { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { AddProtectedFund } from "../Modal/AddClientAccounts/AddClientPostings/AddProtectedFund";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllProtectedFund } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ProtectedFundSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
function ProtectedFund() {
  const [openModal, setOpenModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const searchProtectedFundResult = useSelector(
    (state: RootState) => state.protectedFund.searchProtectedFundResult
  );
  const [protectedFundData, setProtectedFundData] = useState<any>([]);
  const [currentProtectedFunds, setCurrentprotectedFunds] = useState<any[]>([]);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const undertakingType = [
    { value: "", label: "Select" },
    { value: "Given", label: "Given" },
    { value: "Taken", label: "Taken" },
  ];
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: '',
    startDate: '',
    endDate: '',
    caseType: '',
    documentType: '',
    isArea: false,
    isDateBetween: false,
    isSelectbox: true,
    clientBankAccId: 0,
    undertakeType: '',
  });

  const optiondata = [
    {
      name: "Client Bank",
      options: clientAccountLists
    },
    {
      name: "Undertaking Type",
      options: undertakingType
    },
  ]


  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        bankAccountId: filters.clientBankAccId,
        undertakeType: filters.undertakeType
      }
      dispatch(fetchAllProtectedFund(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate, filters]);

  useEffect(() => {
    if (searchProtectedFundResult !== null && searchProtectedFundResult.protectedFundResult !== null) {
      setCurrentprotectedFunds(searchProtectedFundResult.protectedFundResult);
      setTotalRecords(searchProtectedFundResult.totalCount);
    }
  }, [searchProtectedFundResult]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllProtectedFund(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  }

  useEffect(() => {
    if (clientAccounts !== null) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      data.push({ value: 0, label: "Select" });
      setClientAccountLists(data);
    }
  }, [clientAccounts]);




  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
  };

  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  if (openModal) {
    return (
      <AddProtectedFund
        openDrawer={openModal}
        Close={toggleDrawer}
        isView={isView}
        isReverse={false}
        protectedFund={protectedFundData}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="protectedFund"
        getData={protectedFundData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPProtectedFund"
        getData={protectedFundData}
        title="ProtectedFund"
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Protected Fund"
            buttonName="Protected Fund"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-col md:flex-col justify-between gap-7 w-full md:gap-3">
          <div className="w-full">
            <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Given / Taken
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Reason
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Protected Funds
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Is Released
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentProtectedFunds.map((protectedFund: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(protectedFund.protectedDateTime)

                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>{protectedFund.individualNames || protectedFund.organisationNames || protectedFund.companyNames}</p>
                    <p>{protectedFund.caseReferenceAuto}</p> <br />

                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {protectedFund.protectedFundType}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {protectedFund.reason}</td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(protectedFund.protectedAmount)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {protectedFund.protectedContactType}
                  </td>
                  <td className="px-6 py-3 text-center border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {protectedFund.isReleased === false ? "No" : "Yes"}
                  </td>

                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setProtectedFundData(protectedFund);
                        }}
                      />
                    </div>
                  </td>
                  <td className="cursor-pointer px-6 py-3 text-center">
                    <button className="text-xs py-1 px-3 font-medium text-white-bg bg-red-500 rounded hover:bg-red-600"
                      onClick={() => {
                        setOpenReverseDrawer(true);
                        setProtectedFundData(protectedFund);
                      }}
                    >
                      Release
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No ProtectedFund is not found.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => { onPageChange(page); }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { ProtectedFund };
