
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { AddSuppliersCreditNote } from "./SuppliersCreditNoteDetails/AddSuppliersCreditNote";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllOfficeSupplierCreditNote } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierCreditNoteSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { Pagination } from "../Pagination";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
function SuppliersCreditNote() {
  const [openModal, setOpenModal] = useState(false);
  const [officeSupplierCreditNoteData, setOfficeSupplierCreditNoteData] =
    useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { officeSupplierCreditNotes, status, error } = useSelector(
    (state: RootState) => state.officeSupplierCreditNote
  );
  const [isView, setIsView] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [oASupplierCreditNoteData, setOASupplierCreditNoteData] = useState<any[]>([]);
  const officeSupplierData = useSelector(
    (state: RootState) => state.officeSupplier.officeSupplierData
  );
   const [filters, setFilters] = useState<FilterTypeWithEnums>({
        searchTerm: '',
        startDate: '',
        endDate: '',
        caseType: '',
        documentType: '',
        userId: 0,
        isArea: false,
        isDateBetween: true,
        isSelectbox: true,
        oASupplierId:0,
      });

  const optiondata = [
    {
      name: "Supplier",
      options:supplierList
    },
  ]

  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let searchdata = {
        keyword: filters.searchTerm,
        currentPage: currentPage,
        recordsPerPage: totalPage,
        dateStart: (filters.startDate === "" ? null : filters.startDate),
        dateEnd: (filters.endDate === "" ? null : filters.endDate),
        supplierId: filters.oASupplierId,
      }
      dispatch(fetchAllOfficeSupplierCreditNote(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch,isOnAddedOrUpdate, status,filters, error]);

  useEffect(() => {
    if (
      officeSupplierCreditNotes != null &&
      officeSupplierCreditNotes.listingData != null
    ) {
    
      setTotalRecords(officeSupplierCreditNotes.totalRecords);
      setOASupplierCreditNoteData(officeSupplierCreditNotes.listingData);
    }
  }, [officeSupplierCreditNotes]);

  

  useEffect(() => {
    if (officeSupplierData && officeSupplierData.listingData) {
      let data = officeSupplierData.listingData.map((item: any) => ({
        value: item.supplierId,
        label: `${item.supplierName}`,
      }));
      data.unshift({ value: 0, label: "Supplier" });

      setSupplierList(data);
    }
  }, [officeSupplierData]);

  

  const onPageChange =(page: number) =>{
    setCurrentPage(page);
    let searchdata= {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage
    }
    dispatch(fetchAllOfficeSupplierCreditNote(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
}

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsView(false);
    setOpenModal(!openModal);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OASupplierCreditNote"
        getData={officeSupplierCreditNoteData}
        title="Supplier Credit Note"
      />
    );
  }

  if (openModal) {
    return (
      <AddSuppliersCreditNote
        openModal={openModal}
        Close={toggleDrawer}
        isView={isView}
        officeSupplierCreditNote={officeSupplierCreditNoteData}
      />
    );
  }
  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Credit Note"
            buttonName="Credit Note"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
            <div className="flex flex-row gap-x-5 md:gap-1 w-full items-center">
            <AccountFilterComponent optiondata={optiondata} onFilterChange={handleFilterChange} initialFilters={filters} />
            </div>
        </div>
      </div>
      <div className="relative overflow-hidden sm:rounded-lg my-5">
        <div className="overflow-x-auto max-h-96">
        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
          {initialLoading ? <MainAnimation /> : ""}
          <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
            <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Credit Note Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Supplier
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Invoice Number
              </th>
            
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Description
              </th>
              <th
                scope="col"
                className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Net Amount
              </th>
              <th
                scope="col"
                className="text-center px-3 py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-lg 3xl:text-xs">
            {oASupplierCreditNoteData.map(
              (officeSupplierCreditNote: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(officeSupplierCreditNote.entryDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    CN{officeSupplierCreditNote.supplierCreditNoteId}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeSupplierCreditNote.supplierName}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeSupplierCreditNote.invoiceNumber}
                  </td>
                  
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeSupplierCreditNote.description}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(officeSupplierCreditNote.netAmount)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <FaEye className="fill-gray-500 w-6 h-6"
                    onClick={() => {
                      setOpenViewDrawer(true);
                      setIsView(true);
                      setOfficeSupplierCreditNoteData(officeSupplierCreditNote);
                    }} 
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        </div>
        {totalRecords === 0 && (
          <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
            <p className="text-red-800">
              No Supplier Credit Note Details found.
            </p>
          </div>
        )}
      </div>
      {totalRecords > 0 && (
        <div className="w-full flex flex-col pb-[100px]">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPage}
            totalRecords={totalRecords}
            onPageChange={(page) => {onPageChange(page);}}
          />
        </div>
      )}
    </>
  );
}

export { SuppliersCreditNote };
