import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { Heading } from "../AccountSetupHeading/Heading";
import { SelectBox } from "../SelectBox";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GetAllAreasOfPractice } from "../../store/slice/Administrator/AreasOfPracticeSlice";
import { fetchAllCaseType } from "../../store/slice/SystemMaintenance/CaseTypeSlice";
import {
  addCaseTypeIncomeLedger,
  fetchAllCaseTypeIncomeLedger,
  fetchAllCaseTypeIncomeLedgerDropdown,
} from "../../store/slice/Accounting/AccountSetup/CaseTypeInComeLedgerSlice";
import { toast } from "react-toastify";

function CaseTypesIncomeLedgers() {
  const dispatch = useAppDispatch();
  const { areasOfPractice, error: areasError } = useSelector(
    (state: RootState) => state.areasOfPractice
  );
  const {
    casetypes,
    status: caseTypeStatus,
    error: caseTypeError,
  } = useSelector((state: RootState) => state.caseType);
  const {
    caseTypesDropdown,
    incomeLedgers,
    status: caseSubTypeStatus,
    error: caseSubTypeError,
  } = useSelector((state: RootState) => state.caseTypeLedger);
  const isError = areasError || caseTypeError || caseSubTypeError;
  const [isLoading, setIsLoading] = useState(false);
  const [caseTypeIncomeDropdown, setcaseTypeIncomeDropdown] = useState<any[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(GetAllAreasOfPractice());
      dispatch(fetchAllCaseType());
      dispatch(fetchAllCaseTypeIncomeLedgerDropdown());
      dispatch(fetchAllCaseTypeIncomeLedger());
    }
    return () => {
      effectServiceCall.current = true;
    };
  }, [dispatch]);

  useEffect(() => {
    if (caseTypesDropdown != null && caseTypesDropdown.length > 0) {
      let data = caseTypesDropdown.map((item: any) => ({
        value: item.accountId,
        label: item.accountName,
      }));
      setcaseTypeIncomeDropdown(data);
    }
  }, [caseTypesDropdown]);

  useEffect(() => {
    if (incomeLedgers != null && incomeLedgers.length > 0) {
      let data = incomeLedgers.map((item) => ({
        accountId: item.fkAccountId,
        caseTypeId: item.fkCaseTypeId,
      }));
      setSelectedItems(data);
    }
  }, [incomeLedgers]);

  const handleIncomeLedgerChange = (selectedOption: any, casedata: any) => {
    if (selectedOption) {
      let data = {
        accountId: selectedOption,
        caseTypeId: casedata.caseTypeId,
      };

      setSelectedItems([...selectedItems, data]);
    }
  };

  const onsubmitData = async () => {
    if (selectedItems.length == 0) {
      toast.error("Please select at least one case Type");
      return;
    }

    setIsLoading(true);
    try {
      await dispatch(addCaseTypeIncomeLedger(selectedItems)).unwrap();
      toast.success("Successfully Added Case Type Ledger Accounts");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Case Types and Income Ledgers" />
        </div>

        {areasOfPractice.map((area, index) => {
          const caseTypesList = casetypes.filter(
            (caseType) =>
              caseType.fkAreasOfPracticeId === area.areasOfPracticeId
          );
          return (
            <>
              <Heading key={index} title={area.area} />
              <div className="grid md:grid-cols-1 grid-cols-5 3xl:grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                {caseTypesList &&
                  caseTypesList.map((casedata: any, subindex) => {
                    const accountObj = selectedItems.find(
                      (x) => x.caseTypeId === casedata.caseTypeId
                    );
                    return (
                      <div className="" key={subindex}>
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                          {casedata.caseType}{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                          className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                          indicator={
                            <svg
                              width="15"
                              className="fill-current mr-2"
                              height="15"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                          }
                          isMulti={false}
                          options={caseTypeIncomeDropdown}
                          name="frameFiftySix"
                          isSearchable={true}
                          value={accountObj != null ? accountObj.accountId : 0}
                          placeholder="Select"
                          shape="round"
                          color="indigo_50"
                          size="xs"
                          onChange={(e) => {
                            handleIncomeLedgerChange(e, casedata);
                          }}
                        />
                      </div>
                    );
                  })}

                {/*                  
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            n <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div> */}
              </div>
            </>
          );
        })}

        {/* <Heading title="Civil litigation" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Small Claim <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Boundary Dispute <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Landlord and Tenant <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General Disputes <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>


                </div>

                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 my-5">
                    <div>
                        <Heading title="Company commercial" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    General <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    isSearchable={true}
                                    placeholder="Select"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                />

                            </div>
                        </div>
                    </div>

                    <div>
                        <Heading title="Competition" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    General <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    isSearchable={true}
                                    placeholder="Select"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                />

                            </div>
                        </div>
                    </div>

                    <div>
                        <Heading title="Consumer, debt & insolvency" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                    General <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    isSearchable={true}
                                    placeholder="Select"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                />

                            </div>
                        </div>
                    </div>

                </div>

                <Heading title="Criminal justice" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Criminal justice <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Dispute resolution" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Employment" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Employment <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Tribunal <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Family and children" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Mediation <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Civil Proceedings <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Domestic Violence <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Divorce <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Human rights" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Immigration" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Nationality <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Settlement <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Entry Clearance <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Asylum <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Appeal <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Leave to Remain <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            FLR (M) <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            FLR (FP) <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            EEA National <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="In-house" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Legal aid and access to justice" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Personal injury" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Personal injury <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>


                <Heading title="Private client" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>


                <Heading title="Social welfare and housing" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Wills and Probate" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Wills and Probate <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            General <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div>

                <Heading title="Miscellaneous" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Miscellaneous <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Lease Extension <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Second Charge <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Lease Surrender <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                        />

                    </div>
                </div> */}

        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
          <div className="flex flex-row gap-2 items-end justify-end w-full">
            <button className="cursor-pointer h-10 rounded-md text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]">
              Cancel
            </button>
            <button
              type="button"
              className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-lg 3xl:text-sm w-[120px]"
              onClick={onsubmitData}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export { CaseTypesIncomeLedgers };
