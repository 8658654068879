import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ChecklistService from "../../../services/SystemMaintenance/ChecklistService";

export interface ChecklistItem {
    checkListItemId: number;
    fkCheckListId: number;
    checkListItemName: string;
}

export interface CheckListData{
    listingData: [];
    totalCount: number;
}

export interface CheckListItemData{
    lisdtingData: [];
    itemListingData: [];
    totalCount: number;
}
export interface Checklist {
    checklistId: number;
    checklistName: string;
    fkAreasOfPracticeId: number;
    fkCaseTypeId: number;
    fkCaseSubTypeId: number;
    checklistIsActive: boolean;
};

export interface CheckListByIdData {
    area: string;
    caseType: string;
    checklistId: number;
    checklistIsActive: boolean;
    checklistName: string;
    fkAreasOfPracticeId: number;
    fkCaseSubTypeId: number;
    fkCaseTypeId: number;
    subCaseType: string;
};

// Define the state type for Checklist
type ChecklistState = {
    checklistItem: ChecklistItem[];
    checkListByIdData: CheckListByIdData;
    checkListData:CheckListData;
    checkListItemData: CheckListItemData;
    checklists: Checklist[];
    checklistId: number;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ChecklistState = {
    checklistId: 0,
    checklistItem: [],
    checkListByIdData: null!,
    checkListData: null!,
    checkListItemData: null!,
    checklists: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCheckList = createAsyncThunk(
    "fetchAllCheckList",
    async (_, thunkAPI) => {
        try {
            const response = await ChecklistService.GetAllCheckList();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCheckList = createAsyncThunk(
    "addCheckList",
    async (data: any, thunkAPI) => {
        try {
            const response = await ChecklistService.PostCheckList(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCheckListData = createAsyncThunk(
    "fetchCheckListData",
    async (data: any, thunkAPI) => {
        try {
            const response = await ChecklistService.GetCheckListData(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCheckListById = createAsyncThunk(
    "fetchCheckListById",
    async (id: number, thunkAPI) => {
        try {
            const response = await ChecklistService.GetCheckListById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCheckListItemData = createAsyncThunk(
    "fetchCheckListItemData",
    async (data: any, thunkAPI) => {
        try {
            const response = await ChecklistService.GetCheckListItemData(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCheckListItem = createAsyncThunk(
    "addCheckListItem",
    async (data: any, thunkAPI) => {
        try {
            const response = await ChecklistService.PostCheckListItem(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCheckList = createAsyncThunk(
    "updateCheckList",
    async ({ id, data }: { id: number, data: Checklist }, thunkAPI) => {
        try {
            const response = await ChecklistService.PutCheckList(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCheckList = createAsyncThunk(
    "deleteCheckList",
    async (ChecklistId: number, thunkAPI) => {
        try {
            await ChecklistService.deleteCheckList(ChecklistId);
            return ChecklistId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCheckListItem = createAsyncThunk(
    "deleteCheckListItem",
    async (id: number, thunkAPI) => {
        try {
            await ChecklistService.DeleteCheckListItem(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ChecklistSlice = createSlice({
    name: "checklist",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCheckList.fulfilled, (state, action: PayloadAction<Checklist[]>) => {
                state.status = "idle";
                state.checklists = action.payload;
            })
            .addCase(fetchAllCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCheckListData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCheckListData.fulfilled, (state, action: PayloadAction<CheckListData>) => {
                state.status = "idle";
                state.checkListData = action.payload;
            })
            .addCase(fetchCheckListData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCheckListById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCheckListById.fulfilled, (state, action: PayloadAction<CheckListByIdData>) => {
                state.status = "idle";
                state.checkListByIdData = action.payload;
            })
            .addCase(fetchCheckListById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCheckListItemData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCheckListItemData.fulfilled, (state, action: PayloadAction<CheckListItemData>) => {
                state.status = "idle";
                state.checkListItemData = action.payload;
            })
            .addCase(fetchCheckListItemData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCheckList.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.checklistId = action.payload;
            })
            .addCase(addCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCheckListItem.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCheckListItem.fulfilled, (state, action: PayloadAction<ChecklistItem>) => {
                state.status = "idle";
                state.checklistItem.push(action.payload);
            })
            .addCase(addCheckListItem.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCheckList.fulfilled, (state, action: PayloadAction<Checklist>) => {
                state.status = "idle";
                const index = state.checklists.findIndex(t => t.checklistId === action.payload.checklistId);
                if (index !== -1) {
                    state.checklists[index] = action.payload;
                }
            })
            .addCase(updateCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCheckList.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.checklists = state.checklists.filter(t => t.checklistId !== action.payload);
            })
            .addCase(deleteCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(deleteCheckListItem.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCheckListItem.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                // state.checklists = state.checklists.filter(t => t.checklistId !== action.payload);
            })
            .addCase(deleteCheckListItem.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ChecklistSlice.reducer;
