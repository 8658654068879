import { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { AddOfficeToClient } from "../Modal/AddClientAccounts/AddClientPostings/AddOfficeToClient";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllOfficeToClient } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/OfficeToClientSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import { AccountFilterComponent } from "../FilterComponents/AccountFilterComp";
import { FilterTypeWithEnums } from "../../store/model/Accounting/Filter/accountfiltercom";
function OfficeToClient() {
  const [openModal, setOpenModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const searchOfficeToClientResult = useSelector(
    (state: RootState) => state.officeToClient.searchOfficeToClientResult
  );

  const [OfficeToClientData, setOfficeToClientData] = useState<any[]>([]);
  const [currentOfficeToClient, setCurrentOfficeToClient] = useState<any[]>([]);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const [totalPage] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeAccountLists, setOfficeAccountLists] = useState<any[]>([]);
  const [filters, setFilters] = useState<FilterTypeWithEnums>({
    searchTerm: "",
    startDate: "",
    endDate: "",
    caseType: "",
    documentType: "",
    isArea: false,
    isDateBetween: true,
    isSelectbox: true,
    officeBankAccId: 0,
  });

  const optiondata = [
    {
      name: "Office Account",
      options: officeAccountLists,
    },
  ];

  useEffect(() => {
    let searchdata = {
      keyword: filters.searchTerm,
      currentPage: currentPage,
      recordsPerPage: totalPage,
      dateStart: filters.startDate == "" ? null : filters.startDate,
      dateEnd: filters.endDate == "" ? null : filters.endDate,
      fromBank: filters.officeBankAccId,
    };
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllOfficeToClient(searchdata))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate, filters]);

  useEffect(() => {
    if (
      searchOfficeToClientResult &&
      searchOfficeToClientResult.officeToClientResult
    ) {
      setCurrentOfficeToClient(searchOfficeToClientResult.officeToClientResult);
      setTotalRecords(searchOfficeToClientResult.totalCount);
    }
  }, [searchOfficeToClientResult]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    let searchdata = {
      keyword: "",
      currentPage: page,
      recordsPerPage: totalPage,
    };
    dispatch(fetchAllOfficeToClient(searchdata))
      .then(() => setInitialLoading(false))
      .catch(() => setInitialLoading(false));
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsView(false);
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleFilterChange = (newFilters: FilterTypeWithEnums) => {
    setFilters((prevFilters) => {
      if (JSON.stringify(prevFilters) !== JSON.stringify(newFilters)) {
        effectServiceCall.current = false;
        return newFilters;
      }
      return prevFilters;
    });
  };

  useEffect(() => {
    if (officeBanks.length > 0) {
      // console.log(officeBanks)
      let data = officeBanks.map((officeAccount: any, index) => ({
        value: officeAccount.bankAccountId,
        label: `${officeAccount.institution} - ${officeAccount.bankAccountType}`,
      }));
      data.unshift({ value: 0, label: "All Bank" });
      setOfficeAccountLists(data);
    }
  }, [officeBanks]);

  if (openModal) {
    return (
      <AddOfficeToClient
        openDrawer={openModal}
        Close={toggleDrawer}
        isView={isView}
        isReverse={false}
        officeToClient={OfficeToClientData}
      />
    );
  }
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPOfficeToClient"
        getData={OfficeToClientData}
        title="Office To Client"
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="officeToClient"
        getData={OfficeToClientData}
      />
    );
  }
  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Office to Client"
            buttonName="Office to Client"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>

        <div className="w-full">
          <AccountFilterComponent
            optiondata={optiondata}
            onFilterChange={handleFilterChange}
            initialFilters={filters}
          />
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Case Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Transaction Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  From Bank (Office)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  To Bank (Client)
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentOfficeToClient.map((officeToClient: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(officeToClient.transactionDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>
                      {officeToClient.individualNames ||
                        officeToClient.organisationNames ||
                        officeToClient.companyNames}
                    </p>
                    <p>{officeToClient.caseReferenceAuto}</p> <br />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeToClient.transactionDetails}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeToClient.accountName} -{" "}
                    {officeToClient.bankAccountType}
                    <br />({officeToClient.accountNumber})
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeToClient.toAccountName} -{" "}
                    {officeToClient.toBankAccountType}
                    <br />({officeToClient.toAccountNumber})
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(officeToClient.amountTransfer)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setOfficeToClientData(officeToClient);
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                       onClick={() => {
                        setOpenReverseDrawer(true);
                        setOfficeToClientData(officeToClient);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {totalRecords === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800"> office to client lists Not found.</p>
            </div>
          )}
        </div>
        {totalRecords > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              totalRecords={totalRecords}
              onPageChange={(page) => {
                onPageChange(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { OfficeToClient };
