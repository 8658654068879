import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetAllBankReceipt = async (data:any) => {
    try {
        const response = await axiosInstance.post("BankReceiptSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetNextBankReceiptNumber = async () => {
    try {
        const response = await axiosInstance.get("BankReceiptNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostBankReceipt = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("BankReceipt", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostReserveBankReceipt = async (data: any) => {
    try {
        const response = await axiosInstance.post("ReserveBankReceiptt", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PutBankReceipt = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`BankReceipt/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteBankReceipt = async (id: number) => {
    try {
        await axiosInstance.delete(`bankreceipt/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const BankReceiptService = {
    GetAllBankReceipt,
    PostBankReceipt,
    PostReserveBankReceipt,
    PutBankReceipt,
    deleteBankReceipt,
    GetNextBankReceiptNumber
};


export default BankReceiptService;
