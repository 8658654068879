import axiosInstance from '../../axiosInstance';
import { ErrorResult } from '../../../store/model/errorResult';


const GetUnbilledFeeByCaseId = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/caseclose/${id}/unbilledfee`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetUndeletedFeeByCaseId = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/caseclose/${id}/undeletedFee`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetUnbilledDisbursementByCaseId = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/caseclose/${id}/unbilledDisbursement`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetUnDeletedDisbursementByCaseId = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/caseclose/${id}/undeleteddisbursement`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseClosureChecklist = async () => {
    try {
        const response = await axiosInstance.get(`case/caseclose/checklist`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseClosedChecklist = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/caseclose/${id}/checklist`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetUnpaidInvoiceBalanceByCase = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/caseclose/${id}/unpaidinvoicebalance`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const CaseCloseService = {
    GetUnbilledFeeByCaseId,
    GetUndeletedFeeByCaseId,
    GetUnbilledDisbursementByCaseId,
    GetUnDeletedDisbursementByCaseId,
    GetCaseClosureChecklist,
    GetCaseClosedChecklist,
    GetUnpaidInvoiceBalanceByCase,
};

export default CaseCloseService;
