import React, { useState } from "react";
import { SelectBox } from "../SelectBox";

interface SelectBoxFilterCProp {
    optiondata?: { name: string; options: any[] }[];
    onSearchClick: (selectedData: { accountTypeId?: any; undertakeType?: string; caseStatus?:string; accountCodeOrder?: any; bankId?: any; sortOrderBy?: any; supplierId?: any; userId?: number; paymentTypeId?: number; clientType?: string }) => void;
    isMuliData?: boolean;
    // onSearchClick: (selectedData: { accountTypeId?: any; accountCodeOrder?: any; bankId?: any; sortOrderBy?: any; supplierId?: any; userId?: number; paymentTypeId?:number; }) => void;
}

const SelectBoxFilterComp: React.FC<SelectBoxFilterCProp> = ({
    optiondata,
    onSearchClick,
    isMuliData,
}) => {


    const [selectedData, setSelectedData] = useState({
        accountTypeId: undefined,
        accountCodeOrder: undefined,
        officeBankAccId: undefined,
        oASupplierId: undefined,
        clientBankAccId: undefined,
        userId: undefined,
        paymentTypeId: undefined,
        toLedgerId: undefined,
        fromLedgerId: undefined,
        undertakeType: undefined,
        statusId: undefined,
        caseStatus: undefined,
        clientId: undefined,
        contactCategoryId: undefined,
        status: undefined,
        clientType: undefined,
        
    });



    const handleOptionChange = (index: number, selectedOption: any) => {
        const updatedData = { ...selectedData };

        optiondata?.forEach((data, i) => {
            if (i === index) {
                if (data.name === "Account Type") {
                    updatedData.accountTypeId = selectedOption;
                } else if (data.name === "Account Code Order By") {
                    updatedData.accountCodeOrder = selectedOption;
                } else if (data.name === "Office Account") {
                    updatedData.officeBankAccId = selectedOption;
                } else if (data.name === "Supplier") {
                    updatedData.oASupplierId = selectedOption;
                } else if (data.name === "Client Bank") {
                    updatedData.clientBankAccId = selectedOption;
                }
                else if (data.name === "Staff") {
                    updatedData.userId = selectedOption;
                }
                else if (data.name === "Pyment Type") {
                    updatedData.paymentTypeId = selectedOption;
                }
                else if (data.name === "To Ledger") {
                    updatedData.toLedgerId = selectedOption;
                }
                else if (data.name === "From Ledger") {
                    updatedData.fromLedgerId = selectedOption;
                }else if (data.name === "Client Type") {
                    updatedData.clientType = selectedOption;
                }else if (data.name === "Contact Type") {
                    updatedData.contactCategoryId = selectedOption;
                }
                else if (data.name === "Undertaking Type") {
                    updatedData.undertakeType = selectedOption;
                }
                else if (data.name === "Status") {
                    updatedData.statusId = selectedOption;
                }
                else if (data.name === "Case Status") {
                    updatedData.caseStatus = selectedOption;
                }else if (data.name === "CaseManagement Status") {
                    updatedData.status = selectedOption;
                }
            }
        });

        // Filter out undefined values from updatedData
        const filteredData = Object.entries(updatedData)
            .filter(([_, value]) => value !== undefined)
            .reduce((obj, [key, value]) => {
                (obj as any)[key] = value;
                return obj;
            }, {} as Partial<typeof updatedData>);

        setSelectedData(updatedData);
        onSearchClick(filteredData);
    };




    return (
        <div className="flex flex-row gap-x-1 md:gap-1 relative">
            {optiondata !== null && optiondata?.map((options: any, index) => (
                <div key={index} className="w-[200px]">
                    <SelectBox
                    className="bg-blue-500"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={isMuliData === true ? true : false}
                        name={options.name}
                        options={options.options}
                        isSearchable={true}
                        placeholder={options.name}
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(option) => handleOptionChange(index, option)}
                    />
                </div>
            ))}
        </div>
    );
};

export { SelectBoxFilterComp };