import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { fetchAllAgedCreditorsReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/AgedCreditorsReportSlice";
import { SelectBox } from "../SelectBox";
import { toast } from "react-toastify";
import React from "react";
import { Pagination } from "../Pagination";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function AgedCreditorsReport() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const agedCreditorsReports = useSelector(
    (state: RootState) => state.agedCreditorsReport.agedCreditorsReports
  );
  const [totalPages, setTotalPages] = useState(10);
  const [currentAgedCreditorsReport, setCurrentAgedCreditorsReport] = useState<
    any[]
  >([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [dateData, setDateData] = useState({ asDate: new Date().toISOString().split("T")[0] });
  const [userId, setUserId] = useState<any>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
  const officeSupplierData = useSelector((state: RootState) => state.officeSupplier.officeSupplierData);
  const [supplierLists, setSupplierLists] = useState<any[]>([]);

  const periods = [
    { value: "1-30 Days", label: "1-30 Days" },
    { value: "31-60 Days", label: "31-60 Days" },
    { value: "61-90 Days", label: "61-90 Days" },
    { value: "90 Days", label: "90 Days" },
  ];

  useEffect(() => {
    if (officeSupplierData !== null && officeSupplierData?.listingData.length > 0) {
      let data = officeSupplierData?.listingData.map((branchUser: any, index) => ({
        value: branchUser.supplierId,
        label: branchUser.supplierName,
      }));
      setSupplierLists(data);
    }
  }, [officeSupplierData]);

  useEffect(() => {
    if (agedCreditorsReports != null && agedCreditorsReports?.agedCreditorsData?.length > 0) {
      // Flatten all agedCreditors arrays from agedCreditorsData
      const allAgedCreditors = agedCreditorsReports.agedCreditorsData.flatMap(data => data.agedCreditors);
  
      if (allAgedCreditors.length > 0) {
        const totalPages = Math.ceil(allAgedCreditors.length / itemsPerPage);
        setTotalPages(totalPages);
        setCurrentAgedCreditorsReport(allAgedCreditors);
      }
    }
  }, [agedCreditorsReports]);

  const handleDateChange = (e: any) => {
    setDateData((prev) => ({ ...prev, asDate: e.target.value }));
  };

  const handleSearchClick = async () => {
    setInitialLoading(true);
    const data = {
      period: selectedPeriod,
      asDate: dateData.asDate,
      supplierId: userId
    };
    try {
      await dispatch(fetchAllAgedCreditorsReport(data)).unwrap();
      toast.success("Successfully Search AgedCreditor");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };
  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Aged Creditors Report" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col  my-5 gap-4 w-full md:gap-3">
          <div>
            <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <input
                type="date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                max={new Date().toISOString().split("T")[0]}
                defaultValue={dateData.asDate}
                onChange={handleDateChange}
              />
              <div className="">
                <SelectBox
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  options={supplierLists}
                  isSearchable={true}
                  placeholder="Supplier"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setUserId(selectedOption);
                    }
                  }}
                />
              </div>
              <div className="">
                <SelectBox
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  options={periods}
                  isSearchable={true}
                  placeholder="Period"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setSelectedPeriod(selectedOption);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              onClick={handleSearchClick}
              type="button"
            >
              Search
            </button>
          </div>
        </div>

        
        <div className="space-y-2">
          <div className="flex items-center">
            <label className="w-1/5 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
              As Date
            </label>
            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
              :
            </span>
            <p className="w-4/5 text-lg 3xl:text-sm dark:text-white-bg">{formatDateOnlyDisplay(agedCreditorsReports?.agedCreditorSearchData?.asDate)}</p>
          </div>
          <div className="flex items-center">
            <label className="w-1/5 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
              Supplier
            </label>
            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
              :
            </span>
            <p className="w-4/5 text-lg 3xl:text-sm dark:text-white-bg">{agedCreditorsReports?.agedCreditorSearchData?.supplier}</p>
          </div>
          <div className="flex items-center">
            <label className="w-1/5 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
              Period
            </label>
            <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
              :
            </span>
            <p className="w-4/5 text-lg 3xl:text-sm dark:text-white-bg">{agedCreditorsReports?.agedCreditorSearchData?.period}</p>
          </div>
        </div>
        {initialLoading ? <MainAnimation /> : ""}
        <div className="relative overflow-x-auto sm:rounded-lg my-5">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">            
            <thead className="text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Supplier
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Bill
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Due Amount
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Due Days
                </th>
              </tr>
            </thead>
            <tbody>
              {currentAgedCreditorsReport.length > 0 ? (
                <>
                  {currentAgedCreditorsReport.map(
                    (agedCreditorsReport: any, index) => (
                      <tr
                        key={index}
                        className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {agedCreditorsReport.supplierName}
                        </td>
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{agedCreditorsReport.bill}</td>
                        <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          {agedCreditorsReport.dueAmount}
                        </td>
                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{agedCreditorsReport.dueDays}</td>
                      </tr>
                    )
                  )}
                </>
              ) : (
                <tr>
                  <React.Fragment>
                    {agedCreditorsReports?.agedCreditorSearchData?.period === "All" ? (
                      <>
                        <td
                          className="border border-gray-300 px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-center col-span-4"
                          colSpan={4}
                        >
                          1-30 Days
                        </td>
                        <td
                          className="border border-gray-300 px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-center col-span-4"
                          colSpan={4}
                        >
                          31-60 Days
                        </td>
                        <td
                          className="border border-gray-300 px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-center col-span-4"
                          colSpan={4}
                        >
                          61-90 Days
                        </td>
                        <td
                          className="border border-gray-300 px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-center col-span-4"
                          colSpan={4}
                        >
                          90 Days
                        </td>

                      </>
                    ) : (
                      <td
                        className="border border-gray-300 px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-center col-span-4"
                        colSpan={4}
                      >
                        {agedCreditorsReports?.agedCreditorSearchData?.period}
                      </td>
                    )}
                  </React.Fragment>
                </tr>
              )}
            </tbody>
          </table >
        </div >
        {
          currentAgedCreditorsReport.length > 0 && (
            <div className="w-full flex flex-col pb-[100px]">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                totalRecords={currentAgedCreditorsReport.length}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )
        }
      </div >
    </>
  );
}

export { AgedCreditorsReport };
