import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllInvoiceStatusType = async () => {
  try {
    const response = await axiosInstance.get("invoice/statustype");
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
};

const InvoiceStatusTypeService = {
    GetAllInvoiceStatusType,
};

export default InvoiceStatusTypeService;
