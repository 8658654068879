import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { fetchAllBankReconReports } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { SelectBox } from "../SelectBox";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function ReportsBankReconcilliation() {
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(false);
    const [staffId, setStaffId] = useState<any>(null);
    const [selectedMonth, setSelectedMonth] = useState<any>(null);
    const [selectedYear, setSelectedYear] = useState<any>(null);
    const [bankId, setBankId] = useState<any>(null);
    const [branchUserLists, setBranchUserLists] = useState<any[]>([]);
    const { branchUsers } = useSelector((state: RootState) => state.userManage);
    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
    const [bankReconReportsData, setBankReconReportsData] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const bankReconReports = useSelector(
        (state: RootState) => state.clientReports.bankReconReports
    );


    const handleSearchClick = async () => {
        setInitialLoading(true);
        const data = {
            staffId: staffId,
            month: selectedMonth,
            year: selectedYear,
            bankAccountId: bankId
        };
        try {
            await dispatch(fetchAllBankReconReports(data)).unwrap();
            toast.success("Successfully Search Bank Reconcilliation");
        } catch (error: any) {
            toast.error(error?.message || "An error occurred while fetching data");
        } finally {
            setInitialLoading(false);
        }
    };

    const months = [
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];

    const years = [
        { value: "2024", label: "2024" },
        { value: "2023", label: "2023" },
        { value: "2022", label: "2022" },
        { value: "2021", label: "2021" },
        { value: "2020", label: "2020" },
        { value: "2019", label: "2019" },
    ];

    useEffect(() => {
        if (branchUsers != null && branchUsers.length > 0) {
            let data = branchUsers.map((branchUser: any, index) => ({
                value: branchUser.userId,
                label: `${branchUser.firstName} ${branchUser.lastName} `,
            }));
            data.unshift({ value: 0, label: "All Staff" });
            setBranchUserLists(data);
        }
    }, [branchUsers]);
    useEffect(() => {
        if (clientAccounts !== null) {
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
            }));
            data.unshift({ value: 0, label: "All Accounts" });
            setClientAccountLists(data);
        }
    }, [clientAccounts]);

    useEffect(() => {
        if (bankReconReports !== null && bankReconReports.bankReconReportsResult !== null) {
            setBankReconReportsData(bankReconReports.bankReconReportsResult);
            setTotalRecords(bankReconReports.totalCount);
        }
    }, [bankReconReports]);

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Bank Reconcilliation" />

                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">

                    <div className="grid grid-cols-4 gap-x-3 md:gap-1 w-full">
                        <div className="">
                            <SelectBox
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={clientAccountLists}
                                isSearchable={true}
                                placeholder="Client Bank"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setBankId(selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="">
                            <SelectBox
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={branchUserLists}
                                isSearchable={true}
                                placeholder="Staff"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setStaffId(selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="">
                            <SelectBox
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={years}
                                isSearchable={true}
                                placeholder="Years"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setSelectedYear(selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="">
                            <SelectBox
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                options={months}
                                name="frameFiftySix"
                                isSearchable={true}
                                placeholder="Months"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setSelectedMonth(selectedOption);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                            onClick={handleSearchClick}
                            type="button"
                        >
                            Search
                        </button>
                    </div>
                </div>
                <div className="relative overflow-hidden sm:rounded-lg">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <div className="overflow-x-auto max-h-96">
                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Statement Date
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Bank
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Staff
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Report
                                    </th>
                                    {/* <th scope="col" className="text-center w-12 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Action
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {bankReconReportsData.map((bankReport: any, index) => (
                                    <tr
                                        key={index}
                                        className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                    >
                                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            {formatDateOnlyDisplay(bankReport.bankStatementDate)}
                                        </td>
                                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            <p>{bankReport.accountName}</p>
                                        </td>
                                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{bankReport.paymefirstNamentType} {" "}
                                            {bankReport.lastName}</td>
                                        <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{bankReport.reportName}</td>

                                        {/* <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            <div className="flex gap-3 w-full">
                                                <FaEye
                                                    className="fill-gray-500 w-6 h-6"
                                                    onClick={() => {
                                                        setOpenViewDrawer(true);
                                                        setbankReportData(bankReport);
                                                    }}
                                                />
                                                <FaEdit
                                                    className="fill-blue-500 w-6 h-6"
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                        setIsEdit(true);
                                                        setIsView(false);
                                                        setbankReportData(bankReport);
                                                    }}
                                                />
                                                <RiDeleteBin5Fill
                                                    className="fill-red-600 w-6 h-6"
                                                    onClick={() => {
                                                        setOpenDelModal(true);
                                                        setbankReportId(bankReport.clientReceiptId);
                                                    }}
                                                />
                                            </div>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export { ReportsBankReconcilliation };