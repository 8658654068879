import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { SelectBox } from "../SelectBox";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { GetAllAreasOfPractice } from "../../store/slice/Administrator/AreasOfPracticeSlice";
import { fetchAllCaseSubType } from "../../store/slice/SystemMaintenance/CaseSubTypeSlice";
import { fetchAllCaseType } from "../../store/slice/SystemMaintenance/CaseTypeSlice";

interface CaseTypeFilterProps {
  onFilterSubmit: (filterValues: {areasOfPracticeId?: number;caseTypeId?: number; caseSubTypeId?: number}) => void;
}

const CaseTypeFilter: React.FC<CaseTypeFilterProps> = ({ onFilterSubmit }) => {
  const { areasOfPractice } = useSelector(
    (state: RootState) => state.areasOfPractice
  );
  const { casetypes } = useSelector((state: RootState) => state.caseType);
  const { CaseSubTypes } = useSelector((state: RootState) => state.caseSubType);
  const dispatch = useAppDispatch();
  const [filteredCaseTypes, setFilteredCaseTypes] = useState<any[]>([]);
  const [filteredCaseSubTypes, setFilteredCaseSubTypes] = useState<any[]>([]);
  const [areaOptionsLists, setAreaOptionsLists] = useState<any[]>([]);
  const [caseTypeOptionsLists, setCaseTypeOptionsLists] = useState<any[]>([]);
  const [subCaseOptionsLists, setSubCaseOptionsLists] = useState<any[]>([]);
  const effectServiceCall = useRef(false);

  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(GetAllAreasOfPractice());
      dispatch(fetchAllCaseType());
      dispatch(fetchAllCaseSubType());
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  const [filterValues, setFilterValues] = useState({
    areasOfPracticeId: undefined,
    caseTypeId: undefined,
    caseSubTypeId: undefined,
  });

  useEffect(() => {
    if (areasOfPractice.length > 0) {
      const data = areasOfPractice.map((area: any) => ({
        value: area.areasOfPracticeId,
        label: area.area,
      }));
      data.unshift({ value: 0, label: "Select" });
      setAreaOptionsLists(data);
    }
  }, [areasOfPractice]);

  useEffect(() => {
    if (filteredCaseTypes.length > 0) {
      const data = filteredCaseTypes.map((casetype: any) => ({
        value: casetype.caseTypeId,
        label: casetype.caseType,
      }));
      data.unshift({ value: 0, label: "Select" });
      setCaseTypeOptionsLists(data);
    }
  }, [filteredCaseTypes]);

  useEffect(() => {
    if (filteredCaseSubTypes.length > 0) {
      const data = filteredCaseSubTypes.map((subcase: any) => ({
        value: subcase.caseSubTypeId,
        label: subcase.subCaseType,
      }));
      data.unshift({ value: 0, label: "Select" });
      setSubCaseOptionsLists(data);
    }
  }, [filteredCaseSubTypes]);

  const handleAreaChange = (selectedOption: any) => {
    const updatedValues = {
      areasOfPracticeId: selectedOption,
      caseTypeId: undefined,
      caseSubTypeId: undefined,
    };
    setFilterValues(updatedValues);
    onFilterSubmit(updatedValues);
    setFilteredCaseTypes(
      casetypes.filter(
        (type: any) => type.fkAreasOfPracticeId === selectedOption
      )
    );
    setFilteredCaseSubTypes([]);
  };

  const handleCaseTypeChange = (selectedOption: any) => {
    const updatedValues = {
      ...filterValues,
      caseTypeId: selectedOption,
      caseSubTypeId: undefined,
    };
    setFilterValues(updatedValues);
    onFilterSubmit(updatedValues);
    setFilteredCaseSubTypes(
      CaseSubTypes.filter(
        (subType: any) => subType.fkCaseTypeId === selectedOption
      )
    );
  };

  const handleSubCaseTypeChange = (selectedOption: any) => {
    const updatedValues = {
      ...filterValues,
      caseSubTypeId: selectedOption,
    };
    setFilterValues(updatedValues);
    onFilterSubmit(updatedValues);
  };

  return (
    <div className="flex gap-x-1 md:gap-1 w-full relative">
      <div className="w-full">
        <SelectBox
          className="bg-gray-100 w-[200px] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block"
          placeholderClassName="text-blue_gray-500"
          indicator={
            <svg
              width="15"
              className="fill-current mr-2"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          }
          isMulti={false}
          name="areaOptionList"
          color="indigo_50"
          options={areaOptionsLists}
          value={filterValues.areasOfPracticeId}
          placeholder="Area of Practice"
          onChange={handleAreaChange}
        />
      </div>
      <div className="w-full">
        <SelectBox
          className="bg-gray-100  w-[200px] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block"
          placeholderClassName="text-blue_gray-500"
          indicator={
            <svg
              width="15"
              className="fill-current mr-2"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          }
          isMulti={false}
          name="caseTypeOptionList"
          color="indigo_50"
          options={caseTypeOptionsLists}
          value={filterValues.caseTypeId}
          placeholder="Case Type"
          onChange={handleCaseTypeChange}
        />
      </div>
      <div className="w-full">
        <SelectBox
          className="bg-gray-100 w-[200px] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block"
          placeholderClassName="text-blue_gray-500"
          indicator={
            <svg
              width="15"
              className="fill-current mr-2"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          }
          isMulti={false}
          name="subCaseTypeOptionList"
          color="indigo_50"
          options={subCaseOptionsLists}
          value={filterValues.caseSubTypeId}
          placeholder="Case Sub Type"
          onChange={handleSubCaseTypeChange}
        />
      </div>
    </div>
  );
};

export { CaseTypeFilter };
