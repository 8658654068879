import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllIncomingPost = async (data: any) => {
    try {
        const response = await axiosInstance.post("postregister/incoming/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostIncomingPost = async (data: any) => {
    try {
        const response = await axiosInstance.post("postregister/incoming", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutIncomingPost = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`postregister/incoming/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteIncomingPost = async (id: number) => {
    try {
        await axiosInstance.delete(`/postregister/Incoming/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const IncomingPostService = {
    GetAllIncomingPost,
    PostIncomingPost,
    PutIncomingPost,
    deleteIncomingPost
};


export default IncomingPostService;
