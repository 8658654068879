import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import ExpertRegisterService from "../../../../services/OfficeManagement/OfficeInformation/ExpertRegisterService";
import { number, string } from "yup";

export interface ExpertRegister {
  expertType: string;
  expertPersonal: IndividualExpert | null;
  expertCompany: CompanyExpert | null;
}

export type IndividualExpert = {
  logo: string;
  expertRegisterId: number;
  fkTitleId: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  postCode: string;
  champeremail: string;
  IndividualPhone1: number;
  chamberName: string;
  addressLine1: string;
  addressLine2: string;
  champercity: string;
  champercounty: string;
  dx: string;
  website: string;
  email: string;
  Phone1: number;
  detail: string;
  areasOfPracticeIds: [];
  finaldetails: string;
  feedetails: string;
  notes: string;
};
export type CompanyExpert = {
  expertRegisterId: number;
  address1: string;
  address2: string;
  city: string;
  county: string;
  postCode: string;
  champeremail: string;
  IndividualPhone1: number;
  chamberName: string;
  addressLine1: string;
  addressLine2: string;
  champercity: string;
  champercounty: string;
  dx: string;
  website: string;
  email: string;
  Phone1: number;
  detail: string;
  areasOfPracticeIds: [];
  finaldetails: string;
  feedetails: string;
  notes: string;
};

export interface ExpertRegistersList {
  expertRegisterList: any[];
  totalRows: number;
}

export interface ExpertRegisterByIdData {
  expertType: string;
  fkTitleId: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  postCode: string;
  champeremail: string;
  IndividualPhone1: number;
  chamberName: string;
  addressLine1: string;
  addressLine2: string;
  champercity: string;
  champercounty: string;
  dx: string;
  website: string;
  email: string;
  Phone1: number;
  detail: string;
  areasOfPracticeIds: [];
  finaldetails: string;
  feedetails: string;
  notes: string;
  country: string;
}

// Define the state type for Checklist
type ExpertRegisterState = {
  expertRegisters: PaginationResponse<ExpertRegister> | null;
  expertRegsterByIdData: ExpertRegisterByIdData | null;
  expertRegistersLists: ExpertRegistersList | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

// Initial state
const initialState: ExpertRegisterState = {
  expertRegisters: null,
  expertRegistersLists: null,
  expertRegsterByIdData: null!,
  status: "idle",
  error: null,
};

// Async thunks for CRUD operations
export const fetchAllExpertRegister = createAsyncThunk(
  "fetchAllExpertRegister",
  async (data: any, thunkAPI) => {
    try {
      const response = await ExpertRegisterService.GetAllExpertRegister(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchExpertRegisterById = createAsyncThunk(
  "fetchExpertRegisterById",
  async (id: number, thunkAPI) => {
    try {
      const response = await ExpertRegisterService.GetExpertRegisterById(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addExpertRegister = createAsyncThunk(
  "addExpertRegister",
  async (data: any, thunkAPI) => {
    try {
      const response = await ExpertRegisterService.PostExpertRegister(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateExpertRegister = createAsyncThunk(
  "updateExpertRegister",
  async (data: any, thunkAPI) => {
    try {
      const response = await ExpertRegisterService.PutExpertRegister(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteExpertRegister = createAsyncThunk(
  "deleteExpertRegister",
  async (id: number, thunkAPI) => {
    try {
      await ExpertRegisterService.deleteExpertRegister(id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice definition
const ExpertRegisterSlice = createSlice({
  name: "guideToGDPR",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllExpertRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchAllExpertRegister.fulfilled,
        (state, action: PayloadAction<ExpertRegistersList>) => {
          state.status = "idle";
          state.expertRegistersLists = action.payload;
        }
      )
      .addCase(fetchAllExpertRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchExpertRegisterById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchExpertRegisterById.fulfilled,
        (state, action: PayloadAction<ExpertRegisterByIdData>) => {
          state.status = "idle";
          state.expertRegsterByIdData = action.payload;
        }
      )
      .addCase(fetchExpertRegisterById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(addExpertRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        addExpertRegister.fulfilled,
        (state, action: PayloadAction<ExpertRegister>) => {
          state.status = "idle";
          // state.guideToGDPRs.push(action.payload);
        }
      )
      .addCase(addExpertRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateExpertRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updateExpertRegister.fulfilled,
        (state, action: PayloadAction<ExpertRegister>) => {
          state.status = "idle";
          // const index = state.guideToGDPRs.findIndex(t => t.guideToGDPRId === action.payload.guideToGDPRId);
          // if (index !== -1) {
          //     state.guideToGDPRs[index] = action.payload;
          // }
        }
      )
      .addCase(updateExpertRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteExpertRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        deleteExpertRegister.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.status = "idle";
          // state.guideToGDPRs = state.guideToGDPRs.filter(t => t.guideToGDPRId !== action.payload);
        }
      )
      .addCase(deleteExpertRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default ExpertRegisterSlice.reducer;
