import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import RefundCreditNoteService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/RefundCreditNoteService";





export interface RefundCreditNote {
    refundCreditNoteId: number;
    creditNoteRefundId: number;
    creditNoteId: number;
    creditNoteRefundNumber: number;
    refundAmount: number;
    refundDate: Date;
    refundAccountId: number;
    transactionDescription: string;
    refundReason: string;
    refundMethodId: number;
    refundReference: string;
    refundNotes: string;
    invoiceNoteId: number;
    creditNoteRefundNotes: string;
    invoiceNoticeId: number;
    creditNoteRefundNotice: string;
};

export interface SearchOfficeRefundCreditNoteResult {
    creditNoteRefunds: any[];
    currentPage: number;
    recordPerPage: number;
    totalRows: number

};

// Define the state type for Checklist
type RefundCreditNoteState = {
    refundCreditNotes: RefundCreditNote[];
    searchOfficeRefundCreditNoteResult: SearchOfficeRefundCreditNoteResult;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: RefundCreditNoteState = {
    searchOfficeRefundCreditNoteResult: null!,
    refundCreditNotes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllRefundCreditNote = createAsyncThunk(
    "fetchAllRefundCreditNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await RefundCreditNoteService.GetAllRefundCreditNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addRefundCreditNote = createAsyncThunk(
    "addRefundCreditNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await RefundCreditNoteService.PostRefundCreditNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateRefundCreditNote = createAsyncThunk(
    "updateRefundCreditNote",
    async ({ id, data }: { id: number, data: RefundCreditNote }, thunkAPI) => {
        try {
            const response = await RefundCreditNoteService.PutRefundCreditNote(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteRefundCreditNote = createAsyncThunk(
    "deleteRefundCreditNote",
    async (id: number, thunkAPI) => {
        try {
            await RefundCreditNoteService.deleteRefundCreditNote(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const RefundCreditNoteSlice = createSlice({
    name: "refundCreditNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRefundCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllRefundCreditNote.fulfilled, (state, action: PayloadAction<SearchOfficeRefundCreditNoteResult>) => {
                state.status = "idle";
                state.searchOfficeRefundCreditNoteResult = action.payload;
            })
            .addCase(fetchAllRefundCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addRefundCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addRefundCreditNote.fulfilled, (state, action: PayloadAction<RefundCreditNote>) => {
                state.status = "idle";
                state.refundCreditNotes.push(action.payload);
            })
            .addCase(addRefundCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateRefundCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateRefundCreditNote.fulfilled, (state, action: PayloadAction<RefundCreditNote>) => {
                state.status = "idle";
                const index = state.refundCreditNotes.findIndex(t => t.refundCreditNoteId === action.payload.refundCreditNoteId);
                if (index !== -1) {
                    state.refundCreditNotes[index] = action.payload;
                }
            })
            .addCase(updateRefundCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteRefundCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteRefundCreditNote.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.refundCreditNotes = state.refundCreditNotes.filter(t => t.refundCreditNoteId !== action.payload);
            })
            .addCase(deleteRefundCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default RefundCreditNoteSlice.reducer;
