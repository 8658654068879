import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllExpertRegister = async (data: any) => {
    try {
        const response = await axiosInstance.post("expertRegister/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
};

const GetExpertRegisterById = async (id: number) => {
    try {
      const response = await axiosInstance.get(`expertRegister/${id}`);
      return response.data;
    } catch (error) {
      let data = error as ErrorResult;
      throw new Error(data.messages.toString());
    }
  }

const PostExpertRegister = async (data: any) => {
    try {
        const response = await axiosInstance.post("expertRegister/add", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
};

const PutExpertRegister = async (data: any) => {
    try {
        const response = await axiosInstance.put("ExpertRegister");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteExpertRegister = async (id: number) => {
    try {
        await axiosInstance.delete(`/ExpertRegister/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ExpertRegisterService = {
    GetAllExpertRegister,
    GetExpertRegisterById,
    PostExpertRegister,
    PutExpertRegister,
    deleteExpertRegister
};


export default ExpertRegisterService;
