import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllOfficeJournalEntry = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAJournalTransactionsSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetOfficeJournalEntryById = async (id: number) => {
    try {
        const response = await axiosInstance.get("OAJournalEntry/byid/" + id);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostOfficeJournalEntry = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAJournalSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeJournalEntry = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeJournalEntry/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeJournalEntry = async (id: number) => {
    try {
        await axiosInstance.delete(`OAJournal/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeJournalEntryService = {
    GetAllOfficeJournalEntry,
    PostOfficeJournalEntry,
    PutOfficeJournalEntry,
    deleteOfficeJournalEntry,
    GetOfficeJournalEntryById
};


export default OfficeJournalEntryService;
