import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllTrainingRecord = async (data: any) => {
    try {
        const response = await axiosInstance.post("TrainingRecords/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostTrainingRecord = async (data: any) => {
    try {
        const response = await axiosInstance.post("TrainingRecords", data,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutTrainingRecord = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`TrainingRecords/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteTrainingRecord = async (id: number) => {
    try {
        await axiosInstance.delete(`/TrainingRecords/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TrainingRecordService = {
    GetAllTrainingRecord,
    PostTrainingRecord,
    PutTrainingRecord,
    deleteTrainingRecord
};


export default TrainingRecordService;
