import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import DocumentSubCategoryService from "../../../services/SystemMaintenance/DocumentSubCategoryService";

export interface DocumentSubCategory {
    documentSubCategoryId: number;
    documentSubCategory: string;
    fkDocumentCategoryId: number;
    createdBy: string;
    createdOn: string;
    deletedBy: number;
    isDeleted: boolean;
    isActive: boolean;
    deletedOn: string;
};

// Define the state type for DocumentSubCategory
type DocumentSubCategoryState = {
    documentSubCategorys: DocumentSubCategory[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: DocumentSubCategoryState = {
    documentSubCategorys: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllDocumentSubCategory = createAsyncThunk(
    "fetchAllDocumentSubCategory",
    async (_, thunkAPI) => {
        try {
            const response = await DocumentSubCategoryService.GetAllDocumentSubCategory();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addDocumentSubCategory = createAsyncThunk(
    "addDocumentSubCategory",
    async (data: any, thunkAPI) => {
        try {
            const response = await DocumentSubCategoryService.PostDocumentSubCategory(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateDocumentSubCategory = createAsyncThunk(
    "updateDocumentSubCategory",
    async ({ id, data }: { id: number, data: DocumentSubCategory }, thunkAPI) => {
        try {
            const response = await DocumentSubCategoryService.PutDocumentSubCategory(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteDocumentSubCategory = createAsyncThunk(
    "deleteDocumentSubCategory",
    async (DocumentSubCategoryId: number, thunkAPI) => {
        try {
            await DocumentSubCategoryService.DeleteDocumentSubCategory(DocumentSubCategoryId);
            return DocumentSubCategoryId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const DocumentSubCategorySlice = createSlice({
    name: "documentSubCategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllDocumentSubCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllDocumentSubCategory.fulfilled, (state, action: PayloadAction<DocumentSubCategory[]>) => {
                state.status = "idle";
                state.documentSubCategorys = action.payload;
            })
            .addCase(fetchAllDocumentSubCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addDocumentSubCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addDocumentSubCategory.fulfilled, (state, action: PayloadAction<DocumentSubCategory>) => {
                state.status = "idle";
                state.documentSubCategorys.push(action.payload);
            })
            .addCase(addDocumentSubCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateDocumentSubCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateDocumentSubCategory.fulfilled, (state, action: PayloadAction<DocumentSubCategory>) => {
                state.status = "idle";
                const index = state.documentSubCategorys.findIndex(t => t.documentSubCategoryId === action.payload.documentSubCategoryId);
                if (index !== -1) {
                    state.documentSubCategorys[index] = action.payload;
                }
            })
            .addCase(updateDocumentSubCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteDocumentSubCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteDocumentSubCategory.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.documentSubCategorys = state.documentSubCategorys.filter(t => t.documentSubCategoryId !== action.payload);
            })
            .addCase(deleteDocumentSubCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default DocumentSubCategorySlice.reducer;
